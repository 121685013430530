import { Button, ButtonLoading } from '@tovala/component-library'
import { FocusEvent, useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import {
  useAssociateUserToRetailChannel,
  useCreateUser,
} from '@tovala/browser-apis-combinedapi'
import { useForm } from 'react-hook-form'
import { useMachine } from '@xstate/react'

import { AnalyticsEvent, events } from 'analytics/events'
import { ErrorCodeMessageMapCombinedAPI } from 'types/internal'
import { formatPhone } from 'utils/general'
import { identifyRetailChannel, track } from 'utils/analytics'
import { validateNameIsNotURL } from 'utils/user'
import { wrapWithContactSupportTeam } from 'utils/errors'

import {
  AccountFormData,
  NameFormData,
  registrationMachine,
} from './machines/registrationMachine'
import { useAuth } from 'contexts/auth'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import AuthPage from './AuthPage'
import FormInputRHF from 'components/common/FormInputRHF'
import NavigationSteps, {
  NavigationStep,
  NavigationStepsMobile,
} from 'components/common/NavigationSteps'
import RevealPassword from 'components/core/RevealPassword'

const STATE_HEADER_STEPS = [
  {
    machineState: 'enteringName' as const,
    title: 'Name',
  },
  {
    machineState: 'enteringAccountDetails' as const,
    title: 'Account Details',
  },
]

const RegisterPage = ({
  onRegistrationComplete,
  onRegistrationStepChanged,
}: {
  onRegistrationComplete(): void
  onRegistrationStepChanged(newStep: number): void
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { onJWTChanged } = useAuth()

  const channel = searchParams.get('channel')
  const store = searchParams.get('store')

  const {
    error: registerError,
    isError: hasRegisterError,
    mutateAsync: register,
  } = useCreateUser()

  const { mutateAsync: associate } = useAssociateUserToRetailChannel()

  const [state, send] = useMachine(registrationMachine, {
    actions: {
      markRegistrationComplete: () => {
        onRegistrationComplete()
      },
      redirectToOrders: () => {
        navigate('/my-orders')
      },
      redirectToAppDownload: () => {
        navigate('/app-download')
      },
      removeCurrentToken: () => {
        onJWTChanged(null)
      },
      saveNewToken: (_ctx, event) => {
        onJWTChanged(event.data.token)
      },
      trackDoneName: () => {
        track(events.USER_REGISTER_DONE_NAME)
      },
      trackRegistrationFailed: () => {
        // Pass
      },
      trackSubmitRegistration: () => {
        track(events.USER_SUBMIT_REGISTRATION)
      },
      trackUserRegistered: () => {
        track(events.USER_REGISTERED, {
          category: 'User',
        })
      },
      trackUserAsscoiatedToRetailChannel(ctx, data) {
        identifyRetailChannel({
          location: ctx.info.location ?? '',
          retailChannel: ctx.info.retailChannel ?? '',
          phoneNumber: ctx.info.phoneNumber,
          userID: data.data.user.id,
        })
      },
    },
    services: {
      submitRegistration: (ctx) => {
        return register({
          customerCreateAsync: false,
          data: {
            email: ctx.info.email,
            name: `${ctx.info.firstName} ${ctx.info.lastName}`,
            password: ctx.info.password,
          },
        })
      },
      submitAccountAssociation: (ctx, data) => {
        return associate({
          userID: data.data.user.id,
          data: {
            channel: ctx.info.retailChannel ?? '',
            location: ctx.info.location ?? '',
            phone: ctx.info.phoneNumber ?? '',
          },
        })
      },
    },
  })

  let currentStepIndex = STATE_HEADER_STEPS.findIndex(({ machineState }) =>
    state.matches(machineState)
  )
  if (currentStepIndex === -1) {
    currentStepIndex = STATE_HEADER_STEPS.length - 1
  }

  useEffect(() => {
    if (currentStepIndex !== -1) {
      onRegistrationStepChanged(currentStepIndex + 1)
    }
  }, [currentStepIndex, onRegistrationStepChanged])

  return (
    <AuthPage
      headerContent={
        // This is absolutely positioned to be centered even though we have a left logo.
        <nav className="absolute inset-0 flex h-full w-full justify-center md:hidden">
          <NavigationSteps>
            {STATE_HEADER_STEPS.map(({ machineState, title }, i) => {
              return (
                <NavigationStep
                  key={machineState}
                  isActive={state.matches(machineState)}
                  isFuture={i > currentStepIndex}
                  isLastStep={i === STATE_HEADER_STEPS.length - 1}
                >
                  {title}
                </NavigationStep>
              )
            })}
          </NavigationSteps>
        </nav>
      }
      subHeader={
        <div className="hidden md:block">
          <NavigationStepsMobile
            currentStep={STATE_HEADER_STEPS[currentStepIndex].title}
            steps={STATE_HEADER_STEPS.map((_unused, i) => {
              return { isFuture: i > currentStepIndex }
            })}
          />
        </div>
      }
    >
      <div className="flex justify-center">
        <div className="flex flex-col">
          <h1 className="mb-12 text-center text-k/44_110 font-medium md:mb-10 md:text-k/32_105">
            Create Your Account
          </h1>

          {state.matches('enteringName') && (
            <AccountName
              initialValues={{
                firstName: state.context.info.firstName,
                lastName: state.context.info.lastName,
              }}
              onSubmit={(incomingData) => {
                let retailChannel: string | undefined = undefined
                let location: string | undefined = undefined
                if (channel?.toLowerCase() === 'costco') {
                  retailChannel = 'costco'

                  if (store !== null && !isNaN(+store)) {
                    location = store
                  }
                }
                const phoneNumber = incomingData.phoneNumber?.replace(/-/g, '')
                const formData = {
                  ...incomingData,
                  retailChannel,
                  location,
                  phoneNumber,
                }
                send({ formData, type: 'NAME_ENTERED' })
              }}
              showPhoneNumber={channel?.toLowerCase() === 'costco'}
            />
          )}

          {(state.matches('enteringAccountDetails') ||
            state.matches('registering') ||
            state.matches('registered')) && (
            <AccountDetails
              isRegistering={state.matches('registering')}
              onClickBack={() => {
                send({ type: 'BACK' })
              }}
              onSubmit={(formData) => {
                send({ formData, type: 'ACCOUNT_DETAILS_ENTERED' })
              }}
              registerError={hasRegisterError ? registerError : null}
            />
          )}
        </div>
      </div>
    </AuthPage>
  )
}

export default RegisterPage

const AccountName = ({
  showPhoneNumber = false,
  initialValues = {},
  onSubmit,
}: {
  showPhoneNumber: boolean
  initialValues?: Partial<NameFormData>
  onSubmit(data: NameFormData): void
}) => {
  const [phoneNumber, setPhoneNumber] = useState(initialValues.phoneNumber)
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<NameFormData>({
    defaultValues: {
      ...initialValues,
      phoneNumber,
    },
    reValidateMode: 'onSubmit',
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mx-auto w-[320px] space-y-6 md:space-y-4 sm:w-full">
        <FormInputRHF
          error={errors.firstName?.message}
          id="firstName"
          label="First Name"
          labelFor="firstName"
          {...register('firstName', {
            onBlur: (event) => {
              trackIfHasValue({
                analyticsEvent: events.USER_REGISTER_ENTERED_FIRST_NAME,
                event,
              })
            },
            required: 'Please provide your first name.',
            validate: (value) =>
              validateNameIsNotURL(value, 'Please enter a valid first name.'),
          })}
        />
        <FormInputRHF
          error={errors.lastName?.message}
          id="lastName"
          label="Last Name"
          labelFor="lastName"
          {...register('lastName', {
            onBlur: (event) => {
              trackIfHasValue({
                analyticsEvent: events.USER_REGISTER_ENTERED_LAST_NAME,
                event,
              })
            },
            required: 'Please provide your last name.',
            validate: (value) =>
              validateNameIsNotURL(value, 'Please enter a valid last name.'),
          })}
        />
        {showPhoneNumber && (
          <>
            <FormInputRHF
              error={errors.phoneNumber?.message}
              id="phoneNumber"
              label="Phone Number (optional)"
              labelFor="phoneNumber"
              type="tel"
              value={phoneNumber || ''}
              {...register('phoneNumber', {
                onChange: (event) => {
                  const formattedPhone = formatPhone(event.target.value)
                  setPhoneNumber(formattedPhone)
                },
                validate: (value) =>
                  value && value.length < 10
                    ? 'Please enter a valid phone number.'
                    : undefined,
              })}
            />
            <span className="text-k/14_120 text-grey-9">
              Get post-purchase support from Tovala via text
            </span>
          </>
        )}
      </div>

      <div className="mt-8 flex justify-center sm:mt-6">
        <Button size="large" type="submit">
          Next
        </Button>
      </div>

      <div className="mt-4 space-y-4 text-center text-k/14_120 sm:mt-6">
        <div className="space-x-3 text-grey-9">
          <span>Have an account?</span>
          <Link className="text-orange-1 underline" to="/login">
            Sign in here
          </Link>
        </div>
      </div>
    </form>
  )
}

const REGISTER_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  EmailInvalid: {
    helpToFix: 'Please check your email and try again.',
    why: "We couldn't create a new account for you because your email is invalid.",
  },
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't create a new account for you due to a technical issue on our end.",
  },
  PasswordTooShort: {
    helpToFix: 'Please enter at least 8 characters.',
    why: 'Your password is too short.',
  },
  UniqueConstraint: {
    helpToFix: (
      <span>
        If this is your email, please{' '}
        <Link className="underline" to="/login">
          log in here
        </Link>
        .
      </span>
    ),
    wayOut: wrapWithContactSupportTeam('If you need further assistance'),
    why: "We couldn't create a new account for you because this email is already in use.",
  },
}

const AccountDetails = ({
  isRegistering,
  onClickBack,
  onSubmit,
  registerError,
}: {
  isRegistering: boolean
  onClickBack(): void
  onSubmit(data: AccountFormData): void
  registerError: Error | null
}) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<AccountFormData & { passwordConfirm: string }>({
    reValidateMode: 'onSubmit',
  })

  const [revealNewPassword, setRevealNewPassword] = useState(false)
  const [revealConfirmPassword, setRevealConfirmPassword] = useState(false)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mx-auto w-[320px] space-y-6 md:space-y-4 sm:w-full">
        <FormInputRHF
          error={errors.email?.message}
          id="email"
          label="Email"
          labelFor="email"
          type="email"
          {...register('email', {
            onBlur: (event) => {
              trackIfHasValue({
                analyticsEvent: events.USER_REGISTER_ENTERED_EMAIL,
                event,
              })
            },
            required: 'Please provide your email.',
          })}
        />
        <FormInputRHF
          autoComplete="new-password"
          error={errors.password?.message}
          id="password"
          label="Password"
          labelFor="password"
          rightIcon={
            <RevealPassword
              revealPassword={revealNewPassword}
              setRevealPassword={setRevealNewPassword}
            />
          }
          type={revealNewPassword ? 'text' : 'password'}
          {...register('password', {
            minLength: {
              value: 8,
              message: 'Password must be at least eight characters.',
            },
            onBlur: (event) => {
              trackIfHasValue({
                analyticsEvent: events.USER_REGISTER_ENTERED_PASSWORD,
                event,
              })
            },
            required: 'Please provide a password.',
          })}
        />
        <FormInputRHF
          error={errors.passwordConfirm?.message}
          id="passwordConfirm"
          label="Confirm Password"
          labelFor="passwordConfirm"
          rightIcon={
            <RevealPassword
              revealPassword={revealConfirmPassword}
              setRevealPassword={setRevealConfirmPassword}
            />
          }
          type={revealConfirmPassword ? 'text' : 'password'}
          {...register('passwordConfirm', {
            onBlur: (event) => {
              trackIfHasValue({
                analyticsEvent: events.USER_REGISTER_ENTERED_CONFIRM_PASSWORD,
                event,
              })
            },
            required: 'Please confirm your password.',
            validate: (value, formValues) =>
              formValues.password && value !== formValues.password
                ? 'Passwords do not match.'
                : undefined,
          })}
        />
        <span className="text-k/14_120 text-grey-9 ">
          Eight character minimum
        </span>

        {registerError && (
          <APIErrorDisplay
            error={registerError}
            errorCodeMessageMap={REGISTER_ERRORS}
          />
        )}
      </div>

      <div className="mt-8 flex flex-row-reverse justify-center gap-2 sm:mt-6">
        <ButtonLoading isLoading={isRegistering} size="large" type="submit">
          Create Account
        </ButtonLoading>
        <Button buttonStyle="stroke" onClick={onClickBack} size="large">
          Back
        </Button>
      </div>

      <div className="mt-4 space-y-4 text-center text-k/14_120 sm:mt-6">
        <p className="text-grey-9">
          By continuing, I accept the{' '}
          <Link
            className="text-black underline"
            target="_blank"
            to="/legal/terms-of-service"
          >
            Tovala Terms
          </Link>{' '}
          and{' '}
          <Link
            className="text-black underline"
            target="_blank"
            to="/legal/privacy-policy"
          >
            Privacy Policy
          </Link>
          .
        </p>
        <div className="space-x-3 text-grey-9">
          <span>Have an account?</span>
          <Link className="text-orange-1 underline" to="/login">
            Sign in here
          </Link>
        </div>
      </div>
    </form>
  )
}

function trackIfHasValue({
  analyticsEvent,
  event,
}: {
  analyticsEvent: AnalyticsEvent
  event: FocusEvent<HTMLInputElement>
}) {
  if (event.target.value) {
    track(analyticsEvent)
  }
}
