import { cloneDeep } from 'lodash-es'
import {
  MealFilter,
  MealSuggestions,
  MealSummary,
  TermStatus,
} from '@tovala/browser-apis-combinedapi'
import {
  useMutation,
  UseMutationOptions,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

import {
  addMealSelection,
  AddMealSelection,
  AddMealSelectionResponse,
  deleteMealSelection,
  DeleteMealSelection,
  DeleteMealSelectionResponse,
  getMealFilters,
  getMealsInFilters,
  GetMealsInFiltersResponse,
  getMealSuggestions,
  getMealSummaries,
  GetMealSummariesResponse,
  getUserMeal,
  GetUserMealResponse,
  UpdateMealSelectionOption,
  updateMealSelectionOption,
  UpdateMealSelectionOptionResponse,
} from 'services/combinedAPI/meals'
import { isCombinedAPIResponseError } from 'utils/api'
import { minutesToMs } from 'utils/general'

import { useInvalidateTermStatuses } from './termStatus'
import { UserTerm } from 'types/internal'

export function useAddMealSelection(
  opts?: Omit<
    UseMutationOptions<AddMealSelectionResponse, Error, AddMealSelection>,
    'mutationFn'
  >
) {
  const { invalidateUserTermStatuses } = useInvalidateTermStatuses()

  return useMutation({
    ...opts,
    mutationFn: ({ data, userID }) => {
      return addMealSelection({ data, userID })
    },
    onError: (...args) => {
      const err = args[0]
      const { userID } = args[1]

      if (
        isCombinedAPIResponseError(err) &&
        err.response?.data.message === 'MealSoldOut'
      ) {
        invalidateUserTermStatuses(userID)
      }

      opts?.onError?.(...args)
    },
    onSuccess: (...args) => {
      invalidateUserTermStatuses(args[1].userID, {
        newTermStatus: args[0].termStatus,
      })

      opts?.onSuccess?.(...args)
    },
  })
}

export function useDeleteMealSelection(
  opts?: Omit<
    UseMutationOptions<
      DeleteMealSelectionResponse,
      Error,
      DeleteMealSelection & {
        termID: number
      }
    >,
    'mutationFn'
  >
) {
  const { invalidateUserTermStatuses } = useInvalidateTermStatuses()

  return useMutation({
    ...opts,
    mutationFn: ({ selectionID, userID }) => {
      return deleteMealSelection({ selectionID, userID })
    },
    onSuccess: (...args) => {
      const { mealSelections, mealSelectionsPricing, subscriptionPricing } =
        args[0]
      const { termID, userID } = args[1]

      invalidateUserTermStatuses(userID, {
        newTermStatusData: {
          mealSelections,
          mealSelectionsPricing,
          subscriptionPricing,
          termID,
        },
      })

      opts?.onSuccess?.(...args)
    },
  })
}

export function useGetMealFilters(
  opts?: Omit<
    UseQueryOptions<MealFilter[], Error>,
    'queryFn' | 'queryKey' | 'staleTime'
  >
) {
  return useQuery<MealFilter[], Error>({
    ...opts,
    queryFn: () => {
      return getMealFilters()
    },
    queryKey: ['meal-filters'],
    staleTime: minutesToMs(10),
  })
}

export function useMealsInFilters({
  subTermID,
  ...rest
}: {
  subTermID: string | undefined
} & Omit<
  UseQueryOptions<GetMealsInFiltersResponse, Error>,
  'enabled' | 'queryFn' | 'queryKey' | 'staleTime'
>) {
  return useQuery<GetMealsInFiltersResponse, Error>({
    ...rest,
    enabled: !!subTermID,
    queryFn: () => {
      if (!subTermID) {
        throw new Error(
          'Cannot get meal filters because no subTerm ID was provided'
        )
      }

      return getMealsInFilters({ subTermID })
    },
    queryKey: ['meal-filters', subTermID],
    staleTime: minutesToMs(10),
  })
}

export function useMealSugggestions({
  userID,
  ...rest
}: { userID: number | undefined } & Omit<
  UseQueryOptions<MealSuggestions, Error>,
  'enabled' | 'queryFn' | 'queryKey' | 'staleTime'
>) {
  return useQuery<MealSuggestions, Error>({
    ...rest,
    enabled: !!userID,
    queryFn: () => {
      if (!userID) {
        throw new Error(
          'Cannot get meal suggestions because no user ID was provided'
        )
      }

      return getMealSuggestions({ userID })
    },
    queryKey: ['meal-suggestions', userID],
    staleTime: minutesToMs(1),
  })
}

function getMealSummariesQueryConfig({
  subTermID,
  ...rest
}: {
  subTermID: string | undefined
} & Omit<
  UseQueryOptions<GetMealSummariesResponse, Error>,
  'enabled' | 'queryFn' | 'queryKey' | 'staleTime'
>) {
  return {
    ...rest,
    enabled: !!subTermID,
    queryFn: () => {
      if (!subTermID) {
        throw new Error(
          'Cannot get meal summaries because no subTerm ID was provided'
        )
      }

      return getMealSummaries({ subTermID })
    },
    queryKey: ['meal-summaries', subTermID],
    staleTime: minutesToMs(10),
  }
}

export function useAllMealSummaries({
  subTermIDs = [],
  ...rest
}: {
  subTermIDs: string[]
} & Omit<
  UseQueryOptions<GetMealSummariesResponse, Error>,
  'queryFn' | 'queryKey'
>) {
  return useQueries({
    ...rest,
    queries: subTermIDs.map<UseQueryOptions<GetMealSummariesResponse, Error>>(
      (subTermID) => {
        return getMealSummariesQueryConfig({ subTermID, ...rest })
      }
    ),
  })
}

export function useMealSummaries({
  subTermID,
  ...rest
}: {
  subTermID: string | undefined
} & Omit<
  UseQueryOptions<GetMealSummariesResponse, Error>,
  'enabled' | 'queryFn' | 'queryKey' | 'staleTime'
>) {
  return useQuery<GetMealSummariesResponse, Error>(
    getMealSummariesQueryConfig({ subTermID, ...rest })
  )
}

export function useUnavailableMealSelectionsForDeliveryDayChange({
  term,
}: {
  term: UserTerm
}) {
  let unavailableMealSelectionIDs: number[] = []

  const alternateSubTerm = term.subTerms.find(
    (subTerm) =>
      subTerm.subTermID !== term.selectedSubTermID && subTerm.isAvailable
  )

  const { data: mealSummaries = [] } = useMealSummaries({
    subTermID: term.selectedSubTermID,
  })

  const { data: alternateMealSummaries = [] } = useMealSummaries({
    subTermID: alternateSubTerm?.subTermID,
  })

  if (alternateSubTerm) {
    const uniqueSelectedMealIDs = Array.from(
      new Set(term.mealSelections.map((selection) => selection.mealID))
    )

    const alternateSubTermAvailableMealIDs = alternateMealSummaries
      .filter((meal) => !meal.isSoldOut)
      .map((meal) => meal.id)
    unavailableMealSelectionIDs = uniqueSelectedMealIDs.filter(
      (mealID) => !alternateSubTermAvailableMealIDs.includes(mealID)
    )
  }

  const unavailableMealSelections = unavailableMealSelectionIDs
    .map((mealID) => {
      return mealSummaries.find((meal) => meal.id === mealID)
    })
    .filter((meal): meal is MealSummary => !!meal)

  return { unavailableMealSelections }
}

export function useUserMeal({
  enabled = true,
  mealID,
  userID,
  ...rest
}: {
  enabled?: boolean
  mealID: number | undefined
  userID: number | undefined
} & Omit<
  UseQueryOptions<GetUserMealResponse, Error>,
  'queryFn' | 'queryKey' | 'staleTime'
>) {
  return useQuery<GetUserMealResponse, Error>({
    ...rest,
    enabled: enabled && !!mealID && !!userID,
    queryFn: () => {
      if (!mealID || !userID) {
        throw new Error(
          'Cannot get meal because no user ID or meal ID was provided'
        )
      }

      return getUserMeal({ mealID, userID })
    },
    queryKey: ['user-meal', mealID, userID],
    staleTime: minutesToMs(2),
  })
}

export function useIsNoMenuTerm({ term }: { term: UserTerm }) {
  const { data: mealSummaries } = useMealSummaries({
    subTermID: term.selectedSubTermID,
  })

  const noMealsAvailable = mealSummaries && !mealSummaries.length

  return {
    isNoMenuTerm: !!(
      term.readyForView &&
      term.specialEvent &&
      noMealsAvailable
    ),
  }
}

interface UpdateMealSelectionOptionContext {
  termStatuses: TermStatus[]
}

export function useUpdateMealSelectionOption(
  opts?: Omit<
    UseMutationOptions<
      UpdateMealSelectionOptionResponse,
      Error,
      UpdateMealSelectionOption,
      UpdateMealSelectionOptionContext
    >,
    'mutationFn'
  >
) {
  const { invalidateUserTermStatuses } = useInvalidateTermStatuses()
  const queryClient = useQueryClient()

  return useMutation({
    ...opts,
    mutationFn: ({ data, subTermID, userID }) => {
      return updateMealSelectionOption({ data, subTermID, userID })
    },
    onError: (...args) => {
      const userID = args[1].userID
      const context = args[2]

      if (context?.termStatuses) {
        queryClient.setQueryData(
          ['term-statuses', userID],
          context.termStatuses
        )
      }
    },
    onMutate: async (...args) => {
      const { data, userID, subTermID } = args[0]
      await queryClient.cancelQueries({ queryKey: ['term-statuses', userID] })

      const termStatuses = queryClient.getQueryData<TermStatus[]>([
        'term-statuses',
        userID,
      ])

      if (!termStatuses) {
        return
      }

      const newTermStatuses = cloneDeep(termStatuses)
      const termStatusToUpdateIndex = newTermStatuses.findIndex(
        (termStatus) => {
          return termStatus.selectedSubTermID === subTermID
        }
      )

      if (termStatusToUpdateIndex !== -1) {
        const termStatusToUpdate = newTermStatuses[termStatusToUpdateIndex]
        termStatusToUpdate.mealSelections =
          termStatusToUpdate.mealSelections.map((mealSelection) => {
            const updatedMealSelection = cloneDeep(mealSelection)
            if (
              data.mealOption !== null &&
              mealSelection.mealID === data.mealID
            ) {
              updatedMealSelection.mealID = Number(data.mealOption)
            } else if (
              data.currentMealOption &&
              mealSelection.mealID === Number(data.currentMealOption)
            ) {
              updatedMealSelection.mealID = data.mealID
            }
            return updatedMealSelection
          })

        queryClient.setQueryData(['term-statuses', userID], newTermStatuses)
      }

      return { termStatuses }
    },
    onSettled: (...args) => {
      const userID = args[2].userID
      invalidateUserTermStatuses(userID)

      opts?.onSettled?.(...args)
    },
  })
}
