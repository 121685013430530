import { createContext, ReactNode, useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

interface Overlays {
  setHasViewedSkipSurvey(show: boolean): void
  hasViewedSkipSurvey: boolean
  isMenuTutorialSkipMultipleOpen: boolean
  isMenuTutorialSkipSingleOpen: boolean
  setIsMenuTutorialSkipMultipleOpen(isOpen: boolean): void
  setIsMenuTutorialSkipSingleOpen(isOpen: boolean): void
  setIsReferralModalOpen(isOpen: boolean): void
  isReferralModalOpen: boolean
}

const OverlaysContext = createContext<Overlays | undefined>(undefined)

const OverlaysProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const [searchParams] = useSearchParams()

  const [hasViewedSkipSurvey, setHasViewedSkipSurvey] = useState(false)
  const [isMenuTutorialSkipMultipleOpen, setIsMenuTutorialSkipMultipleOpen] =
    useState(false)
  const [isMenuTutorialSkipSingleOpen, setIsMenuTutorialSkipSingleOpen] =
    useState(false)
  const [isReferralModalOpen, setIsReferralModalOpen] = useState(() => {
    const referralSearchParam = searchParams.get('referral')

    return !!referralSearchParam ?? false
  })

  return (
    <OverlaysContext.Provider
      value={{
        setHasViewedSkipSurvey,
        hasViewedSkipSurvey,
        isMenuTutorialSkipMultipleOpen,
        isMenuTutorialSkipSingleOpen,
        setIsMenuTutorialSkipMultipleOpen,
        setIsMenuTutorialSkipSingleOpen,
        setIsReferralModalOpen,
        isReferralModalOpen,
      }}
    >
      {children}
    </OverlaysContext.Provider>
  )
}

function useOverlays() {
  const context = useContext(OverlaysContext)
  if (context === undefined) {
    throw new Error('useOverlays must be used in an OverlaysProvider')
  }

  return context
}

export { OverlaysProvider, useOverlays }
