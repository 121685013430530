import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { Integrations } from '@sentry/tracing'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import * as Sentry from '@sentry/react'
import SentryFullStory from '@sentry/fullstory'
import { setupAPI as setupCDNAPI } from '@tovala/browser-apis-cdn'
import { setupAPI as setupCombinedAPI } from '@tovala/browser-apis-combinedapi'
import { setupAPI as setupMarketplaceAPI } from '@tovala/browser-apis-marketplace'
import { setupAPI as setupMenuDeliveryAPI } from '@tovala/browser-apis-menu-delivery'
import { StrictMode } from 'react'

import { getCookie } from 'utils/storage'
import { getEnvVar } from 'utils/env'
import { isAxiosResponseError } from 'utils/api'

import './index.css'
import 'apiRequests'
import App from './App'
import { AuthProvider } from 'contexts/auth'
import { FeaturesProvider } from 'contexts/features'
import { OverlaysProvider } from 'contexts/overlays'
import { ScreenSizeProvider } from 'contexts/screenSize'
import { ToastProvider } from 'contexts/toast'
import UncaughtErrorPage from 'components/core/UncaughtErrorPage'

Sentry.init({
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
  dsn: getEnvVar('SENTRY_DSN'),
  environment: getEnvVar('APP_ENV'),
  integrations: [
    new Integrations.BrowserTracing(),
    new SentryFullStory(getEnvVar('SENTRY_ORG')),
  ],
  tracesSampleRate: 1.0,
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        const queryError = error instanceof Error ? error : null
        const statusCode = isAxiosResponseError(queryError)
          ? queryError.response?.status
          : undefined

        // We don't want to retry an API request on 4xx responses since they'll just keep failing.
        return (
          failureCount < 3 &&
          (!statusCode || statusCode < 400 || statusCode >= 500)
        )
      },
    },
  },
})

const commonAPIConfig = {
  appID: 'MyTovala',
  apiType: getEnvVar('APP_ENV') === 'production' ? 'prod' : 'dev',
  getBearerToken: () => getCookie('JWT_TOKEN'),
} as const
setupCDNAPI({ apiType: commonAPIConfig.apiType })
setupCombinedAPI(commonAPIConfig)
setupMarketplaceAPI(commonAPIConfig)
setupMenuDeliveryAPI(commonAPIConfig)

const root = createRoot(document.getElementById('root') as HTMLDivElement)
root.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<UncaughtErrorPage />}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ScreenSizeProvider>
            <FeaturesProvider>
              <AuthProvider>
                <ToastProvider>
                  <OverlaysProvider>
                    <App />
                  </OverlaysProvider>
                </ToastProvider>
              </AuthProvider>
            </FeaturesProvider>
          </ScreenSizeProvider>
        </BrowserRouter>

        <ReactQueryDevtools />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>
)
