import {
  Button,
  ButtonLoading,
  CaretRightIcon,
  ThumbsUpIcon,
} from '@tovala/component-library'
import { uniqBy } from 'lodash-es'
import { ComponentProps, Fragment, ReactNode, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import {
  Listing,
  ListingsReceipt,
  OrderHistoryReceipt,
  OrderHistoryReceiptMeal,
  ReviewShort,
  ReviewSummary,
  useListingsReceipt,
  useMealReviewSummaries,
} from '@tovala/browser-apis-combinedapi'
import { useMenuProductDetailsJSON } from '@tovala/browser-apis-cdn'

import { DATE_FORMATS, formatDate, isDatePast, parseToDate } from 'utils/dates'
import { ErrorCodeMessageMapCombinedAPI, ListingFull } from 'types/internal'
import { events } from '../../../analytics/events'
import {
  getDeliveryStatus,
  getMostRecentTermMealsToRate,
  getTermsToRate,
} from 'utils/terms'
import { track } from 'utils/analytics'
import { getMealImageURL } from 'utils/meals'

import { useFirstOvenCookHistory } from 'hooks/combinedAPI/ovens'
import { useOrderHistory } from 'hooks/orderHistory'
import { useUser } from 'contexts/user'
import { useVariantByScreenSize } from 'hooks/variantByScreenSize'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import Link from 'components/common/Link'
import ListingDetailsDialog from '../menu/ExtraDetailsDialog'
import MealDetailsDialog from '../menu/MealDetailsDialog'
import ProfileHeading from './AccountHeading'
import RateMealsCarousel from '../rateMeals/RateMealsCarousel'
import RateMealsPrompt from '../rateMeals/RateMealsPrompt'
import Receipt from './Receipt'
import ReportIssue from './reportIssue/ReportIssue'
import { SmallMealImage } from 'components/common/MealImage'

type ButtonSize = ComponentProps<typeof Button>['size']
type LinkSize = ComponentProps<typeof Link>['size']

const LOAD_ORDER_HISTORY_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page.',
    why: "We couldn't load your order history due to a technical issue on our end.",
  },
}

const OrderHistoryPage = () => {
  const { user } = useUser()

  const { data: ovenCookHistory = [] } = useFirstOvenCookHistory({
    userID: user.id,
  })

  const {
    getOrderHistoryReceiptsError,
    hasGetOrderHistoryReceiptsError,
    isFetchingNextPage,
    isLoading,
    loadMoreOrderHistory,
    receipts,
    recentOrdersPastDeliveryDate,
    totalOrderCount,
  } = useOrderHistory({ userID: user.id })

  const { data: mealReviewSummaries } = useMealReviewSummaries({
    userID: user.id,
  })

  const mostRecentTermMealsToRate = getMostRecentTermMealsToRate({
    termsToRate: getTermsToRate({
      cookHistory: ovenCookHistory,
      mealReviewSummaries,
      orderHistoryReceipts: receipts,
    }),
  })

  const showMoreHistory =
    totalOrderCount !== undefined && receipts.length < totalOrderCount

  const reviewedMealIDs = mealReviewSummaries
    ? mealReviewSummaries.reviews?.map((review) => review.mealId)
    : []

  useEffect(() => {
    track(events.OPENS_ORDER_HISTORY)
  }, [])

  return (
    <div>
      <div className="hidden md:mb-8 md:block">
        <ProfileHeading to="/account">Order History</ProfileHeading>
      </div>
      <div className="flex flex-wrap gap-4">
        <div className="mb-9 max-w-[1040px] grow">
          <h1 className="mb-6 text-k/44_110 md:hidden">Order History</h1>

          <div className="space-y-10 md:space-y-6">
            {receipts.length > 0 ? (
              receipts.map((userTermOrder, index) => {
                const isRecentOrder = recentOrdersPastDeliveryDate
                  .map((receipt) => receipt.termID)
                  .includes(userTermOrder.termID)

                return (
                  <Fragment key={userTermOrder.userTermOrderID}>
                    <HistoryTerm
                      isRecentOrder={isRecentOrder}
                      mealReviewSummaries={mealReviewSummaries}
                      reviewedMealIDs={reviewedMealIDs}
                      userID={user.id}
                      userTermOrder={userTermOrder}
                    />

                    {index === 0 && (
                      <div className="md:mx-4">
                        <RateMealsPrompt
                          mostRecentTermMealsToRate={mostRecentTermMealsToRate}
                        />
                      </div>
                    )}
                  </Fragment>
                )
              })
            ) : hasGetOrderHistoryReceiptsError ? (
              <APIErrorDisplay
                error={getOrderHistoryReceiptsError}
                errorCodeMessageMap={LOAD_ORDER_HISTORY_ERRORS}
              />
            ) : !isLoading && !totalOrderCount ? (
              <p>No order history found.</p>
            ) : null}

            {showMoreHistory && (
              <div className="md:px-4">
                <ButtonLoading
                  isLoading={isFetchingNextPage}
                  onClick={() => {
                    loadMoreOrderHistory()
                  }}
                  size="large"
                >
                  Show more
                </ButtonLoading>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderHistoryPage

const HistoryTerm = ({
  isRecentOrder,
  mealReviewSummaries,
  reviewedMealIDs,
  userID,
  userTermOrder,
}: {
  isRecentOrder: boolean
  mealReviewSummaries: ReviewSummary | undefined
  reviewedMealIDs: number[]
  userID: number
  userTermOrder: OrderHistoryReceipt
}) => {
  const [showReceipt, setShowReceipt] = useState(false)
  const [showRateMealsCarousel, setShowRateMealsCarousel] = useState(false)
  const [firstCarouselMealID, setFirstCarouselMealID] = useState<
    number | undefined
  >()

  const [historyButtonSize, historyLinkSize] = useVariantByScreenSize<
    [ButtonSize, LinkSize]
  >(['medium', 'medium'], {
    md: ['small', 'small'],
  })

  const { data: listingsReceipt } = useListingsReceipt({
    orderID: userTermOrder.userTermOrderID,
    userID,
  })

  const deliveryDate = formatDate(userTermOrder.deliveryDate, {
    format: DATE_FORMATS.DOW_MONTH_FULL_DAY,
  })

  const { listings, meals } = getReceiptSelections({
    listingsReceipt,
    receipt: userTermOrder,
  })
  const mealsToRate = meals.filter((meal) => !reviewedMealIDs.includes(meal.id))

  return (
    <div className="rounded-xl bg-grey-2 md:rounded-none md:bg-grey-0">
      <div className="flex flex-wrap items-center justify-between gap-3 p-6 md:flex-col md:items-start md:bg-grey-2 md:p-4">
        <div className="shrink-0 text-k/20_125">
          {getDeliveryStatus({ receipt: userTermOrder })} {deliveryDate}
        </div>

        <div className="flex items-center space-x-4">
          {(userTermOrder.orderStatus === 'complete' ||
            userTermOrder.orderStatus === 'refunded') && (
            <div>
              {userTermOrder.trackingURL ? (
                <Link
                  linkStyle="white"
                  linkType="external"
                  onClick={() => {
                    track(events.OPENS_ORDER_TRACKING, {
                      term_id: userTermOrder.termID,
                    })
                  }}
                  rel="noreferrer"
                  size={historyLinkSize}
                  target="_blank"
                  to={userTermOrder.trackingURL}
                >
                  Track Order
                </Link>
              ) : (
                // Anchor tags can't be disabled like a button, but leaving off the href and adding role="link" allows us to use aria-disabled
                <a
                  aria-disabled="true"
                  className="flex h-9 items-center justify-center whitespace-nowrap rounded-full bg-grey-7 px-8 text-sm text-grey-2 md:h-7 md:px-6 md:text-xs"
                  role="link"
                >
                  Track Order
                </a>
              )}
            </div>
          )}

          {(userTermOrder.orderStatus === 'complete' ||
            userTermOrder.orderStatus === 'refunded' ||
            userTermOrder.orderStatus === 'canceled') && (
            <>
              <Button
                buttonStyle="white"
                onClick={() => {
                  setShowReceipt(true)
                  track(events.OPENS_ORDER_RECEIPT, {
                    term_id: userTermOrder.termID,
                  })
                }}
                size={historyButtonSize}
              >
                Receipt
              </Button>
            </>
          )}

          {isRecentOrder && <ReportIssue buttonSize={historyButtonSize} />}
        </div>
      </div>

      {(userTermOrder.orderStatus === 'complete' ||
        userTermOrder.orderStatus === 'refunded') && (
        <div className="space-y-6 border-t border-grey-4 p-6 md:space-y-4 md:border-none md:px-4 md:py-6">
          <HistoryItemsGrid>
            {meals.map((selectedMeal) => {
              const { quantity, ...meal } = { ...selectedMeal }

              return (
                <HistoryTermMeal
                  key={meal.id}
                  meal={meal}
                  mealReviewSummaries={mealReviewSummaries}
                  onRateClick={() => {
                    setFirstCarouselMealID(meal.id)
                    setShowRateMealsCarousel(true)
                  }}
                  quantity={quantity}
                  userTermOrder={userTermOrder}
                />
              )
            })}
          </HistoryItemsGrid>

          {userTermOrder.autofillProcessing && (
            <p className="text-k/16_125">
              Auto-Selection In Progress: Your Selections Will be Finalized By
              Our Chefs on Thursday.
            </p>
          )}

          {listings.length > 0 && (
            <div className="border-t border-grey-4 pt-6 md:pt-4">
              <HistoryItemsGrid>
                {listings.map((selectedListing) => {
                  const { quantity, ...listing } = { ...selectedListing }
                  return (
                    <HistoryTermExtra
                      key={listing.id}
                      listing={listing}
                      quantity={quantity}
                    />
                  )
                })}
              </HistoryItemsGrid>
            </div>
          )}
        </div>
      )}

      {showReceipt && (
        <Receipt
          onCloseModal={() => setShowReceipt(false)}
          selections={{
            listings,
            meals,
          }}
          userTermOrder={userTermOrder}
        />
      )}
      {showRateMealsCarousel && (
        <RateMealsCarousel
          firstMealID={firstCarouselMealID}
          meals={mealsToRate}
          onClose={() => setShowRateMealsCarousel(false)}
        />
      )}
    </div>
  )
}

const HistoryTermExtra = ({
  listing,
  quantity,
}: {
  listing: Listing
  quantity: number
}) => {
  const [showModal, setShowModal] = useState(false)

  const { data: listingDetails } = useMenuProductDetailsJSON({
    productDetailsURL: showModal ? listing?.productDetailsURL : undefined,
  })
  const listingFull: ListingFull | undefined =
    listing && listingDetails
      ? {
          ...listingDetails,
          ...listing,
        }
      : undefined

  const closeModal = () => {
    setShowModal(false)
  }

  const openModal = () => {
    setShowModal(true)
  }

  return (
    <>
      {showModal && listingFull && (
        <ListingDetailsDialog listing={listingFull} onClose={closeModal} />
      )}

      <div
        className="flex cursor-pointer items-center justify-between"
        onClick={openModal}
      >
        <div className="mr-4 flex items-center">
          <div className="relative mr-4 shrink-0">
            <SmallMealImage imageURL={listing.imageURL} />
            {quantity > 1 && (
              <span className="absolute -right-2 -top-2 flex h-6 w-6 items-center justify-center rounded-sm bg-white text-h/14_120 font-semibold text-orange-1">
                {quantity}
              </span>
            )}
          </div>

          <p className="text-k/16_125 md:text-h/14_120">{listing.title}</p>
        </div>
        <div className="hidden h-6 w-6 shrink-0 text-grey-6 md:block">
          <CaretRightIcon />
        </div>
      </div>
    </>
  )
}

const HistoryItemsGrid = ({ children }: { children: ReactNode }) => {
  return (
    <div className="grid grid-cols-2 gap-6 lg:grid-cols-1 md:gap-4">
      {children}
    </div>
  )
}

const HistoryTermMeal = ({
  meal,
  mealReviewSummaries,
  onRateClick,
  quantity,
  userTermOrder,
}: {
  meal: OrderHistoryReceiptMeal
  mealReviewSummaries: ReviewSummary | undefined
  onRateClick: () => void
  quantity: number
  userTermOrder: OrderHistoryReceipt
}) => {
  const [showModal, setShowModal] = useState(false)

  const rateButtonSize = useVariantByScreenSize<ButtonSize>('medium', {
    md: 'small',
  })

  const closeModal = () => {
    setShowModal(false)
  }

  const openModal = () => {
    setShowModal(true)
  }

  const isPastDeliveryDate =
    userTermOrder.deliveryDate &&
    isDatePast(parseToDate(userTermOrder.deliveryDate))

  let completedReview: ReviewShort | '' | undefined = ''
  if (mealReviewSummaries && mealReviewSummaries.reviews) {
    completedReview = mealReviewSummaries.reviews.find(
      (review) => review.mealId === meal.id
    )
  }

  return (
    <>
      {showModal && <MealDetailsDialog closeModal={closeModal} meal={meal} />}

      <div className="flex items-center justify-between">
        <div
          className="mr-4 flex cursor-pointer items-center"
          onClick={openModal}
        >
          <div className="relative mr-4 shrink-0">
            <SmallMealImage imageURL={getMealImageURL(meal)} />
            {quantity > 1 && (
              <span className="absolute -right-2 -top-2 flex h-6 w-6 items-center justify-center rounded-sm bg-white text-h/14_120 font-semibold text-orange-1">
                {quantity}
              </span>
            )}
          </div>

          <div>
            <p className="text-k/16_125 md:text-h/14_120">{meal.title}</p>
            <p className="mt-1 text-k/14_120  md:text-k/12_120">
              {meal.subtitle}
            </p>
          </div>
        </div>

        {completedReview ? (
          <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
            {completedReview && completedReview.rating === 100 && (
              <div className="h-6 w-6">
                <ThumbsUpIcon />
              </div>
            )}
          </motion.div>
        ) : (
          <div>
            <Button
              buttonStyle="stroke"
              disabled={!isPastDeliveryDate}
              onClick={onRateClick}
              size={rateButtonSize}
            >
              Rate
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

function getReceiptSelections({
  listingsReceipt,
  receipt,
}: {
  listingsReceipt: ListingsReceipt | undefined
  receipt: OrderHistoryReceipt
}): {
  listings: (Listing & { quantity: number })[]
  meals: (OrderHistoryReceiptMeal & { quantity: number })[]
} {
  const selectedListings = listingsReceipt?.listings ?? []

  const uniqueSelectedListings = uniqBy(selectedListings, 'id')
  const uniqueSelectedMeals = uniqBy(receipt.selectedMeals, 'id')

  return {
    listings: uniqueSelectedListings.map((listing) => {
      const quantity = selectedListings.filter(
        (l) => l.id === listing.id
      ).length

      return {
        ...listing,
        quantity,
      }
    }),
    meals: uniqueSelectedMeals.map((meal) => {
      const quantity = receipt.selectedMeals.filter(
        (m) => m.id === meal.id
      ).length

      return {
        ...meal,
        quantity,
      }
    }),
  }
}
