import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import { useEffect, useRef, useState } from 'react'

export function useDebounce(value: string, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export function useDelayedBoolean({
  delay,
  value,
}: {
  delay: number
  value: boolean
}) {
  const [delayedBoolean, setDelayedBoolean] = useState(false)

  useEffect(() => {
    let timeoutID: number | undefined

    if (value) {
      timeoutID = window.setTimeout(() => {
        setDelayedBoolean(true)
      }, delay)
    } else {
      setDelayedBoolean(false)
    }

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      window.clearTimeout(timeoutID)
    }
  }, [value, delay])

  return delayedBoolean
}

const THRESHOLD = 0

// From https://www.robinwieruch.de/react-hook-scroll-direction/
export const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState<'down' | 'up'>('down')

  const blocking = useRef(false)
  const prevScrollY = useRef(0)

  useEffect(() => {
    prevScrollY.current = window.scrollY

    const updateScrollDirection = () => {
      const scrollY = window.scrollY

      if (Math.abs(scrollY - prevScrollY.current) >= THRESHOLD) {
        const newScrollDirection = scrollY > prevScrollY.current ? 'down' : 'up'

        setScrollDirection(newScrollDirection)

        prevScrollY.current = scrollY > 0 ? scrollY : 0
      }

      blocking.current = false
    }

    const onScroll = () => {
      if (!blocking.current) {
        blocking.current = true
        window.requestAnimationFrame(updateScrollDirection)
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDirection])

  return scrollDirection
}

export function useVisibilityCallback<RefElementType extends HTMLElement>({
  onIsVisible,
  visibilityThreshold,
}: {
  onIsVisible(): void
  visibilityThreshold: number
}) {
  const ref = useRef<RefElementType | null>(null)
  const [hasTriggered, setHasTriggered] = useState(false)

  useEffect(() => {
    if (visibilityThreshold < 0 || visibilityThreshold > 1) {
      console.error(
        'The visibilityThreshold parameter to useVisibilityCallback must be between 0 and 1.'
      )
      return
    }

    if (!ref.current || hasTriggered) {
      return
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setHasTriggered(true)
          onIsVisible()

          if (ref.current) {
            observer.unobserve(ref.current)
          }
        }
      },
      { threshold: visibilityThreshold }
    )

    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [hasTriggered, onIsVisible, visibilityThreshold])

  return { elementRef: ref }
}

export function useUpdateOnValueChange<ValueType>(value: ValueType) {
  const stateReturn = useState(value)

  useDeepCompareEffectNoCheck(() => {
    stateReturn[1](value)
  }, [value])

  return stateReturn
}
