import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, Navigate, useLocation } from 'react-router-dom'
import { useLogin } from '@tovala/browser-apis-combinedapi'
import { ButtonLoading } from '@tovala/component-library'

import {
  ErrorCodeMessageMapCombinedAPI,
  LoginLocationState,
} from 'types/internal'
import { events } from 'analytics/events'
import { getLoginRedirect } from 'utils/auth'
import { removeCookie } from 'utils/storage'
import { track } from 'utils/analytics'

import { useAuth } from 'contexts/auth'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import AuthPage from './AuthPage'
import ErrorDisplay from 'components/common/ErrorDisplay'
import FormInputRHF from 'components/common/FormInputRHF'
import Loader from 'components/common/Loader'
import SuccessDisplay from 'components/common/SuccessDisplay'
import RevealPassword from 'components/core/RevealPassword'

type FormValues = {
  email: string
  password: string
}

const LOGIN_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't log you in due to a technical issue on our end.",
  },
  UnauthorizedRequest: {
    helpToFix: 'Please double check your credentials and try again.',
    wayOut: (
      <span>
        If you've forgotten your password,{' '}
        <Link className="underline" to="/forgot-password">
          reset your password here
        </Link>
        .
      </span>
    ),
    why: 'Your email or password was incorrect.',
  },
}

const LoginPage = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const showGiftCardMessaging =
    searchParams.get('redirect') === '/gift-cards/purchase'

  const [revealPassword, setRevealPassword] = useState(false)

  const { passwordReset: locationPasswordReset } =
    (location.state as LoginLocationState) || {}

  const { hadUnauthEvent, isLoadingUser, isLoggedIn, onJWTChanged } = useAuth()

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const {
    error: loginError,
    isError: hasLoginError,
    isLoading: isLoggingIn,
    mutate: login,
  } = useLogin({
    onError: () => {
      track(events.LOGIN_FAILED)
    },
    onSuccess: (data) => {
      if (data.token) {
        onJWTChanged(data.token)

        track(events.USER_LOGGED_IN)
      } else {
        track(events.LOGIN_FAILED)

        throw new Error('No token found in successful login response')
      }
    },
  })

  if (isLoggedIn) {
    const { to, type } = getLoginRedirect({
      queryRedirect: searchParams.get('redirect'),
    })

    if (type === 'external') {
      window.location.href = to

      return <Loader />
    }

    return <Navigate to={to} />
  }

  // If we have an error on this page logging in, we don't want to duplicate error displays. But
  // if we don't have an error and there was an unauthorized event, then we want to show a message
  // to the user that their session expired.
  const displayDidSessionExpire = !loginError && !isLoggingIn && hadUnauthEvent

  return (
    <AuthPage>
      <div className="flex justify-center">
        <div className="w-[320px] sm:w-full">
          <h1 className="mb-12 text-center text-k/44_110 font-medium md:mb-10 md:text-k/32_105">
            Login
          </h1>
          {showGiftCardMessaging && (
            <p className="mb-6 text-center text-sm text-grey-9">
              To purchase a gift card, please create a Tovala account or log in
              to your existing account. <br />
              Gift cards are a one-time purchase, and you will not be
              automatically signed up for a subscription or charged on a weekly
              basis.
            </p>
          )}
          <form
            className="space-y-4"
            onSubmit={handleSubmit((data) => {
              // This was added during the VisID push because we had users with authentication cookies
              // that were not expired via the cookie expiration but the JWT contents were expired. This
              // prevented logging in since the new token returned from the API was not overriding the already
              // set authentication cookie.
              removeCookie('JWT_TOKEN')
              return login(data)
            })}
          >
            <div className="space-y-6 sm:space-y-4">
              {locationPasswordReset ? (
                <SuccessDisplay message="Your password was reset successfully. Please log in below." />
              ) : displayDidSessionExpire ? (
                <ErrorDisplay
                  helpToFix="Please log in again."
                  wayOut={null}
                  why="Your session has expired."
                />
              ) : null}

              <FormInputRHF
                error={errors?.email?.message}
                id="email"
                label="Email"
                labelFor="email"
                type="email"
                {...register('email', {
                  required: 'Please provide your email',
                })}
              />
              <FormInputRHF
                autoComplete="password"
                error={errors?.password?.message}
                id="password"
                label="Password"
                labelFor="password"
                rightIcon={
                  <RevealPassword
                    revealPassword={revealPassword}
                    setRevealPassword={setRevealPassword}
                  />
                }
                type={revealPassword ? 'text' : 'password'}
                {...register('password', {
                  required: 'Please provide your password',
                })}
              />

              {hasLoginError && (
                <APIErrorDisplay
                  error={loginError}
                  errorCodeMessageMap={LOGIN_ERRORS}
                />
              )}
            </div>
            <div className="mt-8 flex justify-center sm:mt-6">
              <ButtonLoading
                isLoading={isLoggedIn || isLoggingIn || isLoadingUser}
                size="large"
                type="submit"
              >
                Login
              </ButtonLoading>
            </div>
            <div className="mt-4 space-y-4 text-center text-k/14_120 sm:mt-6">
              <p>
                <Link
                  className="text-orange-1 underline"
                  onClick={() => {
                    track(events.TAPPED_FORGOT_PASSWORD)
                  }}
                  to="/forgot-password"
                >
                  Forgot password?
                </Link>
              </p>
              <div className="space-x-3 text-grey-9 sm:space-x-2">
                <span>Don't have an account?</span>
                <Link className="text-orange-1 underline" to="/register">
                  Sign up here
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </AuthPage>
  )
}

export default LoginPage
