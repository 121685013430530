import { assign, createMachine } from 'xstate'

export function createMealTagMachine({
  titleCollapsed,
  titleExpanded,
}: {
  titleCollapsed: string
  titleExpanded: string
}) {
  return createMachine(
    {
      context: {
        titleCollapsed,
        titleExpanded,
        widths: { [titleCollapsed]: '', [titleExpanded]: '' },
      },
      id: 'mealTagMachine',
      predictableActionArguments: true,
      schema: {
        context: {} as {
          titleCollapsed: string
          titleExpanded: string
          widths: Record<string, number | ''>
        },
        events: {} as
          | { type: 'ANIMATION_COMPLETE'; width: number | '' }
          | { type: 'TOGGLE' },
      },
      tsTypes: {} as import('./mealTag.typegen').Typegen0,

      initial: 'findingInitialWidth',
      states: {
        findingInitialWidth: {
          on: {
            ANIMATION_COMPLETE: {
              actions: ['storeCollapsedWidth'],
              target: 'collapsed',
            },
          },
        },
        collapsed: {
          on: { TOGGLE: { target: 'expanding' } },
        },
        expanding: {
          on: {
            ANIMATION_COMPLETE: [
              {
                actions: ['storeExpandedWidth'],
                cond: 'shouldStoreExpandedWidth',
                target: 'expanded',
              },
              { target: 'expanded' },
            ],
          },
        },
        expanded: {
          after: {
            4000: { target: 'collapsing' },
          },
          on: { TOGGLE: { target: 'collapsing' } },
        },
        collapsing: {
          on: {
            ANIMATION_COMPLETE: [
              {
                actions: ['storeCollapsedWidth'],
                cond: 'shouldStoreCollapsedWidth',
                target: 'collapsed',
              },
              { target: 'collapsed' },
            ],
          },
        },
      },
    },
    {
      actions: {
        storeCollapsedWidth: assign({
          widths: (ctx, event) => {
            return {
              ...ctx.widths,
              [ctx.titleCollapsed]: event.width,
            }
          },
        }),
        storeExpandedWidth: assign({
          widths: (ctx, event) => {
            return {
              ...ctx.widths,
              [ctx.titleExpanded]: event.width,
            }
          },
        }),
      },
      guards: {
        // We only want to store widths if one is provided and we haven't yet
        // calculated a width.
        shouldStoreCollapsedWidth: (ctx, event) => {
          return event.width !== '' && ctx.widths[ctx.titleCollapsed] === ''
        },
        shouldStoreExpandedWidth: (ctx, event) => {
          return event.width !== '' && ctx.widths[ctx.titleExpanded] === ''
        },
      },
    }
  )
}
