import { compact, orderBy } from 'lodash-es'
import {
  MealSummary,
  MealSwap,
  MealTag,
} from '@tovala/browser-apis-combinedapi'
import {
  MenuComponentAnimatedMealCarousel,
  MenuComponentAnimatedMealCarouselMealOption,
  MenuComponentMeal,
  MenuComponentMealWithExtra,
  MenuComponentTwoMealPicker,
  MenuComponentTwoMealPickerMeal,
  MenuComponents,
  MenuMeal,
} from '@tovala/browser-apis-cdn'

import { formatCentsToDollars } from './currency'
import {
  getDisplayTags,
  getMealImageURL,
  getMealSurcharge,
  getMealSwaps,
  TAG_IDS,
} from './meals'
import { hasMealTag } from './mealTags'
import {
  MenuComponentCommonMealProperties,
  MenuComponentStandardizedBackgroundImageHeader,
  MenuComponentStandardizedFeedback,
  MenuComponentStandardizedImageTag,
  MenuComponentStandardizedMeal,
  MenuComponentStandardizedMealCarousel,
  MenuComponentStandardizedMealCarouselMealOption,
  MenuComponentStandardizedMealWithExtra,
  MenuComponentStandardizedMealWithExtraMealOption,
  MenuComponentStandardizedSalmonBar,
  MenuComponentStandardizedSuggestions,
  MenuComponentStandardizedTextImageStack,
  MenuComponentStandardizedTextImageStackChildren,
  MenuComponentStandardizedTwoMealPicker,
  MenuComponentStandardizedTwoMealPickerMeal,
  MenuComponentsStandardized,
} from 'types/internal'

export function convertMealCarouselMealToBaseMeal(
  meal: MenuComponentStandardizedMealCarouselMealOption
): MenuComponentStandardizedMeal {
  return {
    properties: {
      id: meal.id,
      image: meal.image,
      mealSummary: meal.mealSummary,
      subtitle: meal.subtitle,
      surcharge: meal.surcharge,
      tags: meal.tags,
      title: meal.title,
    },
    type: 'meal',
  }
}

export function convertTwoMealPickerMealToBaseMeal(
  meal: MenuComponentStandardizedTwoMealPickerMeal
): MenuComponentStandardizedMeal {
  return {
    properties: {
      id: meal.id,
      image: meal.image,
      imageTag: meal.imageTag,
      mealSummary: meal.mealSummary,
      subtitle: meal.subtitle,
      surcharge: meal.surcharge,
      tags: meal.tags,
      title: meal.title,
    },
    type: 'meal',
  }
}

export function getCommonMealProperties({
  meal,
  overrides,
  suggestions,
}: {
  meal: MealSummary
  overrides?: MenuMeal
  suggestions: MealSummary[]
}): MenuComponentCommonMealProperties {
  return {
    id: meal.id,
    image: {
      url: overrides?.image?.url ?? getMealImageURL(meal),
    },
    mealSummary: meal,
    subtitle: overrides?.subtitle ?? meal.subtitle,
    surcharge: getMealSurcharge({ meal }),
    tags: overrides?.displayTags
      ? overrides.displayTags.map(({ title, titleCollapsed }) => {
          if (titleCollapsed) {
            return { titleCollapsed, titleExpanded: title }
          }

          return title
        })
      : getDisplayTags({ meal, suggestions }),
    title: overrides?.title ?? meal.title,
  }
}

export function getStandardizedImageTag({
  meal,
  overrides,
}: {
  meal: { tags: MealTag[] }
  overrides?: MenuMeal
}): MenuComponentStandardizedImageTag | undefined {
  const bannerTag = meal.tags.find((tag) =>
    [
      'meal_banner_top_leading',
      'meal_banner_top_center',
      'meal_banner_top_trailing',
      'meal_banner_top_jumbo',
    ].includes(tag.displayMode)
  )

  return overrides?.imageFlair
    ? {
        icon: overrides.imageFlair.icon?.url,
        title: overrides.imageFlair.title,
      }
    : bannerTag
    ? { icon: bannerTag.image, title: bannerTag.title }
    : undefined
}

function getStandardizedMealCarouselMealOption({
  meal,
  overrides,
  suggestions,
}: {
  meal: MealSummary
  overrides?: MenuComponentAnimatedMealCarouselMealOption
  suggestions: MealSummary[]
}) {
  const mealOption: MenuComponentStandardizedMealCarouselMealOption = {
    ...getCommonMealProperties({ meal, overrides, suggestions }),
    isSoldOut: meal.isSoldOut,
    optionTitle: overrides?.optionTitle ?? meal.shortSubtitle,
  }

  return mealOption
}

function getStandardizedMealProperties({
  meal,
  overrides,
  suggestions,
}: {
  meal: MealSummary
  overrides?: MenuMeal
  suggestions: MealSummary[]
}) {
  const properties: MenuComponentStandardizedMeal['properties'] = {
    ...getCommonMealProperties({ meal, overrides, suggestions }),
    imageTag: getStandardizedImageTag({ meal, overrides }),
  }

  return properties
}

function getStandardizedMealWithExtraMealOption({
  meal,
  mealOption,
  overrides,
  suggestions,
}: {
  meal: MealSummary
  mealOption: MealSummary
  overrides?: MenuMeal
  suggestions: MealSummary[]
}) {
  const surchargeCents = mealOption.surchargeCents - meal.surchargeCents

  const properties: MenuComponentStandardizedMealWithExtraMealOption = {
    ...getCommonMealProperties({
      meal: mealOption,
      overrides,
      suggestions,
    }),
    imageTag: getStandardizedImageTag({ meal: mealOption, overrides }),
    surcharge:
      surchargeCents > 0
        ? `+${formatCentsToDollars(surchargeCents)} per meal`
        : '',
  }

  return properties
}

function getStandardizedTwoMealPickerMeal({
  meal,
  overrides,
  suggestions,
}: {
  meal: MealSummary
  overrides?: MenuComponentTwoMealPickerMeal
  suggestions: MealSummary[]
}) {
  const iconTag = meal.tags?.find(
    (tag) => tag.displayMode === 'customize_it_icon'
  )

  const properties: MenuComponentStandardizedTwoMealPickerMeal = {
    ...getCommonMealProperties({
      meal,
      overrides: overrides?.meal,
      suggestions,
    }),
    buttonIcon: overrides?.buttonIcon
      ? { url: overrides.buttonIcon.url }
      : iconTag
      ? { url: iconTag.image }
      : undefined,
    buttonTitle: overrides ? overrides.buttonTitle : meal.shortSubtitle,
    imageTag: getStandardizedImageTag({ meal, overrides: overrides?.meal }),
    isSoldOut: meal.isSoldOut,
  }

  return properties
}

/**
 * Creates a "standardized" representation of the menu considering both the "meals" array, which is the
 * source of truth of the meals on a menu, and a menuComponents array, which is parsed from a
 * user-editable JSON file.
 *
 * If a menu component references a meal, the meal is displayed in the order that it's present in the
 * menuComponents array. All meals that aren't referenced in the menuComponents file are added to the
 * end of the components array (ordered by their mainDisplayOrder).
 */
export function getMenuMealComponents({
  defaultUIMealWithOneSwap = 'twoMealPicker',
  mealSwaps,
  meals,
  menuComponents,
  specialEvent = '',
  suggestions = [],
}: {
  defaultUIMealWithOneSwap?: 'mealWithExtra' | 'twoMealPicker'
  mealSwaps: MealSwap[]
  meals: MealSummary[]
  menuComponents: MenuComponents['components']
  specialEvent?: string
  suggestions: MealSummary[] | undefined
}) {
  const mealsMap = new Map<number, MealSummary>()
  const mealIDsUsed = new Set<number>()
  meals.forEach((meal) => {
    mealsMap.set(meal.id, meal)
  })

  const components: MenuComponentsStandardized = []

  // We may update this behavior in the future, but pushing the salmon bar at the
  // beginning of the components array is a backwards compatible decision if there's
  // no "salmonBar" widget specified in the menu components JSON file.
  if (
    specialEvent &&
    !menuComponents.find(({ type }) => type === 'salmonBar')
  ) {
    const component: MenuComponentStandardizedSalmonBar = {
      properties: { title: specialEvent },
      type: 'salmonBar',
    }

    components.push(component)
  }

  // We may update this behavior in the future, but pushing the suggestions at the
  // beginning of the components array is a backwards compatible decision if there's
  // no "suggestions" widget specified in the menu components JSON file.
  if (
    suggestions.length > 0 &&
    !menuComponents.find(({ type }) => type === 'suggestions')
  ) {
    const component: MenuComponentStandardizedSuggestions = {
      properties: { suggestions },
      type: 'suggestions',
    }

    components.push(component)
  }

  function makeMealComponent({
    properties,
    type,
  }: Pick<MenuComponentMeal, 'properties' | 'type'>) {
    const meal = mealsMap.get(properties.mealID)
    mealIDsUsed.add(properties.mealID)

    if (!meal) {
      return
    }

    const component: MenuComponentStandardizedMeal = {
      properties: getStandardizedMealProperties({
        meal,
        overrides: properties,
        suggestions,
      }),
      type,
    }

    return component
  }

  function makeMealWithExtraComponent({
    properties,
    type,
  }: Pick<MenuComponentMealWithExtra, 'properties' | 'type'>) {
    const meal = mealsMap.get(properties.meal.mealID)
    mealIDsUsed.add(properties.meal.mealID)

    const mealOption = mealsMap.get(properties.mealOption.meal.mealID)
    mealIDsUsed.add(properties.mealOption.meal.mealID)

    if (!meal || !mealOption) {
      return
    }

    const component: MenuComponentStandardizedMealWithExtra = {
      properties: {
        meal: getStandardizedMealProperties({
          meal,
          overrides: properties.meal,
          suggestions,
        }),
        mealOption: {
          meal: getStandardizedMealWithExtraMealOption({
            meal,
            mealOption,
            overrides: properties.mealOption.meal,
            suggestions,
          }),
          detailsMealID: properties.mealOption.detailsMealID,
        },
      },
      type,
    }

    return component
  }

  function makeTwoMealPickerComponent({
    properties,
    type,
  }: Pick<MenuComponentTwoMealPicker, 'properties' | 'type'>) {
    const makeTwoMealPickerMeal = (
      overrides: MenuComponentTwoMealPickerMeal
    ) => {
      const meal = mealsMap.get(overrides.meal.mealID)
      mealIDsUsed.add(overrides.meal.mealID)

      if (!meal) {
        return
      }

      return getStandardizedTwoMealPickerMeal({
        meal,
        overrides,
        suggestions,
      })
    }

    const meals = compact([
      makeTwoMealPickerMeal(properties.firstMeal),
      makeTwoMealPickerMeal(properties.secondMeal),
    ])

    if (!isValidTwoMealPickerMealsArr(meals)) {
      return
    }

    const component: MenuComponentStandardizedTwoMealPicker = {
      properties: { meals },
      type,
    }

    return component
  }

  function makeAnimatedMealCarouselComponent({
    properties,
    type,
  }: Pick<MenuComponentAnimatedMealCarousel, 'properties' | 'type'>) {
    const mealOptions = compact(
      properties.mealOptions.map((mealConfig) => {
        const meal = mealsMap.get(mealConfig.mealID)
        mealIDsUsed.add(mealConfig.mealID)

        if (!meal) {
          return
        }

        return getStandardizedMealCarouselMealOption({
          meal,
          overrides: mealConfig,
          suggestions,
        })
      })
    )

    if (mealOptions.length === 0) {
      return
    }

    const component: MenuComponentStandardizedMealCarousel = {
      properties: { buttonTitle: properties.buttonTitle, mealOptions },
      type,
    }

    return component
  }

  // Loop over menuComponents and build out full data using overrides and meal summary data
  menuComponents.forEach(({ properties, type }) => {
    if (type === 'meal') {
      const component = makeMealComponent({ properties, type })

      if (!component) {
        return
      }

      components.push(component)
    } else if (type === 'mealWithExtra') {
      const component = makeMealWithExtraComponent({ properties, type })

      if (!component) {
        return
      }

      components.push(component)
    } else if (type === 'twoMealPicker') {
      const component = makeTwoMealPickerComponent({ properties, type })

      if (!component) {
        return
      }

      components.push(component)
    } else if (type === 'animatedMealCarousel') {
      const component = makeAnimatedMealCarouselComponent({ properties, type })

      if (!component) {
        return
      }

      components.push(component)
    } else if (suggestions.length > 0 && type === 'suggestions') {
      const component: MenuComponentStandardizedSuggestions = {
        properties: { suggestions },
        type,
      }

      components.push(component)
    } else if (type === 'salmonBar') {
      const component: MenuComponentStandardizedSalmonBar = {
        properties: { title: properties.title },
        type,
      }

      components.push(component)
    } else if (type === 'menuFeedback') {
      const component: MenuComponentStandardizedFeedback = {
        properties: {
          options: properties.responseOptions.map(({ title, value }) => {
            return { label: title, value }
          }),
          subtitle: properties.subtitle,
          tableID: properties.airtable_table_id,
          title: properties.title,
        },
        type,
      }

      components.push(component)
    } else if (type === 'backgroundImageHeader') {
      const component: MenuComponentStandardizedBackgroundImageHeader = {
        properties: {
          image: { url: properties.image.url },
          subtitle: properties.subtitle,
          subtitleColor: properties.subtitleColor,
          textShadow: properties.textShadow,
          title: properties.title,
          titleColor: properties.titleColor,
        },
        type,
      }

      components.push(component)
    } else if (type === 'textImageStack') {
      const children: MenuComponentStandardizedTextImageStackChildren = []
      properties.children.forEach(({ properties, type }) => {
        if (type === 'meal') {
          const component = makeMealComponent({ properties, type })

          if (!component) {
            return
          }

          children.push(component)
        } else if (type === 'mealWithExtra') {
          const component = makeMealWithExtraComponent({ properties, type })

          if (!component) {
            return
          }

          children.push(component)
        } else if (type === 'twoMealPicker') {
          const component = makeTwoMealPickerComponent({ properties, type })
          if (!component) {
            return
          }

          children.push(component)
        } else if (type === 'animatedMealCarousel') {
          const component = makeAnimatedMealCarouselComponent({
            properties,
            type,
          })

          if (!component) {
            return
          }

          children.push(component)
        }
      })
      const component: MenuComponentStandardizedTextImageStack = {
        properties: {
          children,
          image: properties.image?.url
            ? { url: properties.image.url }
            : undefined,
          subtitle: properties.subtitle,
          title: properties.title,
        },
        type,
      }

      components.push(component)
    }
  })

  const mealsWithoutComponents = getMealSwaps(
    meals.filter((meal) => {
      return !mealIDsUsed.has(meal.id)
    }),
    mealSwaps
  )

  orderBy(mealsWithoutComponents, 'mainDisplayOrder').forEach((meal) => {
    const hasProteinChoiceTag = hasMealTag(meal.tags, TAG_IDS.PROTEIN_CHOICE)
    const isMealCarousel = hasProteinChoiceTag && meal.swaps.length > 0
    const isMealWithOneSwap = !hasProteinChoiceTag && meal.swaps.length === 1
    const isMealExtra =
      isMealWithOneSwap && defaultUIMealWithOneSwap === 'mealWithExtra'
    const isTwoMealPicker =
      isMealWithOneSwap && defaultUIMealWithOneSwap === 'twoMealPicker'

    if (isMealCarousel) {
      const mealOptions = [meal, ...meal.swaps].map((meal) => {
        return getStandardizedMealCarouselMealOption({ meal, suggestions })
      })

      const component: MenuComponentStandardizedMealCarousel = {
        properties: { buttonTitle: 'Choose Protein', mealOptions },
        type: 'animatedMealCarousel',
      }

      components.push(component)
    } else if (isMealExtra) {
      const component: MenuComponentStandardizedMealWithExtra = {
        properties: {
          meal: getStandardizedMealProperties({ meal, suggestions }),
          mealOption: {
            meal: getStandardizedMealWithExtraMealOption({
              meal,
              mealOption: meal.swaps[0],
              suggestions,
            }),
            detailsMealID: meal.swaps[0].id,
          },
        },
        type: 'mealWithExtra',
      }

      components.push(component)
    } else if (isTwoMealPicker) {
      const component: MenuComponentStandardizedTwoMealPicker = {
        properties: {
          meals: [
            getStandardizedTwoMealPickerMeal({ meal, suggestions }),
            getStandardizedTwoMealPickerMeal({
              meal: meal.swaps[0],
              suggestions,
            }),
          ],
        },
        type: 'twoMealPicker',
      }

      components.push(component)
    } else {
      const component: MenuComponentStandardizedMeal = {
        properties: getStandardizedMealProperties({ meal, suggestions }),
        type: 'meal',
      }

      components.push(component)
    }
  })

  // We may update this behavior in the future, but pushing the feedback at the
  // end of the components array is a backwards compatible decision if there's
  // no "menuFeedback" widget specified in the menu components JSON file.
  if (!menuComponents.find(({ type }) => type === 'menuFeedback')) {
    const component: MenuComponentStandardizedFeedback = {
      properties: {
        options: [
          { label: '😠', value: 1 },
          { label: '😕', value: 2 },
          { label: '😐', value: 3 },
          { label: '😋', value: 4 },
          { label: '😍', value: 5 },
        ],
        subtitle: 'All feedback helps us improve our future menus',
        tableID: 'menu_feedback',
        title: 'How would you rate the menu for this week?',
      },
      type: 'menuFeedback' as const,
    }

    components.push(component)
  }

  return components
}

function isValidTwoMealPickerMealsArr(
  meals: MenuComponentStandardizedTwoMealPickerMeal[]
): meals is [
  MenuComponentStandardizedTwoMealPickerMeal,
  MenuComponentStandardizedTwoMealPickerMeal
] {
  return meals.length === 2
}
