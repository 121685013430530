import { Button, ButtonLoading } from '@tovala/component-library'
import {
  ListingSelection,
  MealSelection,
} from '@tovala/browser-apis-combinedapi'
import { ComponentProps, ReactNode, useState } from 'react'

import {
  ErrorCodeMessageMapCombinedAPI,
  PendingMealSelection,
  UserTerm,
} from 'types/internal'
import { useUser } from 'contexts/user'
import { useIsNoMenuTerm } from 'hooks/combinedAPI/meals'
import { useMenuTutorial } from 'hooks/menuTutorial'
import { useOverlays } from 'contexts/overlays'
import { useVariantByScreenSize } from 'hooks/variantByScreenSize'
import { formatCentsToDollars } from 'utils/currency'
import {
  getMealSelectionIDs,
  getPendingMealSelectionPricing,
} from 'utils/meals'
import { track } from 'utils/analytics'
import { events, sourceIDs } from 'analytics/events'

import APIErrorDisplay from 'components/common/APIErrorDisplay'
import Link from 'components/common/Link'
import MenuTutorialPopover from './MenuTutorialPopover'
import {
  Popover,
  PopoverContent,
  PopoverDescription,
  PopoverHeading,
  PopoverClose,
  PopoverTrigger,
} from 'components/common/Popover'
import StatusDot from './StatusDot'
import { OrderSummarySidebarStatus } from './OrderSummarySidebar'
import { clsx } from 'clsx'
import SkippedOrderStatus from './SkippedOrderStatus'

type ButtonSize = ComponentProps<typeof Button>['size']
type LinkSize = ComponentProps<typeof Link>['size']

const UPGRADE_ORDER_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't upgrade your order due to a technical issue on our end.",
  },
}

const OrderStatus = ({
  closeOrderSummary,
  isSelectingListings,
  isUpgrading,
  listingsSelections,
  nextMaxSelections,
  onClickContinue,
  onClickDone,
  onClickSkipAdjust,
  onConfirmUpgrade,
  openOrderSummary,
  orderSummarySidebarStatus,
  pendingMealSelections,
  selectedUserTerm,
  showOrderUpgrade,
  upgradeOrderError,
}: {
  closeOrderSummary(): void
  isSelectingListings: boolean
  isUpgrading: boolean
  listingsSelections: ListingSelection[]
  nextMaxSelections: number | undefined
  onClickContinue(openSource: 'orderSummary' | undefined): void
  onClickDone(openSource: 'orderSummary' | undefined): void
  onClickSkipAdjust(): void
  onConfirmUpgrade(): void
  openOrderSummary(): void
  orderSummarySidebarStatus: OrderSummarySidebarStatus
  pendingMealSelections: PendingMealSelection[]
  selectedUserTerm: UserTerm
  showOrderUpgrade: boolean
  upgradeOrderError: Error | null
}) => {
  const { user } = useUser()
  const [orderStatusButtonSize, orderStatusLinkSize] = useVariantByScreenSize<
    [ButtonSize, LinkSize]
  >(['fluid', 'large'], {
    md: ['medium', 'medium'],
  })

  const { isNoMenuTerm } = useIsNoMenuTerm({ term: selectedUserTerm })

  if (isNoMenuTerm) {
    return null
  }

  if (selectedUserTerm.isSkipped) {
    return <SkippedOrderStatus selectedUserTerm={selectedUserTerm} />
  }

  const mealSelectionIDs = getMealSelectionIDs({
    pendingMealSelections,
    selectedUserTerm,
  })

  const maxSelections = selectedUserTerm.subscriptionType?.maxSelections ?? 0

  const pendingMealSelectionsPricing = getPendingMealSelectionPricing({
    pendingMealSelections,
  })

  function renderMyOrdersLinkButton() {
    return (
      <Link
        onClick={() => {
          const sourceID = orderSummarySidebarStatus.isOpen
            ? sourceIDs.ORDER_SUMMARY
            : isSelectingListings
            ? sourceIDs.EXTRAS_SCREEN
            : sourceIDs.MENU_TERM

          track(events.MY_ORDERS_CTA, {
            source_id: sourceID,
          })
        }}
        size={orderStatusLinkSize}
        to="/my-orders"
      >
        My Orders
      </Link>
    )
  }
  function renderViewSummaryButton() {
    return (
      <Button
        buttonStyle="stroke"
        disabled={orderSummarySidebarStatus.isOpen}
        onClick={() => {
          openOrderSummary()

          const sourceID = isSelectingListings
            ? sourceIDs.EXTRAS_SCREEN
            : sourceIDs.MENU_TERM
          track(events.VIEW_SUMMARY, {
            source_id: sourceID,
          })
        }}
        size="fluid"
      >
        View Summary
      </Button>
    )
  }

  function renderViewSummaryLinkButton() {
    return (
      <Button
        buttonStyle="link"
        disabled={orderSummarySidebarStatus.isOpen}
        onClick={() => {
          openOrderSummary()

          const sourceID = isSelectingListings
            ? sourceIDs.EXTRAS_SCREEN
            : sourceIDs.MENU_TERM
          track(events.VIEW_SUMMARY, {
            source_id: sourceID,
          })
        }}
        size="auto"
      >
        <span
          className={clsx(
            'text-h/11_110 font-semibold uppercase text-orange-2 underline'
          )}
        >
          View Summary
        </span>
      </Button>
    )
  }

  function renderSkipAdjustButton() {
    return (
      <Button
        buttonStyle="stroke"
        onClick={() => {
          if (orderSummarySidebarStatus.isOpen) {
            closeOrderSummary()
          }
          onClickSkipAdjust()
          track(events.SKIP_ADJUST_CTA, {
            source_id: sourceIDs.MENU_TERM,
          })
        }}
        size={orderStatusButtonSize}
      >
        Skip/Adjust
      </Button>
    )
  }

  const commonStatusProps = {
    maxSelections,
    orderStatusButtonSize,
    orderSummarySidebarStatus,
    skipAdjustButton: renderSkipAdjustButton(),
    viewSummaryButton: renderViewSummaryButton(),
    viewSummaryLinkButton: renderViewSummaryLinkButton(),
    myOrdersLinkButton: renderMyOrdersLinkButton(),
  }

  return (
    <div className="space-y-4">
      {upgradeOrderError && (
        <APIErrorDisplay
          error={upgradeOrderError}
          errorCodeMessageMap={UPGRADE_ORDER_ERRORS}
        />
      )}

      {showOrderUpgrade ? (
        <UpgradeSubscriptionStatus
          {...commonStatusProps}
          hasMaxUpgradeSelections={
            mealSelectionIDs.length === nextMaxSelections
          }
          isUpgrading={isUpgrading}
          mealSelectionIDs={mealSelectionIDs}
          nextMaxSelections={nextMaxSelections}
          onConfirmUpgrade={onConfirmUpgrade}
          pendingMealSelectionsPricingCents={
            pendingMealSelectionsPricing.totalAmountCents
          }
        />
      ) : isSelectingListings ? (
        <ExtrasStatus
          {...commonStatusProps}
          hasExtrasSelections={listingsSelections.length > 0}
          hasSelectedAllMeals={selectedUserTerm.hasSelectedAllMeals}
          mealSelections={selectedUserTerm.mealSelections}
          onClickDone={() => {
            const metadata = {
              source_id: orderSummarySidebarStatus.isOpen
                ? sourceIDs.ORDER_SUMMARY
                : sourceIDs.EXTRAS_SCREEN,
              term_id: selectedUserTerm.termID,
            }
            if (listingsSelections.length > 0) {
              track(events.ORDER_DONE_WITH_EXTRA, metadata)
            } else {
              track(events.ORDER_DONE_NO_EXTRA, metadata)
            }

            let openSource = undefined
            if (orderSummarySidebarStatus.isOpen) {
              closeOrderSummary()
              openSource = 'orderSummary' as const
            }

            onClickDone(openSource)
          }}
        />
      ) : (
        <MealsStatus
          {...commonStatusProps}
          hasSelectedAllMeals={selectedUserTerm.hasSelectedAllMeals}
          mealSelections={selectedUserTerm.mealSelections}
          onClickContinue={() => {
            const sourceID = orderSummarySidebarStatus.isOpen
              ? sourceIDs.ORDER_SUMMARY
              : sourceIDs.MENU_TERM
            track(events.ORDER_CONTINUE_CTA, {
              source_id: sourceID,
            })

            let openSource = undefined
            if (orderSummarySidebarStatus.isOpen) {
              closeOrderSummary()
              openSource = 'orderSummary' as const
            }

            onClickContinue(openSource)
          }}
          userID={user.id}
        />
      )}
    </div>
  )
}

export default OrderStatus

const ExtrasStatus = ({
  hasExtrasSelections,
  hasSelectedAllMeals,
  maxSelections,
  mealSelections,
  myOrdersLinkButton,
  onClickDone,
  orderStatusButtonSize,
  orderSummarySidebarStatus,
  skipAdjustButton,
  viewSummaryButton,
  viewSummaryLinkButton,
}: {
  hasExtrasSelections: boolean
  hasSelectedAllMeals: boolean
  maxSelections: number | undefined
  mealSelections: MealSelection[]
  myOrdersLinkButton: ReactNode
  onClickDone(): void
  orderStatusButtonSize: ButtonSize
  orderSummarySidebarStatus: OrderSummarySidebarStatus
  skipAdjustButton: ReactNode
  viewSummaryButton: ReactNode
  viewSummaryLinkButton: ReactNode
}) => {
  if (maxSelections === undefined) {
    return null
  }

  const remainingSelections = maxSelections - mealSelections.length

  return (
    <div className="flex items-center justify-between">
      <div>
        {!orderSummarySidebarStatus.isOpen && (
          <div className="mb-2 hidden md:block">{viewSummaryLinkButton}</div>
        )}
        <MealSelectionsCount
          count={`${mealSelections.length}/${maxSelections} Meals`}
          icon={<StatusDot color={hasSelectedAllMeals ? 'green' : 'orange'} />}
          selectionsText={
            hasSelectedAllMeals
              ? 'Add Extras to your order'
              : `Choose ${remainingSelections} more meal${
                  remainingSelections > 1 ? 's' : ''
                }`
          }
        />
      </div>

      <ButtonContainer>
        <div className="md:hidden">{viewSummaryButton}</div>

        {hasSelectedAllMeals &&
        orderSummarySidebarStatus.openSource === 'selectionsConfirmation' ? (
          myOrdersLinkButton
        ) : hasSelectedAllMeals ? (
          <Button
            buttonStyle={hasExtrasSelections ? 'dark' : 'stroke'}
            onClick={() => {
              onClickDone()
            }}
            size={orderStatusButtonSize}
          >
            Done
          </Button>
        ) : (
          skipAdjustButton
        )}
      </ButtonContainer>
    </div>
  )
}

const MealsStatus = ({
  hasSelectedAllMeals,
  maxSelections,
  mealSelections,
  myOrdersLinkButton,
  onClickContinue,
  orderStatusButtonSize,
  orderSummarySidebarStatus,
  skipAdjustButton,
  userID,
  viewSummaryButton,
  viewSummaryLinkButton,
}: {
  hasSelectedAllMeals: boolean
  maxSelections: number | undefined
  mealSelections: MealSelection[]
  myOrdersLinkButton: ReactNode
  onClickContinue(): void
  orderStatusButtonSize: ButtonSize
  orderSummarySidebarStatus: OrderSummarySidebarStatus
  skipAdjustButton: ReactNode
  userID: number
  viewSummaryButton: ReactNode
  viewSummaryLinkButton: ReactNode
}) => {
  const { showNextMenuTutorial } = useMenuTutorial({ userID })
  const { isMenuTutorialSkipSingleOpen } = useOverlays()

  if (maxSelections === undefined) {
    return null
  }

  const remainingSelections = maxSelections - mealSelections.length

  return (
    <div className="flex items-center justify-between">
      <div>
        {!orderSummarySidebarStatus.isOpen && (
          <div className="mb-2 hidden md:block">{viewSummaryLinkButton}</div>
        )}
        <MealSelectionsCount
          count={`${mealSelections.length}/${maxSelections} Meals`}
          icon={<StatusDot color={hasSelectedAllMeals ? 'green' : 'orange'} />}
          selectionsText={
            hasSelectedAllMeals
              ? "You're all set!"
              : `Choose ${remainingSelections} more meal${
                  remainingSelections > 1 ? 's' : ''
                }`
          }
        />
      </div>

      <ButtonContainer>
        <div className="md:hidden">{viewSummaryButton}</div>
        {hasSelectedAllMeals &&
        orderSummarySidebarStatus.openSource === 'selectionsConfirmation' ? (
          myOrdersLinkButton
        ) : hasSelectedAllMeals ? (
          <Button
            onClick={() => {
              onClickContinue()
            }}
            size={orderStatusButtonSize}
          >
            Continue
          </Button>
        ) : !orderSummarySidebarStatus.isOpen &&
          isMenuTutorialSkipSingleOpen ? (
          <MenuTutorialPopover
            description="Skip or adjust your delivery day and order size for this week."
            heading="Skip or adjust this week's delivery"
            isOpen={isMenuTutorialSkipSingleOpen}
            onOpenChange={() => {
              showNextMenuTutorial()
            }}
            placement="top"
          >
            {skipAdjustButton}
          </MenuTutorialPopover>
        ) : (
          skipAdjustButton
        )}
      </ButtonContainer>
    </div>
  )
}

const UpgradeSubscriptionStatus = ({
  hasMaxUpgradeSelections,
  isUpgrading,
  maxSelections,
  mealSelectionIDs,
  nextMaxSelections,
  onConfirmUpgrade,
  orderSummarySidebarStatus,
  pendingMealSelectionsPricingCents,
  viewSummaryButton,
  viewSummaryLinkButton,
  orderStatusButtonSize,
}: {
  hasMaxUpgradeSelections: boolean
  isUpgrading: boolean
  maxSelections: number | undefined
  mealSelectionIDs: number[]
  nextMaxSelections: number | undefined
  onConfirmUpgrade(): void
  orderSummarySidebarStatus: OrderSummarySidebarStatus
  pendingMealSelectionsPricingCents: number
  viewSummaryButton: ReactNode
  viewSummaryLinkButton: ReactNode
  orderStatusButtonSize: ButtonSize
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  if (nextMaxSelections === undefined) {
    return null
  }

  return (
    <div className="flex items-center justify-between">
      <div>
        {!orderSummarySidebarStatus.isOpen && (
          <div className="mb-2 hidden md:block">{viewSummaryLinkButton}</div>
        )}

        <MealSelectionsCount
          count={`${mealSelectionIDs.length}/${nextMaxSelections} Meals`}
          icon={<DashedCircleIcon />}
          selectionsText={
            hasMaxUpgradeSelections
              ? `Confirm box +${formatCentsToDollars(
                  pendingMealSelectionsPricingCents
                )}`
              : `${maxSelections} or ${nextMaxSelections} meal box available`
          }
        />
      </div>

      <ButtonContainer>
        <div className="md:hidden">{viewSummaryButton}</div>

        {!hasMaxUpgradeSelections ? (
          <Popover onOpenChange={setIsPopoverOpen} open={isPopoverOpen}>
            <PopoverTrigger asChild>
              <Button
                buttonStyle="stroke"
                onClick={() => {
                  setIsPopoverOpen((isOpen) => !isOpen)
                  track(events.ORDER_UPDATE_BOX_SIZE_DISABLED_CTA, {
                    box_size: `${nextMaxSelections}`,
                  })
                }}
                size={orderStatusButtonSize}
              >
                Update Box Size
              </Button>
            </PopoverTrigger>

            <PopoverContent className="z-50">
              <div className="absolute right-3">
                <PopoverClose />
              </div>
              <div className="max-w-[190px] space-y-2">
                <PopoverHeading>
                  Next delivery box size is {nextMaxSelections} meals
                </PopoverHeading>
                <PopoverDescription>
                  Add or remove {nextMaxSelections - mealSelectionIDs.length}{' '}
                  meal to complete order.
                </PopoverDescription>
              </div>
            </PopoverContent>
          </Popover>
        ) : (
          <ButtonLoading
            isLoading={isUpgrading}
            onClick={() => {
              onConfirmUpgrade()
              track(events.ORDER_UPDATE_BOX_SIZE_ENABLED_CTA, {
                box_size: `${nextMaxSelections}`,
              })
            }}
            size={orderStatusButtonSize}
          >
            Update Box Size
          </ButtonLoading>
        )}
      </ButtonContainer>
    </div>
  )
}

const ButtonContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className="grid h-11 auto-cols-[139px] grid-flow-col gap-4 text-k/14_120 md:block md:h-auto">
      {children}
    </div>
  )
}

const DashedCircleIcon = () => {
  return (
    <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="8"
        cy="8"
        r="7"
        stroke="#55554F"
        strokeDasharray="5 5"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  )
}

const MealSelectionsCount = ({
  count,
  icon,
  selectionsText,
}: {
  count: string
  icon: ReactNode
  selectionsText: string
}) => {
  return (
    <div className="grid grid-cols-[min-content_auto] items-baseline gap-2">
      <div className="h-4 w-4 md:h-3 md:w-3">{icon}</div>
      <div className="space-y-1 text-k/20_110 md:text-k/16_125">
        <div>{count}</div>
        <div className="text-grey-8">{selectionsText}</div>
      </div>
    </div>
  )
}
