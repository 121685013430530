import { Button, ButtonLoading } from '@tovala/component-library'
import { Elements } from '@stripe/react-stripe-js'
import { StateFrom } from 'xstate'
import {
  useAddPaymentSource,
  useBuyGiftCard,
} from '@tovala/browser-apis-combinedapi'
import { useEffect, useRef } from 'react'
import { useMachine } from '@xstate/react'

import {
  AMOUNT_OPTIONS,
  GiftCardContext,
  giftCardMachine,
} from './machines/giftCardMachine'
import { ErrorCodeMessageMapCombinedAPI } from 'types/internal'
import { getElementsConfiguration, stripePromise } from 'utils/stripe'
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'utils/storage'
import { wrapWithContactSupportTeam } from 'utils/errors'

import { useCustomer } from 'hooks/combinedAPI/customers'
import { useStripeSubmission } from 'hooks/stripe'
import { useUser } from 'contexts/user'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import CreditCardIcon from 'components/common/icons/CreditCardIcon'
import CreditCardInput from 'components/common/CreditCardInput'
import ErrorDisplay from 'components/common/ErrorDisplay'
import FormFieldLabel from 'components/common/FormFieldLabel'
import FormGroup from 'components/common/FormGroup'
import { HeaderWithLogo } from 'components/common/Header'
import Input from 'components/common/Input'
import Link from 'components/common/Link'
import Listbox, { ListboxLabel } from 'components/common/Listbox'
import LogoLockUpIcon from 'components/common/icons/LogoLockUpIcon'
import NavigationSteps, {
  NavigationStep,
  NavigationStepsMobile,
} from 'components/common/NavigationSteps'
import Textarea from 'components/common/Textarea'
import UserLoader from 'components/common/UserLoader'

const STATE_HEADER_STEPS = [
  {
    machineState: 'choosingAmount' as const,
    title: 'Amount',
  },
  {
    machineState: 'enteringInfo' as const,
    title: 'Recipient',
  },
  {
    machineState: 'enteringPayment' as const,
    title: 'Payment',
  },
  {
    machineState: 'purchased' as const,
    title: 'Confirmation',
  },
]

const LOCAL_STORAGE_KEY = 'gift-card-purchase'
type GiftCardState = {
  giftCardState: StateFrom<typeof giftCardMachine>
  timestamp: number
}

const giftCardPurchaseState =
  getLocalStorageItem<GiftCardState>(LOCAL_STORAGE_KEY)

const GiftCardPurchasePage = () => {
  const [state, send, service] = useMachine(giftCardMachine, {
    // We only preload our state if the machine state was persisted less than a minute ago.
    // This allows the user to maintain their state if they accidentally refreshed the page
    // but also allows them to start over if they're returning after a period of time.
    state:
      giftCardPurchaseState &&
      Date.now() - giftCardPurchaseState.timestamp < 60000
        ? giftCardPurchaseState.giftCardState
        : undefined,
  })

  const giftCardAmount =
    state.context.amount.value === 0
      ? state.context.customAmount.value
      : state.context.amount.value

  const isStepAmount = state.matches('choosingAmount')
  const isStepRecipient = state.matches('enteringInfo')
  const isStepPayment = state.matches('enteringPayment')
  const isStepPurchased = state.matches('purchased')

  const currentStepIndex = STATE_HEADER_STEPS.findIndex(({ machineState }) =>
    state.matches(machineState)
  )

  // We persist the xstate machine state to local storage when updates are made
  // so a refresh later in the process keeps the user's progress. Once they get
  // to the "purchased" state, we drop the persisted state so they can start
  // over if they wish.
  useEffect(() => {
    const subscription = service.subscribe((state) => {
      if (state.matches('purchased')) {
        removeLocalStorageItem(LOCAL_STORAGE_KEY)
      } else {
        setLocalStorageItem<GiftCardState>(LOCAL_STORAGE_KEY, {
          giftCardState: state,
          timestamp: Date.now(),
        })
      }
    })

    return subscription.unsubscribe
  }, [service])

  return (
    <UserLoader>
      <HeaderWithLogo>
        {/* This is absolutely positioned to be centered even though we have a left logo. */}
        <nav className="absolute inset-0 flex h-full w-full justify-center md:hidden">
          <NavigationSteps>
            {STATE_HEADER_STEPS.map(({ machineState, title }, i) => {
              return (
                <NavigationStep
                  key={machineState}
                  isActive={state.matches(machineState)}
                  isFuture={i > currentStepIndex}
                  isLastStep={i === STATE_HEADER_STEPS.length - 1}
                >
                  {title}
                </NavigationStep>
              )
            })}
          </NavigationSteps>
        </nav>
      </HeaderWithLogo>

      {!isStepPurchased && (
        <div className="hidden md:block">
          <NavigationStepsMobile
            currentStep={STATE_HEADER_STEPS[currentStepIndex].title}
            steps={STATE_HEADER_STEPS.map((_unused, i) => {
              return { isFuture: i > currentStepIndex }
            })}
          />
        </div>
      )}

      <main className="px-4 py-20 md:pt-8">
        <div className="mx-auto w-[600px] md:w-[400px] md:max-w-full">
          {isStepAmount && (
            <ChooseAmountStep
              amount={state.context.amount}
              customAmount={state.context.customAmount}
              onChangeCustomAmount={(amount) => {
                send({ amount, type: 'CUSTOM_AMOUNT_CHANGED' })
              }}
              onChooseAmount={(amount) => {
                send({ amount, type: 'AMOUNT_CHOSEN' })
              }}
              onClickContinue={() => {
                send({ type: 'CONTINUE' })
              }}
            />
          )}

          {isStepRecipient && (
            <EnterInfoStep
              info={state.context.info}
              onClickBack={() => {
                send({ type: 'BACK' })
              }}
              onClickContinue={() => {
                send({ type: 'CONTINUE' })
              }}
              onEnterField={(fieldName, value) => {
                send({ fieldName, type: 'FIELD_ENTERED', value })
              }}
            />
          )}

          {isStepPayment &&
            // A giftCardAmount of an empty string means that a "Custom Amount"
            // was chosen and the user didn't type anything into the input. This
            // is just protective coding because we have validation to make sure
            // the user enters an amount before continuing to this step.
            giftCardAmount !== '' && (
              <Elements
                options={getElementsConfiguration()}
                stripe={stripePromise}
              >
                <PaymentStep
                  amount={giftCardAmount}
                  message={state.context.info.giftMessage.value}
                  onClickBack={() => {
                    send({ type: 'BACK' })
                  }}
                  onGiftCardPurchased={() => {
                    send({ type: 'GIFT_CARD_PURCHASED' })
                  }}
                  recipientEmail={state.context.info.recipientEmail.value}
                />
              </Elements>
            )}

          {isStepPurchased && <GiftCardPurchased />}
        </div>
      </main>
    </UserLoader>
  )
}

export default GiftCardPurchasePage

const ChooseAmountStep = ({
  amount,
  customAmount,
  onChangeCustomAmount,
  onChooseAmount,
  onClickContinue,
}: {
  amount: { label: string; value: number }
  customAmount: { error: string; value: number | '' }
  onChangeCustomAmount(amount: number | ''): void
  onChooseAmount(amount: { label: string; value: number }): void
  onClickContinue(): void
}) => {
  const customAmountInput = useRef<HTMLInputElement | null>(null)
  const customAmountFocusRef = useRef<number | undefined>()

  useEffect(() => {
    return () => {
      window.clearTimeout(customAmountFocusRef.current)
    }
  }, [])

  return (
    <div className="space-y-12">
      <h1 className="text-center text-k/44_110 font-medium md:text-k/32_105">
        Give the gift of Tovala
      </h1>

      <p className="text-center">
        Tovala is the perfect gift for anyone wanting an effortless way to eat
        better at home without any prep or cleanup. With the simple scan of a
        barcode, our countertop Smart Oven automatically cooks healthy,
        delicious meals perfectly.
      </p>

      <div className="flex items-start space-x-8 md:flex-col md:items-center md:space-x-0 md:space-y-8">
        <div className="flex h-40 w-[300px] shrink-0 flex-col justify-between rounded-lg bg-black p-6 text-white md:max-w-full">
          <div className="w-1/2">
            <LogoLockUpIcon />
          </div>
          <p>E-Gift Card</p>
        </div>

        <div className="w-full space-y-2">
          <Listbox
            isClearable={false}
            label={
              <ListboxLabel as="div">
                <FormFieldLabel label="Gift Amount" />
              </ListboxLabel>
            }
            onChange={(amount) => {
              if (amount) {
                onChooseAmount(amount)

                if (amount.value === 0) {
                  // This is wrapped in a setTimeout because HeadlessUI will return the focus
                  // to the Listbox button after an item is selected. I couldn't find a different
                  // way to prevent that focus taking place and redirecting it to a different element.
                  customAmountFocusRef.current = window.setTimeout(() => {
                    customAmountInput.current?.focus()
                  }, 100)
                }
              }
            }}
            options={AMOUNT_OPTIONS}
            value={amount}
          />

          {amount.value === 0 && (
            <FormGroup error={customAmount.error}>
              <Input
                ref={customAmountInput}
                hasError={!!customAmount.error}
                id="customAmount"
                leftIcon="$"
                max={1000}
                min={1}
                name="customAmount"
                onChange={(event) => {
                  const resolvedAmount =
                    event.target.value === '' ? '' : Number(event.target.value)

                  onChangeCustomAmount(resolvedAmount)
                }}
                placeholder="0"
                type="number"
                value={customAmount.value}
              />
            </FormGroup>
          )}
        </div>
      </div>

      <div className="flex justify-center">
        <Button
          onClick={() => {
            onClickContinue()
          }}
          size="large"
        >
          Continue
        </Button>
      </div>
    </div>
  )
}

const EnterInfoStep = ({
  info,
  onClickBack,
  onClickContinue,
  onEnterField,
}: {
  info: GiftCardContext['info']
  onClickBack(): void
  onClickContinue(): void
  onEnterField(fieldName: keyof GiftCardContext['info'], value: string): void
}) => {
  return (
    <form
      className="space-y-12"
      onSubmit={(event) => {
        event.preventDefault()

        onClickContinue()
      }}
    >
      <h1 className="text-center text-k/44_110 font-medium md:text-k/32_105">
        Tovala E-Gift Card
      </h1>

      <p className="text-center">
        Enter the recipient's information below. We'll send them an email with a
        unique code and redemption instructions.
      </p>

      <div className="space-y-6">
        <FormGroup
          error={info.recipientEmail.error}
          label="Recipient's Email"
          labelFor="recipientEmail"
        >
          <Input
            hasError={!!info.recipientEmail.error}
            id="recipientEmail"
            name="recipientEmail"
            onChange={(event) => {
              onEnterField('recipientEmail', event.target.value)
            }}
            type="email"
            value={info.recipientEmail.value}
          />
        </FormGroup>

        <FormGroup
          error={info.giftMessage.error}
          label="Gift Message"
          labelFor="giftMessage"
        >
          <Textarea
            hasError={!!info.giftMessage.error}
            id="giftMessage"
            name="giftMessage"
            onChange={(event) => {
              onEnterField('giftMessage', event.target.value)
            }}
            placeholder="Enjoy your gift!"
            rows={5}
            value={info.giftMessage.value}
          />
        </FormGroup>
      </div>

      <div className="flex flex-row-reverse justify-center gap-4">
        <Button size="large" type="submit">
          Continue
        </Button>
        <Button
          buttonStyle="stroke"
          onClick={() => {
            onClickBack()
          }}
          size="large"
        >
          Back
        </Button>
      </div>
    </form>
  )
}

const BUY_GIFT_CARD_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't purchase a gift card due to a technical issue on our end.",
  },
  'StripeError-card_error': {
    helpToFix: 'Please try using different payment credentials.',
    wayOut: wrapWithContactSupportTeam('If you need further assistance'),
    why: "We couldn't purchase a gift card because your credit card was declined.",
  },
}

const LOAD_CUSTOMER_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please refresh the page and try again.',
    why: "We couldn't load your payment information due to a technical issue on our end.",
  },
}

const PaymentStep = ({
  amount,
  message,
  onClickBack,
  onGiftCardPurchased,
  recipientEmail,
}: {
  amount: number
  message: string
  onClickBack(): void
  onGiftCardPurchased(): void
  recipientEmail: string
}) => {
  const { user } = useUser()

  const {
    error: addPaymentSourceError,
    isError: hasAddPaymentSourceError,
    isLoading: isAddingPaymentSource,
    mutateAsync: addPaymentSource,
  } = useAddPaymentSource()

  const {
    error: buyGiftCardError,
    isError: hasBuyGiftCardError,
    isLoading: isBuyingGiftCard,
    mutate: buyGiftCard,
  } = useBuyGiftCard({
    onSuccess: () => {
      onGiftCardPurchased()
    },
  })

  const { isProcessingSubmit, onSubmit, submissionError } = useStripeSubmission(
    {
      action: 'purchase a gift card',
      async onTokenCreated(stripeToken) {
        return addPaymentSource({
          customerID: user.subscription.customerID,
          data: { setAsDefault: true, stripeToken },
          userID: user.id,
        })
      },
    }
  )

  const {
    data: customer,
    error: loadCustomerError,
    isError: hasErrorLoadingCustomer,
    isLoading: isLoadingCustomer,
  } = useCustomer({
    customerID: user.subscription.customerID,
    refetchOnWindowFocus: false,
    retry: false,
    userID: user.id,
  })

  const defaultCard = customer?.sources.data.find(
    (card) => customer?.default_source === card.id
  )

  const purchaseAPIError = hasAddPaymentSourceError
    ? addPaymentSourceError
    : hasBuyGiftCardError
    ? buyGiftCardError
    : null
  const isSubmitting =
    isProcessingSubmit || isAddingPaymentSource || isBuyingGiftCard

  return (
    <form
      className="space-y-12"
      onSubmit={async (event) => {
        // We don't want to let default form submission happen here, which would refresh the page.
        event.preventDefault()

        if (!defaultCard) {
          await onSubmit()
        }

        buyGiftCard({
          data: {
            giftcard_amount_cents: amount * 100,
            giftee_email: recipientEmail,
            giftee_message: message,
            giverName: user.info.name,
          },
          userID: user.id,
        })
      }}
    >
      <h1 className="text-center text-k/44_110 font-medium md:text-k/32_105">
        Review and Enter Payment
      </h1>

      <div className="grid grid-cols-2 items-start gap-12 md:grid-cols-1">
        <div className="space-y-8">
          <FormGroup label="Gift Recipient">
            <p>{recipientEmail}</p>
          </FormGroup>

          <FormGroup label="Gift Message">
            <p>{message}</p>
          </FormGroup>

          {hasErrorLoadingCustomer ? (
            <APIErrorDisplay
              error={loadCustomerError}
              errorCodeMessageMap={LOAD_CUSTOMER_ERRORS}
            />
          ) : isLoadingCustomer ? (
            <FormGroup label="Credit Card">
              <div className="min-h-[70px] md:min-h-[55px]" />
            </FormGroup>
          ) : defaultCard ? (
            <FormGroup label="Credit Card">
              <div className="space-y-2">
                <div className="flex items-center space-x-4">
                  <div className="w-14 shrink-0">
                    <CreditCardIcon brand={defaultCard.brand} />
                  </div>
                  <span>x{defaultCard.last4}</span>
                </div>
                <p className="text-k/12_120 text-grey-9">
                  Your information is populated from your Tovala account.
                </p>
              </div>
            </FormGroup>
          ) : (
            <div className="space-y-4">
              <FormGroup label="Enter Credit Card">
                <div className="space-y-2">
                  <CreditCardInput />
                  <p className="text-k/12_120 text-grey-9">
                    Your credit card will be saved to your Tovala account upon
                    submission.
                  </p>
                </div>
              </FormGroup>
            </div>
          )}

          {purchaseAPIError ? (
            <APIErrorDisplay
              error={purchaseAPIError}
              errorCodeMessageMap={BUY_GIFT_CARD_ERRORS}
            />
          ) : submissionError ? (
            <ErrorDisplay {...submissionError} />
          ) : null}
        </div>

        <div className="rounded-lg bg-grey-2 p-6 md:row-start-1 md:p-4">
          <div className="divide-y divide-grey-4">
            <div className="flex justify-between pb-4">
              <span>E-Gift Card</span>
              <span>${amount}</span>
            </div>
            <div className="flex justify-between pt-4 font-bold">
              <span>Total</span>
              <span>${amount}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row-reverse justify-center gap-4">
        <ButtonLoading
          disabled={hasErrorLoadingCustomer}
          isLoading={isSubmitting}
          size="large"
        >
          Buy Gift
        </ButtonLoading>
        <Button
          buttonStyle="stroke"
          onClick={() => {
            onClickBack()
          }}
          size="large"
        >
          Back
        </Button>
      </div>
    </form>
  )
}

const GiftCardPurchased = () => {
  return (
    <div className="space-y-12">
      <h1 className="text-center text-k/44_110 font-medium md:text-k/32_105">
        🎉 Thank You! 🎉
      </h1>

      <p className="text-center text-k/20_125">
        Your gift card is being processed and
        <br /> your recipient will receive an email shortly.
      </p>

      <div className="flex justify-center">
        <div className="w-40">
          <Link size="large" to="/my-orders">
            Back to My Tovala
          </Link>
        </div>
      </div>
    </div>
  )
}
