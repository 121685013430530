import { clsx } from 'clsx'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'

import { getGoodErrorMessagingParagraph } from 'utils/errors'
import { GoodErrorMessaging } from 'types/internal'

export type Props = GoodErrorMessaging & {
  captureSentryError?: boolean
  display?: 'inline' | 'page'
  sentryError?: Error | null
}

/**
 * This component is inspired by the excellent work the Wix team did to improve their error messaging.
 * You can read the full article here: https://wix-ux.com/when-life-gives-you-lemons-write-better-error-messages-46c5223e1a2f.
 *
 * The order of the text goes: reassurance, why, helpToFix, and finally wayOut. "whatHappened" is a heading
 * and doesn't need punctuation.
 */
const ErrorDisplay = ({
  captureSentryError = true,
  display = 'inline',
  sentryError,
  whatHappened = '',
  ...rest
}: Props): JSX.Element => {
  useEffect(() => {
    if (captureSentryError && sentryError) {
      Sentry.captureException(sentryError)
    }
  }, [captureSentryError, sentryError])

  return (
    <div
      className={clsx('rounded-2xl bg-red-faded', {
        'space-y-1 p-4': display === 'inline',
        'space-y-6 p-10 md:p-6': display === 'page',
      })}
    >
      {whatHappened && (
        <div
          className={clsx({
            'text-h/14_120': display === 'inline',
            'text-k/20_125 font-bold': display === 'page',
          })}
        >
          {whatHappened}
        </div>
      )}

      <p
        className={clsx('text-grey-10', {
          'text-h/14_120': display === 'inline',
          'text-body-lg': display === 'page',
        })}
      >
        {getGoodErrorMessagingParagraph(rest)}
      </p>
    </div>
  )
}

export default ErrorDisplay
