import {
  ButtonLoading,
  CaretDownIcon,
  CaretUpIcon,
} from '@tovala/component-library'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import { convertBooleanToString } from 'utils/general'
import {
  ErrorCodeMessageMapCombinedAPI,
  SubscriptionPreferences,
} from 'types/internal'
import { events } from '../../../analytics/events'
import { getSubscriptionPreferences } from 'utils/subscriptions'
import { track } from 'utils/analytics'

import { useFeatures } from 'contexts/features'
import { useToast } from 'contexts/toast'
import { useUpdatePlanPreferences } from 'hooks/combinedAPI/subscriptions'
import { useUser } from 'contexts/user'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import Collapsible, {
  CollapsibleContent,
  CollapsibleTrigger,
} from 'components/common/Collapsible'
import ProfileHeading from './AccountHeading'
import RadioRHF from 'components/common/RadioRHF'

interface AutoselectedMealsFormData {
  autofillBreakfastOK: string | null
  autofillSurchargeOK: string | null
  isDouble: string | null
}

interface LocationState {
  open?: 'autoselectedMeals' | 'mealSubstitutions' | 'surchargedMeals'
}

interface MealSubstitutionsFormData {
  doNotReplace: string | null
}

interface SurchargedMealsFormData {
  premiumMealsOk: string | null
}

const UPDATE_PREFERENCES_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't update your preferences due to a technical issue on our end.",
  },
}

const MealPlanPreferences = () => {
  const features = useFeatures()
  const { user } = useUser()

  const planPreferences = getSubscriptionPreferences({ user })

  return (
    <div className="w-full max-w-account">
      <div className="hidden md:block">
        <ProfileHeading to="/account">Plan Preferences</ProfileHeading>
      </div>
      <div className="space-y-4 md:mx-4 md:mt-6">
        <AutoselectedMeals planPreferences={planPreferences} />
        <MealSubstitutions planPreferences={planPreferences} />
        {features.showSurchargePrompt && (
          <SurchargedMeals planPreferences={planPreferences} />
        )}
      </div>
    </div>
  )
}

export default MealPlanPreferences

const PreferenceHeader = ({
  description,
  heading,
  onClick,
  open,
}: {
  description: string
  heading: string
  onClick?: () => void
  open: boolean
}) => {
  return (
    <CollapsibleTrigger
      className="flex w-full items-center justify-between space-x-8 p-6"
      onClick={onClick}
    >
      <h2 className="shrink-0 text-k/18_120">{heading}</h2>
      <div className="flex w-px grow items-center justify-end">
        {!open && (
          <span className="truncate whitespace-nowrap pr-3 text-k/16_125 md:hidden">
            {description}
          </span>
        )}
        {open ? (
          <div className="h-6 w-6">
            <CaretUpIcon />
          </div>
        ) : (
          <div className="h-6 w-6">
            <CaretDownIcon />
          </div>
        )}
      </div>
    </CollapsibleTrigger>
  )
}

const AutoselectedMeals = ({
  planPreferences,
}: {
  planPreferences: SubscriptionPreferences
}) => {
  const location = useLocation()
  const locationState = location.state as LocationState

  const preferencesValuesString = `
    ${planPreferences.single === true ? 'Single servings' : 'Double servings'},
    ${
      planPreferences.autofillSurchargeOK === true
        ? 'Surcharged OK'
        : 'No Surcharge'
    },
    ${
      planPreferences.autofillBreakfastOK === true
        ? 'Breakfast OK'
        : 'No Breakfast'
    }

  `

  return (
    <div className="rounded-lg bg-grey-2">
      <Collapsible defaultOpen={locationState?.open === 'autoselectedMeals'}>
        {({ open }) => {
          return (
            <>
              <PreferenceHeader
                description={preferencesValuesString}
                heading="Autoselected Meals"
                open={open}
              />
              <CollapsibleContent open={open}>
                {({ close }) => {
                  return (
                    <AutoselectedMealsForm
                      onSavePreferences={() => {
                        close()
                      }}
                      planPreferences={planPreferences}
                    />
                  )
                }}
              </CollapsibleContent>
            </>
          )
        }}
      </Collapsible>
    </div>
  )
}

const AutoselectedMealsForm = ({
  onSavePreferences,
  planPreferences,
}: {
  onSavePreferences(): void
  planPreferences: SubscriptionPreferences
}) => {
  const { openToast } = useToast()

  const { user } = useUser()

  const { handleSubmit, register, watch } = useForm<AutoselectedMealsFormData>({
    defaultValues: {
      autofillBreakfastOK: convertBooleanToString(
        planPreferences.autofillBreakfastOK
      ),
      autofillSurchargeOK: convertBooleanToString(
        planPreferences.autofillSurchargeOK
      ),
      isDouble: convertBooleanToString(planPreferences.isDouble),
    },
  })

  const values = watch()

  const autoselectPreferences = [
    {
      title: 'Paired Meals',
      description:
        "We'll send you meals in pairs. (Ideal for two people who eat together.)",
      name: 'isDouble',
    },
    {
      title: 'Surcharged Meals',
      description:
        "If available, we'll include higher-end meals featuring more expensive ingredients for a surcharge.",
      name: 'autofillSurchargeOK',
    },
    {
      title: 'Breakfast Meals',
      description:
        "If available, we'll include breakfast meals in our selection.",
      name: 'autofillBreakfastOK',
    },
  ] as const

  const {
    error: updatePlanPreferencesError,
    isError: hasUpdatePlanPreferencesError,
    isLoading: isUpdatingPlanPreferences,
    mutate: updatePlanPreferences,
  } = useUpdatePlanPreferences({
    onSuccess: () => {
      openToast({
        heading: 'Changes Saved',
        message: 'Your Autoselected Meals setting has been updated.',
        type: 'success',
      })

      onSavePreferences()
    },
  })

  const onSubmit = (values: AutoselectedMealsFormData) => {
    const updatedPreferences = { ...planPreferences }
    // Paired meal preference is sent in request as "single" (but received in response as isDouble), so take the negative of isDouble
    updatedPreferences.single = values.isDouble === 'false'
    updatedPreferences.autofillBreakfastOK =
      values.autofillBreakfastOK === 'true'
    updatedPreferences.autofillSurchargeOK =
      values.autofillSurchargeOK === 'true'

    updatePlanPreferences({ data: updatedPreferences, userID: user.id })
  }

  return (
    <form
      className="px-6 pb-6"
      onSubmit={handleSubmit((values) => onSubmit(values))}
    >
      <div>
        <p className="mb-6 text-body-sm text-grey-9">
          For weeks you don't choose meals, skip, or have your meal plan paused,
          we'll automatically send you a chef-curated selection of meals and
          charge your card.
        </p>

        <p className="mb-4 text-h/14_120 font-semibold">
          When I don't select meals, send me:
        </p>

        <div className="mb-6 space-y-4">
          {autoselectPreferences.map(({ title, description, name }) => {
            return (
              <div key={title} className="flex items-start justify-between">
                <div>
                  <span className="text-k/16_125">{title}</span>
                  <p className="mt-1 text-body-sm text-grey-9">{description}</p>
                </div>
                <div className="flex shrink-0 items-center space-x-4 text-body-sm text-grey-9">
                  <RadioRHF
                    checked={values[name] === 'false'}
                    label={<span className="pr-2">No</span>}
                    name={name}
                    register={register}
                    value="false"
                  />
                  <RadioRHF
                    checked={values[name] === 'true'}
                    label={<span className="pr-2">Yes</span>}
                    name={name}
                    register={register}
                    value="true"
                  />
                </div>
              </div>
            )
          })}
        </div>

        {hasUpdatePlanPreferencesError && (
          <div className="my-4">
            <APIErrorDisplay
              error={updatePlanPreferencesError}
              errorCodeMessageMap={UPDATE_PREFERENCES_ERRORS}
            />
          </div>
        )}

        <ButtonLoading
          isLoading={isUpdatingPlanPreferences}
          size="medium"
          type="submit"
        >
          Update Autoselected Meals
        </ButtonLoading>
      </div>
    </form>
  )
}

const MealSubstitutions = ({
  planPreferences,
}: {
  planPreferences: SubscriptionPreferences
}) => {
  const location = useLocation()
  const locationState = location.state as LocationState

  return (
    <div className="rounded-lg bg-grey-2">
      <Collapsible defaultOpen={locationState?.open === 'mealSubstitutions'}>
        {({ open }) => {
          return (
            <>
              <PreferenceHeader
                description={
                  planPreferences.doNotReplace === true
                    ? 'Do not substitute'
                    : 'Substitutions OK'
                }
                heading="Meal Substitutions"
                onClick={() => {
                  if (!open) {
                    track(events.OPENS_MEAL_SUBSTITUTIONS_PREFERENCE)
                  }
                }}
                open={open}
              />
              <CollapsibleContent open={open}>
                {({ close }) => {
                  return (
                    <MealSubstitutionsForm
                      onSavePreferences={() => {
                        close()
                      }}
                      planPreferences={planPreferences}
                    />
                  )
                }}
              </CollapsibleContent>
            </>
          )
        }}
      </Collapsible>
    </div>
  )
}

const MealSubstitutionsForm = ({
  onSavePreferences,
  planPreferences,
}: {
  onSavePreferences(): void
  planPreferences: SubscriptionPreferences
}) => {
  const { openToast } = useToast()

  const { user } = useUser()

  const { handleSubmit, register, watch } = useForm<MealSubstitutionsFormData>({
    defaultValues: {
      doNotReplace: convertBooleanToString(planPreferences.doNotReplace),
    },
  })

  const doNotReplace = watch('doNotReplace')

  const substitutionOptions = [
    {
      label: "I'm fine with a meal substitution.",
      description:
        'For those with dietary restrictions, please note we cannot guarantee substitutions will be similar to your original selection.',
      value: 'false',
    },
    {
      label: "Don't substitute. \nI'd rather receive fewer meals.",
      description: "You will not be charged for any meals you don't receive.",
      value: 'true',
    },
  ]

  const {
    error: updatePlanPreferencesError,
    isError: hasUpdatePlanPreferencesError,
    isLoading: isUpdatingPlanPreferences,
    mutate: updatePlanPreferences,
  } = useUpdatePlanPreferences({
    onSuccess: () => {
      openToast({
        heading: 'Changes Saved',
        message: 'Your Meal Substitutions setting has been updated.',
        type: 'success',
      })

      onSavePreferences()
    },
  })

  const onSubmit = (values: MealSubstitutionsFormData) => {
    const updatedPreferences = { ...planPreferences }
    updatedPreferences.doNotReplace = values.doNotReplace === 'true'

    updatePlanPreferences({ data: updatedPreferences, userID: user.id })
  }

  return (
    <form
      className="px-6 pb-6"
      onSubmit={handleSubmit((values) => onSubmit(values))}
    >
      <div>
        <p className="mb-6 text-body-sm text-grey-9">
          In the very rare case a meal you select becomes unavailable, please
          let us know what you'd prefer.
        </p>

        <p className="mb-4 text-h/14_120 font-semibold">
          If a meal I order becomes unavailable:
        </p>

        <div className="mb-6 space-y-4">
          {substitutionOptions.map(({ description, label, value }) => {
            return (
              <RadioRHF
                key={label}
                checked={doNotReplace === value}
                label={
                  <div className="pr-7">
                    <span className="text-k/16_125">{label}</span>
                    <p className="mt-1 text-body-sm text-grey-9">
                      {description}
                    </p>
                  </div>
                }
                name="doNotReplace"
                register={register}
                value={value}
              />
            )
          })}
        </div>

        {hasUpdatePlanPreferencesError && (
          <div className="my-4">
            <APIErrorDisplay
              error={updatePlanPreferencesError}
              errorCodeMessageMap={UPDATE_PREFERENCES_ERRORS}
            />
          </div>
        )}

        <ButtonLoading
          isLoading={isUpdatingPlanPreferences}
          size="medium"
          type="submit"
        >
          Update Meal Substitutions
        </ButtonLoading>
      </div>
    </form>
  )
}

const SurchargedMeals = ({
  planPreferences,
}: {
  planPreferences: SubscriptionPreferences
}) => {
  const location = useLocation()
  const locationState = location.state as LocationState

  return (
    <div className="rounded-lg bg-grey-2">
      <Collapsible defaultOpen={locationState?.open === 'surchargedMeals'}>
        {({ open }) => {
          return (
            <>
              <PreferenceHeader
                description={
                  planPreferences.premiumMealsOk === true
                    ? "Don't ask before adding"
                    : 'Ask before adding'
                }
                heading="Surcharged Meals"
                onClick={() => {
                  if (!open) {
                    track(events.OPENS_PREMIUM_MEALS_PREFERENCE)
                  }
                }}
                open={open}
              />
              <CollapsibleContent open={open}>
                {({ close }) => {
                  return (
                    <SurchargedMealsForm
                      onSavePreferences={() => {
                        close()
                      }}
                      planPreferences={planPreferences}
                    />
                  )
                }}
              </CollapsibleContent>
            </>
          )
        }}
      </Collapsible>
    </div>
  )
}

const SurchargedMealsForm = ({
  onSavePreferences,
  planPreferences,
}: {
  onSavePreferences(): void
  planPreferences: SubscriptionPreferences
}) => {
  const { openToast } = useToast()

  const { user } = useUser()

  const { handleSubmit, register, watch } = useForm<SurchargedMealsFormData>({
    defaultValues: {
      premiumMealsOk: convertBooleanToString(planPreferences.premiumMealsOk),
    },
  })

  const premiumMealsOk = watch('premiumMealsOk')

  const premiumMealsOptions = [
    {
      label: 'Ask me to verify every time before adding',
      value: 'false',
    },
    {
      label: "Don't ask me to verify every time before adding",
      value: 'true',
    },
  ]

  const {
    error: updatePlanPreferencesError,
    isError: hasUpdatePlanPreferencesError,
    isLoading: isUpdatingPlanPreferences,
    mutate: updatePlanPreferences,
  } = useUpdatePlanPreferences({
    onSuccess: () => {
      track(events.CHANGES_PREMIUM_PREFERENCE)

      openToast({
        heading: 'Changes Saved',
        message: 'Your Surcharged Meals setting has been updated.',
        type: 'success',
      })

      onSavePreferences()
    },
  })

  const onSubmit = (values: SurchargedMealsFormData) => {
    const updatedPreferences = { ...planPreferences }
    updatedPreferences.premiumMealsOk = values.premiumMealsOk === 'true'

    updatePlanPreferences({ data: updatedPreferences, userID: user.id })
  }

  return (
    <form
      className="px-6 pb-6"
      onSubmit={handleSubmit((values) => onSubmit(values))}
    >
      <div>
        <p className="mb-6 text-body-sm text-grey-9">
          Do you want us to verify your meal selection every time you add a
          surcharged meal to your delivery?
        </p>

        <div className="mb-6 space-y-4">
          {premiumMealsOptions.map(({ label, value }) => {
            return (
              <RadioRHF
                key={label}
                checked={premiumMealsOk === value}
                label={
                  <div className="pr-7">
                    <span className="text-k/16_125">{label}</span>
                  </div>
                }
                name="premiumMealsOk"
                register={register}
                value={value}
              />
            )
          })}
        </div>

        {hasUpdatePlanPreferencesError && (
          <div className="my-4">
            <APIErrorDisplay
              error={updatePlanPreferencesError}
              errorCodeMessageMap={UPDATE_PREFERENCES_ERRORS}
            />
          </div>
        )}

        <ButtonLoading
          isLoading={isUpdatingPlanPreferences}
          size="medium"
          type="submit"
        >
          Update Surcharged Meals
        </ButtonLoading>
      </div>
    </form>
  )
}
