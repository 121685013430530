import { FormFieldError } from '@tovala/component-library'
import { OrderHistoryReceipt } from '@tovala/browser-apis-combinedapi'
import { useFormContext } from 'react-hook-form'

import CheckboxRHF from 'components/common/CheckboxRHF'
import Meal from './Meal'

const AffectedMeals = ({ receipt }: { receipt: OrderHistoryReceipt }) => {
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext()
  const affectedMeals = watch('affectedMeals')

  return (
    <>
      <p className="text-k/16_125">
        Select which meal(s) had missing or unusable ingredients
      </p>

      <div className="space-y-4">
        {[...receipt.selectedMeals]
          .sort((a, b) => a.id - b.id)
          .map((meal, index) => {
            const mealID = `${meal.id}-${index}`

            return (
              <label
                key={mealID}
                className="flex items-center justify-between space-x-4"
                htmlFor={mealID}
              >
                <Meal meal={meal} />

                <CheckboxRHF
                  checked={affectedMeals.includes(mealID)}
                  id={mealID}
                  name="affectedMeals"
                  register={register}
                  registerOptions={{
                    required: 'Please select at least one meal',
                  }}
                  value={mealID}
                />
              </label>
            )
          })}
      </div>

      {errors.affectedMeals?.message && (
        <div className="mt-4">
          <FormFieldError>{`${errors.affectedMeals.message}`}</FormFieldError>
        </div>
      )}
    </>
  )
}

export default AffectedMeals
