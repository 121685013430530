import {
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form'

import Radio, { Props as RadioProps } from './Radio'

export type Props<FormData extends FieldValues> = Omit<
  RadioProps,
  'onChange' | 'onBlur'
> & {
  name: Path<FormData>
  registerOptions?: RegisterOptions
  register: UseFormRegister<FormData>
}

function RadioRHF<FormData extends FieldValues>({
  name,
  register,
  registerOptions,
  ...rest
}: Props<FormData>) {
  const {
    onChange,
    onBlur,
    name: inputName,
    ref,
  } = register(name, registerOptions)

  return (
    <Radio
      {...rest}
      ref={ref}
      name={inputName}
      onBlur={onBlur}
      onChange={onChange}
    />
  )
}

export default RadioRHF
