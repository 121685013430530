import Link from 'components/common/Link'

const NotFoundPage = () => {
  return (
    <div className="my-20 flex justify-center">
      <div className="w-[666px] max-w-full space-y-10 px-4">
        <div className="flex flex-col items-center space-y-6">
          <h1 className="text-k/44_110">Page Not Found</h1>

          <p className="text-center text-body-lg text-grey-9">
            The page you were looking for cannot be found. Don't worry, you can
            head back to your previous page or navigate to another.
          </p>
        </div>

        <video autoPlay loop muted playsInline>
          <source
            src="//cdn.tovala.com/tovala.com/video/404-adam.mp4"
            type="video/mp4"
          />
          <source
            src="//cdn.tovala.com/tovala.com/video/404-adam.ogv"
            type="video/ogv"
          />
          <source
            src="//cdn.tovala.com/tovala.com/video/404-adam.webm"
            type="video/webm"
          />
        </video>

        <div className="flex justify-center">
          <Link size="large" to="/my-orders">
            My Orders
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage
