import { assign, createMachine } from 'xstate'
import {
  CreateUserResponse,
  AssociateRetailUserResponse,
} from '@tovala/browser-apis-combinedapi'

export interface NameFormData {
  firstName: string
  lastName: string
  phoneNumber?: string
}

export interface AccountFormData {
  email: string
  password: string
}

export interface RetailChannelData {
  retailChannel?: string
  location?: string
}

export interface RegistrationContext {
  info: NameFormData & AccountFormData & RetailChannelData
}

export type RegistrationEvents =
  | {
      formData: {
        email: string
        password: string
        retailChannel?: string
        location?: string
      }
      type: 'ACCOUNT_DETAILS_ENTERED'
    }
  | { type: 'BACK' }
  | {
      formData: {
        firstName: string
        lastName: string
        retailChannel?: string
        location?: string
      }
      type: 'NAME_ENTERED'
    }

type RegistrationServices = {
  submitRegistration: { data: CreateUserResponse }
  submitAccountAssociation: { data: AssociateRetailUserResponse }
}

const INITIAL_CONTEXT = {
  info: {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    retailChannel: undefined,
    location: undefined,
  },
} satisfies RegistrationContext

export const registrationMachine = createMachine(
  {
    id: 'registration',
    tsTypes: {} as import('./registrationMachine.typegen').Typegen0,
    schema: {
      context: {} as RegistrationContext,
      events: {} as RegistrationEvents,
      services: {} as RegistrationServices,
    },
    context: INITIAL_CONTEXT,
    predictableActionArguments: true,

    initial: 'enteringName',
    states: {
      enteringName: {
        on: {
          NAME_ENTERED: {
            actions: ['storeData', 'trackDoneName'],
            target: 'enteringAccountDetails',
          },
        },
      },
      enteringAccountDetails: {
        on: {
          ACCOUNT_DETAILS_ENTERED: {
            actions: ['storeData'],
            target: 'registering',
          },
          BACK: { target: 'enteringName' },
        },
      },
      registering: {
        // We remove the user's current token because they may be registering for
        // a new account. So when they submit their registration we'll authenticate
        // as the newly created account or the user will be logged out and will have
        // to choose which account to log back in to. Note: This is only relevant since
        // an authenticated user can register for a new account.
        entry: ['removeCurrentToken', 'trackSubmitRegistration'],
        invoke: {
          src: 'submitRegistration',
          onDone: [
            {
              actions: [
                'saveNewToken',
                'markRegistrationComplete',
                'trackUserRegistered',
                'trackUserAsscoiatedToRetailChannel',
              ],
              cond: 'hasRetailChannelInfo',
              target: 'updatingRetailChannel',
            },
            {
              actions: [
                'saveNewToken',
                'markRegistrationComplete',
                'trackUserRegistered',
              ],
              target: 'registered',
            },
          ],
          onError: {
            actions: ['trackRegistrationFailed'],
            target: 'enteringAccountDetails',
          },
        },
      },
      updatingRetailChannel: {
        invoke: {
          src: 'submitAccountAssociation',
          onDone: {
            target: 'registeredAsRetailUser',
          },
          onError: {
            target: 'registeredAsRetailUser',
          },
        },
      },
      registered: { entry: ['redirectToOrders'], type: 'final' },
      registeredAsRetailUser: {
        entry: ['redirectToAppDownload'],
        type: 'final',
      },
    },
  },
  {
    actions: {
      storeData: assign((ctx, event) => {
        return { ...ctx, info: { ...ctx.info, ...event.formData } }
      }),
    },
    guards: {
      hasRetailChannelInfo: (ctx) => {
        return !!(ctx.info.retailChannel || ctx.info.location)
      },
    },
  }
)
