import {
  Meal,
  MealFilter,
  MealSelectionPricing,
  MealSuggestions,
  MealSummary,
  TermStatus,
  TermStatusMealSelection,
} from '@tovala/browser-apis-combinedapi'

import { apiDelete, get, post, put } from './baseAPI'

export interface AddMealSelection {
  data: { mealid: number; termid: number; userid: number }
  userID: number
}

export interface AddMealSelectionResponse {
  selection: TermStatusMealSelection
  termStatus: TermStatus
}

export interface DeleteMealSelection {
  selectionID: string
  userID: number
}

export interface DeleteMealSelectionResponse {
  mealSelections: TermStatus['mealSelections']
  mealSelectionsPricing: MealSelectionPricing
  subscriptionPricing: MealSelectionPricing
}

export type GetMealFiltersResponse = MealFilter[]

export interface GetMealSuggestions {
  userID: number
}

export type GetMealSuggestionsResponse = MealSuggestions

export interface GetMealSummaries {
  subTermID: string
}

export type GetMealSummariesResponse = MealSummary[]

export interface GetMealsInFilters {
  subTermID: string
}

export type GetMealsInFiltersResponse = {
  key: string
  mealsInFilter: number[]
}[]

export interface GetUserMeal {
  mealID: number
  userID: number
}

export interface GetUserMealResponse {
  meal: Meal
}

export interface UpdateMealSelectionOption {
  data: {
    mealID: number
    mealOption: string | null
    currentMealOption?: string
  }
  subTermID: string
  userID: number
}

export interface UpdateMealSelectionOptionResponse {
  error: boolean
  message: string
}

export const ENDPOINTS = {
  ADD_MEAL_SELECTION: ({ userID }: Pick<AddMealSelection, 'userID'>) => ({
    path: `/users/${userID}/mealSelections`,
    version: 'v1' as const,
  }),
  DELETE_MEAL_SELECTION: ({
    selectionID,
    userID,
  }: Pick<DeleteMealSelection, 'selectionID' | 'userID'>) => ({
    path: `/users/${userID}/mealSelections/${selectionID}`,
    version: 'v1' as const,
  }),
  GET_MEAL_FILTERS: () => ({
    path: '/filters/meals',
    version: 'v1' as const,
  }),
  GET_MEAL_SUGGESTIONS: ({ userID }: GetMealSuggestions) => ({
    path: `/mealsuggestions/${userID}`,
    version: 'v1' as const,
  }),
  GET_MEAL_SUMMARIES: ({ subTermID }: GetMealSummaries) => ({
    path: `/subTerms/${subTermID}/mealSummaries`,
    version: 'v1' as const,
  }),
  GET_MEALS_IN_FILTERS: ({ subTermID }: GetMealsInFilters) => ({
    path: `/subTerms/${subTermID}/filters`,
    version: 'v1' as const,
  }),
  GET_USER_MEAL: ({ mealID, userID }: GetUserMeal) => ({
    path: `/users/${userID}/meals/${mealID}`,
    version: `v1` as const,
  }),
  UPDATE_MEAL_SELECTION_OPTION: ({
    subTermID,
    userID,
  }: Pick<UpdateMealSelectionOption, 'subTermID' | 'userID'>) => ({
    path: `/users/${userID}/subterms/${subTermID}/mealselections`,
    version: `v1` as const,
  }),
}

export async function addMealSelection({
  data,
  userID,
}: AddMealSelection): Promise<AddMealSelectionResponse> {
  return post({
    data,
    ...ENDPOINTS.ADD_MEAL_SELECTION({ userID }),
  })
}

export async function deleteMealSelection({
  selectionID,
  userID,
}: DeleteMealSelection): Promise<DeleteMealSelectionResponse> {
  return apiDelete(ENDPOINTS.DELETE_MEAL_SELECTION({ selectionID, userID }))
}

export async function getMealFilters(): Promise<GetMealFiltersResponse> {
  return get(ENDPOINTS.GET_MEAL_FILTERS())
}

export async function getMealSuggestions({
  userID,
}: GetMealSuggestions): Promise<GetMealSuggestionsResponse> {
  return get(ENDPOINTS.GET_MEAL_SUGGESTIONS({ userID }))
}

export async function getMealSummaries({
  subTermID,
}: GetMealSummaries): Promise<GetMealSummariesResponse> {
  return get(ENDPOINTS.GET_MEAL_SUMMARIES({ subTermID }))
}

export async function getMealsInFilters({
  subTermID,
}: GetMealsInFilters): Promise<GetMealsInFiltersResponse> {
  return get(ENDPOINTS.GET_MEALS_IN_FILTERS({ subTermID }))
}

export async function getUserMeal({
  mealID,
  userID,
}: GetUserMeal): Promise<GetUserMealResponse> {
  return get(ENDPOINTS.GET_USER_MEAL({ mealID, userID }))
}

export async function updateMealSelectionOption({
  data,
  subTermID,
  userID,
}: UpdateMealSelectionOption): Promise<UpdateMealSelectionOptionResponse> {
  return put({
    data,
    ...ENDPOINTS.UPDATE_MEAL_SELECTION_OPTION({ subTermID, userID }),
  })
}
