import { ReactNode } from 'react'

import MealImage from './MealImage'

interface MealSuggestion {
  id: number
  image: { url: string }
  stepper: ReactNode
  surcharge?: string | null
  title: string
}

/*
 * Previous meals the user has rated highly that are on the menu being viewed and
 * we want to suggest they order again. It's assumed that all provided suggestions
 * are not sold out.
 */
const MealSuggestions = ({
  mealSuggestions,
  onClickMeal,
}: {
  mealSuggestions: MealSuggestion[]
  onClickMeal(mealID: number): void
}) => {
  return (
    <div className="space-y-6">
      <h2 className="text-k/24_120">Meals You Enjoyed</h2>

      <div className="grid grid-cols-2 gap-4 md:grid-cols-1">
        {mealSuggestions.map((suggestion) => {
          return (
            <div
              key={suggestion.id}
              className="flex items-center justify-between space-x-4"
            >
              <div
                className="flex cursor-pointer items-center space-x-4"
                onClick={() => {
                  onClickMeal(suggestion.id)
                }}
              >
                <div className="h-[86px] w-[86px] shrink-0 overflow-hidden rounded-lg">
                  <MealImage image={suggestion.image} />
                </div>

                <div className="text-k/12_120">
                  {suggestion.surcharge && <span>{suggestion.surcharge}</span>}

                  <h3 className="text-k/14_120">{suggestion.title}</h3>
                </div>
              </div>

              <div className="flex min-w-[105px] shrink-0 justify-end">
                {suggestion.stepper}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MealSuggestions
