import { forwardRef } from 'react'

const MealTag = forwardRef<HTMLDivElement, { children: string }>(
  function MealTag({ children }, ref) {
    return (
      <div
        ref={ref}
        className="flex h-6 items-center justify-start whitespace-nowrap rounded-[4px] bg-grey-3 px-2 text-h/11_120 font-semibold uppercase"
      >
        {children}
      </div>
    )
  }
)

export default MealTag
