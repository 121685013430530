import {
  ArrowLeftIcon,
  Button,
  MealCarousel,
  MealWithExtra,
} from '@tovala/component-library'
import { clsx } from 'clsx'
import { PublicTermStatus } from '@tovala/browser-apis-combinedapi'
import { useNavigate } from 'react-router-dom'

import { DATE_FORMATS, formatDate } from 'utils/dates'
import { events, sourceIDs } from 'analytics/events'
import { getMenuMealComponents } from 'utils/menuComponents'
import { getTotalCreditsCents } from 'utils/user'
import { track } from 'utils/analytics'

import { useFeatures } from 'contexts/features'
import { useDetailsDialog } from 'hooks/mealDetails'
import { useMealsSignUp } from 'hooks/mealsSignUp'
import { useMealSummaries } from 'hooks/combinedAPI/meals'
import { useMenuComponents } from 'hooks/menus'
import { useUser } from 'contexts/user'
import { useVariantByScreenSize } from 'hooks/variantByScreenSize'

import BackgroundImageHeader from './BackgroundImageHeader'
import CreditsBanner from './CreditsBanner'
import Link from 'components/common/Link'
import Meal from './Meal'
import MealDetailsDialog from './MealDetailsDialog'
import MenuFixedBottomContainer from './MenuFixedBottomContainer'
import MenuGridLayout from './MenuGridLayout'
import SpecialEventBanner from './SpecialEventBanner'
import StatusDot from './StatusDot'
import StickyHeader from './StickyHeader'
import TextImageStack from './TextImageStack'
import TwoMealPicker from './TwoMealPicker'

import {
  MenuComponentStandardizedMeal,
  MenuComponentStandardizedMealCarousel,
  MenuComponentStandardizedMealWithExtra,
  MenuComponentStandardizedTwoMealPicker,
  MenuComponentStandardizedTwoMealPickerMeal,
  MenuComponentsStandardized,
} from 'types/internal'

const PublicMenu = ({
  selectedPublicTerm,
}: {
  selectedPublicTerm: PublicTermStatus
}) => {
  const features = useFeatures()
  const { user } = useUser()
  const isPaused = user.subscription.status === 'paused'

  const { closeDetailsDialog, detailsType, meal, openDetailsDialog } =
    useDetailsDialog({
      termID: selectedPublicTerm.termID,
    })

  const { data: mealSummaries = [] } = useMealSummaries({
    subTermID: selectedPublicTerm.selectedSubTermID,
  })

  const { components } = useMenuComponents({
    subTermID: selectedPublicTerm.selectedSubTermID,
  })

  const resolvedMenuComponents = getMenuMealComponents({
    defaultUIMealWithOneSwap: features.defaultUIMealWithOneSwap,
    mealSwaps: selectedPublicTerm.subTerms[0].mainMenu.mealSwaps,
    meals: mealSummaries,
    menuComponents: components,
    specialEvent: selectedPublicTerm.specialEvent,
    suggestions: [],
  })

  const { link } = useMealsSignUp({ utmSource: 'menu' })

  return (
    <div>
      <div className="mb-20 md:mb-12">
        <PublicMenuHeader startDate={selectedPublicTerm.startDate} />
      </div>

      <div className="pb-40">
        <PublicMenuComponentsGrid
          components={resolvedMenuComponents}
          onClickMeal={(id) => {
            openDetailsDialog({
              id,
              type: 'meal',
            })
          }}
        />
      </div>

      <MenuFixedBottomContainer>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <div className="h-4 w-4 md:h-3 md:w-3">
              <StatusDot color={isPaused ? 'grey' : 'orange'} />
            </div>

            <span className="text-k/20_110">
              {isPaused ? 'Plan Paused' : 'No Meal Plan'}
            </span>
          </div>

          <div>
            {isPaused ? (
              <Link
                linkStyle="dark"
                linkType={link.type}
                onClick={() => {
                  track(events.ORDER_RESUME_PLAN_CTA, {
                    source_id: sourceIDs.MENU_TERM,
                  })
                }}
                size="large"
                to={link.to}
              >
                Resume Plan
              </Link>
            ) : (
              <Link
                linkStyle="dark"
                linkType={link.type}
                onClick={() => {
                  track(events.TAPPED_MENU_CTA_GET_STARTED)
                }}
                size="large"
                to={link.to}
              >
                Sign Up
              </Link>
            )}
          </div>
        </div>
      </MenuFixedBottomContainer>

      {detailsType === 'meal' && meal && (
        <MealDetailsDialog closeModal={closeDetailsDialog} meal={meal} />
      )}
    </div>
  )
}

export default PublicMenu

const PublicMenuComponentsGrid = ({
  components,
  onClickMeal,
}: {
  components: MenuComponentsStandardized
  onClickMeal(mealID: number): void
}) => {
  const bgImageHeaderVariant = useVariantByScreenSize<
    'bgImageHeaderMixed' | 'bgImageHeaderTop'
  >('bgImageHeaderMixed', {
    md: 'bgImageHeaderTop',
  })

  function makeTwoMealPickerMeal(
    meal: MenuComponentStandardizedTwoMealPickerMeal
  ) {
    return {
      ...meal,
      quantity: 0,
    }
  }

  function renderMeal(component: MenuComponentStandardizedMeal) {
    const {
      id,
      image,
      imageTag,
      mealSummary,
      subtitle,
      surcharge,
      tags,
      title,
    } = component.properties

    return (
      <Meal
        image={image}
        imageTag={imageTag}
        isSoldOut={mealSummary.isSoldOut}
        onClickMeal={() => {
          onClickMeal(id)
        }}
        subtitle={subtitle}
        surcharge={surcharge}
        tags={tags}
        title={title}
      />
    )
  }

  function renderMealWithExtra(
    component: MenuComponentStandardizedMealWithExtra
  ) {
    const { meal, mealOption } = component.properties

    return (
      <MealWithExtra
        meal={
          <Meal
            image={meal.image}
            imageTag={meal.imageTag}
            isSoldOut={meal.mealSummary.isSoldOut}
            onClickMeal={() => {
              onClickMeal(meal.id)
            }}
            subtitle={meal.subtitle}
            surcharge={meal.surcharge}
            tags={meal.tags}
            title={meal.title}
          />
        }
        mealOption={{
          id: mealOption.meal.id,
          image: mealOption.meal.image,
          isDisabled: true,
          isHiddenByFilters: false,
          isSelected: false,
          isSoldOut:
            mealOption.meal.mealSummary.isSoldOut || meal.mealSummary.isSoldOut,
          surcharge: mealOption.meal.surcharge,
          title: mealOption.meal.mealSummary.shortSubtitle,
        }}
        onClickExtra={() => {
          onClickMeal(mealOption.detailsMealID)
        }}
      />
    )
  }

  function renderAnimatedMealCarousel(
    component: MenuComponentStandardizedMealCarousel
  ) {
    return (
      <MealCarousel
        buttonTitle={component.properties.buttonTitle}
        mealOptions={component.properties.mealOptions.map((mealOption) => {
          return {
            ...mealOption,
            quantity: 0,
          }
        })}
        onClickMeal={onClickMeal}
      />
    )
  }

  function renderTwoMealPicker(
    component: MenuComponentStandardizedTwoMealPicker
  ) {
    return (
      <TwoMealPicker
        meals={[
          makeTwoMealPickerMeal(component.properties.meals[0]),
          makeTwoMealPickerMeal(component.properties.meals[1]),
        ]}
        onClickMeal={onClickMeal}
        onClickMealOption={() => {
          // Pass
        }}
      />
    )
  }

  return (
    <div className="mx-auto max-w-menu lg:px-4 md:px-0">
      <MenuGridLayout>
        {components.map((component, index) => {
          if (component.type === 'meal') {
            return (
              <div key={`meal-${component.properties.id}`} className="md:px-4">
                {renderMeal(component)}
              </div>
            )
          } else if (component.type === 'mealWithExtra') {
            return (
              <div
                key={`mealWithExtra-${component.properties.meal.id}`}
                className="md:px-4"
              >
                {renderMealWithExtra(component)}
              </div>
            )
          } else if (component.type === 'twoMealPicker') {
            return (
              <div
                key={`twoMealPicker-${component.properties.meals[0].id}`}
                className="md:px-4"
              >
                {renderTwoMealPicker(component)}
              </div>
            )
          } else if (component.type === 'animatedMealCarousel') {
            return (
              <div
                key={`mealCarousel-${component.properties.mealOptions[0].id}`}
              >
                {renderAnimatedMealCarousel(component)}
              </div>
            )
          } else if (component.type === 'salmonBar') {
            return (
              <div
                key={`salmonBar-${index}`}
                className="col-span-2 md:col-span-1 md:px-4"
              >
                <SpecialEventBanner>
                  {component.properties.title}
                </SpecialEventBanner>
              </div>
            )
          } else if (component.type === 'backgroundImageHeader') {
            return (
              <div
                key={`backgroundImageHeader-${index}`}
                className={clsx('min-h-96 md:aspect-4/3 sm:aspect-square', {
                  '-mt-6':
                    index === 0 && bgImageHeaderVariant === 'bgImageHeaderTop',
                })}
              >
                <BackgroundImageHeader
                  image={component.properties.image}
                  subtitle={component.properties.subtitle}
                  subtitleColor={component.properties.subtitleColor}
                  title={component.properties.title}
                  titleColor={component.properties.titleColor}
                />
              </div>
            )
          } else if (component.type === 'textImageStack') {
            return (
              <div key={index} className="col-span-2 md:col-span-1">
                <TextImageStack
                  image={component.properties.image}
                  subtitle={component.properties.subtitle}
                  title={component.properties.title}
                >
                  <MenuGridLayout>
                    {component.properties.children.map((child) => {
                      if (child.type === 'meal') {
                        return (
                          <div
                            key={`meal-${child.properties.id}`}
                            className="md:px-4"
                          >
                            {renderMeal(child)}
                          </div>
                        )
                      } else if (child.type === 'mealWithExtra') {
                        return (
                          <div
                            key={`mealWithExtra-${child.properties.meal.id}`}
                            className="md:px-4"
                          >
                            {renderMealWithExtra(child)}
                          </div>
                        )
                      } else if (child.type === 'twoMealPicker') {
                        return (
                          <div
                            key={`twoMealPicker-${child.properties.meals[0].id}`}
                            className="md:px-4"
                          >
                            {renderTwoMealPicker(child)}
                          </div>
                        )
                      } else if (child.type === 'animatedMealCarousel') {
                        return (
                          <div
                            key={`mealCarousel-${child.properties.mealOptions[0].id}`}
                          >
                            {renderAnimatedMealCarousel(child)}
                          </div>
                        )
                      }
                    })}
                  </MenuGridLayout>
                </TextImageStack>
              </div>
            )
          }
        })}
      </MenuGridLayout>
    </div>
  )
}

const PublicMenuHeader = ({ startDate }: { startDate: string }) => {
  const navigate = useNavigate()
  const { user } = useUser()

  return (
    <StickyHeader>
      {({ isHeaderSticky }) => {
        return (
          <div className="mx-auto max-w-menu">
            <div
              className={clsx(
                'flex items-center border-b border-grey-3 lg:px-4 md:border-t md:border-grey-3 md:py-4',
                {
                  'py-6': isHeaderSticky,
                  'py-10': !isHeaderSticky,
                }
              )}
            >
              <div className="absolute">
                <Button
                  aria-label="My Orders"
                  buttonStyle="link"
                  onClick={() => {
                    navigate('/my-orders')
                  }}
                  size="auto"
                >
                  <div className="mr-2 h-6 w-6">
                    <ArrowLeftIcon />
                  </div>
                  <span className="text-k/16_125 md:hidden">My Orders</span>
                </Button>
              </div>

              <h1
                className={clsx('w-full text-center md:text-k/28_130', {
                  'text-k/36_110': isHeaderSticky,
                  'text-k/52_110': !isHeaderSticky,
                })}
              >
                {formatDate(startDate, {
                  format: DATE_FORMATS.MONTH_ABBR_DAY,
                })}
              </h1>
            </div>

            <CreditsBanner totalCreditsCents={getTotalCreditsCents({ user })} />
          </div>
        )
      }}
    </StickyHeader>
  )
}
