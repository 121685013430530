import { AnimatePresence, motion } from 'framer-motion'
import { clsx } from 'clsx'
import { MouseEventHandler, ReactNode, useState } from 'react'

import { MealTagCollapsible } from 'types/internal'

import MealImage from './MealImage'
import { MEAL_IMAGE_CONTAINER_CLASSES } from './Meal'
import MealImageSoldOut from './MealImageSoldOut'
import MealImageTag from './MealImageTag'
import MealTagsScrolling from './MealTagsScrolling'
import MealWithOptionsTitle from './MealWithOptionsTitle'

type MealOption = {
  buttonIcon?: { url: string }
  buttonTitle: string
  id: number
  image: { url: string }
  imageTag?: { icon?: string; title: string }
  isSoldOut: boolean
  quantity: number
  stepper?: ReactNode
  subtitle?: string
  surcharge?: string | null
  tags: MealTagCollapsible[]
  title: string
}

const TwoMealPicker = ({
  meals,
  onClickMeal,
  onClickMealOption,
}: {
  meals: [MealOption, MealOption]
  onClickMeal(mealID: number): void
  onClickMealOption(mealID: number): void
}) => {
  const [displayedMealIndex, setDisplayedMealIndex] = useState(0)
  const displayedMeal = meals[displayedMealIndex]

  return (
    <div>
      <AnimatePresence mode="wait">
        <motion.div
          key={displayedMeal.id}
          animate={{ opacity: 1 }}
          className={`relative ${MEAL_IMAGE_CONTAINER_CLASSES}`}
          exit={{ opacity: 0 }}
        >
          <div
            className="h-full w-full cursor-pointer overflow-hidden rounded-lg"
            onClick={() => {
              onClickMeal(displayedMeal.id)
            }}
          >
            <MealImage
              cover={
                displayedMeal.isSoldOut ? (
                  <MealImageSoldOut textSize="large" />
                ) : null
              }
              image={displayedMeal.image}
              tag={
                displayedMeal.imageTag ? (
                  <MealImageTag
                    icon={
                      displayedMeal.imageTag.icon ? (
                        <img src={displayedMeal.imageTag.icon} />
                      ) : undefined
                    }
                  >
                    {displayedMeal.imageTag.title}
                  </MealImageTag>
                ) : undefined
              }
            />
          </div>

          {displayedMeal.stepper && (
            <div className="absolute bottom-4 right-4">
              {displayedMeal.stepper}
            </div>
          )}
        </motion.div>
      </AnimatePresence>

      <div className="mb-4 mt-2">
        <div className="grid grid-cols-2 gap-3">
          {meals.map((meal, index) => {
            const isDisplayed = displayedMeal.id === meal.id

            return (
              <OptionButton
                key={meal.id}
                icon={meal.buttonIcon}
                isDisplayed={isDisplayed}
                numSelected={meal.quantity}
                onClickOption={() => {
                  setDisplayedMealIndex(index)

                  onClickMealOption(meal.id)
                }}
                surcharge={meal.surcharge}
                title={meal.buttonTitle}
              />
            )
          })}
        </div>
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          key={displayedMeal.id}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div aria-live="polite">
            <div
              className="cursor-pointer"
              onClick={() => {
                onClickMeal(displayedMeal.id)
              }}
            >
              <MealWithOptionsTitle
                subtitle={displayedMeal.subtitle}
                title={displayedMeal.title}
              />
            </div>

            {displayedMeal.tags.length > 0 && (
              <div className="mt-6">
                <MealTagsScrolling tags={displayedMeal.tags} />
              </div>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

export default TwoMealPicker

const OptionButton = ({
  icon,
  isDisplayed,
  numSelected,
  onClickOption,
  surcharge,
  title,
}: {
  icon: MealOption['buttonIcon']
  isDisplayed: boolean
  numSelected: number
  onClickOption: MouseEventHandler<HTMLButtonElement>
  surcharge?: string | null
  title: string
}) => {
  return (
    <div className="relative">
      {!isDisplayed && numSelected > 0 && (
        <div className="absolute -right-1 -top-1 flex h-7 w-7 items-center justify-center rounded-full bg-orange-1 text-k/16_125 text-white">
          {numSelected}
        </div>
      )}

      <button
        aria-pressed={isDisplayed}
        className={clsx(
          'flex h-full w-full items-center rounded-md border px-4 py-3 text-left text-k/14_120 transition-colors',
          {
            'cursor-default border-orange-1 bg-white': isDisplayed,
            'border-grey-3 bg-grey-3 hover:border-grey-5 hover:bg-grey-5':
              !isDisplayed,
          }
        )}
        onClick={onClickOption}
      >
        {icon && <img alt="" className="mr-2" srcSet={`${icon.url} 2x`} />}

        <div>
          {surcharge && (
            <div className="text-k/12_120 text-grey-9">{surcharge}</div>
          )}

          {title}
        </div>
      </button>
    </div>
  )
}
