import { Button, ButtonLoading } from '@tovala/component-library'
import {
  State,
  useEditUserShipping,
  UserV1,
  UserV1ShippingAddress,
  useValidateShippingAddress,
} from '@tovala/browser-apis-combinedapi'
import { Form, Formik, FormikErrors, useField, useFormikContext } from 'formik'
import { useState } from 'react'

import {
  ErrorCodeMessageMapCombinedAPI,
  ReactSelectValue,
} from 'types/internal'
import { formatPhone } from 'utils/general'
import { STATE_OPTIONS } from 'utils/states'
import { ValidateShippingAddressResponse } from 'services/combinedAPI/shipping'
import { wrapWithContactSupportTeam } from 'utils/errors'

import AddressAutocomplete from 'components/common/AddressAutocomplete'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import FormCombobox from 'components/common/FormCombobox'
import FormInput from 'components/common/FormInput'
import Modal, { ModalHeader } from 'components/common/Modal'
import Tooltip from 'components/common/Tooltip'
import { events } from 'analytics/events'
import { track } from 'utils/analytics'
import { isCombinedAPIResponseError } from 'utils/api'

interface AddressData {
  city: string
  line1: string
  line2: string
  name: string
  phone: string
  state: string
  zipCode: string
}

interface FormData {
  city: string
  fullName: string
  line1: string
  line2: string
  phone: string
  state: ReactSelectValue<string> | null
  zipCode: string
}

interface FormDataValidated {
  city: string
  fullName: string
  line1: string
  line2: string
  phone: string
  state: ReactSelectValue<string>
  zipCode: string
}

type AddressErrorCode = ValidateShippingAddressResponse['dpv_match_code']

const EDIT_SHIPPING_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try saving again.',
    why: "We couldn't save your shipping information due to a technical issue on our end.",
  },
  InvalidStateSelection: {
    helpToFix: 'Please reselect your state and try again.',
    why: "We couldn't save your shipping address because we didn't recognize the selected state.",
  },
  InvalidZipCode: {
    helpToFix: 'Please enter your five-digit zip code and try again.',
    why: "We couldn't save your shipping address because we didn't recognize the zip code.",
  },
  PhoneNumberInvalid: {
    helpToFix: 'Please double-check your phone number and try saving again.',
    wayOut: wrapWithContactSupportTeam('If you need further assistance'),
    why: "We couldn't save your shipping information because your phone number was not recognized as valid.",
  },
  POBoxAddressNotValid: {
    helpToFix: 'Please enter a non-P.O. box address.',
    wayOut: wrapWithContactSupportTeam('If you need further assistance'),
    why: "We couldn't save your shipping information because we don't currently deliver to P.O. Boxes.",
  },
}

const ShippingAddressForm = ({
  customerID,
  onEditUserSuccess,
  user,
}: {
  customerID: string
  onEditUserSuccess?(): void
  user: UserV1
}) => {
  const [addressErrorModalCode, setAddressErrorModalCode] =
    useState<AddressErrorCode | null>(null)

  const shippingAddress =
    user.shippingAddresses.length > 0 ? user.shippingAddresses[0] : undefined

  const {
    error: validateAddressError,
    isError: hasValidateAddressError,
    isLoading: isValidatingAddress,
    mutateAsync: validateShippingAddress,
  } = useValidateShippingAddress({
    onError: (err) => {
      if (
        isCombinedAPIResponseError(err) &&
        err.response?.data.message === 'POBoxAddressNotValid'
      ) {
        track(events.SHIPPING_ERROR_NO_PO_BOXES)
      }
    },
    onSuccess: (data) => {
      if (data.dpv_match_code !== 'Y') {
        setAddressErrorModalCode(data.dpv_match_code)
      }
    },
  })

  const {
    error: editUserShippingError,
    isError: hasEditUserShippingError,
    isLoading: isEditingUserShipping,
    mutateAsync: editUserShipping,
  } = useEditUserShipping({
    onSuccess: () => {
      if (onEditUserSuccess) {
        onEditUserSuccess()
      }
    },
  })

  const saveShippingAddress = async ({ data }: { data: AddressData }) => {
    return editUserShipping({
      customerID,
      data: getAPISaveData(data),
      ignoreValidation: true,
      userID: user.id,
    })
  }

  const isSavingShipping = isValidatingAddress || isEditingUserShipping
  const shippingError = hasValidateAddressError
    ? validateAddressError
    : hasEditUserShippingError
    ? editUserShippingError
    : null
  return (
    <>
      <Formik<FormData>
        enableReinitialize
        initialValues={getInitialFormData(shippingAddress)}
        onSubmit={async (formData) => {
          const formDataValidated = formData as FormDataValidated

          const response = await validateShippingAddress({
            customerID,
            data: getAPISaveData({
              ...formDataValidated,
              name: formDataValidated.fullName,
              state: formDataValidated.state.value,
            }),
            userID: user.id,
          })

          if (response.dpv_match_code !== 'Y') {
            return
          }

          return saveShippingAddress({
            data: {
              ...response.address,
              zipCode: response.address.postal_code,
            },
          })
        }}
        validate={validateData}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <Form>
          <SaveShippingAddressForm
            addressErrorModalCode={addressErrorModalCode}
            onCloseAddressErrorModal={() => {
              setAddressErrorModalCode(null)
            }}
            onConfirmAddress={async (opts) => {
              setAddressErrorModalCode(null)

              await saveShippingAddress({
                ...opts,
                data: {
                  ...opts.formData,
                  name: opts.formData.fullName,
                  state: opts.formData.state.value,
                },
              })
            }}
          />

          <div className="my-4">
            {shippingError && (
              <APIErrorDisplay
                error={shippingError}
                errorCodeMessageMap={EDIT_SHIPPING_ERRORS}
              />
            )}
          </div>
          <div className="mt-6">
            <ButtonLoading
              isLoading={isSavingShipping}
              size="large"
              type="submit"
            >
              Save Shipping Information
            </ButtonLoading>
          </div>
        </Form>
      </Formik>
    </>
  )
}

export default ShippingAddressForm

const SaveShippingAddressForm = ({
  addressErrorModalCode,
  onCloseAddressErrorModal,
  onConfirmAddress,
}: {
  addressErrorModalCode: AddressErrorCode | null
  onCloseAddressErrorModal(): void
  onConfirmAddress(opts: { formData: FormDataValidated }): Promise<void>
}) => {
  const { values: formData } = useFormikContext<FormData>()

  return (
    <>
      <div className="grid grid-cols-2 gap-x-6 gap-y-4 md:grid-cols-1 md:gap-x-0">
        <FormInput
          id="full-name"
          label="Full Name"
          labelFor="full-name"
          name="fullName"
        />
        <div className="md:hidden" />
        <div className="col-span-2 md:col-span-1">
          <AddressInput />
        </div>
        <FormInput
          id="line2"
          label="Unit, apartment or suite number (No P.O. Boxes)"
          labelFor="line2"
          name="line2"
        />
        <div className="md:hidden" />
        <FormInput id="city" label="City" labelFor="city" name="city" />
        <FormCombobox
          id="state"
          label="State"
          labelFor="state"
          name="state"
          options={STATE_OPTIONS}
        />
        <FormInput
          id="zipCode"
          label="Zip Code"
          labelFor="zipCode"
          name="zipCode"
        />
        <FormInput
          formatFn={formatPhone}
          id="phone"
          label="Phone Number"
          labelFor="phone"
          name="phone"
          rightIcon={
            <Tooltip
              trigger={
                <div className="flex h-6 w-6 select-none items-center justify-center rounded-full bg-grey-3 text-k/14_120">
                  ?
                </div>
              }
            >
              <p>Phone number is required for home deliveries.</p>
            </Tooltip>
          }
        />
      </div>

      {addressErrorModalCode !== null && (
        <AddressErrorModal
          errorCode={addressErrorModalCode}
          onClickUseEnteredAddress={async () => {
            await onConfirmAddress({
              // This assumes this modal is only shown after initial form validation has been done.
              formData: formData as FormDataValidated,
            })
          }}
          onCloseModal={onCloseAddressErrorModal}
        />
      )}
    </>
  )
}

const AddressInput = () => {
  const { setFieldValue } = useFormikContext<FormData>()
  const [{ value: line1 }] = useField<FormData['line1']>('line1')

  return (
    <AddressAutocomplete
      label="Address"
      labelFor="line1"
      name="line1"
      onChange={(line1) => {
        setFieldValue('line1', line1)
      }}
      onSelectSuggestion={(address) => {
        if (address) {
          setFieldValue('city', address.city)
          setFieldValue('line1', address.line1)
          setFieldValue('line2', '')
          setFieldValue(
            'state',
            STATE_OPTIONS.find(({ value }) => value === address.state) ?? null
          )
          setFieldValue('zipCode', address.zipCode)
        }
      }}
      value={line1}
    />
  )
}

const AddressErrorModal = ({
  errorCode,
  onClickUseEnteredAddress,
  onCloseModal,
}: {
  errorCode: AddressErrorCode
  onClickUseEnteredAddress(): void
  onCloseModal(): void
}) => {
  let content = (
    <p className="text-grey-8">
      We{' '}
      <span className="text-black">
        could not find an exact match for your shipping address
      </span>
      . Please edit your address, or confirm it's correct.
    </p>
  )

  if (errorCode === 'D') {
    content = (
      <p className="text-grey-8">
        Looks like you{' '}
        <span className="text-black">
          did not provide an apartment/suite number
        </span>
        . Please update your address and try again.
      </p>
    )
  }

  return (
    <Modal onCloseModal={onCloseModal}>
      <div className="w-[500px] md:w-full">
        <ModalHeader onClickClose={onCloseModal}>
          Address Incomplete
        </ModalHeader>
        <div className="p-6">
          <div className="pb-12 pt-6 text-center">{content}</div>
          <div className="flex h-12 justify-between space-x-4">
            <div className="grow">
              <Button
                buttonStyle="stroke"
                onClick={onClickUseEnteredAddress}
                size="fluid"
              >
                Use Entered Address
              </Button>
            </div>
            <div className="grow">
              <Button onClick={onCloseModal} size="fluid">
                Edit Address
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export function getAPISaveData(data: AddressData) {
  return {
    address: {
      city: data.city,
      line1: data.line1,
      line2: data.line2,
      postal_code: data.zipCode,
      state: data.state,
    },
    name: data.name,
    phone: data.phone.replace(/[^\d]/gi, ''),
  }
}

function getInitialFormData(
  shipping: UserV1ShippingAddress | undefined
): FormData {
  const stateAbbr = shipping?.state ?? null
  let state: ReactSelectValue<State> | null = null
  if (stateAbbr) {
    state = STATE_OPTIONS.find(({ value }) => value === stateAbbr) ?? null
  }

  return {
    city: shipping?.city ?? '',
    fullName: shipping?.userName ?? '',
    line1: shipping?.line1 ?? '',
    line2: shipping?.line2 ?? '',
    phone: shipping?.phone ?? '',
    state,
    zipCode: shipping?.zipCode ?? '',
  }
}

function validateData(formData: FormData) {
  const errors: FormikErrors<FormData> = {}

  if (!formData.city) {
    errors.city = 'Please enter a city'
  }

  if (!formData.fullName) {
    errors.fullName = 'Please enter a shipping name'
  }

  if (!formData.line1) {
    errors.line1 = 'Please enter an address'
  }

  if (!formData.phone) {
    errors.phone = 'Please enter a phone number'
  }

  if (!formData.state?.value) {
    errors.state = 'Please select a state'
  }

  if (!formData.zipCode) {
    errors.zipCode = 'Please enter a zip code'
  }

  return errors
}
