import {
  Button,
  ButtonLoading,
  OrderTrackIcon,
  XIcon,
} from '@tovala/component-library'
import { orderBy, partition } from 'lodash-es'
import {
  MealSummary,
  PublicTermStatus,
  SkippedWeeks,
  TermStatus,
  TermStatusMealSelection,
  UserV1,
  useChangeSubscriptionStatus,
  useListingByTermBarcode,
  useSkipWeek,
  useSkippedWeeks,
  useUnskipWeek,
  useUserTermStatuses,
} from '@tovala/browser-apis-combinedapi'
import { ComponentProps, useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { DATE_FORMATS, formatDate } from 'utils/dates'
import {
  ErrorCodeMessageMapCombinedAPI,
  MealWithQuantity,
  UserTerm,
} from 'types/internal'
import { events, sourceIDs } from '../../../analytics/events'
import { getListingBarcode, getMealImageURL } from 'utils/meals'
import { getUpgradedSubscriptionTermID, getUserTerm } from 'utils/terms'
import { storageAvailable } from 'utils/storageAvailable'
import { track } from 'utils/analytics'

import { useDetailsDialog } from 'hooks/mealDetails'
import { useHasPastOrders, useScheduledDelivery } from 'hooks/orderHistory'
import { useIsNoMenuTerm, useMealSummaries } from 'hooks/combinedAPI/meals'
import { useMealsSignUp } from 'hooks/mealsSignUp'
import { usePublicCurrentMenu } from 'hooks/menus'
import { useToast } from 'contexts/toast'
import { useUser } from 'contexts/user'
import { useVariantByScreenSize } from 'hooks/variantByScreenSize'

import APIErrorDisplay from 'components/common/APIErrorDisplay'
import ConfirmationDialog, {
  ConfirmationBody,
  ConfirmationButtons,
  ConfirmationHeader,
} from 'components/common/ConfirmationDialog'
import ListingDetailsDialog from '../menu/ExtraDetailsDialog'
import MealDetailsDialog from '../menu/MealDetailsDialog'
import MealImage from 'components/common/MealImage'
import MealsSignUpBanner from './MealsSignUpBanner'
import MyOrdersCard from './MyOrdersCard'
import MyOrdersComponents from './MyOrdersComponents'
import MyOrdersSection from './MyOrdersSection'
import OnboardingModal from './OnboardingModal'
import StatusDot from '../menu/StatusDot'
import TrackOrder from 'components/core/TrackOrder'

type ButtonSize = ComponentProps<typeof Button>['size']
type ButtonStyle = ComponentProps<typeof Button>['buttonStyle']

const CHANGE_SUBSCRIPTION_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't upgrade your subscription due to a technical issue on our end.",
  },
}

const LOAD_PUBLIC_TERM_MENUS_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page to try again.',
    why: "We couldn't load upcoming menus due to a technical issue on our end.",
  },
}

const LOAD_TERMS_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page.',
    why: "We couldn't load menu information due to a technical issue on our end.",
  },
}

const SKIP_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't skip this week due to a technical issue on our end.",
  },
}

const UNSKIP_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't unskip this week due to a technical issue on our end.",
  },
}

const MyOrdersPage = ({
  onEditTermStatus,
}: {
  onEditTermStatus(selectedUserTerm: UserTerm): void
}) => {
  useEffect(() => {
    track(events.OPENS_ORDER_TAB)
  }, [])

  const navigate = useNavigate()

  const { user } = useUser()

  const isSubscriptionActive = user.subscription.status === 'active'

  const { scheduledDelivery } = useScheduledDelivery({ userID: user.id })

  const hasPastOrders = useHasPastOrders({ userID: user.id })

  const [searchParams] = useSearchParams()

  const { shortProductID, termID } =
    getListingDetailsFromSearchParams(searchParams)

  const { data: listingResponse } = useListingByTermBarcode({
    barcode: shortProductID ? getListingBarcode({ shortProductID }) : undefined,
    termID,
    userID: user.id,
  })

  const { closeDetailsDialog, detailsType, listing, meal, openDetailsDialog } =
    useDetailsDialog({
      listings: listingResponse ? [listingResponse] : undefined,
      termID,
    })

  useEffect(() => {
    if (shortProductID && termID && listingResponse) {
      openDetailsDialog({ type: 'listing', id: listingResponse.id })
    }
  }, [listingResponse, openDetailsDialog, shortProductID, termID])

  return (
    <>
      <div className="mx-auto my-20 max-w-[976px] md:my-6 md:mb-20">
        {(isSubscriptionActive || hasPastOrders) && (
          <div className="mb-10 flex justify-between border-b border-grey-4 pb-10 lg:px-4 md:mb-12 md:border-none md:pb-0">
            <h1 className="text-k/52_110 md:text-k/28_110">My Orders</h1>

            {hasPastOrders ? (
              <div className="min-w-[140px]">
                <Button
                  buttonStyle="gray"
                  onClick={() => {
                    navigate('/account/history')
                  }}
                  size="medium"
                >
                  Past Orders
                </Button>
              </div>
            ) : null}
          </div>
        )}

        <div>
          {scheduledDelivery && (
            <div className="lg:px-4">
              <h2 className="mb-10 text-k/36_110 md:mb-6 md:text-k/20_110">
                In the Works
              </h2>

              <div key={scheduledDelivery.termId} className="mb-6 last:mb-20">
                <PastTermOrder
                  deliveryDate={
                    scheduledDelivery.userTermOrderSummary.deliveryDate
                  }
                  termID={scheduledDelivery.termId}
                  trackingURL={
                    scheduledDelivery.userTermOrderSummary.trackingURLs.length >
                    0
                      ? scheduledDelivery.userTermOrderSummary.trackingURLs[0]
                      : null
                  }
                />
              </div>
            </div>
          )}

          {isSubscriptionActive ? (
            <MyOrders onEditTermStatus={onEditTermStatus} user={user} />
          ) : (
            <PublicMyOrders hasPastOrders={hasPastOrders} user={user} />
          )}
        </div>
      </div>

      {detailsType === 'meal' && meal ? (
        <MealDetailsDialog closeModal={closeDetailsDialog} meal={meal} />
      ) : detailsType === 'listing' && listing ? (
        <ListingDetailsDialog listing={listing} onClose={closeDetailsDialog} />
      ) : null}
    </>
  )
}

export default MyOrdersPage

const MyOrders = ({
  onEditTermStatus,
  user,
}: {
  onEditTermStatus(selectedUserTerm: UserTerm): void
  user: UserV1
}) => {
  const [showOnboardingModal, setShowOnboardingModal] = useState(false)

  const {
    data: termStatuses = [],
    error: loadUserTermStatusesError,
    isError: hasLoadUserTermStatusesError,
  } = useUserTermStatuses({ userID: user.id })

  const { data: skippedWeeks } = useSkippedWeeks({ userID: user.id })

  const hasPastOrders = useHasPastOrders({ userID: user.id })

  const [readyForViewTerms, planAheadTerms] = partition(
    termStatuses,
    ({ readyForView }) => readyForView
  )

  useEffect(() => {
    if (hasPastOrders === false && termStatuses.length) {
      const hasSelectedMeals = termStatuses.some((term) => {
        return (
          term.mealSelections.length === term.subscriptionType?.maxSelections
        )
      })

      // Set state to show onboarding modal on first visit of My Orders for new active users
      if (storageAvailable('localStorage')) {
        const hasViewedOnboardingModal =
          localStorage.getItem('viewedOnboarding')
        if (!hasViewedOnboardingModal) {
          setShowOnboardingModal(true)
        }
      } else if (!hasSelectedMeals) {
        // If localStorage isn't available, but they haven't selected meals, assume they haven't viewed onboarding
        setShowOnboardingModal(true)
      }
    }
  }, [termStatuses, hasPastOrders])

  return (
    <>
      <div className="lg:px-4">
        {termStatuses.length > 0 ? (
          <div className="space-y-20 md:space-y-12">
            <TermsReadyForView
              onEditTermStatus={onEditTermStatus}
              skippedWeeks={skippedWeeks}
              termStatuses={readyForViewTerms}
              user={user}
            />

            <PlanAhead
              onEditTermStatus={onEditTermStatus}
              skippedWeeks={skippedWeeks}
              termStatuses={planAheadTerms}
            />
          </div>
        ) : hasLoadUserTermStatusesError ? (
          <APIErrorDisplay
            error={loadUserTermStatusesError}
            errorCodeMessageMap={LOAD_TERMS_ERRORS}
          />
        ) : null}
      </div>
      {showOnboardingModal && (
        <OnboardingModal
          onCloseModal={() => {
            setShowOnboardingModal(false)
          }}
          user={user}
        />
      )}
    </>
  )
}

const PublicMyOrders = ({
  hasPastOrders,
  user,
}: {
  hasPastOrders: boolean | undefined
  user: UserV1
}) => {
  const {
    error: loadPublicCurrentMenuError,
    isError: hasPublicCurrentMenuError,
    mealSummaries,
    term,
  } = usePublicCurrentMenu({})

  const { link, signUpPrompt } = useMealsSignUp({ utmSource: 'banner' })

  return (
    <div className="space-y-20 md:space-y-12">
      {hasPastOrders === true ? (
        <div className="lg:px-4 md:px-0">
          {signUpPrompt && <MealsSignUpBanner link={link} {...signUpPrompt} />}
        </div>
      ) : hasPastOrders === false ? (
        <MyOrdersComponents
          fallbackComponent={
            <div className="lg:px-4 md:px-0">
              {signUpPrompt && (
                <MealsSignUpBanner link={link} {...signUpPrompt} />
              )}
            </div>
          }
          user={user}
        />
      ) : null}

      <div className="lg:px-4">
        {term ? (
          <div className="space-y-20 md:space-y-12">
            <MyOrdersSection heading="Our Current Menu">
              <PublicTerm mealSummaries={mealSummaries} term={term} />
            </MyOrdersSection>
          </div>
        ) : hasPublicCurrentMenuError ? (
          <APIErrorDisplay
            error={loadPublicCurrentMenuError}
            errorCodeMessageMap={LOAD_PUBLIC_TERM_MENUS_ERRORS}
          />
        ) : null}
      </div>
    </div>
  )
}

function getMealQuantity({
  mealID,
  mealSelections,
}: {
  mealID: number
  mealSelections: TermStatusMealSelection[]
}) {
  return mealSelections.filter(
    (mealSelection) => mealID === mealSelection.mealID
  ).length
}

const TermsReadyForView = ({
  onEditTermStatus,
  skippedWeeks,
  termStatuses,
  user,
}: {
  onEditTermStatus(selectedUserTerm: UserTerm): void
  skippedWeeks: SkippedWeeks | undefined
  termStatuses: TermStatus[]
  user: UserV1
}) => {
  const upgradedSubscriptionTermID = getUpgradedSubscriptionTermID({
    termStatuses,
    user,
  })

  const upNextTerm = termStatuses[0]
  const upcomingTerms = termStatuses.slice(1)

  return (
    <div className="space-y-20 md:space-y-12">
      {upNextTerm && (
        <MyOrdersSection
          heading="Up Next"
          subHeading="Finalizing Wed at 6pm CT"
        >
          <TermOrder
            onEditTermStatus={onEditTermStatus}
            upgradedSubscriptionTermID={upgradedSubscriptionTermID}
            userTerm={getUserTerm({
              skippedWeeks,
              term: upNextTerm,
              user,
            })}
          />
        </MyOrdersSection>
      )}

      <MyOrdersSection heading="Upcoming">
        <div className="space-y-6 md:space-y-4">
          {upcomingTerms.map((term) => {
            const userTerm = getUserTerm({
              skippedWeeks,
              term,
              user,
            })

            return (
              <TermOrder
                key={term.termID}
                onEditTermStatus={onEditTermStatus}
                upgradedSubscriptionTermID={upgradedSubscriptionTermID}
                userTerm={userTerm}
              />
            )
          })}
        </div>
      </MyOrdersSection>
    </div>
  )
}

const MealsCarousel = ({ meals }: { meals: MealWithQuantity[] }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
    dragFree: true,
    inViewThreshold: 0.5,
  })

  // Necessary to reflect updates in our supplied "meals" prop.
  // See https://www.embla-carousel.com/guides/add-and-remove-slides/#with-react.
  useEffect(() => {
    if (emblaApi) {
      emblaApi.reInit()
    }
  }, [emblaApi, meals])

  return (
    <div ref={emblaRef} className="overflow-hidden">
      <div className="flex cursor-pointer space-x-3">
        {meals.map((meal) => {
          const { id, quantity } = meal

          return (
            <div
              key={id}
              className="flex-[0_0_176px] last:pr-6 md:flex-[0_0_120px] md:last:pr-4"
            >
              <MyOrdersMeal meal={meal} quantity={quantity} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const MyOrdersMeal = ({
  meal,
  quantity,
}: {
  meal: MealWithQuantity | MealSummary
  quantity?: number
}) => {
  return (
    <div key={meal.id}>
      <div className="relative aspect-square min-h-[176px] overflow-hidden rounded-lg md:min-h-[120px]">
        <div className="hidden h-full md:block">
          <MealImage alt={meal.title} imageURL={getMealImageURL(meal)} />
        </div>
        {/*
          Currently the best data we have for meal image alt text is the meal title. Using that as alt text
          on desktop where we're already displaying the title would be redundant for screen readers.
        */}
        <div className="h-full md:hidden">
          <MealImage imageURL={getMealImageURL(meal)} />
        </div>

        {quantity !== undefined && quantity > 0 && (
          <div className="absolute bottom-1 right-1 flex h-10 w-10 items-center justify-center rounded-full bg-white">
            <span className="text-k/20_125">{quantity}</span>
          </div>
        )}
      </div>
      <p className="mt-2 text-k/16_125 md:hidden">{meal.title}</p>
    </div>
  )
}

const PastTermOrder = ({
  deliveryDate,
  termID,
  trackingURL,
}: {
  deliveryDate: string
  termID: number
  trackingURL: string | null
}) => {
  const navigate = useNavigate()

  const orderStatus = trackingURL ? 'Shipped' : 'Processed'

  return (
    <div className="flex items-center justify-between rounded-xl border border-grey-4 px-6">
      <div
        className="mr-3 flex flex-grow cursor-pointer items-center py-3 text-k/16_125 md:text-k/14_120"
        onClick={() => navigate('/account/history')}
      >
        <div className="mr-1">
          <StatusDot color="green" />
        </div>
        <span className="mr-1 text-grey-9">{orderStatus} </span>
        <span>
          Arrives{' '}
          {formatDate(deliveryDate, {
            format: DATE_FORMATS.MONTH_ABBR_DAY,
          })}
        </span>
      </div>

      <TrackOrder termID={termID} trackingURL={trackingURL}>
        <div className="h-6 w-6 text-black">
          <OrderTrackIcon />
        </div>
      </TrackOrder>
    </div>
  )
}

const PlanAhead = ({
  onEditTermStatus,
  skippedWeeks,
  termStatuses,
}: {
  onEditTermStatus(selectedUserTerm: UserTerm): void
  skippedWeeks: SkippedWeeks | undefined
  termStatuses: TermStatus[]
}) => {
  const { user } = useUser()

  if (!termStatuses.length) {
    return null
  }

  return (
    <MyOrdersSection heading="Plan Ahead">
      <div className="grid grid-cols-plan-ahead gap-6 sm:grid-cols-2 sm:gap-3">
        {termStatuses.map((termStatus) => {
          const userTerm = getUserTerm({
            skippedWeeks,
            term: termStatus,
            user,
          })

          return (
            <PlanAheadTerm
              key={termStatus.termID}
              onEditTermStatus={onEditTermStatus}
              userTerm={userTerm}
            />
          )
        })}
      </div>
    </MyOrdersSection>
  )
}

const PlanAheadTerm = ({
  onEditTermStatus,
  userTerm,
}: {
  userTerm: UserTerm
  onEditTermStatus(selectedUserTerm: UserTerm): void
}) => {
  const maxSelections = userTerm.subscriptionType?.maxSelections

  const {
    error: unskipError,
    isError: hasUnskipError,
    isLoading: isUnskippingWeek,
    mutate: unskipWeek,
  } = useUnskipWeek()

  const {
    error: skipWeekError,
    isError: hasSkipWeekError,
    isLoading: isSkippingWeek,
    mutate: skipWeek,
  } = useSkipWeek()

  return (
    <MyOrdersCard
      header={
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="mr-1">
              <StatusDot color="grey" />
            </div>

            <span className="text-k/16_125 text-grey-9 md:text-k/14_120">
              {userTerm.isSkipped ? (
                <span>Skipped</span>
              ) : (
                <span>{maxSelections} meal delivery</span>
              )}
            </span>
          </div>
        </div>
      }
    >
      <div className="space-y-4">
        <TermDeliveryDate date={getFormattedTermDeliveryDate({ userTerm })} />

        <div className="space-y-6">
          <p className="text-k/16_125 text-grey-10">Menu coming soon</p>

          <div className="space-y-4">
            {hasSkipWeekError ? (
              <APIErrorDisplay
                error={skipWeekError}
                errorCodeMessageMap={SKIP_ERRORS}
              />
            ) : hasUnskipError ? (
              <APIErrorDisplay
                error={unskipError}
                errorCodeMessageMap={UNSKIP_ERRORS}
              />
            ) : null}

            <div className="grid grid-cols-1">
              {userTerm.isSkipped ? (
                <ButtonLoading
                  isLoading={isUnskippingWeek}
                  onClick={() => {
                    unskipWeek(
                      {
                        data: {
                          termid: userTerm.termID,
                        },
                        userID: userTerm.userID,
                      },
                      {
                        onSuccess: () => {
                          track(events.UNSKIPS_WEEK, {
                            source_id: sourceIDs.ORDER_TAB,
                            term_id: userTerm.termID,
                          })
                        },
                      }
                    )
                  }}
                  size="small"
                >
                  Unskip
                </ButtonLoading>
              ) : (
                <>
                  <div className="grid md:hidden">
                    <Button
                      buttonStyle="white"
                      onClick={() => {
                        onEditTermStatus(userTerm)
                        track(events.SKIP_ADJUST_CTA, {
                          source_id: sourceIDs.ORDER_TAB,
                        })
                      }}
                      size="small"
                    >
                      Skip/Adjust
                    </Button>
                  </div>
                  <div className="hidden md:grid">
                    <ButtonLoading
                      buttonStyle="stroke"
                      isLoading={isSkippingWeek}
                      onClick={() => {
                        skipWeek({
                          data: {
                            termid: userTerm.termID,
                          },
                          userID: userTerm.userID,
                        })
                      }}
                      size="small"
                    >
                      Skip
                    </ButtonLoading>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </MyOrdersCard>
  )
}

const PublicTerm = ({
  mealSummaries,
  term,
}: {
  mealSummaries: MealSummary[]
  term: PublicTermStatus
}) => {
  const navigate = useNavigate()

  const selectMealsButtonSize = useVariantByScreenSize<ButtonSize>('medium', {
    md: 'small',
  })

  const mealsToDisplay = 4

  return (
    <div className="rounded-xl bg-grey-3 p-6 md:p-4">
      <div className="flex items-center justify-between">
        <TermDeliveryDate
          date={
            term.startDate
              ? `${formatDate(term.startDate, {
                  format: DATE_FORMATS.MONTH_ABBR_DAY,
                })}`
              : undefined
          }
        />
        <Button
          buttonStyle="dark"
          onClick={() => {
            navigate(`/menu/${term.termID}`)
          }}
          size={selectMealsButtonSize}
        >
          Explore Menu
        </Button>
      </div>

      {mealSummaries.length > 0 && (
        <div className="grid grid-cols-4 gap-3 pt-6 md:grid-cols-2 md:gap-2">
          {mealSummaries.splice(0, mealsToDisplay).map((meal) => {
            return <MyOrdersMeal key={meal.id} meal={meal} />
          })}
        </div>
      )}
    </div>
  )
}

const TermOrder = ({
  onEditTermStatus,
  upgradedSubscriptionTermID,
  userTerm,
}: {
  onEditTermStatus(selectedUserTerm: UserTerm): void
  upgradedSubscriptionTermID: number | null
  userTerm: UserTerm
}) => {
  const { data: mealSummaries = [] } = useMealSummaries({
    subTermID: userTerm.selectedSubTermID,
  })

  const { isNoMenuTerm } = useIsNoMenuTerm({ term: userTerm })

  if (isNoMenuTerm) {
    return <TermOrderNoMenu userTerm={userTerm} />
  }

  if (userTerm.isSkipped) {
    return (
      <TermOrderSkipped
        isSoldOut={userTerm.isStaticSkipped}
        mealSummaries={mealSummaries}
        userTerm={userTerm}
      />
    )
  }

  return (
    <TermOrderActive
      mealSummaries={mealSummaries}
      onEditTermStatus={onEditTermStatus}
      upgradedSubscriptionTermID={upgradedSubscriptionTermID}
      userTerm={userTerm}
    />
  )
}

const TermOrderActive = ({
  mealSummaries,
  onEditTermStatus,
  upgradedSubscriptionTermID,
  userTerm,
}: {
  mealSummaries: MealSummary[]
  onEditTermStatus(selectedUserTerm: UserTerm): void
  upgradedSubscriptionTermID: number | null
  userTerm: UserTerm
}) => {
  const navigate = useNavigate()

  const maxSelections = userTerm.subscriptionType?.maxSelections
  const selectionsCount = userTerm.mealSelections.length
  const hasSelectedAllMeals = selectionsCount === maxSelections
  const selectedMealIDs = new Set(
    userTerm.mealSelections.map((selection) => selection.mealID)
  )

  const meals: MealWithQuantity[] = [...mealSummaries]
    .filter((meal) => {
      return hasSelectedAllMeals ? selectedMealIDs.has(meal.id) : true
    })
    .map((meal) => ({
      ...meal,
      quantity: getMealQuantity({
        mealID: meal.id,
        mealSelections: userTerm.mealSelections,
      }),
    }))

  const sortedMeals = orderBy(
    meals,
    [
      // If the user hasn't yet finished selecting all their meals, we want to bring the
      // meals they have selected to the front. Otherwise, the meals they selected could
      // get burried off-screen in the carousel.
      (meal) => (!hasSelectedAllMeals ? selectedMealIDs.has(meal.id) : true),
      (meal) => meal.mainDisplayOrder,
    ],
    ['desc', 'asc']
  )

  const navigateToMenu = () => {
    let mealFilters
    if (storageAvailable('localStorage')) {
      mealFilters = localStorage.getItem('mealFilters')
    }
    mealFilters = mealFilters ? JSON.parse(mealFilters) : {}

    let filtersArrays: string[] = Object.values(mealFilters)

    if (filtersArrays[0]) {
      filtersArrays = [filtersArrays[0].concat(...filtersArrays.slice(1))]
    } else {
      filtersArrays = []
    }

    const enabled_filters = filtersArrays.toString()

    track(events.OPENS_MENU, {
      term_id: userTerm.termID,
      meal_selection_count: selectionsCount,
      meal_selection_max: maxSelections,
      enabled_filters,
    })

    navigate(`/menu/${userTerm.termID}`)
  }

  const [selectMealsButtonSize, selectMealsButtonStyle] =
    useVariantByScreenSize<[ButtonSize, ButtonStyle]>(['medium', 'dark'], {
      md: ['small', 'white'],
    })

  return (
    <MyOrdersCard
      footer={
        upgradedSubscriptionTermID &&
        userTerm.termID === upgradedSubscriptionTermID ? (
          <UpgradeSubscription userTerm={userTerm} />
        ) : null
      }
      header={
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="mr-1">
              <StatusDot color={hasSelectedAllMeals ? 'green' : 'orange'} />
            </div>

            <div className="text-k/16_125 text-grey-9 md:text-k/14_120">
              {selectionsCount}/{maxSelections} Selected
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <div className="md:hidden">
              <Button
                buttonStyle="white"
                onClick={() => {
                  onEditTermStatus(userTerm)
                  track(events.SKIP_ADJUST_CTA, {
                    source_id: sourceIDs.ORDER_TAB,
                  })
                }}
                size={selectMealsButtonSize}
              >
                Skip/Adjust
              </Button>
            </div>
            <Button
              buttonStyle={
                hasSelectedAllMeals ? 'white' : selectMealsButtonStyle
              }
              onClick={navigateToMenu}
              size={selectMealsButtonSize}
            >
              Select Meals
            </Button>
          </div>
        </div>
      }
      onChildrenClick={navigateToMenu}
      removeRightChildPadding
    >
      <div className="space-y-4">
        <TermDeliveryDate date={getFormattedTermDeliveryDate({ userTerm })} />

        {userTerm.readyForView && <MealsCarousel meals={sortedMeals} />}
      </div>
    </MyOrdersCard>
  )
}

const TermOrderSkipped = ({
  isSoldOut,
  mealSummaries,
  userTerm,
}: {
  isSoldOut: boolean
  mealSummaries: MealSummary[]
  userTerm: UserTerm
}) => {
  const navigate = useNavigate()

  const unskipButtonSize = useVariantByScreenSize<ButtonSize>('medium', {
    md: 'small',
  })

  const {
    error: unskipError,
    isError: hasUnskipError,
    isLoading: isUnskippingWeek,
    mutate: unskipWeek,
  } = useUnskipWeek()

  const meals = orderBy(mealSummaries, 'mainDisplayOrder')

  const navigateToMenu = () => {
    track(events.OPENS_MENU, {
      term_id: userTerm.termID,
      meal_selection_count: undefined,
      meal_selection_max: undefined,
    })

    navigate(`/menu/${userTerm.termID}`)
  }

  return (
    <MyOrdersCard
      header={
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex items-center gap-1">
            <XIcon color="red" width={'20px'} />
            <div>
              {isSoldOut ? <span>Sold Out</span> : <span>Skipped</span>}
            </div>
          </div>

          {!isSoldOut && (
            <ButtonLoading
              buttonStyle="dark"
              isLoading={isUnskippingWeek}
              onClick={() =>
                unskipWeek(
                  {
                    data: {
                      termid: userTerm.termID,
                    },
                    userID: userTerm.userID,
                  },
                  {
                    onSuccess: () => {
                      track(events.UNSKIPS_WEEK, {
                        source_id: sourceIDs.ORDER_TAB,
                        term_id: userTerm.termID,
                      })
                    },
                  }
                )
              }
              size={unskipButtonSize}
            >
              Unskip
            </ButtonLoading>
          )}

          {hasUnskipError && (
            <div className="mt-4 w-full">
              <APIErrorDisplay
                error={unskipError}
                errorCodeMessageMap={UNSKIP_ERRORS}
              />
            </div>
          )}
        </div>
      }
      onChildrenClick={navigateToMenu}
      removeRightChildPadding
    >
      <div className="space-y-4">
        <TermDeliveryDate date={getFormattedTermDeliveryDate({ userTerm })} />

        {userTerm.readyForView && <MealsCarousel meals={meals} />}
      </div>
    </MyOrdersCard>
  )
}

const TermOrderNoMenu = ({ userTerm }: { userTerm: UserTerm }) => {
  return (
    <MyOrdersCard
      header={
        <span className="text-k/16_125 text-grey-9 md:text-k/14_120">
          No Menu This Week
        </span>
      }
    >
      <div className="space-y-6">
        <TermDeliveryDate date={getFormattedTermDeliveryDate({ userTerm })} />
        {userTerm.specialEvent && (
          <p className="text-k/20_125">{userTerm.specialEvent}</p>
        )}
      </div>
    </MyOrdersCard>
  )
}

const TermDeliveryDate = ({ date }: { date: string | undefined }) => {
  if (!date) {
    return null
  }

  return <div className="text-k/28_130 md:text-k/28_110">{date}</div>
}

const UpgradeSubscription = ({ userTerm }: { userTerm: UserTerm }) => {
  const { openToast } = useToast()

  const { user } = useUser()

  const [showConfirmation, setShowConfirmation] = useState(false)

  const {
    error: changeSubscriptionStatusError,
    isError: hasChangeSubscriptionStatusError,
    isLoading: isChangingSubscriptionStatus,
    mutate: changeSubscriptionStatus,
  } = useChangeSubscriptionStatus({
    onSuccess: () => {
      openToast({
        heading: 'Meal Plan Updated',
        message: 'Please double check any pending orders.',
        type: 'success',
      })
    },
  })

  useEffect(() => {
    track(events.PERMANENT_UPTIER_HOME_SCREEN_APPEARS, {
      term_id: userTerm.termID,
    })
  }, [userTerm.termID])

  const handleUpdateSubscription = () => {
    if (!userTerm.selectedSubTerm || !userTerm.subscriptionType) {
      return
    }

    const userID = user.id

    track(events.TAPS_PERMANENT_UPTIER_HOME_SCREEN, {
      original_order_size: user.subscription.subscriptionType?.maxSelections,
      new_order_size: userTerm.subscriptionType.maxSelections,
      term_id: userTerm.termID,
    })

    const subscription = {
      defaultShipPeriod: userTerm.selectedSubTerm.shipPeriod,
      subscriptionTypeID: userTerm.subscriptionType.id,
    }

    changeSubscriptionStatus({
      data: subscription,
      subscriptionStatus: 'activate',
      userID,
    })
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <p className="text-k/20_125 md:text-k/20_110 sm:whitespace-pre">{`Want ${userTerm.subscriptionType?.maxSelections} meals \nevery week?`}</p>

        <div className="shrink-0">
          <Button onClick={() => setShowConfirmation(true)} size="small">
            Upgrade Plan
          </Button>
        </div>
      </div>

      {showConfirmation && (
        <ConfirmationDialog onRequestClose={() => setShowConfirmation(false)}>
          <ConfirmationHeader heading="Meal Plan Changes" />
          <ConfirmationBody>
            <div className="py-16 md:py-12">
              <p className="mx-auto max-w-sm text-body-lg">
                Editing your meal plan affects any deliveries that haven't been
                processed, skipped, or had their delivery size changed.
              </p>
            </div>
          </ConfirmationBody>
          <ConfirmationButtons>
            <div className="space-y-4">
              {hasChangeSubscriptionStatusError && (
                <APIErrorDisplay
                  error={changeSubscriptionStatusError}
                  errorCodeMessageMap={CHANGE_SUBSCRIPTION_ERRORS}
                />
              )}

              <div className="grid grid-cols-2 gap-2">
                <Button
                  buttonStyle="stroke"
                  onClick={() => setShowConfirmation(false)}
                  size="large"
                >
                  Cancel
                </Button>

                <ButtonLoading
                  isLoading={isChangingSubscriptionStatus}
                  onClick={handleUpdateSubscription}
                  size="large"
                >
                  Save
                </ButtonLoading>
              </div>
            </div>
          </ConfirmationButtons>
        </ConfirmationDialog>
      )}
    </>
  )
}

function getFormattedTermDeliveryDate({ userTerm }: { userTerm: UserTerm }) {
  return userTerm.selectedSubTerm
    ? formatDate(userTerm.selectedSubTerm.deliveryDate, {
        format: DATE_FORMATS.MONTH_ABBR_DAY,
      })
    : undefined
}

function getListingDetailsFromSearchParams(searchParams: URLSearchParams) {
  const shortProductID = searchParams.get('extra')
  const termIDParam = searchParams.get('menu')

  const termID = termIDParam ? Number.parseInt(termIDParam, 10) : undefined

  if (shortProductID && termID) {
    return {
      shortProductID,
      termID,
    }
  }

  return {
    shortProductID: undefined,
    termID: undefined,
  }
}
