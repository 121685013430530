import { Button, XIcon } from '@tovala/component-library'
import { Dialog } from '@headlessui/react'
import { map } from 'lodash-es'
import {
  Listing,
  OrderHistoryReceipt,
  OrderHistoryReceiptMeal,
} from '@tovala/browser-apis-combinedapi'

import { DATE_FORMATS, formatDate, getPreviousWednesday } from 'utils/dates'
import { formatCentsToDollars } from 'utils/currency'
import { track } from 'utils/analytics'
import { events } from 'analytics/events'

import Link from 'components/common/Link'

const Receipt = ({
  onCloseModal,
  selections,
  userTermOrder,
}: {
  onCloseModal(): void
  selections: {
    listings: (Listing & { quantity: number })[]
    meals: (OrderHistoryReceiptMeal & { quantity: number })[]
  }
  userTermOrder: OrderHistoryReceipt
}) => {
  const receipt = getReceiptData(userTermOrder)

  return (
    <Dialog onClose={onCloseModal} open={true}>
      <div
        className="fixed inset-0 z-30 bg-black opacity-40"
        onClick={onCloseModal}
      />
      <div className="fixed inset-0 z-30 flex justify-center">
        <Dialog.Panel className=" flex h-full w-full max-w-[375px] flex-col bg-grey-0">
          <div className="relative flex items-center justify-center border-b border-grey-3 py-6">
            <Dialog.Title className="text-k/28_110">Receipt</Dialog.Title>

            <div className="absolute right-6">
              <Button
                aria-label="Close"
                buttonStyle="link"
                onClick={onCloseModal}
                size="auto"
              >
                <div className="h-6 w-6">
                  <XIcon />
                </div>
              </Button>
            </div>
          </div>

          <div className="flex-auto overflow-y-auto px-6 py-10">
            <div className="space-y-12 text-body-sm">
              <div>
                <div className="mb-4 flex items-center justify-between">
                  <h3 className="text-k/20_125">Order Status</h3>
                  {userTermOrder.trackingURL && (
                    <Link
                      linkStyle="stroke"
                      linkType="external"
                      onClick={() => {
                        track(events.OPENS_ORDER_TRACKING, {
                          term_id: userTermOrder.termID,
                        })
                      }}
                      rel="noreferrer"
                      size="small"
                      target="_blank"
                      to={userTermOrder.trackingURL}
                    >
                      Track Order
                    </Link>
                  )}
                </div>

                {receipt.orderStatus === 'complete' ? (
                  <div className="text-grey-9">
                    <p className="text-h/14_120 font-semibold text-black">
                      Order Processed on{' '}
                      {formatDate(receipt.orderProcessedDate, {
                        format: DATE_FORMATS.DOW_MONTH_DAY,
                      })}
                    </p>
                    <p>Payment Complete</p>
                    <p>Your Selections - {receipt.mealCount} Meal Plan</p>
                  </div>
                ) : (
                  <p className="text-h/14_120 font-semibold capitalize text-black">
                    {receipt.orderStatus}
                  </p>
                )}
              </div>

              <div>
                <h3 className="mb-4 text-k/20_125">Meals</h3>
                <div className="space-y-4">
                  {selections.meals.map((meal) => {
                    return (
                      <Selection
                        key={meal.id}
                        quantity={meal.quantity}
                        subtitle={meal.subtitle}
                        title={meal.title}
                      />
                    )
                  })}
                </div>

                {selections.listings.length > 0 && (
                  <div className="mt-4 space-y-4 border-t border-grey-3 pt-4">
                    {selections.listings.map((listing) => {
                      return (
                        <Selection
                          key={listing.id}
                          quantity={listing.quantity}
                          title={listing.title}
                        />
                      )
                    })}
                  </div>
                )}
              </div>

              <div>
                <h3 className="mb-4 text-k/20_125">Shipping Information</h3>

                {receipt.shippingAddress && (
                  <div className="text-grey-9">
                    <p className="text-h/14_120 font-semibold text-black">
                      {receipt.shippingAddress.name}
                    </p>
                    <p>{receipt.shippingAddress.line1}</p>
                    {receipt.shippingAddress.line2 && (
                      <p>{receipt.shippingAddress.line2}</p>
                    )}
                    <p>
                      {receipt.shippingAddress.city},{' '}
                      {receipt.shippingAddress.state}{' '}
                      {receipt.shippingAddress.zipCode}
                    </p>
                  </div>
                )}
              </div>
              <div>
                <h3 className="mb-4 text-k/20_125">Payment Information</h3>
                <div className="divide-y divide-grey-3 text-k/13_120 text-grey-9">
                  {receipt.paymentLineItems
                    .filter((item) => item.amountCents)
                    .map(({ amountCents, name }) => {
                      return (
                        <div key={name}>
                          <div className="flex items-center justify-between py-4">
                            <span>{name}</span>
                            <span>{formatCentsToDollars(amountCents)}</span>
                          </div>

                          {name === 'Surcharges' && (
                            <div className="mb-4">
                              Surcharges include Meal cost over $12.99, Two-Pack
                              Meals, and Extras.
                            </div>
                          )}
                        </div>
                      )
                    })}

                  {receipt.totalAmountCents && (
                    <div className="flex items-center justify-between py-4 text-k/16_125 text-black">
                      <span>Total</span>
                      <span>
                        {formatCentsToDollars(receipt.totalAmountCents)}
                      </span>
                    </div>
                  )}

                  {receipt.refundedAmountCents && (
                    <div className="flex items-center justify-between py-4 text-k/16_125 text-black">
                      <span>Refunded</span>
                      <span>
                        {formatCentsToDollars(receipt.refundedAmountCents)}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default Receipt

const Selection = ({
  quantity,
  subtitle,
  title,
}: {
  quantity: number
  subtitle?: string
  title: string
}) => {
  return (
    <div className="flex items-center gap-4">
      <div className="shrink-0 text-k/32_105">{quantity} x</div>
      <div className="space-y-1">
        <div className="text-k/14_120">{title}</div>
        <div className="text-k/13_120 text-grey-9">{subtitle}</div>
      </div>
    </div>
  )
}

const lineItemTitles = {
  surcharge: 'Surcharges',
  shipping: 'Shipping',
  tax: 'Taxes',
  discount: 'Discount',
  meal_cash: 'Tovala Cash',
  meal_credit: 'Meal Credit',
  gift_card: 'Gift Card',
}

const getReceiptData = (userTermOrder: OrderHistoryReceipt) => {
  const payment = Object.fromEntries(
    userTermOrder.paymentLineItems.map(
      ({ type, invoiceAmountCents, transactionAmountCents }) => {
        const amountCents = invoiceAmountCents || transactionAmountCents
        return [type, amountCents]
      }
    )
  )

  const lineItems = map(lineItemTitles, (item, key) => {
    return { name: item, amountCents: payment[key] }
  })

  /*
    The sku PLI is the total cost of meals on the order (base + surcharges).
    If there are surcharged meals on the order, there will be baseprice and surcharge PLIs.
    We'll use those PLIs instead of sku to show the user the surcharge cost separately
  */
  const paymentLineItems = [
    {
      name: `${userTermOrder.mealCount} Meals`,
      amountCents: payment.baseprice || payment.sku,
    },
    ...lineItems,
  ]

  return {
    mealCount: userTermOrder.mealCount,
    orderProcessedDate: getPreviousWednesday(
      new Date(userTermOrder.termStartDate)
    ),
    orderStatus: userTermOrder.orderStatus,
    paymentLineItems,
    refundedAmountCents: payment.refund,
    shippingAddress: userTermOrder.shippingAddress,
    totalAmountCents: payment.charge,
  }
}
