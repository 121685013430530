import { ButtonLoading } from '@tovala/component-library'
import { Form, Formik, FormikErrors } from 'formik'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useResetPassword } from '@tovala/browser-apis-combinedapi'

import {
  ErrorCodeMessageMapCombinedAPI,
  GoodErrorMessaging,
  LoginLocationState,
} from 'types/internal'
import { events } from 'analytics/events'
import { getUserIdFromJWT } from 'utils/auth'
import { track } from 'utils/analytics'

import { useToast } from 'contexts/toast'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import AuthPage from './AuthPage'
import ErrorDisplay from 'components/common/ErrorDisplay'
import FormInput from 'components/common/FormInput'

interface FormData {
  newPassword: ''
  newPasswordConfirm: ''
}

const INVALID_TOKEN_HELP: GoodErrorMessaging = {
  helpToFix: (
    <span>
      Please{' '}
      <Link className="underline" to="/forgot-password">
        request a new reset link
      </Link>
      .
    </span>
  ),
  why: "We couldn't reset your password because your link has expired.",
}

const RESET_PASSWORD_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try reloading the page.',
    why: "We couldn't reset your password due to a technical issue on our end.",
  },
  InvalidToken: INVALID_TOKEN_HELP,
}

function validateData(data: FormData) {
  const errors: FormikErrors<FormData> = {}

  if (!data.newPassword) {
    errors.newPassword = 'Please provide a new password'
  }

  if (!data.newPasswordConfirm) {
    errors.newPasswordConfirm = 'Please confirm your new password'

    if (data.newPassword !== data.newPasswordConfirm) {
      errors.newPasswordConfirm = 'Passwords do not match'
    }
  }

  return errors
}

const ResetPasswordPage = () => {
  const { resetToken } = useParams<{ resetToken: string }>()

  let userIDFromJWT: number | undefined
  if (resetToken) {
    userIDFromJWT = getUserIdFromJWT(resetToken)
  }

  return (
    <AuthPage>
      <div className="flex justify-center">
        <div className="flex flex-col items-center md:w-full">
          <h1 className="mb-12 text-center text-k/44_110 font-medium md:mb-10 md:text-k/32_105">
            Reset Password
          </h1>

          <div className="w-[320px] md:w-full">
            {resetToken && userIDFromJWT ? (
              <ResetPasswordForm
                resetToken={resetToken}
                userID={userIDFromJWT}
              />
            ) : (
              <ErrorDisplay {...INVALID_TOKEN_HELP} />
            )}
          </div>
        </div>
      </div>
    </AuthPage>
  )
}

export default ResetPasswordPage

const ResetPasswordForm = ({
  resetToken,
  userID,
}: {
  resetToken: string
  userID: number
}) => {
  const navigate = useNavigate()
  const { openToast } = useToast()

  const {
    error: resetPasswordError,
    isError: hasResetPasswordError,
    isLoading: isResettingPassword,
    mutate: resetPassword,
  } = useResetPassword({
    onError: () => {
      track(events.RESET_PASSWORD_FAILED)
    },
    onSuccess: () => {
      track(events.RESET_PASSWORD_SUCCESSFUL)

      openToast({
        heading: 'Success!',
        message: 'Your password was reset.',
        type: 'success',
      })

      const state: LoginLocationState = { passwordReset: true }

      navigate('/login', { state })
    },
  })

  return (
    <Formik<FormData>
      initialValues={{ newPassword: '', newPasswordConfirm: '' }}
      onSubmit={(data) => {
        track(events.RESET_PASSWORD_ATTEMPT)

        return resetPassword({
          data: { password: data.newPassword, token: resetToken },
          userID,
        })
      }}
      validate={(data) => {
        return validateData(data)
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form>
        <div className="space-y-6 sm:space-y-4">
          <FormInput
            id="newPassword"
            label="New Password"
            labelFor="newPassword"
            name="newPassword"
            type="password"
          />
          <FormInput
            id="newPasswordConfirm"
            label="Confirm New Password"
            labelFor="newPasswordConfirm"
            name="newPasswordConfirm"
            type="password"
          />

          {hasResetPasswordError && (
            <APIErrorDisplay
              error={resetPasswordError}
              errorCodeMessageMap={RESET_PASSWORD_ERRORS}
            />
          )}
        </div>

        <div className="mt-8 flex justify-center sm:mt-6">
          <ButtonLoading
            isLoading={isResettingPassword}
            size="large"
            type="submit"
          >
            Change Password
          </ButtonLoading>
        </div>
      </Form>
    </Formik>
  )
}
