import { useUserV0 } from '@tovala/browser-apis-combinedapi'

import { DATE_FORMATS, formatDate } from 'utils/dates'

import { useUser } from 'contexts/user'

const CommitmentDetails = () => {
  const { user } = useUser()

  const { data: userV0 } = useUserV0({ userID: user.id })

  if (!userV0) {
    return null
  }

  const commitmentDuration =
    userV0.subscription.commitment_details?.commitment_duration_date
  const commitmentWeeks =
    userV0.subscription.commitment_details?.commitment_weeks ?? 0
  const weeksRemaining =
    userV0.subscription.commitment_details?.weeks_remaining ?? 0
  const weeksOrdered = commitmentWeeks - weeksRemaining

  return (
    <div>
      <h2 className="mb-6 text-k/28_130">Meal Delivery Fulfillment</h2>
      <div className="rounded-lg bg-grey-2 px-6 py-4">
        <div className="text-k/18_120">
          Fulfill by{' '}
          {commitmentDuration
            ? formatDate(commitmentDuration, {
                format: DATE_FORMATS.MONTH_FULL_DAY_YEAR_FULL,
              })
            : ''}
        </div>
        <div className="text-k/14_120">
          {weeksOrdered} of {commitmentWeeks} meal deliveries ordered
        </div>
      </div>
    </div>
  )
}

export default CommitmentDetails
