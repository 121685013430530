import { FormFieldError } from '@tovala/component-library'
import { OrderHistoryReceipt } from '@tovala/browser-apis-combinedapi'
import { useFormContext } from 'react-hook-form'

import Meal from './Meal'
import RadioRHF from 'components/common/RadioRHF'

const AffectedMeal = ({ receipt }: { receipt: OrderHistoryReceipt }) => {
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext()

  const affectedMeal = watch('affectedMeal')

  return (
    <>
      <p className="text-k/16_125">Select the affected meal</p>

      <div className="space-y-4">
        {[...receipt.selectedMeals]
          .sort((a, b) => a.id - b.id)
          .map((meal, index) => {
            const mealID = `${meal.id}-${index}`

            return (
              <label
                key={mealID}
                className="flex items-center justify-between space-x-4"
                htmlFor={mealID}
              >
                <Meal meal={meal} />

                <RadioRHF
                  checked={affectedMeal === mealID}
                  id={mealID}
                  name="affectedMeal"
                  register={register}
                  registerOptions={{
                    required: 'Please select the affected meal',
                  }}
                  value={mealID}
                />
              </label>
            )
          })}
      </div>

      {errors.affectedMeal?.message && (
        <div className="mt-4">
          <FormFieldError>{`${errors.affectedMeal.message}`}</FormFieldError>
        </div>
      )}
    </>
  )
}

export default AffectedMeal
