import { Button } from '@tovala/component-library'

import ConfirmationDialog, {
  ConfirmationBody,
  ConfirmationButtons,
  ConfirmationHeader,
} from '../../common/ConfirmationDialog'

const MenuFiltersConfirmationDialog = ({ onClose }: { onClose(): void }) => {
  return (
    <ConfirmationDialog onRequestClose={onClose}>
      <ConfirmationHeader
        heading="Allergen in your order"
        onClickClose={onClose}
      />
      <ConfirmationBody>
        <div className="py-6">
          <p>
            One or more items in your order contain an allergen you filtered
            out.
          </p>
        </div>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="space-y-4">
          <div className="grid">
            <Button onClick={onClose} size="large">
              Dismiss
            </Button>
          </div>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}

export default MenuFiltersConfirmationDialog
