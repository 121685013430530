import { clsx } from 'clsx'
import { Controller, ControllerProps, FieldValues } from 'react-hook-form'

import { ReactSelectValue } from 'types/internal'

function DeliveryDays<FormData extends FieldValues>({
  control,
  deliveryOptions,
  name,
}: {
  control: ControllerProps<FormData>['control']
  deliveryOptions: ReactSelectValue<string>[]
  name: ControllerProps<FormData>['name']
}) {
  return (
    <div
      aria-labelledby="delivery-days"
      className="flex h-12 w-full items-center rounded-[48px] bg-grey-3"
      role="radiogroup"
    >
      {deliveryOptions.map(({ label, value }) => {
        return (
          <Controller
            key={label}
            control={control}
            name={name}
            render={({
              field: { onChange, onBlur, value: fieldValue, name, ref },
            }) => {
              const checked = fieldValue === value

              return (
                <label
                  key={label}
                  className={clsx(
                    'mx-1 flex h-10 items-center justify-center rounded-[40px]',
                    checked
                      ? 'bg-white hover:bg-white'
                      : 'transition-colors hover:bg-grey-6',
                    deliveryOptions.length > 1
                      ? 'w-1/2 cursor-pointer'
                      : 'w-full'
                  )}
                >
                  <input
                    ref={ref}
                    checked={checked}
                    className="absolute h-0 w-0 cursor-pointer opacity-0"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    type="radio"
                    value={value}
                  />
                  {label}
                </label>
              )
            }}
          />
        )
      })}
    </div>
  )
}

export default DeliveryDays
