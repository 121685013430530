import { clsx } from 'clsx'

const StatusDot = ({ color }: { color: 'green' | 'grey' | 'orange' }) => {
  return (
    <div
      className={clsx('h-full min-h-[12px] w-full min-w-[12px] rounded-full', {
        'bg-green': color === 'green',
        'bg-grey-8': color === 'grey',
        'bg-orange-1': color === 'orange',
      })}
    />
  )
}

export default StatusDot
