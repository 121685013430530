import { formatCentsToDollars } from 'utils/currency'

const CreditsBanner = ({
  totalCreditsCents,
}: {
  totalCreditsCents: number
}) => {
  if (totalCreditsCents === 0) {
    return null
  }

  return (
    <div className="bg-[#247C15] p-4 text-center text-white md:p-3">
      {formatCentsToDollars(totalCreditsCents) + ' off your next delivery'}
    </div>
  )
}

export default CreditsBanner
