import {
  AmericanExpressIcon,
  DiscoverIcon,
  MasterCardIcon,
  VisaIcon,
} from '@tovala/component-library'

import { CreditCardBrand } from 'types/domainModels/shared'

const CreditCardIcon = ({ brand }: { brand: CreditCardBrand }) => {
  const brandLower = brand.toLowerCase()

  if (brandLower === 'american express') {
    return <AmericanExpressIcon aria-label="American Express" />
  }

  if (brandLower === 'discover') {
    return <DiscoverIcon aria-label="Discover" />
  }

  if (brandLower === 'mastercard') {
    return <MasterCardIcon aria-label="Mastercard" />
  }

  return <VisaIcon aria-label="Visa" />
}

export default CreditCardIcon
