import { Route, Routes } from 'react-router-dom'
import { useEffect } from 'react'

import { events } from '../../../analytics/events'
import { track } from 'utils/analytics'
import { UserTerm } from 'types/internal'

import AccountBalancePage from './AccountBalancePage'
import AccountNav from './AccountNav'
import ManageOrders from './ManageOrders'
import MealPlan from './MealPlan'
import MealPlanPreferences from './MealPlanPreferences'
import OrderHistoryPage from './OrderHistoryPage'
import PaymentPage from './PaymentPage'
import ProfilePage from './ProfilePage'
import ShippingPage from './ShippingPage'

const AccountPage = ({
  onEditTermStatus,
}: {
  onEditTermStatus(term: UserTerm): void
}) => {
  useEffect(() => {
    track(events.OPENS_ACCOUNT_SCREEN)
  }, [])

  return (
    <div>
      <div className="flex">
        <div className="w-[225px] shrink-0 border-r border-grey-3 pt-10 md:hidden">
          <AccountNav />
        </div>
        <div className="mx-20 my-20 flex-auto lg:ml-8 lg:mr-4 md:m-0 md:pb-10 md:pt-4">
          <Routes>
            <Route
              element={
                <>
                  <div className="md:hidden">
                    <MealPlan onEditTermStatus={onEditTermStatus} />
                  </div>
                  <div className="hidden md:block">
                    <AccountNav />
                  </div>
                </>
              }
              path="/"
            />

            <Route element={<MealPlanPreferences />} path="plan-preferences" />
            <Route element={<PaymentPage />} path="payment" />
            <Route element={<ShippingPage />} path="shipping" />
            <Route element={<ProfilePage />} path="edit" />
            <Route element={<OrderHistoryPage />} path="history" />
            <Route element={<AccountBalancePage />} path="apply-promo" />
            <Route
              element={<ManageOrders onEditTermStatus={onEditTermStatus} />}
              path="manage-deliveries"
            />
            <Route
              element={<MealPlan onEditTermStatus={onEditTermStatus} />}
              path="edit-plan"
            />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default AccountPage
