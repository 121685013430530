import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
  SendMenuFeedback,
  sendMenuFeedback,
  SendMenuFeedbackResponse,
  sendSamplerBoxOrder,
  SendSamplerBoxOrder,
  SendSamplerBoxOrderResponse,
} from 'services/combinedAPI/tools'

export function useSendMenuFeedback(
  opts?: Omit<
    UseMutationOptions<SendMenuFeedbackResponse, Error, SendMenuFeedback>,
    'mutationFn'
  >
) {
  return useMutation({
    ...opts,
    mutationFn: sendMenuFeedback,
  })
}

export function useSendSamplerBoxOrder(
  opts?: Omit<
    UseMutationOptions<SendSamplerBoxOrderResponse, Error, SendSamplerBoxOrder>,
    'mutationFn'
  >
) {
  return useMutation({
    ...opts,
    mutationFn: sendSamplerBoxOrder,
  })
}
