import { CaretDownIcon, XIcon } from '@tovala/component-library'
import { clsx } from 'clsx'
import { Combobox as HeadlessUICombobox, Transition } from '@headlessui/react'
import { useState } from 'react'

export interface Props<Option> {
  hasError?: boolean
  id?: string
  isClearable?: boolean
  onChange(newValue: Option | null): void
  options: Option[]
  value: Option | null
}

function Combobox<
  OptionValue,
  Option extends { label: string; value: OptionValue }
>({
  hasError = false,
  id = undefined,
  isClearable = true,
  onChange,
  options,
  value,
}: Props<Option>) {
  const [query, setQuery] = useState('')

  const filteredOptions =
    query === ''
      ? options
      : options.filter(({ label }) => {
          return label.toLowerCase().includes(query.toLowerCase())
        })

  let rightIcon = (
    <div className="h-4 w-4">
      <CaretDownIcon />
    </div>
  )

  if (value && isClearable) {
    rightIcon = (
      <div
        className="rounded-full bg-grey-3 p-1"
        onClick={(event) => {
          event.defaultPrevented = true

          setQuery('')
          onChange(null)
        }}
      >
        <div className="h-3.5 w-3.5">
          <XIcon strokeWidth={2.25} />
        </div>
      </div>
    )
  }

  return (
    <HeadlessUICombobox nullable onChange={onChange} value={value}>
      <div className="relative text-k/14_120">
        <HeadlessUICombobox.Button as="div" className="w-full">
          <div className="relative">
            <HeadlessUICombobox.Input
              className={clsx(
                'h-14 w-full items-center rounded-lg border bg-grey-0 pl-4 pr-12 text-left',
                {
                  'border-red': hasError,
                  'border-grey-4': !hasError,
                }
              )}
              displayValue={(value: Option) => {
                return value?.label
              }}
              id={id}
              onChange={(event) => {
                setQuery(event.target.value)
              }}
            />

            <div className="absolute right-0 top-0 h-full w-12">
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                {rightIcon}
              </div>
            </div>
          </div>
        </HeadlessUICombobox.Button>

        <Transition
          afterLeave={() => {
            setQuery('')
          }}
        >
          <HeadlessUICombobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md border border-grey-4 bg-grey-0 text-k/14_120 shadow-lg focus:outline-none">
            {filteredOptions.map((option, i) => (
              <HeadlessUICombobox.Option
                key={i}
                className="border-b border-grey-4 p-4 ui-selected:font-semibold ui-active:bg-grey-2"
                value={option}
              >
                {option.label}
              </HeadlessUICombobox.Option>
            ))}
          </HeadlessUICombobox.Options>
        </Transition>
      </div>
    </HeadlessUICombobox>
  )
}

export default Combobox
