import { CircleLoader } from '@tovala/component-library'

import Radio, { Props as RadioProps } from './Radio'

export type Props = RadioProps & {
  isLoading: boolean
}

const RadioAsync = ({ isLoading, ...rest }: Props): JSX.Element => {
  return (
    <div className="relative">
      <Radio {...rest} />

      {isLoading && (
        <div className="absolute left-1 top-1">
          <CircleLoader loaderStyle="colored" />
        </div>
      )}
    </div>
  )
}

export default RadioAsync
