import { FormFieldError } from '@tovala/component-library'
import { ReactNode } from 'react'

import FormFieldLabel from './FormFieldLabel'

export interface Props {
  children: ReactNode
  error?: string
  label?: ReactNode
  labelFor?: string
}

const FormGroup = ({
  children,
  error,
  label,
  labelFor,
}: Props): JSX.Element => {
  return (
    <div>
      {label && (
        <div className="mb-3 md:mb-2">
          <FormFieldLabel label={label} labelFor={labelFor} />
        </div>
      )}

      {children}

      {error ? (
        <div className="mt-1">
          <FormFieldError>{error}</FormFieldError>
        </div>
      ) : null}
    </div>
  )
}

export default FormGroup
