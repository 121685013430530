import { EyeIcon, EyeSlashIcon } from '@tovala/component-library'

export default function RevealPassword({
  revealPassword,
  setRevealPassword,
}: {
  revealPassword: boolean
  setRevealPassword: (revealPassword: boolean) => void
}) {
  return (
    <button
      aria-label={revealPassword ? 'Hide password' : 'Show password'}
      className="h-6 w-6 text-grey-9"
      onClick={() => setRevealPassword(!revealPassword)}
      type="button"
    >
      {revealPassword ? <EyeSlashIcon /> : <EyeIcon />}
    </button>
  )
}
