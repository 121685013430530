import { Button } from '@tovala/component-library'
import { HeaderWithLogo } from '../common/Header'
import { useNavigate, useSearchParams } from 'react-router-dom'

const GetStartedRetailPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const channel = searchParams.get('channel')
  const store = searchParams.get('store')

  const handleNext = () => {
    let basePath = '/register'
    if (channel !== null && store !== null) {
      basePath = `${basePath}?channel=${channel}&store=${store}`
    }
    navigate(basePath)
  }

  return (
    <div>
      <HeaderWithLogo />
      <div className="m-auto flex max-w-[700px] flex-col items-center justify-center gap-5 pb-40 pt-5 md:max-w-[350px] sm:max-w-[300px]">
        <h1 className="text-center text-k/36_110">Get Started With Tovala</h1>
        <h2 className="text-center text-body-lg text-grey-9 md:mx-0">
          Create your account now and discover dinner that cooks itself.
        </h2>
        <img
          alt="Tovala Air Fryer sitting on a kitchen counter"
          className="rounded-lg shadow-lg"
          height={250}
          src="https://cdn.tovala.com/mytovala/images/retail-get-started-hero.jpg"
          width={442}
        />
        <Button onClick={handleNext} size="large">
          Create Account
        </Button>
      </div>
    </div>
  )
}

export default GetStartedRetailPage
