import { CustomerCreditCard } from '@tovala/browser-apis-combinedapi'

import { apiDelete, post, put } from './baseAPI'

export interface AddPaymentSource {
  customerID: string
  data: {
    setAsDefault: boolean
    stripeToken: string
  }
  userID: number
}

export interface AddPaymentSourceResponse {
  error: boolean
  extra:
    | CustomerCreditCard
    | {
        message: string
        param: string
        request_id: string
        status: number
        type: string
      }
  message: string
  status: string
}

export interface DeletePaymentSource {
  customerID: string
  paymentSourceID: string
  userID: number
}

export interface DeletePaymentSourceResponse {
  error: boolean
  extra: string
  message: string
  status: string
}

export interface EditDefaultPaymentSource {
  customerID: string
  data: { cardID: string }
  userID: number
}

export interface EditDefaultPaymentSourceResponse {
  error: boolean
  extra: string
  message: string
  status: string
}

export const ENDPOINTS = {
  ADD_PAYMENT_SOURCE: ({
    customerID,
    userID,
  }: Pick<AddPaymentSource, 'customerID' | 'userID'>) => ({
    path: `/users/${userID}/customers/${customerID}/sources`,
    version: 'v0' as const,
  }),
  DELETE_PAYMENT_SOURCE: ({
    customerID,
    paymentSourceID,
    userID,
  }: Pick<
    DeletePaymentSource,
    'customerID' | 'paymentSourceID' | 'userID'
  >) => ({
    path: `/users/${userID}/customers/${customerID}/sources/${paymentSourceID}`,
    version: 'v0' as const,
  }),
  EDIT_DEFAULT_PAYMENT_SOURCE: ({
    customerID,
    userID,
  }: Pick<EditDefaultPaymentSource, 'customerID' | 'userID'>) => ({
    path: `/users/${userID}/customers/${customerID}/defaultPaymentSource`,
    version: 'v0' as const,
  }),
}

export async function addPaymentSource({
  customerID,
  data,
  userID,
}: AddPaymentSource): Promise<AddPaymentSourceResponse> {
  const { stripeToken, ...rest } = data

  return post({
    data: { ...rest, token: stripeToken },
    ...ENDPOINTS.ADD_PAYMENT_SOURCE({ customerID, userID }),
  })
}

export async function deletePaymentSource({
  customerID,
  paymentSourceID,
  userID,
}: DeletePaymentSource): Promise<DeletePaymentSourceResponse> {
  return apiDelete(
    ENDPOINTS.DELETE_PAYMENT_SOURCE({ customerID, paymentSourceID, userID })
  )
}

export async function editDefaultPaymentSource({
  customerID,
  data,
  userID,
}: EditDefaultPaymentSource): Promise<EditDefaultPaymentSourceResponse> {
  return put({
    data: { sourceid: data.cardID },
    ...ENDPOINTS.EDIT_DEFAULT_PAYMENT_SOURCE({ customerID, userID }),
  })
}
