import { forwardRef, ReactNode } from 'react'
import { useField } from 'formik'

import FormGroup from './FormGroup'
import Input, { Props as InputProps } from './Input'

const FormInput = forwardRef<
  HTMLInputElement,
  Omit<InputProps, 'hasError' | 'name'> & {
    formatFn?: (value: string) => void
    label?: ReactNode
    labelFor?: string
    name: string
  }
>(function FormInput({ formatFn, label, labelFor, ...props }, ref) {
  const [field, meta] = useField(props)
  const hasError = !!(meta.touched && meta.error)
  const value = field.value ?? ''

  return (
    <FormGroup
      error={hasError ? meta.error : ''}
      label={label}
      labelFor={labelFor}
    >
      <Input
        ref={ref}
        {...field}
        {...props}
        hasError={hasError}
        value={formatFn ? formatFn(value) : value}
      />
    </FormGroup>
  )
})

export default FormInput
