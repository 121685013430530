import { clsx } from 'clsx'

type TextSize = 'small' | 'large'

const MealImageSoldOut = ({ textSize }: { textSize: TextSize }) => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <p
        className={clsx('font-semibold text-white', {
          'text-h/34_100': textSize === 'large',
          'text-h/18_110': textSize === 'small',
        })}
      >
        Sold Out
      </p>
    </div>
  )
}

export default MealImageSoldOut
