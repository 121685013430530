import { Customer } from '@tovala/browser-apis-combinedapi'

import { get } from './baseAPI'

export interface GetCustomer {
  customerID: string
  userID: number
}

export const ENDPOINTS = {
  GET_CUSTOMER: ({
    customerID,
    userID,
  }: Pick<GetCustomer, 'customerID' | 'userID'>) => ({
    path: `/users/${userID}/customers/${customerID}`,
    version: 'v0' as const,
  }),
}

export async function getCustomer({
  customerID,
  userID,
}: GetCustomer): Promise<Customer> {
  return get(ENDPOINTS.GET_CUSTOMER({ customerID, userID }))
}
