import { cloneDeep } from 'lodash-es'
import {
  TermStatus,
  useInvalidateListings,
  useInvalidateUsers,
} from '@tovala/browser-apis-combinedapi'
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

import {
  createUserTermStatus,
  CreateUserTermStatus,
  EditUserTermStatus,
  editUserTermStatus,
  getUserTermStatuses,
  GetUserTermStatusesResponse,
} from 'services/combinedAPI/termStatus'
import { DATE_FORMATS, formatDate } from 'utils/dates'
import { events } from 'analytics/events'
import { minutesToMs } from 'utils/general'
import { track } from 'utils/analytics'
import { UpdateUserTermStatusData } from 'types/internal'

import { useAuth } from 'contexts/auth'

export function useCreateUserTermStatus(
  opts?: Omit<
    UseMutationOptions<TermStatus, Error, CreateUserTermStatus>,
    'mutationFn'
  >
) {
  return useMutation({
    ...opts,
    mutationFn: ({ data, userID }) => {
      return createUserTermStatus({ data, userID })
    },
  })
}

export function useEditUserTermStatus(
  opts?: Omit<
    UseMutationOptions<TermStatus, Error, EditUserTermStatus>,
    'mutationFn'
  >
) {
  return useMutation({
    ...opts,
    mutationFn: ({ data, termStatusID, userID }) => {
      return editUserTermStatus({ data, termStatusID, userID })
    },
  })
}

export function useInvalidateTermStatuses() {
  const queryClient = useQueryClient()

  return {
    invalidateUserTermStatuses: (
      userID: number,
      opts?:
        | {
            newTermStatus: TermStatus
          }
        | {
            newTermStatusData: UpdateUserTermStatusData
          }
    ) => {
      if (opts) {
        queryClient.setQueryData<TermStatus[]>(
          ['term-statuses', userID],
          (currentTermStatuses) => {
            if (!currentTermStatuses) {
              return currentTermStatuses
            }

            const hasNewTermStatus = 'newTermStatus' in opts
            let termID: number | undefined

            if (hasNewTermStatus) {
              termID = opts.newTermStatus.termID
            } else {
              termID = opts.newTermStatusData.termID
            }

            if (!termID) {
              return currentTermStatuses
            }

            const newTermStatuses = cloneDeep(currentTermStatuses)
            const termStatusToUpdateIndex = newTermStatuses.findIndex(
              (termStatus) => {
                return termStatus.termID === termID
              }
            )

            if (termStatusToUpdateIndex !== -1) {
              if (hasNewTermStatus) {
                newTermStatuses[termStatusToUpdateIndex] = opts.newTermStatus
              } else {
                const termStatusToUpdate =
                  newTermStatuses[termStatusToUpdateIndex]

                termStatusToUpdate.mealSelections =
                  opts.newTermStatusData.mealSelections

                const subTermIndex = termStatusToUpdate.subTerms.findIndex(
                  (subTerm) =>
                    subTerm.subTermID === termStatusToUpdate.selectedSubTermID
                )
                const subTerm = termStatusToUpdate.subTerms[subTermIndex]

                if (subTerm) {
                  subTerm.mealSelectionsPricing =
                    opts.newTermStatusData.mealSelectionsPricing
                  subTerm.subscriptionPricing =
                    opts.newTermStatusData.subscriptionPricing
                }
              }
            }

            return newTermStatuses
          }
        )
      } else {
        queryClient.invalidateQueries(['term-statuses', userID])
      }
    },
  }
}

export function useUpsertUserTermStatus(
  opts?: Omit<
    UseMutationOptions<
      TermStatus,
      Error,
      CreateUserTermStatus & {
        previousMaxSelections?: number
        selectedUserTermID: string | null
        updateSubscription?: boolean
      }
    >,
    'mutationFn'
  >
) {
  const { invalidateUserTermStatuses } = useInvalidateTermStatuses()
  const { invalidateUserV0, invalidateUserV1 } = useInvalidateUsers()
  const { invalidateAllListingSelections } = useInvalidateListings()

  return useMutation({
    ...opts,
    mutationFn: ({ data, selectedUserTermID, userID }) => {
      if (selectedUserTermID) {
        return editUserTermStatus({
          data,
          termStatusID: selectedUserTermID,
          userID,
        })
      }

      return createUserTermStatus({ data, userID })
    },
    onError: (...args) => {
      const { userID } = args[1]

      invalidateUserTermStatuses(userID)
      invalidateUserV0(userID)
      invalidateUserV1(userID)

      opts?.onError?.(...args)
    },
    onSuccess: (...args) => {
      const termStatus = args[0]
      const { previousMaxSelections, selectedUserTermID, userID } = args[1]

      if (selectedUserTermID) {
        const selectedSubTerm = termStatus.subTerms.find(
          (subTerm) => subTerm.subTermID === termStatus.selectedSubTermID
        )

        track(events.USER_EDITS_WEEK, {
          previous_plan_size: previousMaxSelections,
          plan_size: termStatus.subscriptionType?.maxSelections,
          delivery_day_of_week: selectedSubTerm
            ? formatDate(selectedSubTerm.deliveryDate, {
                format: DATE_FORMATS.DOW_FULL,
              })
            : undefined,
          ship_period: selectedSubTerm?.shipPeriod,
          term_id: termStatus.termID,
        })
      }

      invalidateUserTermStatuses(userID, { newTermStatus: termStatus })
      invalidateAllListingSelections(userID)

      opts?.onSuccess?.(...args)
    },
  })
}

export type UseUserTermStatusesOpts<
  FinalDataType = GetUserTermStatusesResponse
> = Omit<
  UseQueryOptions<GetUserTermStatusesResponse, Error, FinalDataType>,
  'queryFn' | 'queryKey' | 'staleTime'
>

export function useUserTermStatuses<
  FinalDataType = GetUserTermStatusesResponse
>({ enabled = true, ...rest }: UseUserTermStatusesOpts<FinalDataType> = {}) {
  const { user } = useAuth()
  const userID = user?.id

  return useQuery<GetUserTermStatusesResponse, Error, FinalDataType>({
    ...rest,
    enabled: enabled && !!userID && user.subscription.status === 'active',
    queryFn: () => {
      if (!userID) {
        throw new Error(
          'Cannot get user term statuses because no user ID was provided'
        )
      }

      return getUserTermStatuses({ userID })
    },
    queryKey: ['term-statuses', userID],
    staleTime: minutesToMs(1),
  })
}
