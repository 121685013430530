const ExtrasAllergenNote = () => {
  return (
    <div className="space-y-4 text-center md:text-left">
      <h2 className="text-k/20_110">Allergen Note</h2>
      <p className="text-k/16_125 text-grey-9 md:text-body-sm">
        Allergen filtering for meals does not apply to our Extras lineup. Please
        double check the allergen and nutritional info for any specific Extras
        in your order.
      </p>
    </div>
  )
}

export default ExtrasAllergenNote
