import { Oven, OvenCookHistoryItem } from '@tovala/browser-apis-combinedapi'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { getOvenCookHistory, getOvens } from 'services/combinedAPI/ovens'
import { minutesToMs } from 'utils/general'

export function useFirstOvenCookHistory({
  userID,
}: {
  userID: number | undefined
}) {
  const { data: ovens = [] } = useOvens({ userID })

  return useOvenCookHistory({
    ovenID: ovens[0]?.id,
    userID,
  })
}

export function useOvenCookHistory({
  ovenID,
  userID,
  ...rest
}: { ovenID: string | undefined; userID: number | undefined } & Omit<
  UseQueryOptions<OvenCookHistoryItem[], Error>,
  'enabled' | 'queryFn' | 'queryKey' | 'staleTime'
>) {
  return useQuery<OvenCookHistoryItem[], Error>({
    ...rest,
    enabled: !!(ovenID && userID),
    queryFn: () => {
      if (!ovenID || !userID) {
        throw new Error(
          'Cannot get oven cook history because an ovenID or userID was not provided'
        )
      }

      return getOvenCookHistory({ ovenID, userID })
    },
    queryKey: ['oven-cook-history', ovenID, userID],
    staleTime: minutesToMs(5),
  })
}

export function useOvens({
  userID,
  ...rest
}: { userID: number | undefined } & Omit<
  UseQueryOptions<Oven[], Error>,
  'enabled' | 'queryFn' | 'queryKey' | 'staleTime'
>) {
  return useQuery<Oven[], Error>({
    ...rest,
    enabled: !!userID,
    queryFn: () => {
      if (!userID) {
        throw new Error('Cannot get ovens because no userID was provided')
      }

      return getOvens({ userID })
    },
    queryKey: ['ovens', userID],
    staleTime: minutesToMs(10),
  })
}
