import { loadStripe } from '@stripe/stripe-js'
import * as Sentry from '@sentry/react'

import { getEnvVar } from './env'

let failedToLoadStripe = false

export const stripePromise = loadStripe(getEnvVar('STRIPE_KEY')).catch(
  (err) => {
    if (err instanceof Error) {
      // Stripe.js may not load for some users due to extensions / ad blockers / etc.
      // We don't need that noise in Sentry so we'll only capture an exception if that's
      // not the case.
      if (err.message === 'Failed to load Stripe.js') {
        failedToLoadStripe = true
      } else {
        Sentry.captureException(err)
      }
    }

    return null
  }
)

export function getElementsConfiguration() {
  return {
    fonts: [
      {
        family: 'Sohne',
        src: 'url(https://cdn.tovala.com/fonts/soehne-buch.woff2)',
        weight: '400',
      },
    ],
  }
}

export function hasFailedToLoadStripe() {
  return failedToLoadStripe
}
