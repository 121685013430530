import { OrderHistoryReceipt } from '@tovala/browser-apis-combinedapi'

import Meal from './Meal'

const AffectedMealsList = ({
  affectedMeals,
  heading,
  receipt,
}: {
  affectedMeals: string[]
  heading?: string
  receipt: OrderHistoryReceipt | undefined
}) => {
  return (
    <div className="space-y-4">
      {heading && <h3 className="text-k/20_125">{heading}</h3>}

      <div className="space-y-4">
        {affectedMeals.map((affectedMeal, index) => {
          const mealID = affectedMeal.split('-')[0]
          const meal =
            receipt &&
            receipt.selectedMeals.find((meal) => meal.id === Number(mealID))

          if (!meal) {
            return null
          }

          return <Meal key={`${meal.id}-${index}`} meal={meal} />
        })}
      </div>
    </div>
  )
}

export default AffectedMealsList
