import { motion } from 'framer-motion'

const TRANSITION = {
  duration: 1.9,
  ease: 'easeInOut',
  repeat: Infinity,
  repeatDelay: 0.75,
  times: [0, 0.2, 0.5, 0.8, 1],
}

const Loader = () => {
  return (
    <div className="fixed flex h-screen w-screen items-center justify-center">
      <div className="relative h-8 w-8">
        <motion.div
          animate={{
            rotate: [0, 0, 90, 90, 90],
          }}
          className="relative h-8 w-8"
          transition={TRANSITION}
        >
          <motion.div
            animate={{
              x: ['-94%', '0%', '0%', '0%', '-94%'],
              y: ['-94%', '0%', '0%', '0%', '-94%'],
            }}
            className="absolute h-8 w-8 bg-orange-1"
            transition={TRANSITION}
          />
          <motion.div
            animate={{
              x: ['94%', '0%', '0%', '0%', '94%'],
              y: ['-94%', '0%', '0%', '0%', '-94%'],
            }}
            className="absolute h-8 w-8 bg-orange-1"
            transition={TRANSITION}
          />
          <motion.div
            animate={{
              x: ['-94%', '0%', '0%', '0%', '-94%'],
              y: ['94%', '0%', '0%', '0%', '94%'],
            }}
            className="absolute h-8 w-8 bg-orange-1"
            transition={TRANSITION}
          />
          <motion.div
            animate={{
              x: ['94%', '0%', '0%', '0%', '94%'],
              y: ['94%', '0%', '0%', '0%', '94%'],
            }}
            className="absolute h-8 w-8 bg-orange-1"
            transition={TRANSITION}
          />
        </motion.div>
      </div>
    </div>
  )
}

export default Loader
