import {
  Meal,
  MealSummary,
  OrderHistoryReceiptMeal,
} from '@tovala/browser-apis-combinedapi'

import DetailsDialog, { MealDetails } from './DetailsDialog'

const MealDetailsDialog = ({
  closeModal,
  meal,
  suggestions = [],
}: {
  closeModal(): void
  meal: Meal | OrderHistoryReceiptMeal
  suggestions?: MealSummary[]
}) => {
  return (
    <DetailsDialog onClose={closeModal}>
      <MealDetails meal={meal} suggestions={suggestions} />
    </DetailsDialog>
  )
}

export default MealDetailsDialog
