const LogoLockUpIcon = () => {
  return (
    <svg fill="none" viewBox="0 0 122 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 5.33394H5.32046V0H0V5.33394ZM10.6428 0V5.33394H15.965V0H10.6428ZM0 16H5.32046V10.6661H0V16ZM10.6428 16H15.965V10.6661H10.6428V16Z"
        fill="#FF6A1B"
      />
      <path
        clipRule="evenodd"
        d="M58.6115 8C58.6115 3.264 55.6562 0 51.6011 0C47.546 0 44.5676 3.264 44.5676 8C44.5676 12.736 47.546 16 51.6011 16C55.6562 16 58.6115 12.736 58.6115 8ZM46.6342 8C46.6342 4.32711 48.6884 1.83822 51.6011 1.83822C54.5138 1.83822 56.568 4.32711 56.568 8C56.568 11.6729 54.5174 14.1618 51.6011 14.1618C48.6848 14.1618 46.6342 11.6729 46.6342 8Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M35.7767 15.7617V2.05329H41.0203V0.238176H28.4629V2.05329H33.7101V15.7617H35.7767Z"
        fill="currentColor"
      />
      <path
        d="M66.7417 15.7617L60.8506 0.238176H63.0732L67.8628 13.168L72.6523 0.238176H74.7668L68.9129 15.7617H66.7417Z"
        fill="currentColor"
      />
      <path
        d="M94.2669 15.7617H104.062V13.9466H96.3389V0.238176H94.2669V15.7617Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M115.602 0.238176H113.313L107.337 15.7617H109.409L110.615 12.5866L110.686 12.4088H118.19L118.399 12.9546L119.485 15.7688H121.576L115.602 0.238176ZM111.376 10.5848L114.422 2.5724L116.778 8.69151V8.70218L117.223 9.85951L117.5 10.5848H111.376Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M81.8623 0.238176H84.1488L90.1198 15.7688H88.0336L86.948 12.9546L86.7387 12.4088H79.2351L79.1642 12.5866L77.9579 15.7617H75.886L81.8623 0.238176ZM79.9252 10.5848H86.0398L85.7631 9.85951L85.3178 8.70218V8.69151L82.9621 2.5724L79.9252 10.5848Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default LogoLockUpIcon
