import { OrderHistoryReceiptMeal } from '@tovala/browser-apis-combinedapi'

const Meal = ({ meal }: { meal: OrderHistoryReceiptMeal }) => {
  return (
    <div className="flex items-center">
      <MealImage meal={meal} />

      <div className="mx-4">
        <p className="mb-1 text-k/14_120">{meal.title}</p>
        <p className="text-k/12_120">{meal.subtitle}</p>
      </div>
    </div>
  )
}

export default Meal

const MealImage = ({ meal }: { meal: OrderHistoryReceiptMeal }) => {
  const image = meal.images.find((m) => m.key === 'cell_tile')

  return (
    <div className="h-[70px] w-[70px] shrink-0">
      {image?.url ? (
        <img
          alt={image.alt}
          className="h-full rounded-lg object-cover"
          src={image.url}
        />
      ) : (
        <div className="w-full rounded-lg bg-grey-4" />
      )}
    </div>
  )
}
