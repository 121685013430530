import { ReactNode, useEffect } from 'react'
import { Dialog } from '@headlessui/react'

import { storageAvailable } from 'utils/storageAvailable'
import { track } from 'utils/analytics'
import { events } from 'analytics/events'

import {
  Button,
  OrderIcon,
  SkipIcon,
  TruckIcon2,
} from '@tovala/component-library'
import { useTypeformPopup } from 'hooks/typeform'
import { UserV1, useTovalaProducts } from '@tovala/browser-apis-combinedapi'

const onboardingItems = [
  {
    icon: <OrderIcon />,
    text: "Add a meal to your order and we'll automatically save it.",
    title: 'Picking Meals',
  },
  {
    icon: <SkipIcon />,
    text: 'It\'s easy. Just tap the "skip/adjust" button to skip any week.',
    title: 'Skip Anytime',
  },
  {
    icon: <TruckIcon2 />,
    text: 'Add or remove items from your order until Wednesday at 6pm (CT) the week before your delivery.',
    title: 'Order Deadline',
  },
]

const OnboardingModal = ({
  onCloseModal,
  user,
}: {
  onCloseModal(): void
  user: UserV1
}) => {
  const { data: tovalaProducts = [] } = useTovalaProducts()

  const mealsOnlyProduct = tovalaProducts.find((product) => {
    return !product.createFulfillment && product.chargeSKU === 'meals_only_flow'
  })

  let hasPurchasedMealsOnlyOven = false
  if (mealsOnlyProduct) {
    // Check for the meals only product id in the user's products purchases
    hasPurchasedMealsOnlyOven = user.productPurchases.some((product) => {
      return product.id === mealsOnlyProduct.id
    })
  }

  const { openTypeformPopup } = useTypeformPopup({
    hiddenFields: {
      email: user.info.email,
      mealsonlysincenov16: `${hasPurchasedMealsOnlyOven}`,
    },
    onClose: () => {
      onCloseModal()
      track(events.CLOSED_POST_PURCHASE_SURVEY)
    },
    onReady: () => {
      track(events.CLOSED_WEB_ONBOARDING_MODAL)
      track(events.VIEWED_POST_PURCHASE_SURVEY)
    },
    onSubmit: () => {
      track(events.COMPLETED_POST_PURCHASE_SURVEY)
    },
    survey: 'postPurchase',
    userID: `${user.id}`,
  })

  useEffect(() => {
    if (storageAvailable('localStorage')) {
      localStorage.setItem('viewedOnboarding', 'true')
    }

    track(events.POST_SIGNUP_BREADCRUMBS_DID_APPEAR)
  }, [])

  return (
    <Dialog onClose={onCloseModal} open={true}>
      <div
        className="fixed inset-0 z-30 bg-black opacity-40"
        onClick={onCloseModal}
      />
      <div className="fixed inset-0 z-30 flex items-center justify-center">
        <Dialog.Panel className="max-w-lg rounded-2xl bg-white p-6 pt-10">
          <Dialog.Title className="mb-10 px-3 text-center text-k/28_130">
            Things to Know
          </Dialog.Title>

          <div className="space-y-10">
            {onboardingItems.map((item) => {
              return <OnboardingItem key={item.title} {...item} />
            })}

            <div className="flex flex-col">
              <Button
                onClick={() => {
                  openTypeformPopup()
                }}
                size="large"
              >
                Dismiss
              </Button>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default OnboardingModal

const OnboardingItem = ({
  icon,
  text,
  title,
}: {
  icon: ReactNode
  text: string
  title: string
}) => {
  return (
    <div className="flex space-x-4">
      <div className="flex h-[72px] w-[72px] shrink-0 items-center justify-center rounded-full bg-grey-2">
        <div className="w-10">{icon}</div>
      </div>
      <div className="max-w-xs space-y-2">
        <span className="inline-block border-b-[3px] border-orange-1 pb-2 text-k/16_125 font-semibold">
          {title}
        </span>
        <p className="text-body-sm">{text}</p>
      </div>
    </div>
  )
}
