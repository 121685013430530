import { compact } from 'lodash-es'
import {
  OrderHistoryReceipt,
  OvenCookHistoryItem,
  ReviewSummary,
  SkippedWeeks,
  TermStatus,
  UserV1,
} from '@tovala/browser-apis-combinedapi'

import {
  addToDate,
  convertUTCToCT,
  DATE_FORMATS,
  formatDate,
  isDateAfter,
  isDateBefore,
  isDatePast,
  parseToDate,
} from './dates'
import { TermToRate, UserTerm } from 'types/internal'

export function getDeliveryStatus({
  receipt,
}: {
  receipt: OrderHistoryReceipt
}) {
  if (
    receipt.orderStatus === 'complete' ||
    receipt.orderStatus === 'refunded'
  ) {
    return isDatePast(parseToDate(receipt.deliveryDate))
      ? 'Delivered'
      : 'Arriving'
  } else if (receipt.orderStatus === 'skipped') {
    return 'Skipped'
  } else if (receipt.orderStatus === 'canceled') {
    return 'Canceled'
  } else if (receipt.orderStatus === 'processing') {
    return 'Processing'
  } else if (receipt.orderStatus === 'payment_error') {
    return 'Payment Error'
  }
}

export function getMostRecentTermMealsToRate({
  termsToRate,
}: {
  termsToRate: TermToRate[]
}) {
  const today = new Date()

  let termToRate = termsToRate.find((term) => {
    /*
     * Find the most recent past term that has meals to rate that was delivered
     * at least 5 days ago, but isn't too far past the delivery date. These meals
     * are likely to have been eaten, but no so long ago the user has forgotten how
     * they feel about them
     */
    const deliveryDate = parseToDate(term.deliveryDate)
    const averageTimeToConsumeMeals = addToDate(deliveryDate, {
      quantity: 5,
      units: 'days',
    })
    const tooOldToPromptToRate = addToDate(deliveryDate, {
      quantity: 12,
      units: 'days',
    })

    return (
      isDateAfter(today, averageTimeToConsumeMeals) &&
      isDateBefore(today, tooOldToPromptToRate)
    )
  })

  if (termToRate) {
    /*
     * Check for more recent terms than the one found above that have cooked meals and add those
     * into the ratings slider
     */
    const moreRecentTerms = termsToRate.filter((term) =>
      termToRate ? term.termID > termToRate.termID : false
    )
    if (moreRecentTerms) {
      // Create a clone so we can add more recent cooked meals
      termToRate = Object.assign({}, termToRate)

      moreRecentTerms.forEach((term) => {
        if (!termToRate) {
          return
        }

        const cookedMeals = term.meals.filter((meal) => meal.cooked)
        termToRate.meals = [...termToRate.meals, ...cookedMeals]

        const cooked = termToRate.meals
          .filter((meal) => meal.cooked)
          .sort((a, b) =>
            a.cooked && b.cooked
              ? +new Date(b.cooked.end_time) - +new Date(a.cooked.end_time)
              : -1
          )
        const notCooked = termToRate.meals.filter((meal) => !meal.cooked)

        termToRate.meals = [...cooked, ...notCooked]
      })
    }
  }

  return termToRate
}

export function getOrderCutoffFormattedDate({
  orderByDate,
}: {
  orderByDate: string
}) {
  return formatDate(convertUTCToCT(orderByDate), {
    format: DATE_FORMATS.ORDER_BY_DATE,
  })
}

export function getTermsToRate({
  cookHistory,
  mealReviewSummaries,
  orderHistoryReceipts,
}: {
  cookHistory: OvenCookHistoryItem[] | ''
  mealReviewSummaries: ReviewSummary | undefined
  orderHistoryReceipts: OrderHistoryReceipt[]
}) {
  let cookedMeals: OvenCookHistoryItem[] = []

  if (cookHistory) {
    cookedMeals = cookHistory.filter((history) => history.meal_id)
  }

  let reviewedMealIDs: number[] = []

  if (mealReviewSummaries) {
    reviewedMealIDs = mealReviewSummaries.reviews.map((review) => review.mealId)
  }

  const termsToRate: TermToRate[] = []

  if (orderHistoryReceipts) {
    orderHistoryReceipts.forEach((receipt) => {
      if (
        (receipt.orderStatus === 'complete' ||
          receipt.orderStatus === 'refunded') &&
        receipt.selectedMeals
      ) {
        const uniqueMeals = new Set(
          receipt.selectedMeals.map((meal) => meal.id)
        )
        const uniqueMealsArr = compact(
          [...Array.from(uniqueMeals)].map((mealID) =>
            receipt.selectedMeals.find((meal) => meal.id === mealID)
          )
        )
        const meals = uniqueMealsArr
          .filter((meal) => !reviewedMealIDs.includes(meal.id))
          .map((meal) => {
            return Object.assign({}, meal, {
              cooked: cookedMeals.find(
                (cookedMeal) =>
                  cookedMeal.meal_id &&
                  Number.parseInt(cookedMeal.meal_id, 10) === meal.id
              ),
            })
          })

        const cooked = meals
          .filter((meal) => meal.cooked)
          .sort((a, b) =>
            a.cooked && b.cooked
              ? +new Date(b.cooked.end_time) - +new Date(a.cooked.end_time)
              : -1
          )
        const notCooked = meals.filter((meal) => !meal.cooked)

        const term: TermToRate = {
          deliveryDate: receipt.deliveryDate,
          meals: [...cooked, ...notCooked],
          termID: receipt.termID,
        }
        termsToRate.push(term)
      }
    })
  }

  return termsToRate
}

export function getUpgradedSubscriptionTermID({
  termStatuses,
  user,
}: {
  termStatuses: TermStatus[]
  user: UserV1
}) {
  const subscriptionMaxSelections =
    user.subscription.subscriptionType?.maxSelections

  const upgradedTerm =
    subscriptionMaxSelections &&
    termStatuses.find((term) => {
      return (
        term.subscriptionType &&
        term.subscriptionType.maxSelections > subscriptionMaxSelections
      )
    })

  return upgradedTerm ? upgradedTerm.termID : null
}

export function getUserTerm({
  skippedWeeks,
  term,
  user,
}: {
  skippedWeeks: SkippedWeeks | undefined
  term: TermStatus
  user: UserV1
}) {
  const selectedSubTerm = term.subTerms.find(
    (subTerm) => subTerm.subTermID === term.selectedSubTermID
  )

  const userTerm: UserTerm = {
    ...term,
    hasSelectedAllMeals:
      term.subscriptionType?.maxSelections === term.mealSelections.length ??
      false,
    isSkipped: skippedWeeks
      ? skippedWeeks.termids.includes(term.termID)
      : term.isSkipped,
    isStaticSkipped: skippedWeeks
      ? skippedWeeks.staticTermIDs.includes(term.termID)
      : term.isStaticSkipped,
    mealSwaps: selectedSubTerm?.mainMenu?.mealSwaps
      ? [...selectedSubTerm.mainMenu.mealSwaps]
      : [],
    selectedSubscriptionTypeIsDefault:
      term.subscriptionType && user?.subscription.subscriptionType
        ? term.subscriptionType.maxSelections ===
          user.subscription.subscriptionType.maxSelections
        : true,
    selectedSubTerm,
    subTerms: [...term.subTerms]
      .filter((subTerm) => subTerm.isAvailable)
      .sort((a, b) => a.shipPeriod - b.shipPeriod),
  }

  return userTerm
}
