import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom'
import { useRef } from 'react'

import Header, { HeaderWithLogo } from 'components/common/Header'
import MarketplaceStorefront from './MarketplaceStorefront'
import UserLoader from 'components/common/UserLoader'

const Marketplace = () => {
  const [searchParams] = useSearchParams()

  // We use a ref for this so it applies across all marketplace routes without
  // children needing to include it in the query params as they navigate. The
  // navigation will appear if the user refreshes on a subsequent page, but that
  // should be fine since the main use-case of this is showing marketplace in a
  // mobile web view where we'd like to prevent navigation to other parts of MyTovala
  // (ideally the user is closing the web view to use the app functionality once
  // they're done with marketplace).
  const noHeaderNav = useRef(searchParams.get('withoutHeaderNav'))

  return (
    <div className="space-y-20 md:space-y-6">
      {noHeaderNav.current === null ? <Header /> : <HeaderWithLogo />}

      <UserLoader>
        <MarketplaceUserLoaded />
      </UserLoader>
    </div>
  )
}

export default Marketplace

const MarketplaceUserLoaded = () => {
  return (
    <Routes>
      <Route path="products">
        <Route element={<MarketplaceStorefront />} index />
      </Route>
      <Route element={<Navigate to="products" />} path="*" />
    </Routes>
  )
}
