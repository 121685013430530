import {
  SkippedWeeks,
  UserSubscriptionType,
  UserV1Subscription,
} from '@tovala/browser-apis-combinedapi'

import { apiDelete, get, put } from './baseAPI'
import { SubscriptionPreferences } from 'types/internal'

export interface ChangeSubscriptionStatus {
  data: { defaultShipPeriod: number; subscriptionTypeID: string }
  subscriptionStatus: 'activate' | 'cancel' | 'pause'
  userID: number
}

export interface GetSkippedWeeks {
  userID: number
}

export type GetSkippedWeeksResponse = SkippedWeeks

export interface GetUserSubscriptionTypes {
  userID: number
}

export interface GetUserSubscriptionTypesResponse {
  subscription_types: UserSubscriptionType[]
}

export interface SkipWeek {
  termID: number
  userID: number
}

export interface UnskipWeek {
  termID: number
  userID: number
}

export interface UpdatePlanPreferences {
  data: SubscriptionPreferences
  userID: number
}

export const ENDPOINTS = {
  CHANGE_SUBSCRIPTION_STATUS: ({
    subscriptionStatus,
    userID,
  }: Pick<ChangeSubscriptionStatus, 'subscriptionStatus' | 'userID'>) => ({
    path: `/users/${userID}/subscription/${subscriptionStatus}`,
    version: 'v1' as const,
  }),
  GET_SKIPPED_WEEKS: ({ userID }: Pick<GetSkippedWeeks, 'userID'>) => ({
    path: `/users/${userID}/subscription/skip`,
    version: 'v0' as const,
  }),
  GET_USER_SUBSCRIPTION_TYPES: ({
    userID,
  }: Pick<GetUserSubscriptionTypes, 'userID'>) => ({
    path: `/users/${userID}/subscriptionTypes`,
    version: 'v1' as const,
  }),
  SKIP_WEEK: ({ userID }: Pick<SkipWeek, 'userID'>) => ({
    path: `/users/${userID}/subscription/skip`,
    version: 'v0' as const,
  }),
  UNSKIP_WEEK: ({ userID }: Pick<UnskipWeek, 'userID'>) => ({
    path: `/users/${userID}/subscription/skip`,
    version: 'v0' as const,
  }),
  UPDATE_PLAN_PREFERENCES: ({
    userID,
  }: Pick<UpdatePlanPreferences, 'userID'>) => ({
    path: `/users/${userID}/subscriptionPreferences`,
    version: 'v1' as const,
  }),
}

export async function changeSubscriptionStatus({
  data,
  subscriptionStatus,
  userID,
}: ChangeSubscriptionStatus): Promise<unknown> {
  return put({
    data,
    ...ENDPOINTS.CHANGE_SUBSCRIPTION_STATUS({ subscriptionStatus, userID }),
  })
}

export async function getSkippedWeeks({
  userID,
}: GetSkippedWeeks): Promise<GetSkippedWeeksResponse> {
  return get(ENDPOINTS.GET_SKIPPED_WEEKS({ userID }))
}

export async function getUserSubscriptionTypes({
  userID,
}: GetUserSubscriptionTypes): Promise<GetUserSubscriptionTypesResponse> {
  return get(ENDPOINTS.GET_USER_SUBSCRIPTION_TYPES({ userID }))
}

export async function skipWeek({
  termID,
  userID,
}: SkipWeek): Promise<SkippedWeeks> {
  return put({
    data: { termId: termID },
    ...ENDPOINTS.SKIP_WEEK({ userID }),
  })
}

export async function unskipWeek({
  termID,
  userID,
}: UnskipWeek): Promise<SkippedWeeks> {
  return apiDelete({
    data: { termId: termID },
    ...ENDPOINTS.UNSKIP_WEEK({ userID }),
  })
}

export async function updatePlanPreferences({
  data,
  userID,
}: UpdatePlanPreferences): Promise<UserV1Subscription> {
  return put({
    data,
    ...ENDPOINTS.UPDATE_PLAN_PREFERENCES({ userID }),
  })
}
