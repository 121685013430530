import { useEffect, useState } from 'react'
import { clsx } from 'clsx'
import { Combobox as HeadlessUICombobox } from '@headlessui/react'
import { useQuery } from '@tanstack/react-query'

import {
  GeocodedPlace,
  geocodePlace,
  getAutocompleteSuggestions,
} from 'utils/googleMaps'
import { ReactSelectValue } from 'types/internal'

import FormGroup, { Props as FormGroupProps } from './FormGroup'

type CachedPredication = ReactSelectValue<string | null> | null

export type Props = Pick<FormGroupProps, 'error' | 'label' | 'labelFor'> & {
  name: string
  onChange(newValue: string): void
  onSelectSuggestion(newValue: GeocodedPlace | null): void
  value: string
}

const AddressAutocomplete = ({
  error,
  label,
  labelFor,
  name,
  onChange,
  onSelectSuggestion,
  value,
}: Props) => {
  const [selectedPrediction, setSelectedPrediction] =
    useState<CachedPredication>(null)

  const trimmedQuery = value.trim()
  const { data } = useQuery({
    enabled: !!trimmedQuery,
    queryFn: () => {
      if (!trimmedQuery) {
        return
      }

      return getAutocompleteSuggestions({ query: trimmedQuery })
    },
    queryKey: [trimmedQuery],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    // We don't need to refetch predictions in the background. It's ok to wait
    // for a page refresh since the predictions are unlikely to change
    // much while a user is on the shipping page.
    staleTime: Infinity,
  })

  const predictions =
    data?.predictions.map((prediction) => {
      return {
        label: prediction.description,
        value: prediction.place_id,
      }
    }) ?? []

  const hasError = !!error

  useEffect(() => {
    setSelectedPrediction({ label: value, value: null })
  }, [value])

  return (
    <FormGroup error={error} label={label} labelFor={labelFor}>
      <HeadlessUICombobox
        onChange={async (newValue) => {
          if (!newValue?.value) {
            onSelectSuggestion(null)
            return
          }

          const geocodedPlace = await geocodePlace({ placeID: newValue.value })

          onSelectSuggestion(geocodedPlace)
        }}
        value={selectedPrediction}
      >
        <div className="relative text-k/14_120">
          <div className="relative">
            <HeadlessUICombobox.Input<CachedPredication>
              className={clsx(
                'h-14 w-full items-center rounded-lg border bg-grey-0 pl-4 pr-12 text-left',
                {
                  'border-red': hasError,
                  'border-grey-4': !hasError,
                }
              )}
              displayValue={(selectedPrediction) => {
                return selectedPrediction ? selectedPrediction.label : value
              }}
              id={labelFor}
              name={name}
              onChange={(event) => {
                onChange(event.target.value)
              }}
            />
          </div>

          {predictions.length > 0 && (
            <HeadlessUICombobox.Options className="absolute z-10 mt-1 max-h-80 w-full overflow-auto rounded-md border border-grey-4 bg-grey-0 text-k/14_120 shadow-lg focus:outline-none">
              {predictions.map((prediction, i) => (
                <HeadlessUICombobox.Option
                  key={i}
                  className="border-b border-grey-4 p-4 ui-selected:font-semibold ui-active:bg-grey-2"
                  value={prediction}
                >
                  {prediction.label}
                </HeadlessUICombobox.Option>
              ))}

              <div className="flex items-center justify-end space-x-2 p-3">
                <span>Powered by</span>
                <span
                  className="text-base leading-4"
                  style={{
                    color: '#5F6368',
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    letterSpacing: '0.0575em',
                  }}
                >
                  Google
                </span>
              </div>
            </HeadlessUICombobox.Options>
          )}
        </div>
      </HeadlessUICombobox>
    </FormGroup>
  )
}

export default AddressAutocomplete
