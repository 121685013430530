import { clsx } from 'clsx'
import { Controller, useFormContext } from 'react-hook-form'

const Chip = ({
  afterChange,
  checked,
  label,
  name,
  type = 'radio',
  value,
}: {
  afterChange(): void
  checked?: boolean
  label: string
  name: string
  type?: 'checkbox' | 'radio'
  value: string
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      key={value}
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, name: inputName, ref, value: inputValue },
      }) => {
        return (
          <label
            className={clsx(
              'flex min-w-[80px] cursor-pointer items-center justify-center whitespace-nowrap rounded-full border border-grey-7 px-3 py-2 text-k/12_120 transition-colors',
              !checked && 'hover:border-black hover:bg-grey-3',
              checked && 'border-orange-1 bg-white'
            )}
          >
            <input
              ref={ref}
              checked={checked}
              className="absolute h-0 w-0 cursor-pointer opacity-0"
              name={inputName}
              onBlur={onBlur}
              onChange={(event) => {
                if (type === 'radio') {
                  onChange(event.target.value)
                } else {
                  if (!checked) {
                    const updatedChips = [...inputValue, event.target.value]
                    onChange(updatedChips)
                  } else {
                    const updatedChips = [...inputValue].filter(
                      (value) => value !== event.target.value
                    )

                    onChange(updatedChips)
                  }
                }

                afterChange()
              }}
              type={type}
              value={value}
            />
            {label}
          </label>
        )
      }}
    />
  )
}

export default Chip
