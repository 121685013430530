import { motion, AnimationDefinition } from 'framer-motion'
import { useMachine } from '@xstate/react'
import { useRef } from 'react'

import { createMealTagMachine } from './machines/mealTag'

import MealTag from './MealTag'

const MealTagExpanding = (props: ExpandingTagProps) => {
  return (
    <ExpandingTag
      {...props}
      // Any time we receive a new set of titles, we want to recreate this expanding
      // tag so any widths for animations can be recalculated.
      key={`${props.titleCollapsed}-${props.titleExpanded}`}
    />
  )
}

export default MealTagExpanding

interface ExpandingTagProps {
  onTagExpanded?: () => void
  titleCollapsed: string
  titleExpanded: string
}

const ExpandingTag = (props: ExpandingTagProps) => {
  const tagRef = useRef<HTMLDivElement | null>(null)

  const [state, send] = useMachine(() =>
    createMealTagMachine({
      titleCollapsed: props.titleCollapsed,
      titleExpanded: props.titleExpanded,
    })
  )
  const { titleCollapsed, titleExpanded, widths } = state.context

  const tagText =
    state.matches('expanding') || state.matches('expanded')
      ? titleExpanded
      : titleCollapsed

  return (
    <motion.button
      key={titleCollapsed}
      // An empty string means we're going to animate to whatever the natural width
      // of the tag content is.
      animate={{ width: widths[tagText] ?? '' }}
      className="overflow-hidden"
      onAnimationComplete={(defintion) => {
        send({
          type: 'ANIMATION_COMPLETE',
          // We only send a calculated width when we are animating with a width of an
          // empty string - which indicates we're figuring out when the rendered width
          // of the tag is. Otherwise, this callback can be invoked multiple times and
          // the tagRef's width may not be in sync with whether we're expanding or collapsing.
          width:
            hasWidthAnimation(defintion) && defintion.width === ''
              ? tagRef.current?.offsetWidth ?? ''
              : '',
        })

        props.onTagExpanded?.()
      }}
      onClick={() => {
        send({ type: 'TOGGLE' })
      }}
    >
      <MealTag ref={tagRef}>{tagText}</MealTag>
    </motion.button>
  )
}

// A type guard to ensure the provided AnimationDefinition contains our width
// property that we animate. framer-motion types aren't generic so we need
// this guard.
function hasWidthAnimation(
  definition: AnimationDefinition
): definition is Record<'width', number | ''> {
  return (definition as Record<'width', number | ''>).width !== undefined
}
