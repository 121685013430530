import { cloneDeep, some } from 'lodash-es'
import { assign, createMachine } from 'xstate'

interface FormField {
  error: string
  value: string
}

export interface GiftCardContext {
  amount: { label: string; value: number }
  customAmount: { error: string; value: number | '' }
  info: {
    giftMessage: FormField
    recipientEmail: FormField
  }
}

export type GiftCardEvents =
  | {
      amount: { label: string; value: number }
      type: 'AMOUNT_CHOSEN'
    }
  | { type: 'BACK' }
  | { type: 'CONTINUE' }
  | { amount: number | ''; type: 'CUSTOM_AMOUNT_CHANGED' }
  | {
      fieldName: keyof GiftCardContext['info']
      type: 'FIELD_ENTERED'
      value: string
    }
  | { type: 'GIFT_CARD_PURCHASED' }

export const AMOUNT_OPTIONS = [
  { label: '$75', value: 75 },
  { label: '$100', value: 100 },
  { label: '$125', value: 125 },
  { label: '$150', value: 150 },
  { label: '$200', value: 200 },
  { label: '$250', value: 250 },
  { label: '$350', value: 350 },
  { label: '$500', value: 500 },
  { label: 'Custom Amount', value: 0 },
]
const DEFAULT_AMOUNT = AMOUNT_OPTIONS[1]

const INITIAL_CONTEXT = {
  amount: DEFAULT_AMOUNT,
  customAmount: { error: '', value: '' },
  info: {
    giftMessage: { error: '', value: 'Enjoy your gift!' },
    recipientEmail: { error: '', value: '' },
  },
} satisfies GiftCardContext

export const giftCardMachine = createMachine(
  {
    id: 'giftCard',
    tsTypes: {} as import('./giftCardMachine.typegen').Typegen0,
    schema: {
      context: {} as GiftCardContext,
      events: {} as GiftCardEvents,
    },
    context: INITIAL_CONTEXT,
    predictableActionArguments: true,

    initial: 'choosingAmount',
    states: {
      choosingAmount: {
        on: {
          AMOUNT_CHOSEN: { actions: ['storeAmount'] },
          CONTINUE: [
            {
              cond: 'hasCustomAmountError',
              actions: ['storeCustomAmountError'],
            },
            { target: 'enteringInfo' },
          ],
          CUSTOM_AMOUNT_CHANGED: { actions: ['storeCustomAmount'] },
        },
      },
      enteringInfo: {
        on: {
          BACK: { actions: ['clearInfo'], target: 'choosingAmount' },
          CONTINUE: [
            {
              cond: 'hasInfoErrors',
              actions: ['storeInfoErrors'],
            },
            { target: 'enteringPayment' },
          ],
          FIELD_ENTERED: { actions: ['storeInfo'] },
        },
      },
      enteringPayment: {
        on: {
          BACK: { target: 'enteringInfo' },
          GIFT_CARD_PURCHASED: { target: 'purchased' },
        },
      },
      purchased: { type: 'final' },
    },
  },
  {
    actions: {
      clearInfo: assign((ctx) => {
        return { ...ctx, info: INITIAL_CONTEXT.info }
      }),
      storeAmount: assign({
        amount: (_ctx, event) => event.amount,
      }),
      storeCustomAmount: assign({
        customAmount: (_ctx, event) => {
          return { error: '', value: event.amount }
        },
      }),
      storeCustomAmountError: assign({
        customAmount: (ctx) => {
          return {
            ...ctx.customAmount,
            error: getCustomAmountError({
              amount: ctx.amount.value,
              customAmount: ctx.customAmount.value,
            }),
          }
        },
      }),
      storeInfo: assign((ctx, event) => {
        return {
          ...ctx,
          info: {
            ...ctx.info,
            [event.fieldName]: { error: '', value: event.value },
          },
        }
      }),
      storeInfoErrors: assign((ctx) => {
        const newCtx = cloneDeep(ctx)
        const errors = getInfoErrors(ctx.info)

        newCtx.info.giftMessage.error = errors.giftMessage
        newCtx.info.recipientEmail.error = errors.recipientEmail

        return newCtx
      }),
    },
    guards: {
      hasCustomAmountError: (ctx) => {
        return !!getCustomAmountError({
          amount: ctx.amount.value,
          customAmount: ctx.customAmount.value,
        })
      },
      hasInfoErrors: (ctx) => {
        return some(getInfoErrors(ctx.info), (error) => !!error)
      },
    },
  }
)

function getCustomAmountError({
  amount,
  customAmount,
}: {
  amount: number
  customAmount: number | ''
}) {
  if (amount > 0) {
    return ''
  }

  if (customAmount === '') {
    return 'Please enter a custom amount.'
  } else if (!Number.isInteger(customAmount)) {
    return 'Please enter a whole dollar amount without cents.'
  } else if (customAmount <= 0 || customAmount >= 1001) {
    return 'Please enter a custom amount between $1 and $1,000.'
  }

  return ''
}

function getInfoErrors(formData: GiftCardContext['info']) {
  return {
    giftMessage:
      formData.giftMessage.value === '' ? 'Please enter a gift message.' : '',
    recipientEmail:
      formData.recipientEmail.value === '' ? 'Please enter an email.' : '',
  }
}
