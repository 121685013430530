import { Button, XIcon } from '@tovala/component-library'
import { useState } from 'react'

import { events } from 'analytics/events'
import { TermToRate } from 'types/internal'
import { track } from 'utils/analytics'

import RateMealsCarousel from './RateMealsCarousel'

const RateMealsPrompt = ({
  mostRecentTermMealsToRate,
  onClickClose,
}: {
  mostRecentTermMealsToRate: TermToRate | undefined
  onClickClose?: () => void
}) => {
  const [showRateMealsCarousel, setShowRateMealsCarousel] = useState(false)

  if (
    !mostRecentTermMealsToRate ||
    !mostRecentTermMealsToRate.meals ||
    mostRecentTermMealsToRate.meals.length === 0
  ) {
    return null
  }

  return (
    <>
      <div className="relative w-full rounded-xl bg-black p-4 text-white">
        {onClickClose && (
          <div className="flex justify-end">
            <button
              aria-label="Close"
              className="h-6 w-6 cursor-pointer text-white"
              onClick={onClickClose}
            >
              <XIcon />
            </button>
          </div>
        )}
        <div className="flex flex-wrap justify-between">
          <div>
            <h2 className="mb-4 text-h/34_100 font-semibold">
              Help us improve our food
            </h2>
            <p className="mb-6 text-k/16_125">
              Rate meals to get more of what you love (and less of what you
              don't) on our menu
            </p>
          </div>

          <Button
            buttonStyle="white"
            onClick={() => {
              setShowRateMealsCarousel(true)
              track(events.REVIEW_PROMPT_MODAL_APPEARS, {
                meal_id: mostRecentTermMealsToRate.meals[0].id,
              })
            }}
            size="medium"
          >
            Rate a Meal
          </Button>
        </div>
      </div>

      {showRateMealsCarousel && (
        <RateMealsCarousel
          meals={mostRecentTermMealsToRate.meals}
          onClose={() => setShowRateMealsCarousel(false)}
        />
      )}
    </>
  )
}

export default RateMealsPrompt
