import { flatMap, isArray, orderBy } from 'lodash-es'
import {
  Listing,
  Meal,
  MealSuggestions,
  MealSummary,
  MealSwap,
  OrderHistoryReceiptMeal,
} from '@tovala/browser-apis-combinedapi'

import { DATE_FORMATS, formatDate, isValidDate, parseToDate } from './dates'
import { formatCentsToDollars } from './currency'
import {
  MealWithSwaps,
  PendingMealSelection,
  PendingMealSelectionsPricing,
  UserTerm,
} from 'types/internal'
import { storageAvailable } from './storageAvailable'

export const TAG_IDS = {
  BLACK_SHEET_TRAY: 58,
  OIL_WARNING: 62,
  PREMIUM: 64,
  PROTEIN_CHOICE: 94,
  TWO_SERVINGS: 66,
}

export function getDisplayTags({
  meal,
  suggestions = [],
}: {
  meal: Meal | MealSummary
  suggestions?: MealSummary[]
}) {
  const displayTags = orderBy(meal.tags, 'priority')
    .filter((tag) => {
      return (
        tag.id === TAG_IDS.PREMIUM ||
        tag.displayMode === 'banner_text' ||
        tag.displayMode === 'menu_category' ||
        tag.displayMode === 'menu_subtitle'
      )
    })
    .map(({ collapsedTitle, displayMode, title }) => {
      if (
        (displayMode === 'menu_category' || displayMode === 'menu_subtitle') &&
        collapsedTitle
      ) {
        return { titleCollapsed: collapsedTitle, titleExpanded: title }
      }

      return title
    })

  if (suggestions.some((suggestion) => suggestion.id === meal.id)) {
    displayTags.push('Enjoy it again')
  }

  return displayTags
}

export function getFormattedExpirationDate(expirationDate: string) {
  const displayFormat = DATE_FORMATS.DOW_MONTH_FULL_DAY_SUFFIX
  const parseFormat = DATE_FORMATS.MEAL_EXPIRATION_DATE

  // Something seems funky with our expiration date handling where a not-set expiration
  // date doesn't fit in the format of a set expiration date. So we try to display our
  // meal expiration date using the expected parse format but if that isn't valid, we'll
  // parse the date as an ISO date.
  if (isValidDate(parseToDate(expirationDate, { parseFormat }))) {
    return formatDate(expirationDate, {
      format: displayFormat,
      parseFormat,
    })
  }

  return formatDate(expirationDate, { format: displayFormat })
}

export function getMaxSelections({
  userTerm,
}: {
  userTerm: UserTerm | undefined
}) {
  if (userTerm?.nextLargestSubscriptionType) {
    return userTerm.nextLargestSubscriptionType.maxSelections
  }

  // This means there's no next largest subscription so the user's current
  // subscription is the largest subscription they can be on.
  return userTerm?.subscriptionType?.maxSelections
}

export function getListingBarcode({
  shortProductID,
}: {
  shortProductID: string
}) {
  return `133A254|${shortProductID}||A`
}

export function getMealBarcode({
  mealID,
  barcodeIdentifier,
}: {
  mealID: number
  barcodeIdentifier: string
}) {
  // Note: The 133A254 string and the trailing pipe are needed for the back-end.
  // See comments on https://tovala.atlassian.net/browse/WAT-395 for more context.
  // https://tovala.atlassian.net/wiki/spaces/SOF/pages/301563905/Barcode+Schema for
  // Confluence documentation.
  // See https://tovala.atlassian.net/browse/WAT-690 for addition of barcodeIdentifier.
  return `133A254|${mealID}||${barcodeIdentifier}`
}

export function getMealSurcharge({ meal }: { meal: Meal | MealSummary }) {
  const isTwoServings = meal.tags.find((tag) => tag.id === TAG_IDS.TWO_SERVINGS)

  if (isTwoServings) {
    const totalCost = meal.basePriceCents + meal.surchargeCents

    // Use Math.floor to truncate $12.99 dual serving meals to $6.99/serving rather than rounding
    const servingCost = isTwoServings ? Math.floor(totalCost / 2) / 100 : ''

    return `2 servings $${servingCost} each`
  } else if (meal.surchargeCents > 0) {
    return `+${formatCentsToDollars(meal.surchargeCents)}`
  }

  return null
}

export const getMealImageURL = (
  meal: Listing | Meal | MealSummary | OrderHistoryReceiptMeal
) => {
  if ('imageURL' in meal) {
    return meal.imageURL
  }

  const image = meal.images.find((image) => image.key === 'cell_tile')

  return image?.url ?? ''
}

export function getMealSelectionIDs({
  pendingMealSelections,
  selectedUserTerm,
}: {
  pendingMealSelections?: PendingMealSelection[]
  selectedUserTerm: UserTerm | undefined
}) {
  const mealSelectionIDs =
    selectedUserTerm?.mealSelections.map((selection) => selection.mealID) ?? []
  const pendingMealSelectionIDs =
    pendingMealSelections?.map((meal) => meal.id) ?? []

  return [...mealSelectionIDs, ...pendingMealSelectionIDs]
}

// Get up to two meal suggestions (positively rated)
export function getMealSuggestionsForMenu({
  mealSuggestions,
  mealSummaries,
}: {
  mealSuggestions: MealSuggestions | undefined
  mealSummaries: MealSummary[]
}) {
  if (mealSuggestions?.suggest) {
    const mealSuggestionsForMenu = mealSummaries.filter(
      (meal) => mealSuggestions.suggest.includes(meal.id) && !meal.isSoldOut
    )

    if (mealSuggestionsForMenu.length) {
      return mealSuggestionsForMenu.slice(0, 2)
    }
  }
}

export function getMealSwaps(meals: MealSummary[], mealSwaps: MealSwap[]) {
  const mealIDs = meals.map((meal) => meal.id)
  // Some meals may not being included in this meals array if they've already been used in a menu component.
  // We want to remove any mealSwaps where the primary meal is not included in the meals array so
  // any secondary meals that no longer have a primary meal to be displayed with are included as individual meals
  const filteredMealSwaps = mealSwaps.filter((mealSwap) =>
    mealIDs.includes(mealSwap.mealID)
  )
  const secondaryMealSwapIDs = flatMap(
    filteredMealSwaps.map((swap) => swap.swapIDs)
  )

  const mealsWithSwaps = meals
    // Remove secondary swap meals which will be displayed with the primary meal using the swaps array that is added below
    .filter((meal) => !secondaryMealSwapIDs.includes(meal.id))
    .map((m) => {
      const mealSwap = mealSwaps.find((mealSwap) => mealSwap.mealID === m.id)

      // Get the meal data for the swaps while maintaining the order from swapIDs
      const swaps = mealSwap?.swapIDs
        .map((swap) => meals.find((meal) => meal.id === swap))
        .filter((meal): meal is MealSummary => !!meal)

      const meal: MealWithSwaps = {
        ...m,
        swaps: swaps ?? [],
      }

      return meal
    })

  return mealsWithSwaps
}

export function getPendingMealSelectionPricing({
  pendingMealSelections,
}: {
  pendingMealSelections: PendingMealSelection[]
}) {
  const basePriceCents = pendingMealSelections
    .map((s) => s.basePriceCents)
    .reduce((total, current) => total + current, 0)
  const surchargeCents = pendingMealSelections
    .map((s) => s.surchargeCents)
    .reduce((total, current) => total + current, 0)

  const pricing: PendingMealSelectionsPricing = {
    basePriceCents,
    surchargeCents,
    totalAmountCents: basePriceCents + surchargeCents,
  }

  return pricing
}

export function getTagBST({ meal }: { meal: MealSummary }) {
  return meal.tags.find((tag) => tag.id === TAG_IDS.BLACK_SHEET_TRAY)
}

export function getTagOilWarning({ meal }: { meal: MealSummary }) {
  return meal.tags.find((tag) => tag.id === TAG_IDS.OIL_WARNING)
}

export function hasViewedOilWarning(meal: MealSummary) {
  const oilWarning = getTagOilWarning({ meal })

  if (!oilWarning || !storageAvailable('localStorage')) {
    return false
  }

  let mealWarnings = localStorage.getItem('mealWarnings')
  mealWarnings = mealWarnings ? JSON.parse(mealWarnings) : ''

  return isArray(mealWarnings) && mealWarnings.includes(oilWarning.id)
}
