import { Checkbox } from '@tovala/component-library'
import { ComponentProps } from 'react'
import {
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form'

export type Props<FormData extends FieldValues> = Omit<
  ComponentProps<typeof Checkbox>,
  'onBlur'
> & {
  name: Path<FormData>
  registerOptions?: RegisterOptions
  register: UseFormRegister<FormData>
}

function CheckboxRHF<FormData extends FieldValues>({
  name,
  onChange,
  register,
  registerOptions,
  ...rest
}: Props<FormData>) {
  const {
    onChange: onChangeRHF,
    onBlur,
    name: inputName,
    ref,
  } = register(name, registerOptions)

  return (
    <Checkbox
      {...rest}
      ref={ref}
      name={inputName}
      onBlur={onBlur}
      onChange={(...args) => {
        onChangeRHF(...args)

        if (onChange) {
          onChange(...args)
        }
      }}
    />
  )
}

export default CheckboxRHF
