import { getUserTerm } from 'utils/terms'

import { useSkippedWeeks } from './subscriptions'
import { useUser } from 'contexts/user'
import { useUserTermStatuses, UseUserTermStatusesOpts } from './termStatus'

export function useSelectedUserTerm({
  selectedUserTermID,
}: {
  selectedUserTermID: number | null | undefined
}) {
  const { user } = useUser()

  const {
    data: termStatuses = [],
    error: loadUserTermStatusesError,
    isError: hasLoadUserTermStatusesError,
    isLoading: isLoadingUserTermStatuses,
  } = useUserTermStatuses()

  const { data: skippedWeeks, isLoading: isLoadingSkippedWeeks } =
    useSkippedWeeks({
      userID: user.id,
    })

  const selectedTerm = termStatuses.find(
    (term) => term.termID === selectedUserTermID
  )

  return {
    error: hasLoadUserTermStatusesError ? loadUserTermStatusesError : null,
    isLoading: isLoadingUserTermStatuses || isLoadingSkippedWeeks,
    selectedUserTerm: selectedTerm
      ? getUserTerm({ skippedWeeks, term: selectedTerm, user })
      : undefined,
  }
}

export function useUserNextAvailableTermID(
  opts?: Omit<UseUserTermStatusesOpts<number>, 'select'>
) {
  return useUserTermStatuses({
    ...opts,
    select: (data) => {
      return data[0].termID
    },
  })
}
