import { Oven, OvenCookHistoryItem } from '@tovala/browser-apis-combinedapi'

import { get } from './baseAPI'

export interface GetOvenCookHistory {
  ovenID: string
  userID: number
}

export interface GetOvens {
  userID: number
}

export type GetOvensResponse = Oven[]

export const ENDPOINTS = {
  GET_OVEN_COOK_HISTORY: ({
    ovenID,
    userID,
  }: Pick<GetOvenCookHistory, 'ovenID' | 'userID'>) => ({
    path: `/users/${userID}/ovens/${ovenID}/cook/history`,
    version: 'v0' as const,
  }),
  GET_OVENS: ({ userID }: Pick<GetOvens, 'userID'>) => ({
    path: `/users/${userID}/ovens`,
    version: 'v0' as const,
  }),
}

export async function getOvenCookHistory({
  ovenID,
  userID,
}: GetOvenCookHistory): Promise<OvenCookHistoryItem[]> {
  return get(ENDPOINTS.GET_OVEN_COOK_HISTORY({ ovenID, userID }))
}

export async function getOvens({
  userID,
}: GetOvens): Promise<GetOvensResponse> {
  return get(ENDPOINTS.GET_OVENS({ userID }))
}
