import { ReactNode } from 'react'

const MyOrdersSection = ({
  children,
  heading,
  subHeading,
}: {
  children: ReactNode
  heading: string
  subHeading?: string
}) => {
  return (
    <div>
      <div className="mb-10 space-y-4 text-k/36_110 md:mb-6 md:space-y-2 md:text-k/20_110">
        <h2>{heading}</h2>

        {subHeading && (
          <p className="text-k/20_125 text-grey-10 md:text-k/18_120 md:text-grey-8">
            {subHeading}
          </p>
        )}
      </div>

      {children}
    </div>
  )
}

export default MyOrdersSection
