import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  ButtonRound,
  CustomizeIcon,
} from '@tovala/component-library'
import Autoplay from 'embla-carousel-autoplay'
import { clsx } from 'clsx'
import { ReactNode, useEffect } from 'react'
import useEmblaCarousel from 'embla-carousel-react'

import { AnalyticsEvent, events } from 'analytics/events'
import { MealTagCollapsible } from 'types/internal'

import Collapsible, {
  CollapsibleContent,
  CollapsibleTrigger,
} from 'components/common/Collapsible'
import CustomizeMealTitle from './MealWithOptionsTitle'
import MealImage from './MealImage'
import MealImageSoldOut from './MealImageSoldOut'
import MealImageTag from './MealImageTag'
import MealTagsScrolling from './MealTagsScrolling'

type MealOption = {
  id: number
  image: { url: string }
  isSoldOut: boolean
  optionTitle: string
  quantity: number
  stepper?: ReactNode
  subtitle?: string
  surcharge?: string | null
  tags: MealTagCollapsible[]
  title: string
}

const MealCarousel = ({
  buttonTitle,
  mealOptions,
  onClickMeal,
  onEventForMeal,
}: {
  buttonTitle: string
  mealOptions: MealOption[]
  onClickMeal(mealID: number): void
  onEventForMeal(opts: { event: AnalyticsEvent; mealInView: MealOption }): void
}) => {
  const allMealsSoldOut = mealOptions.every(
    (mealOption) => mealOption.isSoldOut
  )

  // If any of the options have been chosen, we want the options to be displayed so
  // the user can see their quantity selections.
  const defaultOpen = !!mealOptions.find((option) => option.quantity > 0)

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: 'start',
      loop: true,
    },
    [
      Autoplay({
        // UX team approved arbitrary delay value.
        delay: 3700,
        // rootNode is used to determine the element for stopOnMouseEnter
        rootNode: (emblaRoot) => emblaRoot.parentElement,
        stopOnMouseEnter: true,
      }),
    ]
  )

  const scrollNext = () => {
    trackClickEventForCurrentMeal(events.DID_TAP_CUSTOMIZE_IT_ARROW_RIGHT)

    emblaApi?.scrollNext()
  }

  const scrollPrev = () => {
    trackClickEventForCurrentMeal(events.DID_TAP_CUSTOMIZE_IT_ARROW_LEFT)

    emblaApi?.scrollPrev()
  }

  const trackClickEventForCurrentMeal = (event: AnalyticsEvent) => {
    const slideIndex = emblaApi?.selectedScrollSnap()
    const mealInView =
      slideIndex !== undefined && slideIndex >= 0
        ? mealOptions[slideIndex]
        : null

    if (mealInView) {
      onEventForMeal({ event, mealInView })
    }
  }

  // Reinitialize the carousel if the options change, for example when a filter
  // removes a meal option, to prevent empty slides.
  useEffect(() => {
    if (emblaApi) {
      emblaApi.reInit()
    }
  }, [emblaApi, mealOptions])

  return (
    <div>
      <Collapsible defaultOpen={defaultOpen}>
        {({ open }) => {
          return (
            <>
              <div>
                <div className="relative">
                  <div
                    ref={!open ? emblaRef : null}
                    className="w-full overflow-hidden"
                  >
                    <div className="flex">
                      {mealOptions.map((meal) => (
                        // Note: right-margin is intentionally used here so when images are sliding,
                        // there is space between subsequent images. Using a space-x-8 on the parent
                        // does not have this effect.
                        <div key={meal.id} className="mr-8 w-full shrink-0">
                          {open ? (
                            <div className="space-y-4">
                              <MealCarouselImage
                                allMealsSoldOut={allMealsSoldOut}
                                meal={meal}
                                reducedHeight
                              />
                              <div className="md:px-4">
                                <CustomizeMealTitle
                                  subtitle={meal.subtitle}
                                  title={meal.title}
                                />
                              </div>
                            </div>
                          ) : (
                            <CollapsibleTrigger className="w-full space-y-4 text-left">
                              <MealCarouselImage
                                allMealsSoldOut={allMealsSoldOut}
                                meal={meal}
                              />
                              <div className="md:px-4">
                                <CustomizeMealTitle
                                  subtitle={meal.subtitle}
                                  title={meal.title}
                                />
                              </div>
                            </CollapsibleTrigger>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="absolute left-4 top-4">
                    <MealImageTag
                      icon={
                        <div className="h-4 w-4 text-orange-1">
                          <CustomizeIcon />
                        </div>
                      }
                    >
                      Customize
                    </MealImageTag>
                  </div>

                  {!open && (
                    // Need to use arbitrary positioning here because we want the images
                    // behind this content to slide, not this content itself.
                    <div className="absolute left-4 right-4 top-[236px] flex items-center justify-between md:top-[350px]">
                      <div className="flex space-x-2">
                        <ButtonRound
                          buttonSize="medium"
                          buttonStyle="white"
                          icon={<ArrowLeftIcon />}
                          label="Previous"
                          onClick={scrollPrev}
                        />

                        <ButtonRound
                          buttonSize="medium"
                          buttonStyle="white"
                          icon={<ArrowRightIcon />}
                          label="Next"
                          onClick={scrollNext}
                        />
                      </div>
                      <CollapsibleTrigger
                        as="div"
                        className="h-12 w-36 text-k/14_120"
                      >
                        <Button
                          buttonStyle="orange"
                          onClick={() => {
                            trackClickEventForCurrentMeal(
                              events.DID_EXPAND_CUSTOMIZE_IT
                            )
                          }}
                          size="fluid"
                        >
                          {buttonTitle}
                        </Button>
                      </CollapsibleTrigger>
                    </div>
                  )}
                </div>
              </div>

              <div className="md:px-4">
                <CollapsibleContent open={open}>
                  {() => {
                    return (
                      <div className="mt-8 space-y-4 border-t border-grey-4">
                        <p className="mt-4 text-k/18_120">Pick your protein:</p>

                        <div className="space-y-4">
                          {mealOptions.map((meal) => {
                            return (
                              <MealCarouselOption
                                key={meal.id}
                                meal={meal}
                                onClickMeal={onClickMeal}
                              />
                            )
                          })}
                        </div>
                      </div>
                    )
                  }}
                </CollapsibleContent>
              </div>
            </>
          )
        }}
      </Collapsible>
    </div>
  )
}

export default MealCarousel

const MealCarouselImage = ({
  allMealsSoldOut,
  meal,
  reducedHeight,
}: {
  allMealsSoldOut: boolean
  meal: MealOption
  reducedHeight?: boolean
}) => {
  return (
    <div
      className={clsx(
        'relative h-menu-meal-image w-full overflow-hidden rounded-lg object-cover transition-all md:h-[414px] md:rounded-none',
        {
          'max-h-56': reducedHeight,
        }
      )}
    >
      {/**
       * Per design, only show the sold out message on the carousel/larger image
       * if all meals are sold out, otherwise, it's just shown on the individual meal options.
       */}
      <MealImage
        cover={allMealsSoldOut ? <MealImageSoldOut textSize="large" /> : null}
        image={meal.image}
      />
    </div>
  )
}

const MealCarouselOption = ({
  meal,
  onClickMeal,
}: {
  meal: MealOption
  onClickMeal(mealID: number): void
}) => {
  return (
    <div className="flex w-full overflow-hidden">
      <div className="relative shrink-0">
        {/*
         * The right padding (and right absolute positioning for the stepper) allows
         * the user to click the whitespace between the image and meal details.
         */}
        <div
          className="cursor-pointer pr-4"
          onClick={() => onClickMeal(meal.id)}
        >
          <div className="relative h-[150px] w-[150px] overflow-hidden rounded-lg">
            <MealImage
              cover={
                meal.isSoldOut ? <MealImageSoldOut textSize="small" /> : null
              }
              image={meal.image}
            />
          </div>
        </div>

        {meal.stepper && (
          <div className="absolute bottom-2 right-6">{meal.stepper}</div>
        )}
      </div>

      {/*
       * We use a single pixel width and then flex-grow to respect the parent's container size.
       * Without this, the scrolling tags will not scroll properly.
       */}
      <div className="flex w-px grow flex-col space-y-1">
        {/* We want any empty space to be clickable so that's the usage of flex here. */}
        <div
          className={clsx(
            'flex flex-grow flex-col justify-end space-y-1 text-k/13_120 md:text-k/12_120',
            {
              'cursor-pointer': !meal.isSoldOut,
            }
          )}
          onClick={() => onClickMeal(meal.id)}
        >
          {meal.surcharge && <p className="text-orange-1">{meal.surcharge}</p>}
          <p className="text-grey-10">{meal.optionTitle}</p>
        </div>

        <MealTagsScrolling tags={meal.tags} />
      </div>
    </div>
  )
}
