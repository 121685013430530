import DetailsDialog, { ListingDetails } from './DetailsDialog'
import { ListingFull } from 'types/internal'

const ListingDetailsDialog = ({
  onClose,
  listing,
}: {
  onClose(): void
  listing: ListingFull
}) => {
  return (
    <DetailsDialog onClose={onClose}>
      <ListingDetails listing={listing} />
    </DetailsDialog>
  )
}

export default ListingDetailsDialog
