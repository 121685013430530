import { CheckIcon } from '@tovala/component-library'
import { ReactNode } from 'react'

const SuccessDisplay = ({ message }: { message: ReactNode }): JSX.Element => {
  return (
    <div className="flex items-start space-x-1 rounded-xl bg-green-faded px-4 py-3 text-k/12_120 text-green">
      <div className="h-4 w-4 shrink-0 ">
        <CheckIcon />
      </div>
      <div>{message}</div>
    </div>
  )
}

export default SuccessDisplay
