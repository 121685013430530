import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ButtonRound,
  XIcon,
} from '@tovala/component-library'
import { Dialog } from '@headlessui/react'
import { OrderHistoryReceiptMeal } from '@tovala/browser-apis-combinedapi'
import { useCallback, useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'

import { events } from 'analytics/events'
import { track } from 'utils/analytics'

import { useToast } from 'contexts/toast'
import MealRatingCard from './MealRatingCard'

const RateMealsCarousel = ({
  firstMealID,
  meals,
  onClose,
}: {
  firstMealID?: number | undefined
  meals: OrderHistoryReceiptMeal[]
  onClose: () => void
}) => {
  const { openToast } = useToast()

  const [carouselMeals, setCarouselMeals] = useState([...meals])

  let startIndex = 0
  if (firstMealID) {
    startIndex = carouselMeals.map((meal) => meal.id).indexOf(firstMealID)
  }

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    startIndex,
  })

  const scrollPrev = useCallback(() => {
    emblaApi && emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    emblaApi && emblaApi.scrollNext()
  }, [emblaApi])

  useEffect(() => {
    // Remove extra slides from carousel as meals are reviewed
    if (carouselMeals.length < meals.length) {
      emblaApi?.reInit({
        startIndex: emblaApi?.selectedScrollSnap() || 0,
      })
    }
  }, [carouselMeals, emblaApi, meals])

  const showAnotherMeal = (reviewedMealID: number) => {
    if (carouselMeals.length > 1) {
      scrollNext()

      const updatedMeals = carouselMeals.filter(
        (meal) => meal.id !== reviewedMealID
      )
      setCarouselMeals(updatedMeals)
      track(events.TAPS_REVIEW_ANOTHER_MEAL)
    } else {
      handleOnClose()
    }
  }

  const handleOnClose = () => {
    onClose()

    if (meals.length > carouselMeals.length) {
      openToast({
        heading: 'Ratings Saved',
        message: 'Thanks for your feedback!',
        type: 'success',
      })
    }
  }

  return (
    <Dialog onClose={handleOnClose} open={true}>
      <div
        className="fixed top-0 z-30 h-full w-full bg-black opacity-40"
        onClick={handleOnClose}
      />
      <div className="fixed top-0 z-30 flex max-h-full w-full justify-center overflow-y-auto ">
        <Dialog.Panel>
          <div>
            <div className="my-5 flex justify-center">
              <ButtonRound
                buttonSize="medium"
                buttonStyle="white"
                icon={<XIcon />}
                label="Close"
                onClick={handleOnClose}
              />
            </div>

            <div className="embla relative flex max-w-[100vw] items-center gap-2 px-2">
              {carouselMeals.length > 1 && (
                <div className="shrink-0">
                  <ButtonRound
                    buttonSize="small"
                    buttonStyle="white"
                    icon={<ArrowLeftIcon />}
                    iconSize="small"
                    label="Previous"
                    onClick={scrollPrev}
                  />
                </div>
              )}

              <div
                ref={emblaRef}
                className="embla__viewport w-full overflow-hidden"
              >
                <div className="embla__container flex w-full max-w-sm items-start md:w-auto md:max-w-none md:flex-1">
                  {carouselMeals.map((meal) => {
                    return (
                      <div
                        key={meal.id}
                        className="embla__slide ml-3 min-w-full rounded-lg bg-grey-0"
                      >
                        <MealRatingCard
                          meal={meal}
                          onMealReviewCreated={() => {
                            showAnotherMeal(meal.id)
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
              {carouselMeals.length > 1 && (
                <div className="shrink-0">
                  <ButtonRound
                    buttonSize="small"
                    buttonStyle="white"
                    icon={<ArrowRightIcon />}
                    iconSize="small"
                    label="Next"
                    onClick={scrollNext}
                  />
                </div>
              )}
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default RateMealsCarousel
