import { Button, ButtonLoading } from '@tovala/component-library'
import { useNavigate } from 'react-router-dom'
import {
  UserV1,
  useCreateZendeskRequest,
  useDeactivateUser,
} from '@tovala/browser-apis-combinedapi'

import { ErrorCodeMessageMapCombinedAPI } from 'types/internal'
import { events } from 'analytics/events'
import { getEnvVar } from 'utils/env'
import { track } from 'utils/analytics'

import APIErrorDisplay from 'components/common/APIErrorDisplay'
import ConfirmationDialog, {
  ConfirmationBody,
  ConfirmationButtons,
  ConfirmationHeader,
} from 'components/common/ConfirmationDialog'

const DELETE_ACCOUNT_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't delete your account due to a technical issue on our end.",
  },
}
const ENABLE_REQUEST_TEST_MESSAGE = getEnvVar('APP_ENV') !== 'production'

const DeleteAccountModal = ({
  onClickClose,
  user,
}: {
  onClickClose(): void
  user: UserV1
}) => {
  const navigate = useNavigate()

  const {
    error: deactivateUserError,
    isError: hasDeactivateUserError,
    isLoading: isDeactivatingAccount,
    mutate: deactivateUser,
  } = useDeactivateUser({
    onSuccess: () => {
      navigate('/logout')
    },
  })

  const {
    error: createZendeskRequestError,
    isError: hasCreateZendeskRequestError,
    mutate: createZendeskRequest,
  } = useCreateZendeskRequest({
    onSuccess: () => {
      deactivateUser({
        userID: user.id,
      })
    },
  })

  const handleDeleteAccountRequest = () => {
    const request = getZendeskRequestPayload(user)

    createZendeskRequest(request)
  }

  return (
    <ConfirmationDialog onRequestClose={onClickClose}>
      <ConfirmationHeader
        heading={
          <span className="block whitespace-pre-wrap text-left">
            {'Are you sure you want to\ndelete your account?'}
          </span>
        }
        onClickClose={onClickClose}
      />
      <ConfirmationBody>
        <div className="space-y-4 pb-20 pt-3 text-left text-body-sm text-grey-9 md:py-12">
          {(hasDeactivateUserError || hasCreateZendeskRequestError) && (
            <APIErrorDisplay
              error={createZendeskRequestError || deactivateUserError}
              errorCodeMessageMap={DELETE_ACCOUNT_ERRORS}
            />
          )}
          <p>
            Deleting your account also deletes all non-transaction and certain
            transactional user data associated with it. Some transactional data,
            such as oven and meal purchase records, must be kept in order to
            authenticate transaction and maintain compliance with applicable law
            and other legal obligation such as a payment processing obligations.
            Delete all user data may take several weeks.
          </p>
          <p>
            By deleting your account, you will no longer be able to use the app
            to control your oven.
          </p>
          <p>
            If you purchased your oven with a promotion requiring you to order 6
            weeks of meals and have not yet fulfilled this commitment, you will
            be charged to void this commitment over the next several weeks. See
            Purchase terms on my.tovala.com/legal/purchase-terms for more
            details.
          </p>
        </div>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="flex justify-between">
          <Button buttonStyle="stroke" onClick={onClickClose} size="large">
            Cancel
          </Button>

          <ButtonLoading
            isLoading={isDeactivatingAccount}
            onClick={() => {
              track(events.TAPS_DELETE_ACCOUNT_CONFIRM)
              handleDeleteAccountRequest()
            }}
            size="large"
          >
            Delete Account
          </ButtonLoading>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}

export default DeleteAccountModal

function getZendeskRequestPayload(user: UserV1) {
  const requester = {
    email: user.info.email,
    // Zendesk requires at least one character for the name
    name:
      user.info.name || user.shippingAddresses[0]?.userName || user.info.email,
  }

  // Sending a test message on any environments other than production so no dev account info
  // gets mistaken for a production account
  if (ENABLE_REQUEST_TEST_MESSAGE) {
    return {
      request: {
        comment: {
          body: 'This is a test message sent from web. Please disregard, no action is required.',
        },
        requester,
        subject: `
          Test Request
        `,
      },
    }
  }

  return {
    request: {
      comment: {
        body: `UserID: ${user.id}\nEmail: ${user.info.email}\nCommitment: ${
          user.subscription.isCommitment ? 'true' : 'false'
        }`,
      },
      requester,
      subject: `
        Delete User ${user.id} Request
      `,
    },
  }
}
