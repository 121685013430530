import { UserV1 } from '@tovala/browser-apis-combinedapi'

export function getSubscriptionPreferences({ user }: { user: UserV1 }) {
  const { subscription } = user

  return {
    autofillBreakfastOK: subscription.autofillBreakfastOK,
    autofillSurchargeOK: subscription.autofillSurchargeOK,
    defaultShipPeriod: subscription.defaultShipPeriod,
    doNotReplace: subscription.isDoNotReplace,
    hasBlackSheetTray: subscription.hasBlackSheetTray,
    isDouble: subscription.isDouble,
    premiumMealsOk: subscription.isPremiumMealsOk,
    single: !subscription.isDouble,
  }
}
