import { GoodErrorMessaging } from 'types/internal'

export function getGoodErrorMessagingParagraph({
  helpToFix = '',
  reassurance = '',
  wayOut = wrapWithContactSupportTeam('If the issue keeps happening'),
  why,
}: Pick<GoodErrorMessaging, 'helpToFix' | 'reassurance' | 'wayOut' | 'why'>) {
  return (
    <span>
      {reassurance} {why} {helpToFix} {wayOut}
    </span>
  )
}

/**
 * Adds HTML to open a new tab to our Tovala support page. The supplied prefix should
 * not include a comma.
 */
export function wrapWithContactSupportTeam(prefixWithoutComma: string) {
  return (
    <span>
      {prefixWithoutComma}, please{' '}
      <a
        className="underline"
        href="https://support.tovala.com"
        rel="noreferrer noopener"
        target="_blank"
      >
        contact our support team
      </a>
      .
    </span>
  )
}
