import { Customer, UserV1 } from '@tovala/browser-apis-combinedapi'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'

import {
  ErrorCodeMessageMapCombinedAPI,
  ReactivateLocationState,
} from 'types/internal'
import { events } from 'analytics/events'
import { track } from 'utils/analytics'

import { useCustomer } from 'hooks/combinedAPI/customers'
import { useToast } from 'contexts/toast'
import { useUser } from 'contexts/user'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import ErrorDisplay from 'components/common/ErrorDisplay'
import ProfileHeading from './AccountHeading'
import ShippingAddressForm from './ShippingAddressForm'

const LOAD_CUSTOMER_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page.',
    why: "We couldn't load your information due to a technical issue on our end.",
  },
}

const ShippingPage = () => {
  const { user } = useUser()

  const {
    data: customer,
    error: loadCustomerError,
    isError: hasLoadCustomerError,
  } = useCustomer({
    customerID: user.subscription.customerID,
    userID: user.id,
  })

  return (
    <div>
      <h1 className="mb-10 text-k/44_110 md:hidden">Shipping</h1>
      <div className="hidden md:block">
        <ProfileHeading to="/account">Shipping</ProfileHeading>
      </div>

      {customer ? (
        <ShippingPageLoaded customer={customer} user={user} />
      ) : hasLoadCustomerError ? (
        <APIErrorDisplay
          error={loadCustomerError}
          errorCodeMessageMap={LOAD_CUSTOMER_ERRORS}
        />
      ) : null}
    </div>
  )
}

export default ShippingPage

const ShippingPageLoaded = ({
  customer,
  user,
}: {
  customer: Customer
  user: UserV1
}) => {
  const location = useLocation()
  const { openToast } = useToast()

  const [reactivateError, setReactivateError] = useState(() => {
    return (location.state as ReactivateLocationState | undefined)
      ?.resubscribeError
  })

  return (
    <div className="max-w-account space-y-4 md:px-4 md:py-6">
      {reactivateError && <ErrorDisplay wayOut={null} why={reactivateError} />}

      <ShippingAddressForm
        customerID={customer.id}
        onEditUserSuccess={() => {
          track(events.CHANGE_SHIPPING_ADDRESS)

          openToast({
            heading: 'Changes Saved',
            message: 'Your address was updated successfully.',
            type: 'success',
          })

          // It's confusing to the user if we leave an error display after they have saved
          // their shipping address so we clear it out here.
          setReactivateError('')
        }}
        user={user}
      />
    </div>
  )
}
