import { ReactNode } from 'react'

import Modal, { ModalBody, ModalHeader } from './Modal'

const ConfirmationDialog = ({
  children,
  onRequestClose,
}: {
  children: ReactNode
  onRequestClose(): void
}) => {
  return (
    <Modal onCloseModal={onRequestClose}>
      <div className="max-w-confirmation-dialog md:max-w-full">{children}</div>
    </Modal>
  )
}

export default ConfirmationDialog

export const ConfirmationBody = ({ children }: { children: ReactNode }) => {
  return (
    <ModalBody>
      <div className="px-6 text-center text-k/20_110 text-grey-8">
        {children}
      </div>
    </ModalBody>
  )
}

export const ConfirmationButtons = ({ children }: { children: ReactNode }) => {
  return <div className="p-6 pt-0">{children}</div>
}

export const ConfirmationHeader = ({
  heading,
  onClickClose,
}: {
  heading: ReactNode
  onClickClose?: () => void
}) => {
  return (
    <ModalHeader onClickClose={onClickClose}>
      <span className="block text-base uppercase">{heading}</span>
    </ModalHeader>
  )
}
