import { Button, ArrowLeftIcon, FilterIcon } from '@tovala/component-library'
import { events } from 'analytics/events'
import { useNavigate } from 'react-router-dom'
import { SelectedMealFilters, UserTerm } from 'types/internal'
import { track } from 'utils/analytics'
import MenuFilters from './MenuFilters'
import MenuHeader from './MenuHeader'

const MealsMenuHeader = ({
  onChangeSelectedMealFilters,
  selectedMealFilters,
  selectedUserTerm,
  showOrderUpgrade,
}: {
  onChangeSelectedMealFilters(filters: SelectedMealFilters): void
  selectedMealFilters: SelectedMealFilters
  selectedUserTerm: UserTerm
  showOrderUpgrade: boolean
}) => {
  const navigate = useNavigate()

  return (
    <MenuHeader
      backButton={
        <Button
          aria-label="My Orders"
          buttonStyle="link"
          onClick={() => {
            navigate('/my-orders')

            const termMealCount =
              showOrderUpgrade && selectedUserTerm.nextLargestSubscriptionType
                ? selectedUserTerm.nextLargestSubscriptionType.maxSelections
                : !showOrderUpgrade && selectedUserTerm.subscriptionType
                ? selectedUserTerm.subscriptionType.maxSelections
                : ''

            track(events.EXITS_MENU, {
              meal_selection_count: selectedUserTerm.mealSelections.length,
              meal_selection_max: termMealCount,
            })
          }}
          size="auto"
        >
          <div className="mr-2 h-6 w-6">
            <ArrowLeftIcon />
          </div>
          <span className="text-k/16_125 md:hidden">My Orders</span>
        </Button>
      }
      filters={
        <MenuFilters
          onChangeSelectedMealFilters={onChangeSelectedMealFilters}
          renderButton={({ openSidebar }) => {
            return (
              <Button
                aria-label="Filter"
                buttonStyle="link"
                onClick={openSidebar}
                size="auto"
              >
                <div className="mr-2 h-6 w-6">
                  <FilterIcon />
                </div>
                <span className="text-k/16_125 md:hidden">Filter</span>
              </Button>
            )
          }}
          selectedMealFilters={selectedMealFilters}
          selectedUserTerm={selectedUserTerm}
        />
      }
      selectedUserTerm={selectedUserTerm}
    />
  )
}

export default MealsMenuHeader
