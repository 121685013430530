import { clsx } from 'clsx'
import { useFormContext } from 'react-hook-form'

import { IssueConfig } from 'types/internal'

import Chip from './Chip'

const Feedback = ({
  afterChange,
  feedbackData,
  heading,
}: {
  afterChange?: () => void
  feedbackData: IssueConfig['feedback']
  heading: string
}) => {
  const { watch } = useFormContext()
  const feedback = watch('feedback')

  return (
    <div className="space-y-4">
      <h3 className="text-k/20_125">{heading}</h3>

      <div>
        {feedbackData
          ?.filter((feedbackItem) => feedbackItem.tags.includes('section'))
          .map((section) => {
            return (
              <div
                key={section.name}
                className={clsx(
                  '-mt-2 mb-2 flex flex-wrap justify-center',
                  section.tags.includes('alignCenter')
                    ? 'text-center'
                    : 'text-left'
                )}
              >
                {section.children?.map((child) => {
                  const type = section.tags.includes('singleSelection')
                    ? 'radio'
                    : 'checkbox'

                  const checked =
                    type === 'radio'
                      ? feedback && feedback[section.name] === child.name
                      : feedback &&
                        feedback[section.name] &&
                        feedback[section.name].includes(child.name)

                  return (
                    <div key={child.name} className="m-2">
                      <Chip
                        afterChange={() => {
                          if (afterChange) {
                            afterChange()
                          }
                        }}
                        checked={checked}
                        label={child.name}
                        name={`feedback[${section.name}]`}
                        type={type}
                        value={child.name}
                      />
                    </div>
                  )
                })}

                {section.tags?.includes('trailingDivider') && (
                  <div className="my-2 w-full border-t border-grey-3" />
                )}
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default Feedback
