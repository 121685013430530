import { clsx } from 'clsx'
import { forwardRef, InputHTMLAttributes, ReactNode } from 'react'

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: ReactNode
  name: string
}

const Radio = forwardRef<HTMLInputElement, Props>(function Radio(
  { checked, disabled = false, id, label, ...rest },
  ref
) {
  return (
    <label
      className={clsx(
        'relative mb-0 flex select-none items-start justify-between text-k/16_125 text-black',
        {
          'cursor-not-allowed': disabled,
          'cursor-pointer': !disabled,
        }
      )}
      htmlFor={id}
    >
      {label && <span>{label}</span>}

      {/* Hide the browser's default radio. */}
      <input
        {...rest}
        ref={ref}
        checked={checked}
        className="absolute h-0 w-0 cursor-pointer opacity-0"
        disabled={disabled}
        id={id}
        type="radio"
      />

      {/* Create a custom radio. */}
      <div
        className={clsx(
          'relative flex h-6 w-6 shrink-0 items-center justify-center rounded-full border',
          {
            'border-grey-7 bg-grey-7': disabled,
            'border-grey-7 bg-white': !disabled,
          }
        )}
      >
        {checked && <div className="h-4 w-4 rounded-full bg-orange-1" />}
      </div>
    </label>
  )
})

export default Radio
