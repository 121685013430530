import { ArrowLeftIcon } from '@tovala/component-library'
import { Link } from 'react-router-dom'
import { ReactNode } from 'react'

const AccountHeading = ({
  children,
  to,
}: {
  children: ReactNode
  to?: string
}) => {
  return (
    <div className="flex items-center justify-center border-grey-3 md:border-b md:pb-4">
      {to && (
        <Link
          aria-label="Back"
          className="absolute left-0 hidden p-4 md:block"
          to={to}
        >
          <div className="h-6 w-6">
            <ArrowLeftIcon />
          </div>
        </Link>
      )}
      <h1 className="mb-6 text-k/44_110 md:mb-0 md:text-k/28_130">
        {children}
      </h1>
    </div>
  )
}

export default AccountHeading
