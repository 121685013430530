import { cloneElement, CSSProperties, ReactNode, useRef, useState } from 'react'
import {
  arrow,
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  shift,
  Side,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions'

const ARROW_SIDE_MAP: { [side in Side]: Side } = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
}

const Tooltip = ({
  alwaysShow = false,
  children,
  placement = 'top',
  trigger,
}: {
  alwaysShow?: boolean
  children: ReactNode
  placement?: Placement
  trigger: JSX.Element
}) => {
  const arrowRef = useRef(null)
  const [open, setOpen] = useState(alwaysShow)

  const {
    context,
    floating,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    placement: finalPlacement,
    reference,
    strategy,
    x,
    y,
  } = useFloating({
    placement,
    open,
    onOpenChange: alwaysShow ? undefined : setOpen,
    middleware: [
      offset(({ placement }) => ({
        // The value of "8" takes into account the space for the arrow and then a little
        // extra to separate the tooltip from its trigger element.
        mainAxis: placement === 'top' ? 8 : -8,
      })),
      flip(),
      shift({ padding: 8 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { restMs: 150 }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ])

  const sharedArrowClasses = 'absolute w-2 h-2 -z-10'

  const sideFromPlacement = finalPlacement.split('-')[0] as Side
  const staticSide = ARROW_SIDE_MAP[sideFromPlacement]

  const arrowStyle: CSSProperties = { top: arrowY, left: arrowX }
  if (staticSide) {
    arrowStyle[staticSide] = '-4px'
  }

  return (
    <>
      {cloneElement(
        trigger,
        getReferenceProps({ ref: reference, ...trigger.props })
      )}

      <FloatingPortal>
        {open && (
          <div
            ref={floating}
            className="z-40 max-w-xs rounded bg-black p-3 text-k/14_120 text-white shadow-lg"
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
          >
            {children}

            <div
              ref={arrowRef}
              className={sharedArrowClasses}
              style={arrowStyle}
            >
              <div
                className={`${sharedArrowClasses} top-0 left-0 rotate-45 bg-black`}
              />
            </div>
          </div>
        )}
      </FloatingPortal>
    </>
  )
}

export default Tooltip
