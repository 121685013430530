import { ReactNode } from 'react'
import { useField } from 'formik'

import Combobox, { Props as ComboboxProps } from './Combobox'
import FormGroup from './FormGroup'

function FormCombobox<Option>({
  label,
  labelFor,
  ...props
}: Omit<ComboboxProps<Option>, 'hasError' | 'onChange' | 'value'> & {
  label?: ReactNode
  labelFor?: string
  name: string
}): JSX.Element {
  const [field, meta, helpers] = useField(props.name)
  const hasError = !!(meta.touched && meta.error)

  return (
    <FormGroup
      error={hasError ? meta.error : ''}
      label={label}
      labelFor={labelFor}
    >
      <Combobox
        {...field}
        {...props}
        hasError={hasError}
        onChange={(value) => {
          helpers.setTouched(true)
          helpers.setValue(value)
        }}
      />
    </FormGroup>
  )
}

export default FormCombobox
