import { CaretDownIcon, XIcon } from '@tovala/component-library'
import { clsx } from 'clsx'
import { Listbox as HeadlessUIListbox } from '@headlessui/react'

export interface Props<Option> {
  hasError?: boolean
  isClearable?: boolean
  label?: JSX.Element
  onChange(newValue: Option | null): void
  options: Option[]
  placeholder?: string
  value: Option | null
}

function Listbox<
  OptionValue,
  Option extends { label: string; value: OptionValue }
>({
  hasError = false,
  isClearable = true,
  label = undefined,
  onChange,
  options,
  placeholder = '',
  value,
}: Props<Option>) {
  let rightIcon = (
    <div className="h-3 w-3">
      <CaretDownIcon />
    </div>
  )

  if (value && isClearable) {
    rightIcon = (
      <div
        className="rounded-full bg-grey-3 p-1"
        onClick={(event) => {
          event.defaultPrevented = true

          onChange(null)
        }}
      >
        <div className="h-3 w-3">
          <XIcon strokeWidth={2.25} />
        </div>
      </div>
    )
  }

  return (
    <HeadlessUIListbox onChange={onChange} value={value}>
      {label}

      <div className="relative text-k/14_120">
        <HeadlessUIListbox.Button
          className={clsx(
            'relative h-14 w-full items-center rounded-lg border bg-grey-0 px-4 pr-10 text-left',
            {
              'border-red': hasError,
              'border-grey-4': !hasError,
            }
          )}
        >
          {value ? (
            <span>{value.label}</span>
          ) : placeholder ? (
            <span className="text-grey-8">{placeholder}</span>
          ) : null}

          <div className="absolute right-4 top-1/2 -translate-y-1/2">
            {rightIcon}
          </div>
        </HeadlessUIListbox.Button>
        <HeadlessUIListbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md border border-grey-4 bg-grey-0 text-k/14_120 shadow-lg focus:outline-none">
          {options.map((option, i) => (
            <HeadlessUIListbox.Option
              key={i}
              className="border-b border-grey-4 p-4 ui-selected:font-semibold ui-active:bg-grey-2"
              value={option}
            >
              {option.label}
            </HeadlessUIListbox.Option>
          ))}
        </HeadlessUIListbox.Options>
      </div>
    </HeadlessUIListbox>
  )
}

export default Listbox

export const ListboxLabel = HeadlessUIListbox.Label
