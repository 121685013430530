import { CSSProperties } from 'react'

import { getEnvVar } from './env'

const MYTOVALA_IMAGES_URL = `${getEnvVar('CDN_URL')}/mytovala/images`

export const IMAGES = {
  MARKETPLACE_MENU_PROMPT: `${MYTOVALA_IMAGES_URL}/marketplace-menu-prompt.png`,
  MEAL_PLAN_PREFERENCES_START: `${MYTOVALA_IMAGES_URL}/meal-plan-preferences-start.jpg`,
  WANT_MORE_MEALS: `${MYTOVALA_IMAGES_URL}/want-more-meals-bg.jpg`,
}

export function getDarkenedBGImage({
  imageURL,
}: {
  imageURL: string
}): CSSProperties {
  return {
    backgroundImage: `linear-gradient(0deg, rgba(57, 57, 45, 0.35), rgba(57, 57, 45, 0.35)), url(${imageURL})`,
  }
}
