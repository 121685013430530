import { ButtonLoading, FormFieldError } from '@tovala/component-library'
import { useForm } from 'react-hook-form'
import { useRedeemCoupon } from '@tovala/browser-apis-combinedapi'

import {
  ErrorCodeMessageMapCombinedAPI,
  GoodErrorMessaging,
} from 'types/internal'
import { events } from 'analytics/events'
import { formatCentsToDollars } from 'utils/currency'
import { track } from 'utils/analytics'
import { wrapWithContactSupportTeam } from 'utils/errors'

import { useToast } from 'contexts/toast'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import FormGroup from 'components/common/FormGroup'
import Input from 'components/common/Input'

type FormValues = {
  code: string
}

const ALREADY_REDEEMED_ERROR = {
  wayOut: wrapWithContactSupportTeam('If you need further assistance'),
  why: "We couldn't apply that promo to your account because that promo has already been applied.",
} as const satisfies GoodErrorMessaging

const REDEEM_PROMO_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please double check the promo code and try again.',
    why: "We couldn't apply the promo to your account due to a technical issue on our end.",
  },
  couponcodesappliedUniqueConstraintError: ALREADY_REDEEMED_ERROR,
  couponcodesDoesNotExist: {
    helpToFix: 'Please double check the code and try again.',
    why: "We couldn't apply that promo to your account because that promo code does not exist.",
  },
  'InvalidCoupon-AlreadyRedeemedByUser': ALREADY_REDEEMED_ERROR,
  'InvalidCoupon-DollarOrPercentCouponOnFreeProduct': {
    wayOut: wrapWithContactSupportTeam('If you need further assistance'),
    why: "We couldn't apply that promo to your account because it's only valid for a Tovala Oven purchase.",
  },
  'InvalidCoupon-ExpiredRedeemDate': {
    wayOut: wrapWithContactSupportTeam('If you need further assistance'),
    why: "We couldn't apply that promo to your account because it's expired.",
  },
  'InvalidCoupon-MaxRedemptionsReached': ALREADY_REDEEMED_ERROR,
  'InvalidCoupon-SKUMismatch': {
    wayOut: wrapWithContactSupportTeam('If you need further assistance'),
    why: "We couldn't apply that promo to your account because it's only valid for a Tovala Oven purchase.",
  },
  'InvalidCoupon-UserIDMismatch': {
    wayOut: wrapWithContactSupportTeam('If you need further assistance'),
    why: "We couldn't apply that promo to your account because it's not applicable to you.",
  },
}

const RedeemPromo = ({ userID }: { userID: number }): JSX.Element => {
  const { openToast } = useToast()

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<FormValues>({ defaultValues: { code: '' } })

  const {
    error: redeemError,
    isError: hasRedeemError,
    isLoading: redeemLoading,
    mutate: redeemCouponCode,
  } = useRedeemCoupon({
    onSuccess: (response) => {
      reset()

      openToast({
        heading: 'Promotion Applied',
        message: `Your ${formatCentsToDollars(
          response.amount
        )} promo has been applied to your account!`,
        type: 'success',
      })

      track(events.REDEEMS_PROMO, { code_type: 'coupon' })
    },
  })

  return (
    <form
      onSubmit={handleSubmit((data) =>
        redeemCouponCode({
          data,
          userID,
        })
      )}
    >
      <FormGroup
        label={
          <div className="space-y-1">
            <div>Promotion</div>
            <p className="font-normal">
              Enter your promo, discount or coupon code below
            </p>
          </div>
        }
        labelFor="promo-code-input"
      >
        <div className="flex max-w-[500px] items-start space-x-4 md:block md:space-x-0">
          <div className="grow md:w-full">
            <Input
              hasError={!!errors.code}
              id="promo-code-input"
              placeholder="Enter Promo Code"
              type="text"
              {...register('code', {
                required: 'Please enter a promo code',
              })}
            />

            {errors.code?.message && (
              <div className="mt-1">
                <FormFieldError>{errors.code.message}</FormFieldError>
              </div>
            )}
          </div>

          <div className="h-[56px] w-[185px] md:mt-3 md:h-[36px] md:w-[178px] md:text-k/14_120">
            <ButtonLoading
              buttonStyle="stroke"
              isLoading={redeemLoading}
              size="fluid"
              type="submit"
            >
              Redeem Promo
            </ButtonLoading>
          </div>
        </div>

        {hasRedeemError && (
          <div className="mt-4">
            <APIErrorDisplay
              error={redeemError}
              errorCodeMessageMap={REDEEM_PROMO_ERRORS}
            />
          </div>
        )}
      </FormGroup>
    </form>
  )
}

export default RedeemPromo
