import { clsx } from 'clsx'
import { MouseEventHandler, ReactNode } from 'react'

import MealImageBase from 'components/common/MealImage'

const MealImage = ({
  cover,
  image,
  onClick,
  tag,
}: {
  cover?: ReactNode
  image: { url: string }
  onClick?: MouseEventHandler<HTMLDivElement>
  tag?: ReactNode
}) => {
  return (
    <div
      className={clsx('relative h-full w-full', {
        'cursor-pointer': !!onClick,
      })}
      onClick={onClick}
    >
      {tag && <div className="absolute left-4 top-5">{tag}</div>}

      <MealImageBase imageURL={image.url} />

      {cover && <div className="absolute inset-0 bg-black/60">{cover}</div>}
    </div>
  )
}

export default MealImage
