import { HeaderWithLogo } from '../common/Header'

const DownloadAppPage = () => {
  return (
    <div>
      <HeaderWithLogo />
      <div className="m-auto flex max-w-[700px] flex-col items-center justify-center gap-5 pb-40 pt-5 md:max-w-[350px] sm:max-w-[300px]">
        <h1 className="text-center text-k/36_110">Download the Tovala App</h1>
        <h2 className="text-center text-body-lg text-grey-9 md:mx-0">
          Your account has been created! Get the Tovala App now to connect your
          oven to WiFi, view recipes, and scan & cook groceries and meals.
        </h2>
        <img
          alt="Tovala Air Fryer and Oven with the Tovala App on a phone"
          className="rounded-lg shadow-lg"
          height={250}
          src="https://cdn.tovala.com/mytovala/images/app-download-hero.jpg"
          width={442}
        />
        <div className="grid grid-cols-2 gap-4">
          <a
            className="flex items-center"
            href="https://apps.apple.com/app/apple-store/id1166594440?pt=118392104&ct=Costco_Account_Creation&mt=8"
            rel="noreferrer"
            target="_blank"
          >
            <img
              alt="Apple App Store Badge"
              className="p-2"
              height={188 / 2}
              src="https://cdn.tovala.com/tovala.com/app-badges/apple.png"
              width={562 / 2}
            />
          </a>
          <a
            className="flex items-center"
            href="https://play.google.com/store/apps/details?id=com.tovala.tovala&listing=costco-web"
            rel="noreferrer"
            target="_blank"
          >
            <img
              alt="Google Play Store Badge"
              height={170 / 2}
              src="https://cdn.tovala.com/tovala.com/app-badges/google.png"
              width={567 / 2}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default DownloadAppPage
