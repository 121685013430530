import { CaretRightIcon } from '@tovala/component-library'
import { clsx } from 'clsx'
import { Customer, UserV1 } from '@tovala/browser-apis-combinedapi'
import { Link, NavLink } from 'react-router-dom'
import { ReactNode } from 'react'

import { events } from '../../../analytics/events'
import { formatCentsToDollars } from 'utils/currency'
import { track } from 'utils/analytics'

import { useCustomer } from 'hooks/combinedAPI/customers'
import { useFeatures } from 'contexts/features'
import { useOverlays } from 'contexts/overlays'
import { useUser } from 'contexts/user'
import ProfileHeading from './AccountHeading'
import ReportIssue from './reportIssue/ReportIssue'

const AccountNav = () => {
  const features = useFeatures()

  const { user } = useUser()

  const { data: customer } = useCustomer({
    customerID: user.subscription.customerID,
    userID: user.id,
  })

  const isSubscriptionActive = user.subscription.status === 'active'

  const profile = getProfileInfo({ customer, user })

  const priceCents = profile.subscriptionType?.priceCents ?? 0
  const shippingCents = profile.subscriptionType?.shippingCents ?? 0

  return (
    <nav aria-label="Account navigation">
      <div className="hidden md:block">
        <ProfileHeading>Account</ProfileHeading>
      </div>

      <div className="mx-4 mb-3 mt-6 hidden rounded-xl bg-black p-4 text-white md:block">
        {isSubscriptionActive && (
          <>
            <h2 className="mb-3 text-k/20_110 font-semibold">
              {profile.subscriptionType?.maxSelections} Meal Plan •{' '}
              {profile.deliveryDayOfTheWeek} Deliveries
            </h2>
            <div className="mb-3 text-k/14_120">
              <span className="border-r border-white pr-3">
                Shipping:{' '}
                {shippingCents > 0
                  ? `${formatCentsToDollars(shippingCents)}`
                  : 'Free'}
              </span>

              <span className="pl-3">
                Estimated Weekly Total:{' '}
                {formatCentsToDollars(priceCents + shippingCents)}
              </span>
            </div>
          </>
        )}
        <div className="flex space-x-3">
          {isSubscriptionActive && (
            <Link
              className="flex h-7 items-center rounded-full border border-white px-6 text-k/12_120"
              onClick={() => {
                track(events.MANAGE_DELIVERIES)
              }}
              to="/account/manage-deliveries"
            >
              Skip/Manage Deliveries
            </Link>
          )}
          <Link
            className="flex h-7 items-center rounded-full border border-white px-6 text-k/12_120"
            to="/account/edit-plan"
          >
            {isSubscriptionActive ? 'Edit Plan' : 'Browse Plans'}
          </Link>
        </div>
      </div>
      <ul className="flex flex-col space-y-10 text-right text-k/20_125 md:mx-4 md:space-y-3 md:text-left md:text-k/18_120">
        <AccountNavLink
          path="/account/plan-preferences"
          state={{ open: 'autoselectedMeals' }}
          visibility="mobile"
        >
          Autoselected Meals
          <div className="hidden text-k/13_120 text-grey-10 md:block">
            {profile.preferences.autoselectedMeals}
          </div>
        </AccountNavLink>
        <AccountNavLink
          path="/account/plan-preferences"
          state={{ open: 'mealSubstitutions' }}
          visibility="mobile"
        >
          Meal Substitutions
          <div className="hidden text-k/13_120 text-grey-10 md:block">
            {profile.preferences.mealSubstitutions}
          </div>
        </AccountNavLink>
        {features.showSurchargePrompt && (
          <AccountNavLink
            path="/account/plan-preferences"
            state={{ open: 'surchargedMeals' }}
            visibility="mobile"
          >
            Surcharged Meals
            <div className="hidden text-k/13_120 text-grey-10 md:block">
              {profile.preferences.surchargedMeals}
            </div>
          </AccountNavLink>
        )}
        <AccountNavLink path="/account" visibility="non-mobile">
          Meal Plan
        </AccountNavLink>
        <AccountNavLink path="shipping">
          Shipping
          <div className="hidden text-k/13_120 text-grey-10 md:block">
            {profile.shippingAddress?.line1}
          </div>
        </AccountNavLink>
        <AccountNavLink path="payment">
          Payment
          <div className="hidden text-k/13_120 text-grey-10 md:block">
            {profile.defaultCard && (
              <span>
                Active - {profile.defaultCard.brand.toUpperCase()}{' '}
                {profile.defaultCard.last4}
              </span>
            )}
          </div>
        </AccountNavLink>

        <AccountNavLink path="edit">
          Profile
          <div className="hidden text-k/13_120 text-grey-10 md:block">
            {profile.email}
          </div>
        </AccountNavLink>

        <li className="flex w-full justify-end border-b border-t border-grey-3 py-10 md:h-20 md:items-center md:rounded-lg md:border-none md:bg-grey-2 md:px-4">
          <div className="w-full pr-4">
            <ReferralOffer />
          </div>
          <div className="hidden h-6 w-6 md:block">
            <CaretRightIcon />
          </div>
        </li>

        <AccountNavLink path="history">Order History</AccountNavLink>
        <AccountNavLink path="apply-promo">Gift cards/promos</AccountNavLink>

        <li className="w-full border-t border-grey-3 pt-10 md:flex md:h-20 md:items-center md:rounded-lg md:border-none md:bg-grey-2 md:px-4 md:pt-0">
          <a
            className="w-full pr-4"
            href="https://support.tovala.com"
            onClick={() => {
              track(events.OPENS_SUPPORT_TAB)
            }}
            rel="noreferrer"
            target="_blank"
          >
            Help Center
          </a>
          <div className="hidden h-6 w-6 md:block">
            <CaretRightIcon />
          </div>
        </li>

        <li className="flex w-full justify-end pr-4 md:justify-start">
          <ReportIssue />
        </li>
      </ul>
    </nav>
  )
}

export default AccountNav

const AccountNavLink = ({
  children,
  path,
  state,
  visibility = 'always',
}: {
  children: ReactNode
  path: string
  state?: { open: string }
  visibility?: 'always' | 'mobile' | 'non-mobile'
}) => {
  return (
    <li
      className={clsx(
        'w-full md:flex md:h-20 md:items-center md:rounded-lg md:bg-grey-2 md:px-4',
        {
          'hidden md:block': visibility === 'mobile',
          'md:hidden': visibility === 'non-mobile',
        }
      )}
    >
      <NavLink
        className={({ isActive }) => {
          return clsx('w-full md:flex md:items-center md:justify-between', {
            'font-semibold': isActive,
          })
        }}
        end
        state={state}
        to={path}
      >
        <div className="pr-4">{children}</div>
        <div className="hidden h-6 w-6 md:block">
          <CaretRightIcon />
        </div>
      </NavLink>
    </li>
  )
}

const getProfileInfo = ({
  customer,
  user,
}: {
  customer: Customer | undefined
  user: UserV1
}) => {
  const shippingAddress = user.shippingAddresses[0]
  const shipPeriodInfo = shippingAddress?.shipPeriodInfo

  const subscription = user.subscription

  const defaultShipPeriodInfo = shipPeriodInfo?.find(
    (shipPeriod) => shipPeriod.shipPeriod === subscription?.defaultShipPeriod
  )

  const defaultCard = customer?.sources.data.find(
    (card) => customer.default_source === card.id
  )

  const autoselectedMeals =
    subscription &&
    `${
      subscription.isDouble === false ? 'Single servings' : 'Double servings'
    }, ${
      subscription.autofillSurchargeOK === true
        ? 'Surcharged OK'
        : 'No Surcharge'
    }, ${
      subscription.autofillBreakfastOK === true
        ? 'Breakfast OK'
        : 'No Breakfast'
    }`

  const surchargedMeals =
    subscription && subscription.isPremiumMealsOk === true
      ? "Don't ask before adding"
      : 'Ask before adding'

  const mealSubstitutions =
    subscription && subscription.isDoNotReplace === true
      ? 'Do not substitute'
      : 'Substitutions OK'

  return {
    email: user.info.email ?? '',
    defaultCard,
    preferences: {
      autoselectedMeals,
      surchargedMeals,
      mealSubstitutions,
    },
    subscriptionType: subscription?.subscriptionType,
    deliveryDayOfTheWeek: defaultShipPeriodInfo?.deliveryDayOfTheWeek,
    shippingAddress,
  }
}

const ReferralOffer = () => {
  const { setIsReferralModalOpen } = useOverlays()

  return (
    <>
      <button
        className="w-full text-right text-orange-1 md:text-left"
        onClick={() => {
          setIsReferralModalOpen(true)
          track(events.OPENS_REFERRAL_SCREEN)
        }}
      >
        Get $50
      </button>
    </>
  )
}
