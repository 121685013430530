import { ReactNode } from 'react'

const TextImageStack = ({
  children,
  image,
  subtitle,
  title,
}: {
  children: ReactNode
  image?: { url: string }
  subtitle?: string
  title: string
}) => {
  return (
    <div className="space-y-8">
      <div className="grid grid-cols-2 items-center md:grid-cols-1 md:gap-8 md:px-4">
        <div className="space-y-3 md:space-y-2">
          <div className="whitespace-pre-line text-k/36_110 md:text-k/26_110">
            {title}
          </div>
          {subtitle && (
            <p className="whitespace-pre-line text-k/20_125 text-grey-9 md:text-k/18_120">
              {subtitle}
            </p>
          )}
        </div>
        {image && (
          <div className="justify-self-end md:justify-self-center">
            <img alt="" className="max-h-64" src={image.url} />
          </div>
        )}
      </div>

      {children}
    </div>
  )
}

export default TextImageStack
