import { Button, ButtonLoading } from '@tovala/component-library'
import { useDeactivateUser } from '@tovala/browser-apis-combinedapi'
import { useNavigate } from 'react-router-dom'

import { ErrorCodeMessageMapCombinedAPI } from 'types/internal'
import { events } from 'analytics/events'
import { track } from 'utils/analytics'

import APIErrorDisplay from 'components/common/APIErrorDisplay'
import ConfirmationDialog, {
  ConfirmationBody,
  ConfirmationButtons,
  ConfirmationHeader,
} from 'components/common/ConfirmationDialog'

const DEACTIVATE_ACCOUNT_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't deactivate your account due to a technical issue on our end.",
  },
}

const DeactivateAccountModal = ({
  onClickClose,
  userID,
}: {
  onClickClose(): void
  userID: number
}) => {
  const navigate = useNavigate()

  const {
    error: deactivateUserError,
    isError: hasDeactivateUserError,
    isLoading: isDeactivatingAccount,
    mutate: deactivateUser,
  } = useDeactivateUser({
    onSuccess: () => {
      navigate('/logout')
    },
  })

  const handleDeactivateAccount = () => {
    deactivateUser({
      userID,
    })
  }

  return (
    <ConfirmationDialog onRequestClose={onClickClose}>
      <ConfirmationHeader
        heading={
          <span className="block whitespace-pre-wrap text-left">
            {'Are you sure you want to\ndeactivate your account?'}
          </span>
        }
        onClickClose={onClickClose}
      />
      <ConfirmationBody>
        <div className="space-y-4 pb-20 pt-3 text-left text-body-sm text-grey-9 md:py-12">
          {hasDeactivateUserError && (
            <APIErrorDisplay
              error={deactivateUserError}
              errorCodeMessageMap={DEACTIVATE_ACCOUNT_ERRORS}
            />
          )}
          <p>
            By deactivating your account, you will no longer be able to use the
            app to control your oven.
          </p>
          <p>
            Some transactional data, such as oven and meal purchase records,
            must be kept in order to authenticate transaction and maintain
            compliance with applicable law and other legal obligation such as a
            payment processing obligations.
          </p>
          <p>
            If you purchased your oven with a promotion requiring you to order 6
            weeks of meals and have not yet fulfilled this commitment, you will
            be charged to void this commitment over the next several weeks. See
            Purchase terms on my.tovala.com/legal/purchase-terms for more
            details.
          </p>
        </div>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="flex justify-between">
          <Button buttonStyle="stroke" onClick={onClickClose} size="large">
            Cancel
          </Button>

          <ButtonLoading
            isLoading={isDeactivatingAccount}
            onClick={() => {
              track(events.TAPS_DELETE_ACCOUNT_CONFIRM)
              handleDeactivateAccount()
            }}
            size="large"
          >
            Deactivate Account
          </ButtonLoading>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}

export default DeactivateAccountModal
