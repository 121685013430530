import { Button, XIcon } from '@tovala/component-library'
import { Dialog } from '@headlessui/react'
import { ReactNode } from 'react'

const Sidebar = ({
  children,
  onCloseSidebar,
}: {
  children: ReactNode
  onCloseSidebar: () => void
}): JSX.Element => {
  return (
    <Dialog
      onClose={() => {
        onCloseSidebar()
      }}
      open={true}
    >
      <div
        className="fixed inset-0 z-30 bg-black opacity-40"
        onClick={onCloseSidebar}
      />
      <div className="fixed bottom-0 right-0 top-0 z-30 w-full max-w-[426px]">
        <Dialog.Panel className="flex h-full flex-col bg-grey-0">
          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default Sidebar

export const SidebarBody = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  return <div className="flex-auto overflow-y-auto pb-20">{children}</div>
}

export const SidebarButtons = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  return (
    <div className="fixed bottom-0 w-full max-w-[426px] shrink-0">
      {children}
    </div>
  )
}

export const SidebarHeader = ({
  children,
  onClickClose,
}: {
  children: ReactNode
  onClickClose?: () => void
}): JSX.Element => {
  return (
    <div className="relative flex shrink-0 items-center justify-between border-b border-grey-3">
      <Dialog.Title className="flex-grow py-4 text-center text-k/28_130">
        {children}
      </Dialog.Title>
      {onClickClose && (
        <div className="absolute right-6">
          <Button
            aria-label="Close"
            buttonStyle="link"
            onClick={onClickClose}
            size="auto"
          >
            <div className="h-6 w-6">
              <XIcon />
            </div>
          </Button>
        </div>
      )}
    </div>
  )
}
