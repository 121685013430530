import { Button } from '@tovala/component-library'

import ConfirmationDialog, {
  ConfirmationBody,
  ConfirmationButtons,
  ConfirmationHeader,
} from '../../common/ConfirmationDialog'

const NoListingFiltersConfirmationDialog = ({
  onClose,
}: {
  onClose(): void
}) => {
  return (
    <ConfirmationDialog onRequestClose={onClose}>
      <ConfirmationHeader heading="Allergen Note" />
      <ConfirmationBody>
        <div className="pb-28 pt-12">
          <p className="mx-auto max-w-xs">
            Extras are not filtered by allergen. Please check their nutritional
            info.
          </p>
        </div>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="space-y-4">
          <div className="grid">
            <Button onClick={onClose} size="large">
              Ok, thanks
            </Button>
          </div>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}

export default NoListingFiltersConfirmationDialog
