import { useCallback, useEffect } from 'react'
import useEmblaCarousel from 'embla-carousel-react'

import { MealTagCollapsible } from 'types/internal'

import MealTag from './MealTag'
import MealTagExpanding from './MealTagExpanding'

const MealTagsScrolling = ({ tags }: { tags: MealTagCollapsible[] }) => {
  const [tagsContainer, emblaApi] = useEmblaCarousel({
    align: 'start',
    // We only enable dragging if there are more tags than what fit in the
    // user's viewport.
    active: false,
    inViewThreshold: 1,
    slidesToScroll: 'auto',
  })

  const reintEmblaWithVisibleSlides = useCallback(() => {
    if (emblaApi) {
      // We need to first reInit based on the current state so the following
      // slidesNotInView call takes into account any changes to the DOM since
      // we last initialized.
      emblaApi.reInit()

      // Only enable dragging if there are too many tags to fit in view to
      // prevent being able to move the tags unnecessarily.
      emblaApi.reInit({ active: emblaApi.slidesNotInView().length > 0 })
    }
  }, [emblaApi])

  useEffect(() => {
    reintEmblaWithVisibleSlides()
  }, [reintEmblaWithVisibleSlides])

  return (
    <div ref={tagsContainer} className="overflow-hidden">
      <div className="flex items-center space-x-1">
        {tags.map((tag, index) => {
          return (
            <div key={index} className="flex shrink-0">
              {typeof tag === 'string' ? (
                <MealTag>{tag}</MealTag>
              ) : (
                <MealTagExpanding
                  // Once a tag expands, it may not be completely visible in the container so
                  // we reinit embla to potentially enable dragging.
                  onTagExpanded={reintEmblaWithVisibleSlides}
                  titleCollapsed={tag.titleCollapsed}
                  titleExpanded={tag.titleExpanded}
                />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MealTagsScrolling
