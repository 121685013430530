import { UserV1 } from '@tovala/browser-apis-combinedapi'
import { isURL } from './general'

export function getTotalCreditsCents({ user }: { user: UserV1 }) {
  return user.cashCents + user.discountCents
}

export function validateNameIsNotURL(
  name: string,
  error: string
): true | string {
  return !isURL(name.trim()) ? true : error
}
