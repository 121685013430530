import { ButtonLoading } from '@tovala/component-library'
import { clsx } from 'clsx'
import {
  Fragment,
  InputHTMLAttributes,
  ReactNode,
  TextareaHTMLAttributes,
  useState,
} from 'react'
import { Form, Formik, useField } from 'formik'
import { motion } from 'framer-motion'
import { orderBy } from 'lodash-es'
import {
  MealImage,
  OrderHistoryReceiptMeal,
  useCreateMealReview,
  useMealReviewChips,
} from '@tovala/browser-apis-combinedapi'

import { ErrorCodeMessageMapCombinedAPI } from 'types/internal'
import { events } from 'analytics/events'
import { track } from 'utils/analytics'

import { useUser } from 'contexts/user'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import Collapsible, { CollapsibleContent } from 'components/common/Collapsible'

interface FormData {
  comment: string
  rating: string
  selectedChipIDs: string[]
}

const CREATE_REVIEW_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't save your feedback due to a technical issue on our end.",
  },
}

const LOAD_REIVEW_CHIPS_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page and try again.',
    why: "We couldn't load feedback suggestions due to a technical issue on our end.",
  },
}

const perfectRating = '100'
const feedbackRating = '0'

const MealRatingCard = ({
  meal,
  onMealReviewCreated,
}: {
  meal: OrderHistoryReceiptMeal
  onMealReviewCreated(): void
}) => {
  const [reviewStarted, setReviewStarted] = useState(false)
  const [isReviewed, setIsReviewed] = useState(false)

  const image = meal.images?.find(
    (image: MealImage) => image.key === 'cell_tile'
  )

  return (
    <div>
      <div>
        {image?.url && (
          <div
            className={clsx(
              'relative overflow-hidden rounded-t-lg',
              reviewStarted && 'h-40',
              !reviewStarted && 'h-64'
            )}
          >
            <img
              className="h-full w-full object-cover"
              src={`https://${image.url}`}
            />
            <div className="absolute bottom-2 left-0 right-0 bg-grey-0 bg-opacity-80 p-2 text-center">
              <p className="text-k/16_125">{meal.title}</p>
              <p className="text-k/14_120">{meal.subtitle}</p>
            </div>
          </div>
        )}
      </div>

      <div className="p-6">
        {isReviewed ? (
          <div>
            <p>
              You've rated this meal.
              <br />
              Thanks for the feedback!
            </p>
          </div>
        ) : (
          <ReviewForm
            meal={meal}
            onMealReviewCreated={() => {
              onMealReviewCreated()
              setIsReviewed(true)
            }}
            onReviewStarted={() => {
              setReviewStarted(true)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default MealRatingCard

const ReviewForm = ({
  meal,
  onMealReviewCreated,
  onReviewStarted,
}: {
  meal: OrderHistoryReceiptMeal
  onMealReviewCreated(): void
  onReviewStarted(): void
}) => {
  const { user } = useUser()

  const {
    data: mealReviewChips,
    error: loadMealReviewChipsError,
    isError: hasLoadMealReviewChipsError,
  } = useMealReviewChips()

  const {
    error: createMealReviewError,
    isError: hasCreateMealReviewError,
    isLoading: isCreatingMealReview,
    mutate: createMealReview,
  } = useCreateMealReview({
    onSuccess: () => {
      onMealReviewCreated()
    },
  })

  const handleRatingSubmit = (values: FormData) => {
    track(events.REVIEW_SUBMITTED)

    createMealReview({
      data: {
        comment: values.comment,
        mealid: meal.id,
        rating: Number(values.rating),
        ratingType: 'thumbs',
        routineRevisionID: null,
        selectedChipIDs: values.selectedChipIDs,
        platform: 'web',
        userid: user.id,
      },
    })
  }

  const initialValues = {
    comment: '',
    rating: '',
    selectedChipIDs: [],
  }

  const feedbackChips = mealReviewChips
    ? orderBy(mealReviewChips.feedback, 'displayOrder').map(({ children }) => {
        return children
      })
    : []

  return (
    <Formik<FormData>
      key={meal.id}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleRatingSubmit}
    >
      {({ handleChange, submitForm, values }) => {
        return (
          <Form>
            <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
              {(!values.rating || values.rating === perfectRating) && (
                <>
                  <div className="text-center">
                    <h2 className="mb-4 text-k/20_125">How was your meal?</h2>

                    {hasCreateMealReviewError && (
                      <APIErrorDisplay
                        error={createMealReviewError}
                        errorCodeMessageMap={CREATE_REVIEW_ERRORS}
                      />
                    )}

                    <div className="m-2 inline-block">
                      <HiddenField
                        id={`${meal.id}-perfect`}
                        name="rating"
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          track(events.USER_TAPS_RATE_MEAL, {
                            meal_id: meal.id,
                          })

                          handleChange(e)
                          submitForm()
                        }}
                        type="radio"
                        value={perfectRating}
                      />
                      <ChipLabel
                        checked={values.rating === perfectRating}
                        labelFor={`${meal.id}-perfect`}
                      >
                        Perfect
                      </ChipLabel>
                    </div>

                    <div className="m-2 inline-block">
                      <HiddenField
                        id={`${meal.id}-feedback`}
                        name="rating"
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          track(events.USER_TAPS_RATE_MEAL, {
                            meal_id: meal.id,
                          })

                          handleChange(e)
                          onReviewStarted()
                        }}
                        type="radio"
                        value={feedbackRating}
                      />
                      <ChipLabel
                        checked={values.rating === feedbackRating}
                        labelFor={`${meal.id}-feedback`}
                      >
                        I have feedback
                      </ChipLabel>
                    </div>
                  </div>
                </>
              )}

              {hasLoadMealReviewChipsError ? (
                <APIErrorDisplay
                  error={loadMealReviewChipsError}
                  errorCodeMessageMap={LOAD_REIVEW_CHIPS_ERRORS}
                />
              ) : (
                feedbackChips.length > 0 &&
                values.rating === feedbackRating && (
                  <Collapsible defaultOpen={true}>
                    {({ open }: { open: boolean }) => {
                      return (
                        <CollapsibleContent open={open}>
                          <div className="text-center">
                            <h2 className="mb-2 text-k/20_125">
                              What needed improvement?
                            </h2>
                            <p className="mb-4 text-k/14_120 text-grey-9">
                              Tap all that apply
                            </p>

                            <div>
                              {feedbackChips.map((chipSet, i) => {
                                return (
                                  <Fragment key={`${meal.id}-chipset-${i}`}>
                                    <div>
                                      {orderBy(chipSet, 'displayOrder').map(
                                        (chip) => {
                                          return (
                                            <div
                                              key={`${meal.id}-${chip.id}`}
                                              className="m-2 inline-block"
                                            >
                                              <HiddenField
                                                id={`${meal.id}-${chip.id}`}
                                                name="selectedChipIDs"
                                                onChange={(
                                                  e: React.FormEvent<HTMLInputElement>
                                                ) => {
                                                  track(events.TAPS_CHIP, {
                                                    review_chip_id: chip.id,
                                                  })

                                                  handleChange(e)
                                                }}
                                                type="checkbox"
                                                value={chip.id}
                                              />
                                              <ChipLabel
                                                checked={values.selectedChipIDs.includes(
                                                  chip.id
                                                )}
                                                labelFor={`${meal.id}-${chip.id}`}
                                              >
                                                {chip.name}
                                              </ChipLabel>
                                            </div>
                                          )
                                        }
                                      )}
                                    </div>
                                    {i !== feedbackChips.length - 1 && (
                                      <div className="mx-auto my-2 w-14 border-t border-grey-3" />
                                    )}
                                  </Fragment>
                                )
                              })}
                            </div>

                            {values.selectedChipIDs.length > 0 && (
                              <Collapsible defaultOpen={true}>
                                {({ open }: { open: boolean }) => {
                                  return (
                                    <CollapsibleContent open={open}>
                                      <div className="mt-4">
                                        <Textarea
                                          id={`${meal.id}-comment`}
                                          name="comment"
                                          placeholder="Add a comment"
                                        />
                                      </div>
                                    </CollapsibleContent>
                                  )
                                }}
                              </Collapsible>
                            )}

                            <div className="mt-4 space-y-4">
                              {hasCreateMealReviewError && (
                                <APIErrorDisplay
                                  error={createMealReviewError}
                                  errorCodeMessageMap={CREATE_REVIEW_ERRORS}
                                />
                              )}

                              <div className="flex justify-center">
                                <ButtonLoading
                                  disabled={values.selectedChipIDs.length === 0}
                                  isLoading={isCreatingMealReview}
                                  name={`${meal.id}-submit`}
                                  size="large"
                                  type="submit"
                                >
                                  Submit Rating
                                </ButtonLoading>
                              </div>
                            </div>
                          </div>
                        </CollapsibleContent>
                      )
                    }}
                  </Collapsible>
                )
              )}
            </motion.div>
          </Form>
        )
      }}
    </Formik>
  )
}

const ChipLabel = ({
  checked,
  children,
  labelFor,
}: {
  checked: boolean
  children: ReactNode
  labelFor: string
}) => {
  return (
    <label
      className={clsx(
        'inline-block cursor-pointer rounded-full border border-grey-7 px-3 py-2 text-k/12_120 transition-colors',
        !checked && 'hover:border-black hover:bg-grey-3',
        checked && 'border-orange-1 bg-white'
      )}
      htmlFor={labelFor}
    >
      {children}
    </label>
  )
}

const HiddenField = (
  props: InputHTMLAttributes<HTMLInputElement> & { name: string }
) => {
  const [field] = useField(props.name)

  return (
    <input
      className="absolute h-0 w-0 cursor-pointer opacity-0"
      {...field}
      {...props}
    />
  )
}

const Textarea = (
  props: TextareaHTMLAttributes<HTMLTextAreaElement> & { name: string }
) => {
  const [field] = useField(props.name)

  return (
    <textarea
      className="w-full rounded-lg border border-grey-4 bg-grey-0 p-4 text-k/14_120"
      {...field}
      {...props}
    />
  )
}
