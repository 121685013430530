import { Button, MealWithExtra } from '@tovala/component-library'
import { compact, flatMap, isArray, isEmpty, some, throttle } from 'lodash-es'
import { clsx } from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import {
  MealSummary,
  MealTag,
  MenuListingsLayout,
  useAddListingSelection,
  useDeleteListingSelection,
  useListingSelections,
  useMealReviewSummaries,
} from '@tovala/browser-apis-combinedapi'
import { ReactNode, useEffect, useState } from 'react'
import { useAddOns } from '@tovala/browser-apis-cdn'
import { useMachine } from '@xstate/react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'

import { AnalyticsEvent, events, sourceIDs } from '../../../analytics/events'
import { createMenuMachine } from './machines/menu'
import {
  getFilteredAllergenMealSelections,
  getFilteredComponentsCounts,
  getFilteredMenuComponents,
} from 'utils/menus'
import {
  getMealImageURL,
  getMealSelectionIDs,
  getMealSuggestionsForMenu,
  getMealSurcharge,
  getTagBST,
  getTagOilWarning,
  hasViewedOilWarning,
} from 'utils/meals'
import { getMenuMealComponents } from 'utils/menuComponents'
import { getMostRecentTermMealsToRate, getTermsToRate } from 'utils/terms'
import { getSubscriptionPreferences } from 'utils/subscriptions'
import { isCombinedAPIResponseError } from 'utils/api'
import {
  MenuComponentsStandardized,
  MenuComponentStandardizedMeal,
  MenuComponentStandardizedMealWithExtra,
  MenuComponentStandardizedMealCarousel,
  MenuComponentStandardizedTwoMealPicker,
  MenuComponentStandardizedTwoMealPickerMeal,
  SelectedMealFilters,
  UserTerm,
} from 'types/internal'
import { storageAvailable } from 'utils/storageAvailable'
import { track } from 'utils/analytics'
import { wrapWithContactSupportTeam } from 'utils/errors'

import {
  useAddMealSelection,
  useDeleteMealSelection,
  useGetMealFilters,
  useMealsInFilters,
  useMealSugggestions,
  useMealSummaries,
  useUpdateMealSelectionOption,
} from 'hooks/combinedAPI/meals'
import { useHasPastOrders, useOrderHistory } from 'hooks/orderHistory'
import { useDetailsDialog } from 'hooks/mealDetails'
import { useMenuComponents } from 'hooks/menus'
import { useCustomer } from 'hooks/combinedAPI/customers'
import { useFeatures } from 'contexts/features'
import { useFirstOvenCookHistory } from 'hooks/combinedAPI/ovens'
import { useUpsertUserTermStatus } from 'hooks/combinedAPI/termStatus'
import { useToast } from 'contexts/toast'
import { useUpdatePlanPreferences } from 'hooks/combinedAPI/subscriptions'
import { useUser } from 'contexts/user'
import { useVariantByScreenSize } from 'hooks/variantByScreenSize'
import BackgroundImageHeader from './BackgroundImageHeader'
import ConfirmationDialog, {
  ConfirmationBody,
  ConfirmationButtons,
  ConfirmationHeader,
} from 'components/common/ConfirmationDialog'
import ExtrasAllergenNote from './ExtrasAllergenNote'
import ExtrasGrid from './ExtrasGrid'
import ExtrasMenuHeader from './ExtrasMenuHeader'
import ListingDetailsDialog from './ExtraDetailsDialog'
import Meal from './Meal'
import MealCarousel from './MealCarousel'
import MealDetailsDialog from './MealDetailsDialog'
import MealExtraDisabledConfirmationDialog from './MealExtraDisabledConfirmationDialog'
import MealsMenuHeader from './MealsMenuHeader'
import MealSelectionsConfirmationDialog, {
  AddOnPrompt,
  WantMoreMealsPrompt,
} from './MealSelectionsConfirmationDialog'
import MealSuggestions from './MealSuggestions'
import MenuFeedback from './MenuFeedback'
import MenuFilters from './MenuFilters'
import MenuFiltersConfirmationDialog from './MenuFiltersConfirmationDialog'
import MenuFixedBottomContainer from './MenuFixedBottomContainer'
import MenuGridLayout from './MenuGridLayout'
import OrderStatus from './OrderStatus'
import OrderSummarySidebar, {
  OrderSummaryExtra,
  OrderSummaryMeal,
  useOrderSummarySidebar,
} from './OrderSummarySidebar'
import QuantityStepper from 'components/common/QuantityStepper'
import RateMealsCarousel from '../rateMeals/RateMealsCarousel'
import RateMealsPrompt from '../rateMeals/RateMealsPrompt'
import SheetTrayConfirmation from './SheetTrayConfirmation'
import SpecialEventBanner from './SpecialEventBanner'
import SurchargeConfirmation from './SurchargeConfirmation'
import TextImageStack from './TextImageStack'
import TwoMealPicker from './TwoMealPicker'
import NoListingFiltersConfirmationDialog from './NoListingFiltersConfirmationDialog'

type ConfirmationDialogData =
  | {
      meal: MealSummary
      onConfirm(): void
      type: 'blackSheetTray' | 'oilWarning' | 'releaseFromCart' | 'surcharge'
    }
  | { type: 'filteredAllergens' }
  | { type: 'mealExtraDisabled' }
  | { onConfirm(): void; type: 'noListingFilters' }
  | { openSource?: 'orderSummary'; type: 'mealsSelected' }

const Menu = ({
  menuListingsLayout,
  onEditTermStatus,
  selectedUserTerm,
}: {
  menuListingsLayout: MenuListingsLayout
  onEditTermStatus(selectedUserTerm: UserTerm): void
  selectedUserTerm: UserTerm
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { openToast } = useToast()

  const features = useFeatures()
  const { user } = useUser()

  const { components, isLoading: isLoadingComponents } = useMenuComponents({
    subTermID: selectedUserTerm.selectedSubTermID,
  })

  const { data: customer } = useCustomer({
    customerID: user.subscription.customerID,
    userID: user.id,
  })

  const hasPaymentSource = !!(customer && customer.sources.data.length > 0)

  const isSubscriptionActive = user.subscription.status === 'active'

  const { data: ovenCookHistory = [] } = useFirstOvenCookHistory({
    userID: user.id,
  })

  const hasPastOrders = useHasPastOrders({ userID: user.id })

  const { data: mealReviewSummaries } = useMealReviewSummaries({
    userID: user.id,
  })

  const { data: mealSuggestions } = useMealSugggestions({
    userID: user.id,
  })

  const { data: mealSummaries = [], isLoading: isLoadingMealSummaries } =
    useMealSummaries({
      subTermID: selectedUserTerm.selectedSubTermID,
    })

  const { closeDetailsDialog, detailsType, listing, meal, openDetailsDialog } =
    useDetailsDialog({
      listings: flatMap(menuListingsLayout.sections, (section) => {
        return section ? section.listings : []
      }),
      termID: selectedUserTerm.termID,
    })

  const { receipts, totalOrderCount } = useOrderHistory({ userID: user.id })

  const mostRecentTermMealsToRate = getMostRecentTermMealsToRate({
    termsToRate: getTermsToRate({
      cookHistory: ovenCookHistory,
      mealReviewSummaries,
      orderHistoryReceipts: receipts,
    }),
  })

  const mealSuggestionsForMenu = getMealSuggestionsForMenu({
    mealSuggestions,
    mealSummaries,
  })

  const menuHasListings =
    menuListingsLayout.sections &&
    menuListingsLayout.sections.some((section) => section?.listings.length > 0)
  const selectedMenuID = selectedUserTerm.selectedSubTerm?.mainMenu.id

  const { data: listingSelectionsResponse } = useListingSelections({
    menuID: selectedMenuID,
    userID: user.id,
  })
  const listingsSelections = listingSelectionsResponse?.listingsSelected ?? []
  const listingsTotalPriceCents =
    listingSelectionsResponse?.estimatedTotalCents ?? 0
  const listingsSelectionIDs = listingsSelections.map((listing) => listing.id)
  const selectedListings = flatMap(menuListingsLayout.sections, (section) => {
    return section ? section.listings : []
  }).filter((listing) => {
    return listingsSelectionIDs.includes(listing.id)
  })

  const [confirmationDialogData, setConfirmationDialogData] =
    useState<ConfirmationDialogData | null>(null)
  const [selectedMealFilters, setSelectedMealFilters] =
    useState<SelectedMealFilters>(() => {
      if (storageAvailable('localStorage')) {
        const mealFilters = localStorage.getItem('mealFilters')

        return mealFilters ? JSON.parse(mealFilters) : {}
      }

      return {}
    })
  const [showRateMealsCarousel, setShowRateMealsCarousel] = useState(false)
  const [showRateMealsPrompt, setShowRateMealsPrompt] = useState(true)

  const { closeOrderSummary, openOrderSummary, orderSummarySidebarStatus } =
    useOrderSummarySidebar({
      hasSelectedAllMeals: selectedUserTerm.hasSelectedAllMeals,
    })

  const [state, send] = useMachine(
    () => {
      return createMenuMachine({
        termID: selectedUserTerm.termID,
        userID: user.id,
      })
    },
    {
      actions: {
        showAddListingError: (_ctx, event) => {
          const err = event.data

          if (err instanceof Error && isCombinedAPIResponseError(err)) {
            if (err.response?.data.message === 'listing is sold out') {
              openToast({
                goodErrorMessaging: {
                  wayOut: null,
                  whatHappened: 'Extra is sold out',
                  why: "We couldn't add this extra because it's sold out.",
                },
                type: 'error',
              })

              return
            }
          }

          openToast({
            goodErrorMessaging: {
              helpToFix: 'Please try adding the extra again.',
              whatHappened: 'Unable to add extra',
              why: "We couldn't add this extra due to a technical issue on our end.",
            },
            type: 'error',
          })
        },
        showAddMealError: (_ctx, event) => {
          const err = event.data

          if (err instanceof Error && isCombinedAPIResponseError(err)) {
            if (err.response?.data.message === 'BlackSheetTrayRequired') {
              openToast({
                goodErrorMessaging: {
                  helpToFix: 'Please try again.',
                  wayOut: wrapWithContactSupportTeam(
                    'If you need further assistance'
                  ),
                  whatHappened: 'Unable to add meal',
                  why: "We couldn't add this meal because it requires a Tovala Sheet Tray and your account does not indicate you have one.",
                },
                type: 'error',
              })

              return
            } else if (err.response?.data.message === 'MealSoldOut') {
              openToast({
                goodErrorMessaging: {
                  wayOut: null,
                  whatHappened: 'Meal is sold out',
                  why: "We couldn't add this meal because it's sold out.",
                },
                type: 'error',
              })

              return
            } else if (err.response?.data.message === 'UserSkippedTerm') {
              openToast({
                goodErrorMessaging: {
                  wayOut:
                    'Please unskip this week and try adding the meal again.',
                  whatHappened: 'Week Skipped',
                  why: "We couldn't add this meal because you've skipped this week.",
                },
                type: 'error',
              })

              return
            }
          }

          openToast({
            goodErrorMessaging: {
              helpToFix: 'Please try adding the meal again.',
              whatHappened: 'Unable to add meal',
              why: "We couldn't add this meal due to a technical issue on our end.",
            },
            type: 'error',
          })
        },
        showAddOptionError: (_ctx, event) => {
          const err = event.data

          if (err instanceof Error && isCombinedAPIResponseError(err)) {
            if (err.response?.data.message === 'BlackSheetTrayRequired') {
              openToast({
                goodErrorMessaging: {
                  helpToFix: 'Please try again.',
                  wayOut: wrapWithContactSupportTeam(
                    'If you need further assistance'
                  ),
                  whatHappened: 'Unable to add meal',
                  why: "We couldn't add this extra because it requires a Tovala Sheet Tray and your account does not indicate you have one.",
                },
                type: 'error',
              })

              return
            } else if (err.response?.data.message === 'MealSoldOut') {
              openToast({
                goodErrorMessaging: {
                  wayOut: null,
                  whatHappened: 'Extra is sold out',
                  why: "We couldn't add this extra because it's sold out.",
                },
                type: 'error',
              })

              return
            } else if (err.response?.data.message === 'UserSkippedTerm') {
              openToast({
                goodErrorMessaging: {
                  wayOut:
                    'Please unskip this week and try adding the extra again.',
                  whatHappened: 'Week Skipped',
                  why: "We couldn't add this extra because you've skipped this week.",
                },
                type: 'error',
              })

              return
            }
          }
          openToast({
            goodErrorMessaging: {
              helpToFix: 'Please try adding the extra again.',
              whatHappened: 'Unable to add extra',
              why: "We couldn't add this extra due to a technical issue on our end.",
            },
            type: 'error',
          })
        },
        showRemoveListingError: () => {
          openToast({
            goodErrorMessaging: {
              helpToFix: 'Please try removing the extra again.',
              whatHappened: 'Unable to remove extra',
              why: "We couldn't remove this extra due to a technical issue on our end.",
            },
            type: 'error',
          })
        },
        showRemoveMealError: () => {
          openToast({
            goodErrorMessaging: {
              helpToFix: 'Please try removing the meal again.',
              whatHappened: 'Unable to remove meal',
              why: "We couldn't remove this meal due to a technical issue on our end.",
            },
            type: 'error',
          })
        },
        showRemoveOptionError: () => {
          openToast({
            goodErrorMessaging: {
              helpToFix: 'Please try removing the meal extra.',
              whatHappened: 'Unable to remove extra',
              why: "We couldn't remove this extra due to a technical issue on our end.",
            },
            type: 'error',
          })
        },
        trackConfirmedUpgrade: () => {
          track(events.USER_CONFIRMED_UPSELL)
        },
        trackFinishedMealSelection: (ctx) => {
          track(events.FINISHES_MEAL_SELECTION, {
            term_id: ctx.termID,
          })
        },
        trackMealAdded: (ctx, event) => {
          const { addMealMeta, termID } = ctx
          const { termStatus } = event.data

          if (addMealMeta) {
            trackMealModified({
              maxSelections: termStatus.subscriptionType?.maxSelections ?? 0,
              mealID: addMealMeta.mealID,
              mealSelectionEvent: addMealMeta.mealSelectionEvent,
              numSelections: termStatus.mealSelections.length,
              termID,
            })
          }
        },
        trackMealOptionAdded: (ctx) => {
          const { termID, addMealOptionMeta } = ctx

          if (addMealOptionMeta) {
            track(events.TAPPED_ADD_MEAL_EXTRA, {
              meal_id: addMealOptionMeta.mealOption,
              term_id: termID,
            })
          }
        },
        trackMealOptionRemoved: (ctx) => {
          const { termID, removeMealOptionMeta } = ctx

          if (removeMealOptionMeta) {
            track(events.TAPPED_REMOVE_MEAL_EXTRA, {
              meal_id: removeMealOptionMeta.mealOption,
              term_id: termID,
            })
          }
        },
        trackMealRemoved: (ctx, event) => {
          const { removeMealMeta, termID } = ctx

          if (removeMealMeta) {
            trackMealModified({
              maxSelections: removeMealMeta.maxSelections,
              mealID: removeMealMeta.mealID,
              mealSelectionEvent: removeMealMeta.mealSelectionEvent,
              numSelections: event.data.mealSelections.length,
              termID,
            })
          }
        },
      },
      guards: {
        hasFewerMealsThanNextPlanMax: () => {
          return (
            !!selectedUserTerm.nextLargestSubscriptionType &&
            selectedUserTerm.nextLargestSubscriptionType.maxSelections >
              mealSelectionIDs.length
          )
        },
        hasFewerThanMaxMeals: () => {
          return selectedUserTerm.nextLargestSubscriptionType !== null
        },
        hasMaxMeals: (_ctx, event) => {
          const termStatus = event.data.termStatus
          const numMealsToAdd = termStatus.subscriptionType
            ? termStatus.subscriptionType.maxSelections -
              termStatus.mealSelections.length
            : 0

          return numMealsToAdd === 0
        },
        hasSelectedAllMeals: () => {
          return selectedUserTerm.hasSelectedAllMeals
        },
        hasSelectionsOfMeal: (_ctx, event) => {
          const mealID = event.mealID
          return selectedUserTerm.mealSelections
            .map((mealSelection) => mealSelection.mealID)
            .includes(mealID)
        },
        hasSelectionsOfMealOption: (_ctx, event) => {
          const mealID = Number(event.mealOption)
          return selectedUserTerm.mealSelections
            .map((mealSelection) => mealSelection.mealID)
            .includes(mealID)
        },
      },
      services: {
        addListing: (ctx, event) => {
          if (!selectedMenuID) {
            throw new Error('Cannot select an extra without a menu ID.')
          }

          const { userID } = ctx

          return addListingSelectionAsync({
            data: {
              listingID: event.listingID,
            },
            listingID: event.listingID,
            menuID: selectedMenuID,
            userID,
          })
        },
        addMeal: (ctx, event) => {
          const { termID, userID } = ctx

          return addMealSelectionAsync({
            data: {
              mealid: event.mealID,
              termid: termID,
              userid: userID,
            },
            userID,
          })
        },
        addMealOption: (ctx, event) => {
          const { userID } = ctx

          return updateMealSelectionOptionAsync({
            data: {
              mealID: event.mealID,
              mealOption: event.mealOption,
            },
            subTermID: selectedUserTerm.selectedSubTermID,
            userID,
          })
        },
        removeListing: (ctx, event) => {
          if (!selectedMenuID) {
            throw new Error('Cannot select an extra without a menu ID.')
          }

          const { userID } = ctx

          return deleteListingSelectionAsync({
            listingID: event.listingID,
            menuID: selectedMenuID,
            userID,
          })
        },
        removeMeal: (ctx, event) => {
          const { termID, userID } = ctx

          if (!event.selectionID) {
            throw new Error('No meal selection ID specified for removal.')
          }

          return deleteMealSelectionAsync({
            selectionID: event.selectionID,
            termID,
            userID,
          })
        },
        removeMealOption: (ctx, event) => {
          const { userID } = ctx

          return updateMealSelectionOptionAsync({
            data: {
              mealID: event.mealID,
              mealOption: null,
              currentMealOption: event.mealOption,
            },
            subTermID: selectedUserTerm.selectedSubTermID,
            userID,
          })
        },
        updateTermStatus: async () => {
          if (!selectedUserTerm.nextLargestSubscriptionType) {
            throw new Error('No next subscription type for upgrade.')
          }

          return await upsertUserTermStatus({
            data: {
              notes: '',
              subscriptionTypeID:
                selectedUserTerm.nextLargestSubscriptionType.id,
              subTermID: selectedUserTerm.selectedSubTermID,
              termID: selectedUserTerm.termID,
              userID: user.id,
            },
            selectedUserTermID: selectedUserTerm.id,
            userID: user.id,
          })
        },
        savePendingMeal: async (ctx) => {
          return await addMealSelectionAsync({
            data: {
              mealid: ctx.pendingMealIDs[ctx.nextPendingMealIndex],
              termid: selectedUserTerm.termID,
              userid: user.id,
            },
            userID: user.id,
          })
        },
      },
    }
  )

  const { nextMaxSelections, pendingMealIDs } = state.context
  const pendingMealSelections = compact(
    pendingMealIDs.map((mealID) => {
      return mealSummaries.find(({ id }) => id === mealID)
    })
  )
  const mealSelectionIDs = getMealSelectionIDs({
    pendingMealSelections,
    selectedUserTerm,
  })
  const selectedMeals = mealSummaries.filter((meal) => {
    return mealSelectionIDs.includes(meal.id)
  })

  const {
    error: upsertUserTermStatusError,
    isError: hasUpsertUserTermStatusError,
    mutateAsync: upsertUserTermStatus,
  } = useUpsertUserTermStatus()

  const { mutateAsync: addMealSelectionAsync } = useAddMealSelection()

  const { mutateAsync: deleteMealSelectionAsync } = useDeleteMealSelection()

  const { mutateAsync: updateMealSelectionOptionAsync } =
    useUpdateMealSelectionOption()

  const { mutateAsync: addListingSelectionAsync } = useAddListingSelection()

  const { mutateAsync: deleteListingSelectionAsync } =
    useDeleteListingSelection()

  const showOrderUpgrade = state.matches('upgrading')
  const isUpgrading = state.matches('upgrading.savingUpgrades')
  const isUpdatingListingSelections =
    state.matches('addingListing') || state.matches('removingListing')
  const isUpdatingMealSelections =
    state.matches('addingMeal') ||
    state.matches('addingMealOption') ||
    state.matches('removingMeal') ||
    state.matches('removingMealOption') ||
    state.matches('upgrading.removingMeal')
  const canDecrementListing = !isUpdatingListingSelections
  const canDecrementMeal = !isUpdatingMealSelections
  const canAddMoreMealsForUpgrade =
    !!selectedUserTerm.nextLargestSubscriptionType &&
    mealSelectionIDs.length <
      selectedUserTerm.nextLargestSubscriptionType.maxSelections
  const canIncrementListing = !isUpdatingListingSelections
  const canIncrementMeal =
    (!selectedUserTerm.hasSelectedAllMeals || canAddMoreMealsForUpgrade) &&
    !isUpdatingMealSelections

  const { data: mealFilters = [], isLoading: isLoadingMealFilters } =
    useGetMealFilters()
  const { data: mealsInFilters = [], isLoading: isLoadingMealsInFilters } =
    useMealsInFilters({
      subTermID: selectedUserTerm.selectedSubTermID,
    })

  const handleMealSelectionConfirmationDialogs = async ({
    onConfirm,
    meal,
  }: {
    onConfirm(): void
    meal: MealSummary
  }) => {
    const oilWarning = getTagOilWarning({ meal })
    const shouldShowOilWarning = oilWarning && !hasViewedOilWarning(meal)

    if (
      features.showSurchargePrompt &&
      meal.surchargeCents > 0 &&
      !planPreferences.premiumMealsOk
    ) {
      setConfirmationDialogData({ meal, onConfirm, type: 'surcharge' })
      return
    }

    if (getTagBST({ meal }) && !planPreferences.hasBlackSheetTray) {
      // If user is a new customer, skip showing the confirmation since they'll have a tray
      // that came with their oven.
      if (!hasPastOrders) {
        /* Requires Cooking Oil warning is appended to sheet tray confirmation, but if
          we're not showing the sheet tray confirmation, show the oil warning instead */
        if (shouldShowOilWarning) {
          setConfirmationDialogData({ meal, onConfirm, type: 'oilWarning' })
          return
        }

        const updatedPreferences = { ...planPreferences }
        updatedPreferences.hasBlackSheetTray = true

        try {
          await updatePlanPreferences({
            data: updatedPreferences,
            userID: user.id,
          })
        } catch (err) {
          // We fail silently here since updating the plan preferences to indicate
          // the user has a Tovala sheet tray is not a crucial workflow update and
          // can be done at a later time.
        } finally {
          onConfirm()
        }
        return
      }

      setConfirmationDialogData({ meal, onConfirm, type: 'blackSheetTray' })
      return
    }

    if (shouldShowOilWarning) {
      setConfirmationDialogData({ meal, onConfirm, type: 'oilWarning' })
      return
    }

    onConfirm()
  }

  const incrementListing = ({ listingID }: { listingID: string }) => {
    send({
      listingID,
      type: 'ADD_LISTING',
    })
  }

  const onClickIncrementListing = ({
    listingID,
    onClickEventSourceID = sourceIDs.EXTRAS_SCREEN,
  }: {
    listingID: string
    onClickEventSourceID?: 'extras_screen' | 'order_summary'
  }) => {
    track(events.DID_TAP_EXTRA_INCREMENT, {
      listing_id: listingID,
      source_id: onClickEventSourceID,
    })

    let extrasNotFilteredViewed: string | null = null

    if (storageAvailable('localStorage')) {
      extrasNotFilteredViewed = localStorage.getItem('extrasNotFilteredViewed')
    }

    if (!isEmpty(selectedMealFilters) && !extrasNotFilteredViewed) {
      if (storageAvailable('localStorage')) {
        localStorage.setItem('extrasNotFilteredViewed', 'true')
      }

      setConfirmationDialogData({
        onConfirm: () => {
          incrementListing({ listingID })
          setConfirmationDialogData(null)
        },
        type: 'noListingFilters',
      })
    } else {
      incrementListing({ listingID })
    }
  }

  const incrementMeal = ({
    meal,
    mealSelectionEvent = events.MODIFY_MEAL_SELECTION,
  }: {
    meal: MealSummary
    mealSelectionEvent?: AnalyticsEvent
  }) => {
    send({
      mealID: meal.id,
      mealSelectionEvent,
      nextMaxSelections:
        selectedUserTerm.nextLargestSubscriptionType?.maxSelections,
      type: 'ADD_MEAL',
    })
  }

  const onClickIncrementMeal = async ({
    meal,
    mealSelectionEvent = events.MODIFY_MEAL_SELECTION,
    onClickEventSourceID = sourceIDs.MENU_TERM,
  }: {
    meal: MealSummary
    mealSelectionEvent?: AnalyticsEvent
    onClickEventSourceID?: 'menu_term' | 'order_summary'
  }) => {
    track(events.DID_TAP_MEAL_INCREMENT, {
      meal_id: meal.id,
      source_id: onClickEventSourceID,
      term_id: selectedUserTerm.termID,
    })

    handleMealSelectionConfirmationDialogs({
      meal,
      onConfirm: () => {
        incrementMeal({ meal, mealSelectionEvent })
      },
    })
  }

  const deselectMealOption = ({
    meal,
    mealOption,
  }: {
    meal: MealSummary
    mealOption: string
  }) => {
    send({
      mealID: meal.id,
      mealOption,
      type: 'REMOVE_MEAL_OPTION',
    })
  }

  const onClickDeselectMealOption = ({
    meal,
    mealOption,
  }: {
    meal: MealSummary
    mealOption: MealSummary
  }) => {
    deselectMealOption({ meal, mealOption: `${mealOption.id}` })
  }

  const selectMealOption = ({
    meal,
    mealOption,
  }: {
    meal: MealSummary
    mealOption: string
  }) => {
    send({
      mealID: meal.id,
      mealOption,
      type: 'ADD_MEAL_OPTION',
    })
  }

  const onClickSelectMealOption = ({
    meal,
    mealOption,
  }: {
    meal: MealSummary
    mealOption: MealSummary
  }) => {
    handleMealSelectionConfirmationDialogs({
      meal: mealOption,
      onConfirm: () => {
        selectMealOption({ meal, mealOption: `${mealOption.id}` })
      },
    })
  }

  const planPreferences = getSubscriptionPreferences({ user })
  const { mutateAsync: updatePlanPreferences } = useUpdatePlanPreferences()

  const decrementListing = ({ listingID }: { listingID: string }) => {
    send({
      listingID,
      type: 'REMOVE_LISTING',
    })
  }

  const onClickDecrementListing = ({
    listingID,
    onClickEventSourceID = sourceIDs.EXTRAS_SCREEN,
  }: {
    listingID: string
    onClickEventSourceID?: 'extras_screen' | 'order_summary'
  }) => {
    track(events.DID_TAP_EXTRA_DECREMENT, {
      listing_id: listingID,
      source_id: onClickEventSourceID,
    })

    decrementListing({ listingID })
  }

  const decrementMeal = ({
    mealID,
    mealSelectionEvent = events.MODIFY_MEAL_SELECTION,
  }: {
    mealID: number
    mealSelectionEvent?: AnalyticsEvent
  }) => {
    const quantity = mealSelectionIDs.filter((x) => x === mealID).length

    if (selectedUserTerm.subscriptionType && quantity > 0) {
      const selections = [...selectedUserTerm.mealSelections].sort((a, b) => {
        return +new Date(b.created) - +new Date(a.created)
      })
      const mealSelectionToDelete = selections.find(
        (selection) => selection.mealID === mealID
      )

      send({
        maxSelections: selectedUserTerm.subscriptionType.maxSelections,
        mealID,
        mealSelectionEvent,
        selectionID: mealSelectionToDelete?.id,
        type: 'REMOVE_MEAL',
      })
    }
  }

  const onClickDecrementMeal = ({
    mealID,
    mealSelectionEvent = events.MODIFY_MEAL_SELECTION,
    onClickEventSourceID = sourceIDs.MENU_TERM,
  }: {
    mealID: number
    mealSelectionEvent?: AnalyticsEvent
    onClickEventSourceID?: 'menu_term' | 'order_summary'
  }) => {
    track(events.DID_TAP_MEAL_DECREMENT, {
      meal_id: mealID,
      source_id: onClickEventSourceID,
      term_id: selectedUserTerm.termID,
    })

    decrementMeal({ mealID, mealSelectionEvent })
  }

  // Outside actions can cause the user to have all their meals selected, such as
  // editing their subscription type to be a smaller number of meals.
  useEffect(() => {
    if (selectedUserTerm.hasSelectedAllMeals) {
      send({ type: 'ALL_MEALS_SELECTED' })
    }
  }, [send, selectedUserTerm.hasSelectedAllMeals])

  const onChangeSelectedMealFilters = (filters: SelectedMealFilters) => {
    setSelectedMealFilters(filters)

    if (storageAvailable('localStorage')) {
      if (isEmpty(filters)) {
        localStorage.removeItem('mealFilters')
      } else {
        localStorage.setItem('mealFilters', JSON.stringify(filters))
      }
    }

    handleMealSelectionsOutsideFilters({ selectedMealFilters: filters })
  }

  const handleMealSelectionsOutsideFilters = ({
    selectedMealFilters,
  }: {
    selectedMealFilters: SelectedMealFilters
  }) => {
    const filteredAllergenMealSelections = getFilteredAllergenMealSelections({
      mealSelections: selectedUserTerm.mealSelections,
      mealsInFilters,
      selectedMealFilters,
    })

    if (filteredAllergenMealSelections.length > 0) {
      setConfirmationDialogData({
        type: 'filteredAllergens',
      })
    }
  }

  useTrackScrollDepth({
    maxSelections: selectedUserTerm.subscriptionType?.maxSelections,
    numMeals: mealSummaries.length,
    numSelections: selectedUserTerm.mealSelections.length,
    termID: selectedUserTerm.termID,
  })

  const resolvedMenuComponents = getMenuMealComponents({
    defaultUIMealWithOneSwap: features.defaultUIMealWithOneSwap,
    mealSwaps: selectedUserTerm.mealSwaps,
    meals: mealSummaries,
    menuComponents: components,
    specialEvent:
      selectedUserTerm.specialEvent ||
      selectedUserTerm.selectedSubTerm?.specialEvent,
    suggestions: mealSuggestionsForMenu,
  })

  const { everythingElse, filteredComponents, mealIDsInFilters } =
    getFilteredMenuComponents({
      allMealIDs: mealSummaries.map(({ id }) => id),
      selectedMealFilters,
      mealFilters,
      mealsInFilters,
      menuComponents: resolvedMenuComponents,
    })

  const numFilteredResults = getFilteredComponentsCounts({
    filteredComponents,
  })

  // "selectedMealFilters" can be an empty object or it can be an object
  // like { "Dietary Preferences": boolean | string[] }.
  const hasSelectedFilters =
    !isEmpty(selectedMealFilters) &&
    some(
      selectedMealFilters,
      (filterKeys) => typeof filterKeys !== 'boolean' && filterKeys.length > 0
    )

  const canModifySelections = !selectedUserTerm.isSkipped

  const commonMenuComponentGridProps = {
    canDecrement: canDecrementMeal,
    canIncrement: canIncrementMeal,
    canModifySelections,
    mealIDsInFilters,
    mealSelectionIDs,
    onClickDecrement: onClickDecrementMeal,
    onClickDeselectMealOption,
    onClickIncrement: onClickIncrementMeal,
    onClickMeal: (mealID: number) => {
      openDetailsDialog({ type: 'meal', id: mealID })
    },
    onClickSelectMealOption,
    selectedUserTerm,
    setConfirmationDialogData,
  }

  const commonOrderStatusProps = {
    closeOrderSummary,
    isSelectingListings: location.pathname.includes('extras'),
    isUpgrading,
    listingsSelections,
    nextMaxSelections,
    onClickContinue: (openSource: 'orderSummary' | undefined = undefined) => {
      if (menuHasListings) {
        navigate(`/menu/${selectedUserTerm.termID}/extras`)
      } else {
        setConfirmationDialogData({ openSource, type: 'mealsSelected' })
      }
    },
    onClickDone: (openSource: 'orderSummary' | undefined = undefined) => {
      if (selectedUserTerm.hasSelectedAllMeals) {
        setConfirmationDialogData({ openSource, type: 'mealsSelected' })
      } else {
        window.setTimeout(() => {
          navigate(`/menu/${selectedUserTerm.termID}`)
        }, 500)
      }
    },
    onClickSkipAdjust: () => {
      onEditTermStatus(selectedUserTerm)
    },
    onConfirmUpgrade: () => {
      send({ type: 'SAVE_PENDING_MEALS' })
    },
    openOrderSummary: () => {
      openOrderSummary()
    },
    orderSummarySidebarStatus,
    pendingMealSelections,
    selectedUserTerm,
    showOrderUpgrade,
    upgradeOrderError: hasUpsertUserTermStatusError
      ? upsertUserTermStatusError
      : null,
  }

  const isLoadingNeededFilters =
    hasSelectedFilters && (isLoadingMealFilters || isLoadingMealsInFilters)

  // If the user has selected filters, we want to wait until the filter data
  // loads so we avoid a flash of inaccurate meal displays (in the case where
  // meal summaries load before filter data does).
  const canDisplayMenu =
    selectedUserTerm.readyForView &&
    mealSummaries.length > 0 &&
    !isLoadingNeededFilters

  // Show Holiday bar if holiday term and no meals
  const showHolidayBar =
    // subterm must have a special event
    selectedUserTerm.specialEvent &&
    // particular subterm has no meals
    !selectedUserTerm.subTerms.find(
      (term) => term.subTermID === selectedUserTerm.selectedSubTermID
    )?.menus.length &&
    // make sure required data is actually loaded
    !isLoadingComponents &&
    !isLoadingMealSummaries &&
    // no menu components with type meal
    !components.filter((comp) => comp.type === 'meal').length &&
    // make sure menu data is loaded, but there are no meals
    selectedUserTerm.readyForView &&
    !isLoadingNeededFilters &&
    mealSummaries.length <= 0

  return (
    <div>
      {selectedUserTerm && (
        <div>
          <AnimatePresence initial={false}>
            <Routes>
              <Route
                element={
                  <div>
                    {selectedUserTerm && (
                      <div>
                        <MealsMenuHeader
                          onChangeSelectedMealFilters={
                            onChangeSelectedMealFilters
                          }
                          selectedMealFilters={selectedMealFilters}
                          selectedUserTerm={selectedUserTerm}
                          showOrderUpgrade={showOrderUpgrade}
                        />

                        {showHolidayBar && (
                          <div className="md:pt-4">
                            <div className="mx-auto max-w-menu lg:px-4 md:px-0">
                              <div className="space-y-10 md:space-y-6">
                                <div
                                  key={`salmonBar-abc`}
                                  className="col-span-2 md:col-span-1 md:px-4"
                                >
                                  <SpecialEventBanner>
                                    {selectedUserTerm.specialEvent}
                                  </SpecialEventBanner>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {canDisplayMenu && (
                          <div className="overflow-hidden">
                            <motion.div
                              key="meals"
                              animate={{ x: 0, opacity: 1 }}
                              exit={{ x: -300, opacity: 0 }}
                              initial={{ x: 300, opacity: 0 }}
                              transition={{
                                x: {
                                  type: 'spring',
                                  stiffness: 300,
                                  damping: 30,
                                },
                                opacity: { duration: 0.2 },
                              }}
                            >
                              <div className="pb-40 pt-6">
                                {hasSelectedFilters && (
                                  <FilterMealGroup>
                                    <div className="mx-auto max-w-menu lg:px-4 md:px-0">
                                      <div className="space-y-10 md:space-y-6">
                                        <FilterGroupTitle
                                          description={
                                            numFilteredResults === 0
                                              ? 'No meals match your filter criteria'
                                              : ''
                                          }
                                          filters={
                                            <MenuFilters
                                              onChangeSelectedMealFilters={
                                                onChangeSelectedMealFilters
                                              }
                                              renderButton={({
                                                openSidebar,
                                              }) => {
                                                return (
                                                  <Button
                                                    buttonStyle="stroke"
                                                    onClick={openSidebar}
                                                    size="small"
                                                  >
                                                    Edit Filter
                                                  </Button>
                                                )
                                              }}
                                              selectedMealFilters={
                                                selectedMealFilters
                                              }
                                              selectedUserTerm={
                                                selectedUserTerm
                                              }
                                            />
                                          }
                                          numResults={numFilteredResults}
                                          title="My Filtered Meals"
                                        />

                                        <MenuComponentsGrid
                                          {...commonMenuComponentGridProps}
                                          components={filteredComponents}
                                        />
                                      </div>
                                    </div>
                                  </FilterMealGroup>
                                )}
                                <FilterMealGroup>
                                  <div className="mx-auto max-w-menu lg:px-4 md:px-0">
                                    <div className="space-y-10 md:space-y-6">
                                      {hasSelectedFilters && (
                                        <FilterGroupTitle
                                          description="Meals outside your filter criteria"
                                          title="Everything Else"
                                        />
                                      )}
                                      <MenuComponentsGrid
                                        {...commonMenuComponentGridProps}
                                        components={everythingElse}
                                      />
                                    </div>
                                  </div>
                                </FilterMealGroup>
                              </div>
                            </motion.div>
                          </div>
                        )}
                        {!selectedUserTerm.readyForView && (
                          <div className="mx-auto max-w-menu text-center">
                            <h2 className="mb-4 text-k/36_110 md:mb-2 md:text-k/20_110">
                              Menu coming soon.
                              <br />
                              Stay tuned.
                            </h2>
                            <p className="mb-10 text-k/20_125 text-grey-10 md:mb-6 md:text-k/18_120 md:text-grey-8">
                              For new customers, we'll send you an email as soon
                              as the new menu is available so you can pick your
                              first meals!
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                }
                index
              />

              <Route
                element={
                  menuHasListings && selectedUserTerm.readyForView ? (
                    <div>
                      {selectedUserTerm && (
                        <div>
                          <ExtrasMenuHeader
                            selectedUserTerm={selectedUserTerm}
                          />

                          <div className="overflow-hidden">
                            <motion.div
                              key="extras"
                              animate={{ x: 0, opacity: 1 }}
                              exit={{ x: -300, opacity: 0 }}
                              initial={{ x: 300, opacity: 0 }}
                              transition={{
                                x: {
                                  type: 'spring',
                                  stiffness: 300,
                                  damping: 30,
                                },
                                opacity: { duration: 0.2 },
                              }}
                            >
                              <div className="pb-40 pt-6">
                                <ExtrasGrid
                                  canModifySelections={canModifySelections}
                                  disabledDecrement={!canDecrementListing}
                                  disabledIncrement={!canIncrementListing}
                                  listingsSelections={listingsSelections}
                                  menuListingsLayout={menuListingsLayout}
                                  onClickDecrement={onClickDecrementListing}
                                  onClickIncrement={onClickIncrementListing}
                                  onClickListing={(listingID: string) => {
                                    openDetailsDialog({
                                      id: listingID,
                                      type: 'listing',
                                    })

                                    track(events.OPENS_EXTRA_DETAIL, {
                                      listing_id: listingID,
                                    })
                                  }}
                                />

                                <div className="md:px-4">
                                  <div className="mx-auto mt-16 max-w-[512px] rounded-lg bg-white px-10 py-4 md:px-4">
                                    <ExtrasAllergenNote />
                                  </div>
                                </div>
                              </div>
                            </motion.div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Navigate replace to={`/menu/${selectedUserTerm.termID}`} />
                  )
                }
                path="/extras"
              />
            </Routes>
          </AnimatePresence>

          <MenuFixedBottomContainer>
            <OrderStatus {...commonOrderStatusProps} />
          </MenuFixedBottomContainer>
        </div>
      )}

      {detailsType === 'meal' && meal ? (
        <MealDetailsDialog
          closeModal={closeDetailsDialog}
          meal={meal}
          suggestions={mealSuggestionsForMenu}
        />
      ) : detailsType === 'listing' && listing ? (
        <ListingDetailsDialog listing={listing} onClose={closeDetailsDialog} />
      ) : null}

      {orderSummarySidebarStatus.isOpen && (
        <OrderSummarySidebar
          listings={selectedListings.map((listing) => {
            const quantity =
              listingsSelections.find(
                (listingSelection) => listingSelection.id === listing.id
              )?.quantity ?? 0
            return (
              <OrderSummaryExtra
                key={`order-summary-${listing.id}`}
                listing={listing}
                stepper={
                  <QuantityStepper
                    disabledDecrement={!canDecrementMeal}
                    disabledIncrement={!canIncrementMeal || listing.isSoldOut}
                    labelDecrement="Remove Meal"
                    labelIncrement="Add Meal"
                    min={0}
                    onClickDecrement={() => {
                      onClickDecrementListing({
                        listingID: listing.id,
                        onClickEventSourceID: sourceIDs.ORDER_SUMMARY,
                      })
                    }}
                    onClickIncrement={() => {
                      onClickIncrementListing({
                        listingID: listing.id,
                        onClickEventSourceID: sourceIDs.ORDER_SUMMARY,
                      })
                    }}
                    quantity={quantity}
                    size="small"
                  />
                }
              />
            )
          })}
          listingsTotalPriceCents={listingsTotalPriceCents}
          meals={selectedMeals.map((meal) => {
            const quantity = mealSelectionIDs.filter(
              (x) => x === meal.id
            ).length

            return (
              <OrderSummaryMeal
                key={`order-summary-${meal.id}`}
                meal={meal}
                mealStepper={
                  <QuantityStepper
                    disabledDecrement={!canDecrementMeal}
                    disabledIncrement={!canIncrementMeal || meal.isSoldOut}
                    labelDecrement="Remove Meal"
                    labelIncrement="Add Meal"
                    min={0}
                    onClickDecrement={() => {
                      onClickDecrementMeal({
                        onClickEventSourceID: sourceIDs.ORDER_SUMMARY,
                        mealID: meal.id,
                      })
                    }}
                    onClickIncrement={() => {
                      onClickIncrementMeal({
                        onClickEventSourceID: sourceIDs.ORDER_SUMMARY,
                        meal,
                      })
                    }}
                    quantity={quantity}
                    size="small"
                  />
                }
              />
            )
          })}
          onClickSkipAdjust={() => {
            onEditTermStatus(selectedUserTerm)
          }}
          onCloseSidebar={() => {
            closeOrderSummary()

            if (
              location.pathname.includes('extras') &&
              !selectedUserTerm.hasSelectedAllMeals
            ) {
              navigate(`/menu/${selectedUserTerm.termID}`)
            }
          }}
          orderStatus={
            <MenuFixedBottomContainer>
              <OrderStatus {...commonOrderStatusProps} />
            </MenuFixedBottomContainer>
          }
          selectedUserTerm={selectedUserTerm}
          showOrderUpgrade={showOrderUpgrade}
        />
      )}

      {isSubscriptionActive && customer && !hasPaymentSource && (
        <div className="fixed bottom-0 left-0 right-0 flex flex-col items-center justify-center bg-white p-10">
          <h4 className="mb-4 text-k/20_125">
            Please add a payment method on the Account page to receive meals.
          </h4>

          <Button onClick={() => navigate('/account/payment')} size="large">
            Add Payment Method
          </Button>
        </div>
      )}
      {showRateMealsPrompt && (
        <div className="fixed bottom-2 left-2 max-w-[180px] lg:hidden">
          <RateMealsPrompt
            mostRecentTermMealsToRate={mostRecentTermMealsToRate}
            onClickClose={() => setShowRateMealsPrompt(false)}
          />
        </div>
      )}
      {showRateMealsCarousel && mostRecentTermMealsToRate && (
        <RateMealsCarousel
          meals={mostRecentTermMealsToRate.meals}
          onClose={() => setShowRateMealsCarousel(false)}
        />
      )}
      {confirmationDialogData && (
        <ConfirmationDialogs
          confirmationDialogData={confirmationDialogData}
          hasBlackSheetTray={planPreferences.hasBlackSheetTray}
          onChangeConfirmationDialog={setConfirmationDialogData}
          onClickViewSummary={() => {
            setConfirmationDialogData(null)
            openOrderSummary('selectionsConfirmation')
          }}
          onClickWantMoreMeals={() => {
            if (menuHasListings) {
              navigate(`/menu/${selectedUserTerm.termID}`)
            }
          }}
          onClose={() => {
            setConfirmationDialogData(null)
          }}
          onDecrementMeal={decrementMeal}
          selectedUserTerm={selectedUserTerm}
          totalOrderCount={totalOrderCount}
        />
      )}
    </div>
  )
}

export default Menu

const FilterMealGroup = ({ children }: { children: ReactNode }) => {
  return (
    <div className="py-10 first:pt-0 last:pb-0 even:bg-white even:pb-10 md:py-6">
      {children}
    </div>
  )
}

const FilterGroupTitle = ({
  description,
  filters,
  numResults,
  title,
}: {
  description?: string
  filters?: ReactNode
  numResults?: number
  title: string
}) => {
  return (
    <div className="space-y-3 md:px-4">
      <div className="flex items-center justify-between">
        <h2 className="text-k/36_110 md:text-k/26_110">{title}</h2>

        {filters}
      </div>

      <div className="space-y-10">
        {numResults !== undefined && (
          <p className="text-k/20_125 text-grey-9 md:text-k/18_120">
            {numResults} results
          </p>
        )}

        {description && (
          <div className="text-k/20_125 text-grey-9 md:text-k/18_120">
            {description}
          </div>
        )}
      </div>
    </div>
  )
}

const MenuComponentsGrid = ({
  canDecrement,
  canIncrement,
  canModifySelections,
  components,
  mealIDsInFilters,
  mealSelectionIDs,
  onClickDecrement,
  onClickDeselectMealOption,
  onClickIncrement,
  onClickMeal,
  onClickSelectMealOption,
  selectedUserTerm,
  setConfirmationDialogData,
}: {
  canDecrement: boolean
  canIncrement: boolean
  canModifySelections: boolean
  components: MenuComponentsStandardized
  mealIDsInFilters: number[]
  mealSelectionIDs: number[]
  onClickDecrement(opts: {
    mealID: number
    mealSelectionEvent?: AnalyticsEvent | undefined
  }): void
  onClickDeselectMealOption(opts: {
    meal: MealSummary
    mealOption: MealSummary
  }): void
  onClickIncrement(opts: {
    meal: MealSummary
    mealSelectionEvent?: AnalyticsEvent | undefined
  }): void
  onClickMeal(mealID: number): void
  onClickSelectMealOption(opts: {
    meal: MealSummary
    mealOption: MealSummary
  }): void
  selectedUserTerm: UserTerm
  setConfirmationDialogData(data: ConfirmationDialogData | null): void
}) => {
  const bgImageHeaderVariant = useVariantByScreenSize<
    'bgImageHeaderMixed' | 'bgImageHeaderTop'
  >('bgImageHeaderMixed', {
    md: 'bgImageHeaderTop',
  })

  const [showMenuFeedback, setShowMenuFeedback] = useState(() => {
    if (storageAvailable('localStorage')) {
      let menuFeedback: number[] | string | null =
        localStorage.getItem('menuFeedback')
      menuFeedback = menuFeedback ? JSON.parse(menuFeedback) : ''

      return (
        !menuFeedback ||
        (menuFeedback &&
          typeof menuFeedback !== 'string' &&
          !menuFeedback.includes(selectedUserTerm.termID))
      )
    }
  })

  function makeMealQuantityStepper({
    meal,
    onDecrement,
    onIncrement,
  }: {
    meal: { id: number; mealSummary: MealSummary }
    onDecrement?(): void
    onIncrement?(): void
  }) {
    const quantity = mealSelectionIDs.filter((x) => x === meal.id).length

    if (
      !canModifySelections ||
      (meal.mealSummary.isSoldOut && quantity === 0)
    ) {
      return { quantity: 0, stepper: null }
    }

    return {
      quantity,
      stepper: (
        <QuantityStepper
          disabledDecrement={!canDecrement}
          disabledIncrement={!canIncrement || meal.mealSummary.isSoldOut}
          labelDecrement="Remove Meal"
          labelIncrement="Add Meal"
          min={0}
          onClickDecrement={() => {
            if (onDecrement) {
              onDecrement()
            } else {
              onClickDecrement({ mealID: meal.id })
            }
          }}
          onClickIncrement={() => {
            if (onIncrement) {
              onIncrement()
            } else {
              onClickIncrement({ meal: meal.mealSummary })
            }
          }}
          quantity={quantity}
          size="medium"
        />
      ),
    }
  }

  function makeTwoMealPickerMeal(
    meal: MenuComponentStandardizedTwoMealPickerMeal
  ) {
    const { id, mealSummary } = meal

    const { stepper, quantity } = makeMealQuantityStepper({
      meal: { id, mealSummary },
    })

    return {
      ...meal,
      quantity,
      stepper,
    }
  }

  function renderMeal(component: MenuComponentStandardizedMeal) {
    const {
      id,
      image,
      imageTag,
      mealSummary,
      subtitle,
      surcharge,
      tags,
      title,
    } = component.properties

    return (
      <Meal
        image={image}
        imageTag={imageTag}
        isSoldOut={mealSummary.isSoldOut}
        onClickMeal={() => {
          onClickMeal(id)
        }}
        stepper={makeMealQuantityStepper({ meal: { id, mealSummary } }).stepper}
        subtitle={subtitle}
        surcharge={surcharge}
        tags={tags}
        title={title}
      />
    )
  }

  function renderMealWithExtra(
    component: MenuComponentStandardizedMealWithExtra
  ) {
    const { meal, mealOption } = component.properties

    const { quantity: quantityMealOption, stepper: stepperMealOption } =
      makeMealQuantityStepper({
        meal: {
          id: mealOption.meal.id,
          mealSummary: mealOption.meal.mealSummary,
        },
        onDecrement: () => {
          onClickDecrement({
            mealID: mealOption.meal.id,
          })
        },
        onIncrement: () => {
          onClickIncrement({
            meal: mealOption.meal.mealSummary,
          })

          if (isHiddenByFilters) {
            track(events.DID_MODIFY_HIDDEN_MEAL_SELECTION, {
              meal_id: mealOption.meal.id,
              term_id: selectedUserTerm.termID,
            })
          }
        },
      })

    const { stepper: stepperMeal, quantity: quantityMeal } =
      makeMealQuantityStepper({
        meal: { id: meal.id, mealSummary: meal.mealSummary },
      })

    const isHiddenByFilters =
      mealIDsInFilters.length > 0 &&
      !mealIDsInFilters.includes(mealOption.meal.id)
    const isMealOptionSelected = quantityMealOption > 0

    return (
      <MealWithExtra
        meal={
          <Meal
            image={meal.image}
            imageTag={meal.imageTag}
            isSoldOut={meal.mealSummary.isSoldOut}
            onClickMeal={() => {
              onClickMeal(meal.id)
            }}
            stepper={isMealOptionSelected ? stepperMealOption : stepperMeal}
            subtitle={meal.subtitle}
            surcharge={meal.surcharge}
            tags={meal.tags}
            title={meal.title}
          />
        }
        mealOption={{
          id: mealOption.meal.id,
          image: mealOption.meal.image,
          isDisabled: quantityMeal === 0 && !isMealOptionSelected,
          isHiddenByFilters,
          isSelected: isMealOptionSelected,
          isSoldOut:
            mealOption.meal.mealSummary.isSoldOut || meal.mealSummary.isSoldOut,
          surcharge: mealOption.meal.surcharge,
          title: mealOption.meal.mealSummary.shortSubtitle,
        }}
        onClickDisabled={() => {
          setConfirmationDialogData({ type: 'mealExtraDisabled' })
        }}
        onClickExtra={() => {
          onClickMeal(mealOption.detailsMealID)
        }}
        onSelectMealOption={() => {
          const opts = {
            meal: meal.mealSummary,
            mealOption: mealOption.meal.mealSummary,
          }
          if (isMealOptionSelected) {
            onClickDeselectMealOption(opts)
          } else {
            onClickSelectMealOption(opts)
          }
        }}
      />
    )
  }

  function renderAnimatedMealCarousel(
    component: MenuComponentStandardizedMealCarousel
  ) {
    return (
      <MealCarousel
        buttonTitle={component.properties.buttonTitle}
        mealOptions={component.properties.mealOptions.map((mealOption) => {
          return {
            ...mealOption,
            ...makeMealQuantityStepper({ meal: mealOption }),
          }
        })}
        onClickMeal={onClickMeal}
        onEventForMeal={({ event, mealInView }) => {
          track(event, {
            meal_id: mealInView.id,
            term_id: selectedUserTerm.termID,
          })
        }}
      />
    )
  }

  function renderTwoMealPicker(
    component: MenuComponentStandardizedTwoMealPicker
  ) {
    return (
      <TwoMealPicker
        meals={[
          makeTwoMealPickerMeal(component.properties.meals[0]),
          makeTwoMealPickerMeal(component.properties.meals[1]),
        ]}
        onClickMeal={onClickMeal}
        onClickMealOption={() => {
          // Pass
        }}
      />
    )
  }

  return (
    <MenuGridLayout>
      {components.map((component, index) => {
        if (component.type === 'meal') {
          return (
            <div key={`meal-${component.properties.id}`} className="md:px-4">
              {renderMeal(component)}
            </div>
          )
        } else if (component.type === 'mealWithExtra') {
          return (
            <div
              key={`mealWithExtra-${component.properties.meal.id}`}
              className="md:px-4"
            >
              {renderMealWithExtra(component)}
            </div>
          )
        } else if (component.type === 'twoMealPicker') {
          return (
            <div
              key={`twoMealPicker-${component.properties.meals[0].id}`}
              className="md:px-4"
            >
              {renderTwoMealPicker(component)}
            </div>
          )
        } else if (component.type === 'animatedMealCarousel') {
          return (
            <div key={`mealCarousel-${component.properties.mealOptions[0].id}`}>
              {renderAnimatedMealCarousel(component)}
            </div>
          )
        } else if (component.type === 'suggestions') {
          return (
            <div
              key={`suggestions-${index}`}
              className="col-span-2 md:col-span-1 md:px-4"
            >
              <MealSuggestions
                mealSuggestions={component.properties.suggestions.map(
                  (meal) => {
                    return {
                      id: meal.id,
                      image: { url: getMealImageURL(meal) },
                      isSoldOut: meal.isSoldOut,
                      stepper: makeMealQuantityStepper({
                        meal: { id: meal.id, mealSummary: meal },
                        onDecrement: () => {
                          onClickDecrement({
                            mealID: meal.id,
                            mealSelectionEvent:
                              events.MODIFIED_MEAL_SELECTION_MEALS_YOU_LOVED,
                          })
                        },
                        onIncrement: () => {
                          onClickIncrement({
                            meal,
                            mealSelectionEvent:
                              events.MODIFIED_MEAL_SELECTION_MEALS_YOU_LOVED,
                          })
                        },
                      }).stepper,
                      surcharge: getMealSurcharge({ meal }),
                      title: meal.title,
                    }
                  }
                )}
                onClickMeal={onClickMeal}
              />
            </div>
          )
        } else if (component.type === 'salmonBar') {
          return (
            <div
              key={`salmonBar-${index}`}
              className="col-span-2 md:col-span-1 md:px-4"
            >
              <SpecialEventBanner>
                {component.properties.title}
              </SpecialEventBanner>
            </div>
          )
        } else if (showMenuFeedback && component.type === 'menuFeedback') {
          return (
            <div
              key={`menuFeedback-${index}`}
              className="col-span-2 md:col-span-1 md:px-4"
            >
              <MenuFeedback
                onMenuFeedbackSaved={() => {
                  setShowMenuFeedback(false)
                }}
                options={component.properties.options}
                subtitle={component.properties.subtitle}
                tableID={component.properties.tableID}
                termID={selectedUserTerm.termID}
                title={component.properties.title}
              />
            </div>
          )
        } else if (component.type === 'backgroundImageHeader') {
          return (
            <div
              key={`backgroundImageHeader-${index}`}
              className={clsx('min-h-96 md:aspect-4/3 sm:aspect-square', {
                '-mt-6':
                  index === 0 && bgImageHeaderVariant === 'bgImageHeaderTop',
              })}
            >
              <BackgroundImageHeader
                image={component.properties.image}
                subtitle={component.properties.subtitle}
                subtitleColor={component.properties.subtitleColor}
                title={component.properties.title}
                titleColor={component.properties.titleColor}
              />
            </div>
          )
        } else if (component.type === 'textImageStack') {
          return (
            <div key={index} className="col-span-2 md:col-span-1">
              <TextImageStack
                image={component.properties.image}
                subtitle={component.properties.subtitle}
                title={component.properties.title}
              >
                <MenuGridLayout>
                  {component.properties.children.map((child) => {
                    if (child.type === 'meal') {
                      return (
                        <div
                          key={`meal-${child.properties.id}`}
                          className="md:px-4"
                        >
                          {renderMeal(child)}
                        </div>
                      )
                    } else if (child.type === 'mealWithExtra') {
                      return (
                        <div
                          key={`mealWithExtra-${child.properties.meal.id}`}
                          className="md:px-4"
                        >
                          {renderMealWithExtra(child)}
                        </div>
                      )
                    } else if (child.type === 'twoMealPicker') {
                      return (
                        <div
                          key={`twoMealPicker-${child.properties.meals[0].id}`}
                          className="md:px-4"
                        >
                          {renderTwoMealPicker(child)}
                        </div>
                      )
                    } else if (child.type === 'animatedMealCarousel') {
                      return (
                        <div
                          key={`mealCarousel-${child.properties.mealOptions[0].id}`}
                        >
                          {renderAnimatedMealCarousel(child)}
                        </div>
                      )
                    }
                  })}
                </MenuGridLayout>
              </TextImageStack>
            </div>
          )
        }
      })}
    </MenuGridLayout>
  )
}

export const ReleaseFromCartConfirmation = ({
  onClickClose,
  onClickConfirm,
}: {
  onClickClose(): void
  onClickConfirm(): void
}) => {
  return (
    <ConfirmationDialog onRequestClose={onClickClose}>
      <ConfirmationHeader heading="Release from cart?" />
      <ConfirmationBody>
        <div className="py-16 md:py-12">
          <p className="mx-auto max-w-sm text-body-lg">
            This meal is sold out. If you remove this meal, you may not be able
            to add it back.
          </p>
        </div>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="grid grid-cols-2 gap-2">
          <Button buttonStyle="stroke" onClick={onClickClose} size="large">
            Cancel
          </Button>

          <Button onClick={onClickConfirm} size="large">
            OK
          </Button>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}

export const RequiresCookingOilConfirmation = ({
  oilWarning,
  onClose,
  onConfirm,
}: {
  oilWarning: MealTag
  onClose(): void
  onConfirm(): void
}) => {
  return (
    <ConfirmationDialog onRequestClose={onClose}>
      <ConfirmationHeader heading={oilWarning.title} />
      <ConfirmationBody>
        <div className="py-16 md:py-12">
          <p className="mx-auto max-w-sm text-body-lg">
            {oilWarning.description}
          </p>
        </div>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="grid grid-cols-2 gap-2">
          <Button buttonStyle="stroke" onClick={onClose} size="large">
            Cancel
          </Button>

          <Button onClick={onConfirm} size="large">
            Sounds good
          </Button>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}

const ConfirmationDialogs = ({
  confirmationDialogData,
  hasBlackSheetTray,
  onChangeConfirmationDialog,
  onClickViewSummary,
  onClickWantMoreMeals,
  onClose,
  onDecrementMeal,
  selectedUserTerm,
  totalOrderCount,
}: {
  confirmationDialogData: ConfirmationDialogData
  hasBlackSheetTray: boolean | null
  onChangeConfirmationDialog(data: ConfirmationDialogData): void
  onClickViewSummary(): void
  onClickWantMoreMeals(): void
  onClose(): void
  onDecrementMeal(opts: { mealID: number }): void
  selectedUserTerm: UserTerm
  totalOrderCount: number | undefined
}) => {
  const addOn = useApplicableAddOn({
    termID: selectedUserTerm.termID,
    totalOrderCount: totalOrderCount ?? 0,
  })

  if (confirmationDialogData.type === 'mealsSelected') {
    return (
      <MealSelectionsConfirmationDialog
        nextActionPrompt={
          addOn ? (
            <AddOnPrompt addOn={addOn} />
          ) : // If a user is at the largest subscription type, they can't add more meals so we
          // shouldn't show a prompt for them to add more.
          selectedUserTerm.nextLargestSubscriptionType ? (
            <WantMoreMealsPrompt
              onClickAddMoreMeals={() => {
                onClickWantMoreMeals()
                onClose()
              }}
            />
          ) : null
        }
        onClickViewSummary={onClickViewSummary}
        onCloseConfirmation={() => {
          onClose()
        }}
        openSource={confirmationDialogData.openSource}
        selectedUserTerm={selectedUserTerm}
      />
    )
  }

  if (confirmationDialogData.type === 'filteredAllergens') {
    return (
      <MenuFiltersConfirmationDialog
        onClose={() => {
          onClose()
        }}
      />
    )
  }

  if (confirmationDialogData.type === 'mealExtraDisabled') {
    return (
      <MealExtraDisabledConfirmationDialog
        onClose={() => {
          onClose()
        }}
      />
    )
  }

  if (confirmationDialogData.type === 'noListingFilters') {
    return (
      <NoListingFiltersConfirmationDialog
        onClose={() => {
          confirmationDialogData.onConfirm()
        }}
      />
    )
  }

  const { onConfirm, meal, type } = confirmationDialogData
  const bstTag = getTagBST({ meal })
  const oilWarning = hasViewedOilWarning(meal)
    ? null
    : getTagOilWarning({ meal })

  if (type === 'blackSheetTray') {
    return (
      <SheetTrayConfirmation
        oilWarning={oilWarning}
        onClose={onClose}
        onConfirm={() => {
          onConfirm()

          if (oilWarning) {
            setMealWarningAsViewed(oilWarning.id)
          }

          onClose()
        }}
      />
    )
  }

  if (type === 'oilWarning' && oilWarning) {
    return (
      <RequiresCookingOilConfirmation
        oilWarning={oilWarning}
        onClose={onClose}
        onConfirm={() => {
          onConfirm()
          setMealWarningAsViewed(oilWarning.id)
          onClose()
        }}
      />
    )
  }

  if (type === 'releaseFromCart') {
    return (
      <ReleaseFromCartConfirmation
        onClickClose={() => {
          onClose()
        }}
        onClickConfirm={() => {
          onDecrementMeal({ mealID: meal.id })
          onClose()
        }}
      />
    )
  }

  if (type === 'surcharge') {
    const confirmBST = !!bstTag && !hasBlackSheetTray

    return (
      <SurchargeConfirmation
        onClose={onClose}
        onConfirm={() => {
          if (confirmBST) {
            onChangeConfirmationDialog({
              meal,
              onConfirm,
              type: 'blackSheetTray',
            })
          } else {
            onConfirm()
          }

          onClose()
        }}
      />
    )
  }

  return null
}

function useApplicableAddOn({
  termID,
  totalOrderCount,
}: {
  termID: number
  totalOrderCount: number
}) {
  const { user } = useUser()

  const { data: getAddOnsResponse } = useAddOns()
  const addOns = getAddOnsResponse?.addOns ?? []
  return totalOrderCount > 5
    ? addOns.find((addOn) => {
        return (
          addOn.termID === termID &&
          addOn.states.includes(user.shippingAddresses[0]?.state)
        )
      })
    : undefined
}

function useTrackScrollDepth({
  maxSelections,
  numMeals,
  numSelections,
  termID,
}: {
  maxSelections: number | undefined
  numMeals: number
  numSelections: number
  termID: number
}) {
  useEffect(() => {
    let scrollDepth = 0

    const trackScrollMenu = (event: AnalyticsEvent) => {
      track(event, {
        meal_selection_count: numSelections,
        meal_selection_max: maxSelections,
        term_id: termID,
      })
    }

    const throttleTrackScrollsMenu25 = throttle(trackScrollMenu, 2000, {
      trailing: false,
    })
    const throttleTrackScrollsMenu50 = throttle(trackScrollMenu, 2000, {
      trailing: false,
    })
    const throttleTrackScrollsMenu75 = throttle(trackScrollMenu, 2000, {
      trailing: false,
    })
    const throttleTrackScrollsMenu100 = throttle(trackScrollMenu, 2000, {
      trailing: false,
    })

    const onMenuScroll = () => {
      if (numMeals) {
        const scrollY = window.pageYOffset
        const scrollHeight =
          document.documentElement.scrollHeight - window.innerHeight
        const newScrollDepth = Math.round((scrollY / scrollHeight) * 100)
        scrollDepth = newScrollDepth

        if (scrollDepth === 25) {
          throttleTrackScrollsMenu25(events.SCROLLS_MENU_25)
        }

        if (scrollDepth === 50) {
          throttleTrackScrollsMenu50(events.SCROLLS_MENU_50)
        }

        if (scrollDepth === 75) {
          throttleTrackScrollsMenu75(events.SCROLLS_MENU_75)
        }

        if (scrollDepth === 100) {
          throttleTrackScrollsMenu100(events.SCROLLS_MENU_100)
        }
      }
    }
    window.addEventListener('scroll', onMenuScroll)

    return () => {
      window.removeEventListener('scroll', onMenuScroll)
    }
  }, [maxSelections, numMeals, numSelections, termID])
}

function setMealWarningAsViewed(tagID: number) {
  if (storageAvailable('localStorage')) {
    let mealWarnings = localStorage.getItem('mealWarnings')
    mealWarnings = mealWarnings ? JSON.parse(mealWarnings) : []

    if (isArray(mealWarnings)) {
      mealWarnings.push(tagID)
      localStorage.setItem('mealWarnings', JSON.stringify(mealWarnings))
    }
  }
}

function trackMealModified({
  maxSelections,
  mealID,
  mealSelectionEvent,
  numSelections,
  termID,
}: {
  maxSelections: number
  mealID: number
  mealSelectionEvent: AnalyticsEvent
  numSelections: number
  termID: number
}) {
  track(mealSelectionEvent, {
    meal_id: mealID,
    meal_selection_diff:
      maxSelections > 0 ? maxSelections - numSelections : undefined,
    meal_selection_count: numSelections,
    meal_selection_max: maxSelections,
    term_id: termID,
  })
}
