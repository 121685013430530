import { ButtonLoading } from '@tovala/component-library'
import { Form, Formik, FormikErrors } from 'formik'
import { useForgotPassword } from '@tovala/browser-apis-combinedapi'

import { ErrorCodeMessageMapCombinedAPI } from 'types/internal'

import { useToast } from 'contexts/toast'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import AuthPage from './AuthPage'
import FormInput from 'components/common/FormInput'

interface FormData {
  email: string
}

const FORGOT_PASSWORD_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    why: "We couldn't send you a password reset link due to a technical issue on our end.",
  },
  UserNotFound: {
    helpToFix: 'Please double check and try again.',
    why: "We couldn't find that email in our system.",
  },
}

function validateData(data: FormData) {
  const errors: FormikErrors<FormData> = {}

  if (!data.email) {
    errors.email = 'Please provide your email'
  }

  return errors
}

const ForgotPasswordPage = () => {
  const { openToast } = useToast()

  const {
    error: forgotPasswordError,
    isError: hasForgotPasswordError,
    isLoading: isRequestingReset,
    mutate: forgotPassword,
  } = useForgotPassword({
    onSuccess: () => {
      openToast({
        heading: 'Success!',
        message:
          "Check your inbox! If you don't see an email from us, please check your spam folder.",
        type: 'success',
      })
    },
  })

  return (
    <AuthPage>
      <div className="flex justify-center">
        <div className="flex flex-col items-center">
          <div className="mb-12 space-y-4 sm:mb-10 sm:space-y-6">
            <h1 className="text-center text-k/44_110 font-medium md:text-k/32_105">
              Forgot Your Password?
            </h1>
            <p className="mx-auto max-w-xs text-center text-k/14_120 text-grey-9 sm:max-w-full">
              Trouble logging in? Enter your email below and we'll send you a
              link to reset your password.
            </p>
          </div>
          <div className="w-[320px] sm:w-full">
            <Formik<FormData>
              initialValues={{ email: '' }}
              onSubmit={(data) => {
                return forgotPassword(data)
              }}
              validate={(data) => {
                return validateData(data)
              }}
              validateOnBlur={false}
              validateOnChange={false}
            >
              <Form>
                <div className="space-y-6 sm:space-y-4">
                  <FormInput
                    id="email"
                    label="Email"
                    labelFor="email"
                    name="email"
                    type="email"
                  />

                  {hasForgotPasswordError && (
                    <APIErrorDisplay
                      error={forgotPasswordError}
                      errorCodeMessageMap={FORGOT_PASSWORD_ERRORS}
                    />
                  )}
                </div>

                <div className="mt-8 flex justify-center sm:mt-6">
                  <ButtonLoading
                    isLoading={isRequestingReset}
                    size="large"
                    type="submit"
                  >
                    Reset Password
                  </ButtonLoading>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </AuthPage>
  )
}

export default ForgotPasswordPage
