import { useFormContext } from 'react-hook-form'

import { events } from 'analytics/events'
import { IssueCategory, IssueConfig } from 'types/internal'
import { track } from 'utils/analytics'

import Chip from './Chip'
import { SidebarBody } from 'components/common/Sidebar'

const Issues = ({
  issues,
  nextPage,
}: {
  issues: {
    [category in IssueCategory]: IssueConfig
  }
  nextPage(): void
}) => {
  const { setValue } = useFormContext()
  return (
    <SidebarBody>
      <div className="-mt-2 flex flex-wrap justify-center p-6">
        {Object.values(issues).map((issue) => {
          return (
            <div key={issue.name} className="m-2">
              <Chip
                afterChange={() => {
                  setValue('issueName', issue.name)
                  nextPage()
                  track(events.REPORT_ISSUE, {
                    issue_id: issue.name.toLowerCase(),
                  })
                }}
                label={issue.name}
                name="issueID"
                value={issue.id}
              />
            </div>
          )
        })}
      </div>
    </SidebarBody>
  )
}

export default Issues
