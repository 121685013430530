import { ScreenSizes, useScreenSize } from 'contexts/screenSize'

type MediaVariants<VariantType> = {
  [key in ScreenSizes]?: VariantType
}

export const useVariantByScreenSize = <VariantType>(
  defaultVariant: VariantType,
  mediaVariants: MediaVariants<VariantType>
) => {
  const screenSize = useScreenSize()

  const allMediaVariants = getAllMediaVariants(defaultVariant, mediaVariants)

  return allMediaVariants[screenSize] || defaultVariant
}

function getAllMediaVariants<VariantType>(
  defaultVariant: VariantType,
  mediaVariants: MediaVariants<VariantType>
): MediaVariants<VariantType> {
  return {
    '2xl': mediaVariants['2xl'] || defaultVariant,
    xl: mediaVariants.xl || mediaVariants['2xl'] || defaultVariant,
    lg:
      mediaVariants.lg ||
      mediaVariants.xl ||
      mediaVariants['2xl'] ||
      defaultVariant,
    md:
      mediaVariants.md ||
      mediaVariants.lg ||
      mediaVariants.xl ||
      mediaVariants['2xl'] ||
      defaultVariant,
    sm:
      mediaVariants.sm ||
      mediaVariants.md ||
      mediaVariants.lg ||
      mediaVariants.xl ||
      mediaVariants['2xl'] ||
      defaultVariant,
    xs:
      mediaVariants.xs ||
      mediaVariants.sm ||
      mediaVariants.md ||
      mediaVariants.lg ||
      mediaVariants.xl ||
      mediaVariants['2xl'] ||
      defaultVariant,
  }
}
