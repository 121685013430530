import { isArray } from 'lodash-es'
import { useEffect } from 'react'
import { useTutorials } from '@tovala/browser-apis-cdn'

import { useOrderHistory } from './orderHistory'
import { useOverlays } from 'contexts/overlays'

import { events } from 'analytics/events'
import { storageAvailable } from 'utils/storageAvailable'
import { track } from 'utils/analytics'

const MENU_TUTORIAL_ID = 'menu_exploration'
const MENU_TUTORIAL_KEY = 'viewedMenuTutorial'
const MENU_TUTORIAL_POPOVER_KEY = 'viewedPopoverMenuTutorial'

export const useMenuTutorial = ({ userID }: { userID: number | undefined }) => {
  const { totalOrderCount } = useOrderHistory({ userID })

  const { setIsMenuTutorialSkipMultipleOpen, setIsMenuTutorialSkipSingleOpen } =
    useOverlays()

  const { data: tutorials } = useTutorials()

  const menuTutorial = tutorials?.find(
    (tutorial) => tutorial.id === MENU_TUTORIAL_ID
  )

  const showNextMenuTutorial = () => {
    setIsMenuTutorialSkipSingleOpen(false)
    setIsMenuTutorialSkipMultipleOpen(true)

    if (storageAvailable('localStorage')) {
      let viewedTutorial = localStorage.getItem(MENU_TUTORIAL_POPOVER_KEY)
      viewedTutorial = viewedTutorial ? JSON.parse(viewedTutorial) : []

      if (isArray(viewedTutorial)) {
        viewedTutorial.push('skip-multiple')

        localStorage.setItem(
          MENU_TUTORIAL_POPOVER_KEY,
          JSON.stringify(viewedTutorial)
        )
      }
    }
  }

  const closeMenuTutorial = () => {
    setIsMenuTutorialSkipMultipleOpen(false)

    if (storageAvailable('localStorage')) {
      localStorage.setItem(MENU_TUTORIAL_KEY, 'true')
    }

    track(events.TUTORIAL_DONE, { tutorial_id: MENU_TUTORIAL_ID })
  }

  useEffect(() => {
    // Check for "new customers": orders > 0 && orders <= 3
    if (
      totalOrderCount !== undefined &&
      totalOrderCount > 0 &&
      totalOrderCount <= 3 &&
      storageAvailable('localStorage') &&
      menuTutorial
    ) {
      const hasViewedMenuTutorial = localStorage.getItem(MENU_TUTORIAL_KEY)
      const hasViewedPopoverMenuTutorial = localStorage.getItem(
        MENU_TUTORIAL_POPOVER_KEY
      )

      if (!hasViewedMenuTutorial && !hasViewedPopoverMenuTutorial) {
        setIsMenuTutorialSkipSingleOpen(true)
        localStorage.setItem(
          MENU_TUTORIAL_POPOVER_KEY,
          JSON.stringify(['skip-single'])
        )

        track(events.TUTORIAL_SHOWN, { tutorial_id: MENU_TUTORIAL_ID })
      }
    }
  }, [menuTutorial, setIsMenuTutorialSkipSingleOpen, totalOrderCount])

  return {
    closeMenuTutorial,
    showNextMenuTutorial,
  }
}
