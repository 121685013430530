import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { events } from '../../../analytics/events'
import { track } from 'utils/analytics'
import { UserTerm } from 'types/internal'

import { useUser } from 'contexts/user'
import CommitmentDetails from './CommitmentDetails'
import EditPlan from './EditPlan'
import ManageOrders from './ManageOrders'
import MealPlanPreferences from './MealPlanPreferences'
import ProfileHeading from './AccountHeading'

const MealPlan = ({
  onEditTermStatus,
}: {
  onEditTermStatus(term: UserTerm): void
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { user } = useUser()

  const isSubscriptionActive = user.subscription.status === 'active'

  const mealCommitmentPlanFlag = user.subscription.isCommitment

  const manageOrdersRef = useRef<HTMLDivElement | null>(null)

  const onClickSkipAFewDeliveries = () => {
    if (location.pathname === '/account/edit-plan') {
      navigate('/account/manage-deliveries')
    } else if (manageOrdersRef.current) {
      manageOrdersRef.current.scrollIntoView(true)
    }
  }

  const onSubscriptionStatusChanged = () => {
    if (location.pathname === '/account/edit-plan') {
      navigate('/account')
    }
  }

  useEffect(() => {
    track(events.OPENS_MEAL_PLAN_SETTINGS)
  }, [])

  return (
    <div className="w-full max-w-account">
      <div className="hidden md:block">
        <ProfileHeading to="/account">Edit Plan</ProfileHeading>
      </div>

      <h1 className="mb-6 text-k/44_110 md:hidden">Meal Plan</h1>
      <div className="mb-6">
        <EditPlan
          onClickSkipAFewDeliveries={onClickSkipAFewDeliveries}
          onSubscriptionStatusChanged={onSubscriptionStatusChanged}
        />
      </div>

      {isSubscriptionActive && (
        <>
          <div className="md:hidden">
            <MealPlanPreferences />
          </div>

          {mealCommitmentPlanFlag && (
            <div className="mt-10">
              <CommitmentDetails />
            </div>
          )}

          <div ref={manageOrdersRef} className="mt-10 md:hidden">
            <ManageOrders onEditTermStatus={onEditTermStatus} />
          </div>
        </>
      )}
    </div>
  )
}

export default MealPlan
