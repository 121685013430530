import { ReactNode } from 'react'

export interface Props {
  label: ReactNode
  labelFor?: string
}

const FormFieldLabel = ({ label, labelFor }: Props): JSX.Element => {
  return (
    <label
      className="flex items-center text-h/14_120 font-semibold"
      htmlFor={labelFor}
    >
      {label}
    </label>
  )
}

export default FormFieldLabel
