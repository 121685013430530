import {
  addDays,
  addMonths,
  addYears,
  format as dateFnsFormat,
  formatDistanceToNowStrict,
  isAfter,
  isBefore,
  isFuture,
  isPast,
  isSameDay,
  isSameMonth,
  isSameYear,
  isValid,
  parse,
  parseISO,
  previousWednesday,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export const DATE_FORMATS = {
  // General date formats
  DEFAULT_DATE_FORMAT: 'M/d/yyyy',
  DEFAULT_DATETIME_FORMAT: 'M/d/yyyy h:mm:ss aaa',
  DOW_FULL: 'EEEE',
  DOW_MONTH_ABBR_DAY: 'E, MMM d',
  DOW_MONTH_DAY: 'E, M/d',
  DOW_MONTH_FULL_DAY: 'E, MMMM d',
  DOW_MONTH_FULL_DAY_SUFFIX: 'E, MMMM do',
  DOW_FULL_MONTH_DAY: 'EEEE, M/d',
  MONTH_ABBR_DAY: 'MMM d',
  MONTH_DAY: 'M/d',
  MONTH_FULL_DAY_SUFFIX: 'MMMM do',
  MONTH_FULL_DAY_YEAR_FULL: 'MMMM d, yyyy',

  // Specific use cases
  MEAL_EXPIRATION_DATE: "yyyy-MM-dd'T00:00:00Z'", // Escape the non-date part so it's ignored.
  ORDER_BY_DATE: "EEEE, M/d 'at' h aaaa 'CT'",
} as const

export type DateFormatKey = keyof typeof DATE_FORMATS
export type DateFormat = typeof DATE_FORMATS[DateFormatKey]
export type DateUnits = 'days' | 'months' | 'years'

export function addToDate(
  date: Date,
  { quantity, units }: { quantity: number; units: DateUnits }
): Date {
  if (units === 'days') {
    return addDays(date, quantity)
  } else if (units === 'months') {
    return addMonths(date, quantity)
  }

  return addYears(date, quantity)
}

export function convertUTCToCT(date: string) {
  return utcToZonedTime(date, 'US/Central')
}

export function formatDate(
  value: Date | string,
  {
    format,
    parseFormat,
  }: { format: DateFormat; parseFormat?: DateFormat | null }
): string {
  if (!format) {
    return typeof value === 'string'
      ? value
      : value instanceof Date
      ? value.toISOString()
      : ''
  }

  const valueAsDate =
    typeof value === 'string' ? parseToDate(value, { parseFormat }) : value

  // If we fail to parse the provided input, we can't try to format it.
  if (!isValidDate(valueAsDate)) {
    return typeof value === 'string' ? value : ''
  }

  return dateFnsFormat(valueAsDate, format)
}

/**
 * Returns a human readable duration from the current time.
 * For example: a day, 2 months, 20 minutes, etc.
 *
 * opts.moreFriendly Set this flag to use "a" instead of "1" (e.g. "a day" instead of "1 day").
 */
export function getHumanReadableDurationFromNow(
  date: Date,
  { moreFriendly = false }: { moreFriendly?: boolean } = {}
) {
  let duration = formatDistanceToNowStrict(date)

  if (moreFriendly) {
    // An internal decision to use "a" instead of "1" if there's only 1
    // of the unit.
    if (duration.startsWith('1 ')) {
      duration = `a${duration.substring(1)}`
    }
  }

  return duration
}

export function getPreviousWednesday(date: Date): Date {
  return previousWednesday(date)
}

export function isDateAfter(firstDate: Date, secondDate: Date): boolean {
  return isAfter(firstDate, secondDate)
}

export function isDateBefore(firstDate: Date, secondDate: Date): boolean {
  return isBefore(firstDate, secondDate)
}

export function isDateFuture(firstDate: Date): boolean {
  return isFuture(firstDate)
}

export function isDatePast(firstDate: Date): boolean {
  return isPast(firstDate)
}

export function isDateSame(
  firstDate: Date,
  secondDate: Date,
  { units }: { units: DateUnits }
): boolean {
  if (units === 'days') {
    return isSameDay(firstDate, secondDate)
  } else if (units === 'months') {
    return isSameMonth(firstDate, secondDate)
  }

  return isSameYear(firstDate, secondDate)
}

export function isValidDate(date: Date) {
  return isValid(date)
}

/**
 * Parses the provided value to a Date. By default, this will try to parse the provided
 * value as an ISO date. If you provide a parseFormat, it will try to parse using that
 * format instead.
 */
export function parseToDate(
  value: string,
  { parseFormat = null }: { parseFormat?: DateFormat | null } = {}
): Date {
  return parseFormat ? parse(value, parseFormat, new Date()) : parseISO(value)
}
