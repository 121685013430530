import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
  addPaymentSource,
  AddPaymentSource,
  AddPaymentSourceResponse,
  DeletePaymentSource,
  deletePaymentSource,
  DeletePaymentSourceResponse,
  editDefaultPaymentSource,
  EditDefaultPaymentSource,
  EditDefaultPaymentSourceResponse,
} from 'services/combinedAPI/payments'
import { events } from 'analytics/events'
import { track } from 'utils/analytics'

import { useInvalidateCustomers } from './customers'

export function useAddPaymentSource(
  opts?: Omit<
    UseMutationOptions<AddPaymentSourceResponse, Error, AddPaymentSource>,
    'mutationFn'
  >
) {
  const { invalidateCustomer } = useInvalidateCustomers()

  return useMutation({
    ...opts,
    mutationFn: ({ customerID, data, userID }) => {
      track(events.ADD_PAYMENT_ATTEMPTED)

      return addPaymentSource({ customerID, data, userID })
    },
    onError: (...args) => {
      opts?.onError?.(...args)
    },
    onSuccess: (...args) => {
      const { customerID, userID } = args[1]

      invalidateCustomer({ customerID, userID })

      opts?.onSuccess?.(...args)
    },
  })
}

export function useDeletePaymentSource(
  opts?: Omit<
    UseMutationOptions<DeletePaymentSourceResponse, Error, DeletePaymentSource>,
    'mutationFn'
  >
) {
  const { invalidateCustomer } = useInvalidateCustomers()

  return useMutation({
    ...opts,
    mutationFn: ({ customerID, paymentSourceID, userID }) => {
      return deletePaymentSource({ customerID, paymentSourceID, userID })
    },
    onSuccess: (...args) => {
      const { customerID, userID } = args[1]

      track(events.REMOVES_PAYMENT_METHOD)

      invalidateCustomer({ customerID, userID })

      opts?.onSuccess?.(...args)
    },
  })
}

export function useEditDefaultPaymentSource(
  opts?: Omit<
    UseMutationOptions<
      EditDefaultPaymentSourceResponse,
      Error,
      EditDefaultPaymentSource
    >,
    'mutationFn'
  >
) {
  const { invalidateCustomer } = useInvalidateCustomers()

  return useMutation({
    ...opts,
    mutationFn: ({ customerID, data, userID }) => {
      return editDefaultPaymentSource({ customerID, data, userID })
    },
    onSuccess: (...args) => {
      const { customerID, userID } = args[1]

      track(events.CHANGE_PAYMENT_METHOD)

      invalidateCustomer({ customerID, userID })

      opts?.onSuccess?.(...args)
    },
  })
}
