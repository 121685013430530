import { throttle } from 'lodash-es'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { clsx } from 'clsx'

function StickyHeader({
  children,
}: {
  children(opts: { isHeaderSticky: boolean }): ReactNode
}) {
  const { isHeaderSticky, menuHeight, ref } = useFixedHeaderWhenScrolling()

  return (
    <div>
      {/* This is an empty element that takes up the space of the menu when it becomes
            fixed so the page doesn't jump. */}
      {isHeaderSticky && menuHeight && (
        <div className="w-full" style={{ height: menuHeight }} />
      )}

      <div
        ref={ref}
        className={clsx('w-full bg-grey-0', {
          'fixed top-0 z-30': isHeaderSticky,
        })}
      >
        {children({ isHeaderSticky })}
      </div>
    </div>
  )
}

export default StickyHeader

function useFixedHeaderWhenScrolling() {
  const [hasScrolledForStickyMenu, setHasScrolledForStickyMenu] =
    useState(false)
  const [menuHeight, setMenuHeight] = useState<number | undefined>()

  const menuHeaderRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const updateIsStuck = () => {
      setHasScrolledForStickyMenu(window.scrollY > 100)
    }

    const throttledUpdate = throttle(updateIsStuck, 200)

    window.addEventListener('scroll', throttledUpdate)

    return () => {
      window.removeEventListener('scroll', throttledUpdate)
    }
  }, [])

  useEffect(() => {
    setMenuHeight((menuHeight) => {
      if (menuHeight) {
        return menuHeight
      }

      return menuHeaderRef.current?.offsetHeight
    })
  }, [])

  // We'll only make our header sticky if we have figured out the height of the placeholder
  // element that will take its place once it's fixed.
  const isHeaderSticky = hasScrolledForStickyMenu && !!menuHeight

  return {
    isHeaderSticky,
    menuHeight,
    ref: menuHeaderRef,
  }
}
