import { isArray } from 'lodash-es'
import {
  RoutineStepClassic,
  RoutineStepIntent,
} from '@tovala/browser-apis-combinedapi'

export function getFormattedCookTime({
  cookTimeInSeconds,
}: {
  cookTimeInSeconds: number
}) {
  const minutes = Math.floor(cookTimeInSeconds / 60)
  const seconds = cookTimeInSeconds - minutes * 60

  const totalTimeInMinsAndSeconds =
    minutes +
    ':' +
    seconds.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })

  return totalTimeInMinsAndSeconds
}

export function getTotalCookTimeInSeconds({
  routineSteps,
}: {
  routineSteps: RoutineStepClassic | RoutineStepClassic[] | RoutineStepIntent[]
}) {
  const routineStepsArr: (RoutineStepClassic | RoutineStepIntent)[] = isArray(
    routineSteps
  )
    ? routineSteps
    : [routineSteps]

  return routineStepsArr.reduce((time, step) => {
    const stepTime = isRoutineIntentStep(step) ? step.time : step.cookTime

    return time + stepTime
  }, 0)
}

function isRoutineIntentStep(
  routineStep: RoutineStepClassic | RoutineStepIntent
): routineStep is RoutineStepIntent {
  return (routineStep as RoutineStepIntent).mode !== undefined
}
