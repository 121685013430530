import {
  useLastProcessedOrderSummary,
  useOrderHistoryReceipts,
} from '@tovala/browser-apis-combinedapi'

import { isDateAfter, isDateBefore, isDateSame, parseToDate } from 'utils/dates'
import { flatten } from 'lodash-es'
import { useUserNextAvailableTermID } from 'hooks/combinedAPI/terms'

export function useHasPastOrders({ userID }: { userID: number }) {
  const { data: lastProcessedOrderSummary, isError } =
    useLastProcessedOrderSummary({
      retry: false,
      userID,
    })

  if (isError) {
    return false
  }

  if (lastProcessedOrderSummary !== undefined) {
    return true
  }

  return undefined
}

export function useOrderHistory({ userID }: { userID: number | undefined }) {
  const {
    data: getOrderHistoryReceiptsResponse,
    error: getOrderHistoryReceiptsError,
    fetchNextPage: loadMoreOrderHistory,
    isError: hasGetOrderHistoryReceiptsError,
    isFetchingNextPage,
    isLoading,
  } = useOrderHistoryReceipts({
    userID,
  })

  const { pages = [] } = getOrderHistoryReceiptsResponse || {}
  const receipts = flatten(pages.map((page) => page.receipts ?? []))
  const totalOrderCount = pages[0]?.totalOrderCount

  const { data: nextTermID } = useUserNextAvailableTermID()
  const recentPastTermIDs = nextTermID
    ? Array.from({ length: 3 }, (_item, i) => nextTermID - (i + 1))
    : []

  const recentOrdersPastDeliveryDate = receipts
    .filter((receipt) => {
      if (!receipt) {
        return false
      }

      const now = new Date()
      const deliveryDate = parseToDate(receipt.deliveryDate)

      return (
        recentPastTermIDs.includes(receipt.termID) &&
        receipt.orderStatus !== 'skipped' &&
        (isDateBefore(deliveryDate, now) ||
          isDateSame(deliveryDate, now, { units: 'days' }))
      )
    })
    .sort((a, b) => a.termID - b.termID)

  return {
    getOrderHistoryReceiptsError,
    hasGetOrderHistoryReceiptsError,
    isFetchingNextPage,
    isLoading,
    loadMoreOrderHistory,
    receipts,
    recentOrdersPastDeliveryDate,
    totalOrderCount,
  }
}

export function useScheduledDelivery({ userID }: { userID: number }) {
  let scheduledDelivery = null

  const { data: lastProcessedOrderSummary } = useLastProcessedOrderSummary({
    userID,
  })

  const now = new Date()

  if (lastProcessedOrderSummary?.userTermOrderSummary?.deliveryDate) {
    const deliveryDate = parseToDate(
      lastProcessedOrderSummary?.userTermOrderSummary.deliveryDate
    )

    if (
      lastProcessedOrderSummary.userTermOrderSummary.orderStatus ===
        'complete' &&
      (isDateAfter(deliveryDate, now) ||
        isDateSame(deliveryDate, now, { units: 'days' }))
    ) {
      scheduledDelivery = lastProcessedOrderSummary
    }
  }

  return {
    scheduledDelivery,
  }
}
