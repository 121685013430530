import { ReactNode } from 'react'

const MenuGridLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="grid grid-cols-2 gap-x-8 gap-y-12 md:grid-cols-1 md:gap-12">
      {children}
    </div>
  )
}

export default MenuGridLayout
