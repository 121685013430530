import { post } from './baseAPI'

export interface SendMenuFeedback {
  data: {
    records: {
      fields: {
        createdAt: string
        rating: number
        termID: number
        source: 'web'
        userID: number
      }
    }[]
  }
  tableID: string
}

export type SendMenuFeedbackResponse = {
  fields: {
    createdAt: string
    rating: number
    termID: number
    source: 'web'
    userID: number
  }
}[]

export interface SendSamplerBoxOrder {
  data: {
    records: {
      fields: {
        userID: number
      }
    }[]
  }
  tableID: string
}

export type SendSamplerBoxOrderResponse = {
  fields: {
    userID: number
  }
}[]

export const ENDPOINTS = {
  SEND_MENU_FEEDBACK: () => ({
    path: '/tools/airtable/proxy',
    version: 'v1' as const,
  }),
  SEND_SAMPLER_BOX_ORDER: () => ({
    path: '/tools/airtable/proxy',
    version: 'v1' as const,
  }),
}

// The data here comes from the Airtable API Docs:
// https://airtable.com/appu5v4JI1D3GDkvp/api/docs#javascript/introduction
export async function sendMenuFeedback({
  data,
  tableID,
}: SendMenuFeedback): Promise<SendMenuFeedbackResponse> {
  return post({
    config: {
      headers: {
        'airtable-uri': `v0/appu5v4JI1D3GDkvp/${tableID}`,
      },
    },
    data,
    ...ENDPOINTS.SEND_MENU_FEEDBACK(),
  })
}

export async function sendSamplerBoxOrder({
  data,
  tableID,
}: SendSamplerBoxOrder): Promise<SendSamplerBoxOrderResponse> {
  return post({
    config: {
      headers: {
        'airtable-uri': `v0/appu5v4JI1D3GDkvp/${tableID}`,
      },
    },
    data,
    ...ENDPOINTS.SEND_SAMPLER_BOX_ORDER(),
  })
}
