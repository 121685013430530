import { OrderHistoryReceipt } from '@tovala/browser-apis-combinedapi'
import { useFormContext } from 'react-hook-form'

import { DATE_FORMATS, formatDate } from 'utils/dates'

import Chip from './Chip'

const AffectedDelivery = ({
  afterChange,
  receipts,
}: {
  afterChange?: () => void
  receipts: OrderHistoryReceipt[]
}) => {
  const { watch } = useFormContext()

  const affectedDelivery = watch('affectedDelivery')

  return (
    <div className="space-y-4">
      <h3 className="text-k/20_125">Affected Delivery</h3>

      {receipts.length ? (
        <div className="grid grid-cols-[repeat(auto-fill,minmax(80px,1fr))] gap-4">
          {receipts.map((receipt) => {
            return (
              <Chip
                key={receipt.termID}
                afterChange={() => {
                  if (afterChange) {
                    afterChange()
                  }
                }}
                checked={affectedDelivery === receipt.termID.toString()}
                label={formatDate(receipt.deliveryDate, {
                  format: DATE_FORMATS.MONTH_ABBR_DAY,
                })}
                name="affectedDelivery"
                value={receipt.termID.toString()}
              />
            )
          })}
        </div>
      ) : (
        <p>No recent deliveries found.</p>
      )}
    </div>
  )
}

export default AffectedDelivery
