import { ReactNode } from 'react'

import { ErrorCodeMessageMapCombinedAPI } from 'types/internal'

import { useAuth } from 'contexts/auth'
import { useDelayedBoolean } from 'hooks/general'
import { UserProvider } from 'contexts/user'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import Loader from './Loader'

const LOAD_USER_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page.',
    whatHappened: 'Unable to Load User Information',
    why: "We couldn't load your user information due to a technical issue on our end.",
  },
}

const UserLoader = ({ children }: { children: ReactNode }) => {
  const { isLoadingUser, loadUserError, user } = useAuth()

  const delayedIsLoadingUser = useDelayedBoolean({
    delay: 1000,
    value: isLoadingUser,
  })

  return (
    <>
      {delayedIsLoadingUser ? (
        <Loader />
      ) : user ? (
        <UserProvider user={user}>{children}</UserProvider>
      ) : loadUserError ? (
        <div className="mx-auto my-20 max-w-[976px] lg:px-4 md:my-6 md:mb-20">
          <APIErrorDisplay
            display="page"
            error={loadUserError}
            errorCodeMessageMap={LOAD_USER_ERRORS}
          />
        </div>
      ) : null}
    </>
  )
}

export default UserLoader
