import { Button, ButtonLoading } from '@tovala/component-library'
import { useForm } from 'react-hook-form'

import { events } from '../../../analytics/events'
import { getSubscriptionPreferences } from 'utils/subscriptions'
import { track } from 'utils/analytics'

import { useUpdatePlanPreferences } from 'hooks/combinedAPI/subscriptions'
import { useUser } from 'contexts/user'
import CheckboxRHF from 'components/common/CheckboxRHF'
import ConfirmationDialog, {
  ConfirmationBody,
  ConfirmationButtons,
  ConfirmationHeader,
} from '../../common/ConfirmationDialog'

interface FormData {
  premiumMealsOk: boolean
}

const SurchargeConfirmation = ({
  onClose,
  onConfirm,
}: {
  onClose(): void
  onConfirm(): void
}) => {
  const { user } = useUser()

  const planPreferences = getSubscriptionPreferences({ user })

  const { handleSubmit, register, watch } = useForm<FormData>({
    defaultValues: {
      premiumMealsOk: false,
    },
  })

  const premiumMealsOk = watch('premiumMealsOk')

  const {
    isLoading: isUpdatingPlanPreferences,
    mutate: updatePlanPreferences,
  } = useUpdatePlanPreferences()

  const onSubmit = (data: FormData) => {
    if (
      planPreferences &&
      planPreferences.premiumMealsOk !== data.premiumMealsOk
    ) {
      updatePlanPreferences({
        data: { ...planPreferences, premiumMealsOk: data.premiumMealsOk },
        userID: user.id,
      })

      track(events.CHANGES_PREMIUM_PREFERENCE)
    }

    onConfirm()
  }

  return (
    <ConfirmationDialog onRequestClose={onClose}>
      <ConfirmationHeader heading="Surcharges Okay?" onClickClose={onClose} />
      <ConfirmationBody>
        <div className="flex flex-col items-center space-y-6 py-20 md:py-12">
          <p className="mx-auto max-w-sm text-body-lg">
            Would you like to add this meal for an additional cost?
          </p>

          <form>
            <CheckboxRHF
              checked={premiumMealsOk}
              id="premiumMealsOk"
              label={<span className="pl-2">Don't ask me again</span>}
              name="premiumMealsOk"
              register={register}
            />
          </form>
        </div>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="grid grid-cols-2 gap-2">
          <Button buttonStyle="stroke" onClick={onClose} size="large">
            Cancel
          </Button>

          <ButtonLoading
            isLoading={isUpdatingPlanPreferences}
            onClick={handleSubmit(onSubmit)}
            size="large"
            type="submit"
          >
            Add Meal
          </ButtonLoading>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}

export default SurchargeConfirmation
