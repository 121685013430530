import { AddOn } from '@tovala/browser-apis-cdn'
import { Button } from '@tovala/component-library'
import { clsx } from 'clsx'
import { ReactNode } from 'react'

import { events, sourceIDs } from 'analytics/events'
import { getDarkenedBGImage, IMAGES } from 'utils/images'
import { getOrderCutoffFormattedDate } from 'utils/terms'
import { track } from 'utils/analytics'
import { UserTerm } from 'types/internal'

import { useVisibilityCallback } from 'hooks/general'
import ConfirmationDialog, {
  ConfirmationBody,
  ConfirmationHeader,
} from '../../common/ConfirmationDialog'
import Link from 'components/common/Link'

const MealSelectionsConfirmationDialog = ({
  nextActionPrompt,
  onClickViewSummary,
  onCloseConfirmation,
  openSource,
  selectedUserTerm,
}: {
  nextActionPrompt: ReactNode
  onClickViewSummary(): void
  onCloseConfirmation(): void
  openSource: 'orderSummary' | undefined
  selectedUserTerm: UserTerm
}) => {
  return (
    <ConfirmationDialog onRequestClose={onCloseConfirmation}>
      <ConfirmationHeader
        heading="Selections Confirmed"
        onClickClose={onCloseConfirmation}
      />
      <ConfirmationBody>
        <div
          className={clsx('flex flex-col items-center', {
            'space-y-6 py-6': nextActionPrompt,
            'space-y-20 p-6 pt-20 md:space-y-6 md:px-0 md:pt-6':
              !nextActionPrompt,
          })}
        >
          <p className="text-body-lg">
            You can edit{' '}
            {selectedUserTerm.nextLargestSubscriptionType ? 'or add more ' : ''}
            meals until
            <br />
            {getOrderCutoffFormattedDate({
              orderByDate: selectedUserTerm.orderByDate,
            })}
            .
          </p>

          {nextActionPrompt}

          <div
            className={clsx('grid h-11 w-full gap-6 text-k/14_120', {
              'grid-cols-2': openSource !== 'orderSummary',
            })}
          >
            {openSource !== 'orderSummary' && (
              <Button
                buttonStyle="stroke"
                onClick={() => {
                  track(events.VIEW_SUMMARY, {
                    source_id: sourceIDs.CONFIRMATION_POP_UP,
                  })
                  onClickViewSummary()
                }}
                size="fluid"
              >
                View Summary
              </Button>
            )}
            <Link
              onClick={() => {
                track(events.MY_ORDERS_CTA, {
                  source_id: sourceIDs.CONFIRMATION_POP_UP,
                })
              }}
              size="large"
              to="/my-orders"
            >
              My Orders
            </Link>
          </div>
        </div>
      </ConfirmationBody>
    </ConfirmationDialog>
  )
}

export default MealSelectionsConfirmationDialog

const NextActionPrompt = ({
  children,
  imageURL,
  prompt,
}: {
  children: ReactNode
  imageURL: string
  prompt: string
}) => {
  return (
    <div
      className="flex h-[190px] w-[450px] max-w-full flex-col items-center justify-between space-y-6 rounded-md bg-cover bg-center p-6 text-center"
      style={getDarkenedBGImage({ imageURL })}
    >
      <div className="flex grow items-center">
        <h2 className="text-k/28_110 text-white">{prompt}</h2>
      </div>

      {children}
    </div>
  )
}

export const AddOnPrompt = ({ addOn }: { addOn: AddOn }) => {
  const { elementRef } = useVisibilityCallback<HTMLDivElement>({
    onIsVisible: () => {
      // Pass
    },
    visibilityThreshold: 0.75,
  })

  return (
    <div ref={elementRef} className="w-full">
      <NextActionPrompt
        imageURL={addOn.productImage}
        prompt={addOn.productPrompt}
      >
        <Link linkStyle="white" size="medium" to={addOn.myTovalaURLInfo.path}>
          {addOn.promptButtonText}
        </Link>
      </NextActionPrompt>
    </div>
  )
}

export const WantMoreMealsPrompt = ({
  onClickAddMoreMeals,
}: {
  onClickAddMoreMeals(): void
}) => {
  const { elementRef } = useVisibilityCallback<HTMLDivElement>({
    onIsVisible: () => {
      track(events.DID_DISPLAY_CONFIRMATION_UPSELL_BANNER)
    },
    visibilityThreshold: 0.75,
  })

  return (
    <div ref={elementRef} className="w-full">
      <NextActionPrompt
        imageURL={IMAGES.WANT_MORE_MEALS}
        prompt="Want more meals this week?"
      >
        <Button
          buttonStyle="white"
          onClick={() => {
            track(events.TAPPED_UPSELL_BUTTON)
            onClickAddMoreMeals()
          }}
          size="medium"
        >
          Add More Meals
        </Button>
      </NextActionPrompt>
    </div>
  )
}
