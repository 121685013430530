import { Suspense, lazy, useEffect, useRef } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  Routes as RouterRoutes,
  useLocation,
  useSearchParams,
} from 'react-router-dom'

import { events } from 'analytics/events'
import { track } from 'utils/analytics'

import { useAuth } from 'contexts/auth'
import CorePages from 'components/core/CorePages'
const DMCACopyrightPolicyPage = lazy(
  () => import('components/legal/DMCACopyrightPolicyPage')
)
import ForgotPasswordPage from 'components/auth/ForgotPasswordPage'
import GiftCardPurchasePage from 'components/core/GiftCardPurchasePage'
import LoginPage from 'components/auth/LoginPage'
import LogoutPage from 'components/auth/LogoutPage'
import Marketplace from 'components/marketplace/Marketplace'
const PrivacyPolicyPage = lazy(
  () => import('components/legal/PrivacyPolicyPage')
)
const PromotionalTermsPage = lazy(
  () => import('components/legal/PromotionalTermsPage')
)
const PurchaseTermsPage = lazy(
  () => import('components/legal/PurchaseTermsPage')
)
const RedemptionTermsOfServicePage = lazy(
  () => import('components/legal/RedemptionTermsOfServicePage')
)
const ReferralSweepsPage = lazy(
  () => import('components/legal/ReferralSweepsPage')
)
import RegisterPage from 'components/auth/RegisterPage'
import ResetPasswordPage from 'components/auth/ResetPasswordPage'
import DownloadAppPage from './components/miscellaneous/DownloadAppPage'
import GetStartedRetailPage from './components/miscellaneous/GetStartedRetailPage'
const SearchGroceryItemsPage = lazy(
  () => import('components/miscellaneous/SearchGroceryItemsPage')
)
const TermsOfServicePage = lazy(
  () => import('components/legal/TermsOfServicePage')
)
const TrialTermsOfServicePage = lazy(
  () => import('components/legal/TrialTermsOfServicePage')
)

const REGISTER_PAGE_PATHNAME = '/register'

const Routes = () => {
  const { onRegistrationComplete, onRegistrationStepChanged } =
    useExitsRegistrationFlow()

  return (
    <RouterRoutes>
      {/* Authentication Routes */}
      <Route element={<LoginPage />} path="/login" />
      <Route element={<ForgotPasswordPage />} path="/forgot-password" />
      <Route
        element={
          <RegisterPage
            onRegistrationComplete={onRegistrationComplete}
            onRegistrationStepChanged={onRegistrationStepChanged}
          />
        }
        path={REGISTER_PAGE_PATHNAME}
      />
      <Route
        element={<ResetPasswordPage />}
        path="/reset-password/:resetToken"
      />

      {/* Legal Pages */}
      <Route
        element={
          <Suspense>
            <DMCACopyrightPolicyPage />
          </Suspense>
        }
        path="/legal/dmca-copyright-policy"
      />
      <Route
        element={
          <Suspense>
            <TermsOfServicePage />
          </Suspense>
        }
        path="/legal/terms-of-service"
      />
      <Route
        element={
          <Suspense>
            <TrialTermsOfServicePage />
          </Suspense>
        }
        path="/legal/trial-terms-of-service"
      />
      <Route
        element={
          <Suspense>
            <RedemptionTermsOfServicePage />
          </Suspense>
        }
        path="/legal/redemption-terms-of-service"
      />
      <Route
        element={
          <Suspense>
            <PrivacyPolicyPage />
          </Suspense>
        }
        path="/legal/privacy-policy"
      />
      <Route
        element={
          <Suspense>
            <PurchaseTermsPage />
          </Suspense>
        }
        path="/legal/purchase-terms"
      />
      <Route
        element={
          <Suspense>
            <PromotionalTermsPage />
          </Suspense>
        }
        path="/legal/promotional-terms"
      />
      <Route
        element={
          <Suspense>
            <ReferralSweepsPage />
          </Suspense>
        }
        path="/legal/referral-sweeps"
      />

      {/* Miscellaneous */}
      <Route
        element={
          <Suspense>
            <SearchGroceryItemsPage />
          </Suspense>
        }
        path="/search-grocery-items"
      />

      <Route element={<DownloadAppPage />} path="/app-download" />
      <Route element={<GetStartedRetailPage />} path="/retail-get-started" />

      {/* Mobile deep-links */}
      <Route
        element={<Navigate to="/account/apply-promo" />}
        path="/mapp/settings/mealPlan/redeem"
      />

      <Route element={<AuthenticatedRoute />}>
        <Route element={<LogoutPage />} path="/logout" />
        <Route element={<Marketplace />} path="/marketplace/*" />
        <Route
          element={<Navigate replace to="/my-orders" />}
          path="/greatest-hits-box/*"
        />
        <Route element={<GiftCardPurchasePage />} path="/gift-cards/purchase" />
        <Route element={<CorePages />} path="/*" />
      </Route>
    </RouterRoutes>
  )
}

export default Routes

function AuthenticatedRoute() {
  const { isLoggedIn } = useAuth()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  if (!isLoggedIn) {
    let rewrittenSearchParams = ''
    const meal = searchParams.get('meal')
    if (meal !== null) {
      rewrittenSearchParams = `?meal=${meal}`
    }

    const redirect =
      location.pathname === '' ||
      location.pathname === '/login' ||
      location.pathname === '/logout'
        ? ''
        : `?redirect=${location.pathname}${rewrittenSearchParams}`

    return <Navigate replace to={`/login${redirect}`} />
  }

  return <Outlet />
}

// I wish this logic could live inside the RegisterPage component, but there doesn't
// seem to be a reliable way to detect when the path changes since the component will
// be unmounted before we can fire any events. And using a useEffect in that component
// will trigger the event more than we want since effects are triggered twice in strict
// mode.

function useExitsRegistrationFlow() {
  const location = useLocation()
  const lastPathname = useRef('')
  const lastRegistrationStep = useRef<number | null>(null)

  useEffect(() => {
    if (
      lastRegistrationStep.current &&
      lastPathname.current === REGISTER_PAGE_PATHNAME &&
      location.pathname !== REGISTER_PAGE_PATHNAME
    ) {
      track(events.USER_EXITS_REGISTER_FLOW, {
        step: lastRegistrationStep.current,
      })
    }

    lastPathname.current = location.pathname
  }, [location.pathname])

  useEffect(() => {
    function onBeforeUnload() {
      if (lastRegistrationStep.current) {
        track(events.USER_EXITS_REGISTER_FLOW, {
          step: lastRegistrationStep.current,
        })
      }
    }

    // This may not fire often because it's up to the browser on whether or
    // not this event will be respected on a page reload.
    window.addEventListener('beforeunload', onBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [])

  return {
    onRegistrationComplete: () => {
      lastRegistrationStep.current = null
    },
    onRegistrationStepChanged: (newStep: number) => {
      lastRegistrationStep.current = newStep
    },
  }
}
