import { AnchorHTMLAttributes } from 'react'
import { clsx } from 'clsx'
import { Link as ReactRouterLink } from 'react-router-dom'

type LinkSize = 'large' | 'medium' | 'small'
type LinkStyle = 'dark' | 'gray' | 'orange' | 'stroke' | 'white'
export type LinkType = 'external' | 'internal'

export type Props = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
  linkStyle?: LinkStyle
  linkType?: LinkType
  size: LinkSize
  to: string
}

const COLOR_CLASS_MAP: { [key in LinkStyle]: string } = {
  dark: 'bg-black text-white hover:bg-grey-10 transition-colors',
  gray: 'bg-grey-3 text-black hover:bg-grey-5 transition-colors',
  orange: 'bg-orange-1 text-white transition-colors hover:bg-orange-2',
  stroke:
    'border border-black text-black hover:border-grey-6 transition-colors',
  white: 'bg-white text-black hover:bg-grey-5 transition-colors',
}

const HEIGHT_MAP: { [key in LinkSize]: string } = {
  large: 'h-11 px-12 text-k/14_120',
  medium: 'h-9 px-8 text-sm',
  small: 'h-7 px-6 text-xs',
}

const Link = ({
  linkStyle = 'dark',
  linkType = 'internal',
  size,
  to,
  ...rest
}: Props): JSX.Element => {
  if (linkType === 'external') {
    return (
      <a
        className={clsx(
          'flex items-center justify-center whitespace-nowrap rounded-full',
          COLOR_CLASS_MAP[linkStyle],
          HEIGHT_MAP[size]
        )}
        href={to}
        {...rest}
      />
    )
  }

  return (
    <ReactRouterLink
      className={clsx(
        'flex items-center justify-center whitespace-nowrap rounded-full',
        COLOR_CLASS_MAP[linkStyle],
        HEIGHT_MAP[size]
      )}
      to={to}
      {...rest}
    />
  )
}

export default Link
