import { ButtonLoading } from '@tovala/component-library'
import { useForm } from 'react-hook-form'
import { useUpdateUser, UserV1 } from '@tovala/browser-apis-combinedapi'

import { ErrorCodeMessageMapCombinedAPI } from 'types/internal'
import { validateNameIsNotURL } from 'utils/user'
import { wrapWithContactSupportTeam } from 'utils/errors'

import { useToast } from 'contexts/toast'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import FormInputRHF from 'components/common/FormInputRHF'

type FormValues = {
  email: string
  name: string
}

const UPDATE_USER_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  EmailInvalid: {
    helpToFix: 'Please double-check your email address and try again.',
    wayOut: wrapWithContactSupportTeam('If you need further assistance'),
    why: "We couldn't save your email because the email you entered is invalid.",
  },
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't update your information due to a technical issue on our end.",
  },
  UniqueConstraint: {
    helpToFix: 'Please double-check your email address and try again.',
    wayOut: wrapWithContactSupportTeam('If you need further assistance'),
    why: "We couldn't save your email because the email you entered is already in use.",
  },
}

const UpdateEmailForm = ({
  closeCollapsible,
  user,
}: {
  closeCollapsible: () => void
  user: UserV1
}) => {
  const { openToast } = useToast()

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    defaultValues: {
      email: user.info.email,
      name: user.info.name,
    },
  })

  const {
    error: updateUserError,
    isError: hasUpdateUserError,
    isLoading: updateUserLoading,
    mutate: updateUserRecord,
  } = useUpdateUser({
    onSuccess: () => {
      openToast({
        heading: 'Changes Saved',
        message: 'Your information has been updated.',
        type: 'success',
      })

      closeCollapsible()
    },
  })

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit((data) =>
        updateUserRecord({ data, userID: user.id })
      )}
    >
      <FormInputRHF
        error={errors?.email?.message}
        id="email"
        label="Email"
        labelFor="email"
        type="email"
        {...register('email', { required: 'Please enter an email' })}
      />

      <FormInputRHF
        error={errors?.name?.message}
        id="name"
        label="Name"
        labelFor="name"
        type="text"
        {...register('name', {
          required: 'Please enter a name',
          validate: (value) =>
            validateNameIsNotURL(value, 'Please enter a valid name'),
        })}
      />

      {hasUpdateUserError && (
        <div className="mt-4">
          <APIErrorDisplay
            error={updateUserError}
            errorCodeMessageMap={UPDATE_USER_ERRORS}
          />
        </div>
      )}

      <ButtonLoading isLoading={updateUserLoading} size="large" type="submit">
        Save
      </ButtonLoading>
    </form>
  )
}

export default UpdateEmailForm
