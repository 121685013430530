import Link from 'components/common/Link'

const TermSoldOutSidebarBody = ({
  onClickMyOrders,
}: {
  onClickMyOrders(): void
}) => {
  return (
    <div className="flex flex-col items-center space-y-4">
      <div className="text-center">
        <p>
          <strong>Sorry, this week is sold out.</strong>
        </p>
        <p>Pick meals for future weeks from the My Orders page.</p>
      </div>

      <Link onClick={onClickMyOrders} size="large" to="/my-orders">
        My Orders
      </Link>
    </div>
  )
}

export default TermSoldOutSidebarBody
