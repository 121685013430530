import { forwardRef, ReactNode } from 'react'

import FormGroup from './FormGroup'
import Input, { Props as InputProps } from './Input'

const FormInputRHF = forwardRef<
  HTMLInputElement,
  Omit<InputProps, 'hasError'> & {
    error?: string
    label?: ReactNode
    labelFor?: string
  }
>(function FormInputRHF({ error, label, labelFor, ...props }, ref) {
  const hasError = !!error
  return (
    <FormGroup error={error} label={label} labelFor={labelFor}>
      <Input ref={ref} {...props} hasError={hasError} />
    </FormGroup>
  )
})

export default FormInputRHF
