import { cloneDeep } from 'lodash-es'
import { Customer } from '@tovala/browser-apis-combinedapi'
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

import { getCustomer } from 'services/combinedAPI/customers'
import { minutesToMs } from 'utils/general'

export function useCustomer({
  customerID,
  userID,
  ...rest
}: { customerID: string | undefined; userID: number | undefined } & Omit<
  UseQueryOptions<Customer, Error>,
  'enabled' | 'queryFn' | 'queryKey' | 'staleTime'
>) {
  return useQuery<Customer, Error>({
    ...rest,
    enabled: !!(customerID && userID),
    queryFn: () => {
      if (!customerID || !userID) {
        throw new Error(
          'Could not fetch customer information because no customer ID or user ID was provided'
        )
      }

      return getCustomer({ customerID, userID })
    },
    queryKey: ['customers', userID, customerID],
    staleTime: minutesToMs(1),
  })
}

export function useInvalidateCustomers() {
  const queryClient = useQueryClient()

  return {
    invalidateCustomer: ({
      customerID,
      shippingInfo,
      userID,
    }: {
      customerID: string
      shippingInfo?: {
        address: {
          city: string
          line1: string
          line2: string
          postal_code: string
          state: string
        }
        name: string
        phone: string
      }
      userID: number
    }) => {
      const queryKey = ['customers', userID, customerID]

      if (shippingInfo) {
        queryClient.setQueryData<Customer>(queryKey, (customer) => {
          if (!customer) {
            return customer
          }

          const newCustomer = cloneDeep(customer)

          newCustomer.shipping = {
            address: {
              ...shippingInfo.address,
              country: customer.shipping.address.country,
            },
            name: shippingInfo.name,
            phone: shippingInfo.phone,
          }
        })
      }

      queryClient.invalidateQueries(queryKey)
    },
  }
}
