import {
  Button,
  ButtonLoading,
  FormFieldError,
} from '@tovala/component-library'
import { clsx } from 'clsx'
import {
  Controller,
  ControllerProps,
  FieldErrorsImpl,
  FieldValues,
  Path,
  useForm,
} from 'react-hook-form'
import { Dialog } from '@headlessui/react'
import { ReactNode, useState } from 'react'

import { convertBooleanToString } from 'utils/general'
import {
  ErrorCodeMessageMapCombinedAPI,
  SubscriptionPreferences,
} from 'types/internal'
import { IMAGES } from 'utils/images'

import { useToast } from 'contexts/toast'
import { useUpdatePlanPreferences } from 'hooks/combinedAPI/subscriptions'
import { useUser } from 'contexts/user'
import APIErrorDisplay from 'components/common/APIErrorDisplay'

interface FormData {
  autofillBreakfastOK: string | null
  autofillSurchargeOK: string | null
  doNotReplace: string | null
  isDouble: string | null
}

const UPDATE_PREFERENCES_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't save your preferences due to a technical issue on our end.",
  },
}

const MealPlanPreferencesModal = ({
  closeModal,
  planPreferences,
}: {
  closeModal(): void
  planPreferences: SubscriptionPreferences
}) => {
  const { openToast } = useToast()

  const { user } = useUser()

  const {
    control,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useForm<FormData>({
    defaultValues: {
      autofillBreakfastOK: convertBooleanToString(
        planPreferences.autofillBreakfastOK
      ),
      autofillSurchargeOK: convertBooleanToString(
        planPreferences.autofillSurchargeOK
      ),
      doNotReplace: convertBooleanToString(planPreferences.doNotReplace),
      isDouble: convertBooleanToString(planPreferences.isDouble),
    },
    mode: 'all',
  })

  const [step, setStep] = useState(0)

  const {
    error: updatePlanPreferencesError,
    isError: hasUpdatePlanPreferencesError,
    isLoading: isUpdatingPlanPreferences,
    mutate: updatePlanPreferences,
  } = useUpdatePlanPreferences({
    onSuccess: () => {
      openToast({
        heading: 'Changes Saved',
        message: 'Your meal plan settings have been updated.',
        type: 'success',
      })

      closeModal()
    },
  })

  const onSubmit = (values: FormData) => {
    updatePlanPreferences({
      data: {
        autofillBreakfastOK: values.autofillBreakfastOK === 'true',
        autofillSurchargeOK: values.autofillSurchargeOK === 'true',
        defaultShipPeriod: planPreferences.defaultShipPeriod,
        doNotReplace: values.doNotReplace === 'true',
        hasBlackSheetTray: planPreferences.hasBlackSheetTray,
        isDouble: values.isDouble === 'true',
        premiumMealsOk: planPreferences.premiumMealsOk,
        single: values.isDouble === 'false',
      },
      userID: user.id,
    })
  }

  return (
    <Dialog onClose={closeModal} open={true}>
      <div
        className="fixed inset-0 z-30 bg-black opacity-40"
        onClick={closeModal}
      />
      <div className="fixed inset-0 z-30 flex items-center justify-center md:inset-auto md:bottom-0 md:m-1">
        <Dialog.Panel>
          <form>
            <div>
              {step === 0 && (
                <div className="flex max-w-[504px] flex-col rounded-2xl bg-white p-6 pt-10">
                  <Dialog.Title className="mb-6 px-16 text-center text-k/28_130 md:text-k/24_120">
                    Thanks for starting your meal plan.
                  </Dialog.Title>

                  <img
                    alt=""
                    className="rounded-xl"
                    src={IMAGES.MEAL_PLAN_PREFERENCES_START}
                  />

                  <p className="mb-10 mt-6 text-center text-k/20_125 text-grey-8">
                    Please take one minute to review your meal plan settings,
                    which apply to all future deliveries.
                  </p>
                  <Button onClick={() => setStep(1)} size="large">
                    Update Your Meal Plan Settings
                  </Button>
                </div>
              )}

              {step === 1 && (
                <Step>
                  <div className="p-6">
                    <StepHeading>1/4: Autoselected Meals</StepHeading>
                    <Heading>
                      If we need to, how should we select meals for you?
                    </Heading>
                    <Description>
                      For weeks that you don't choose meals, don't skip, or
                      don't have your meal plan paused, we'll automatically send
                      you a chef-curated selection of meals and charge your
                      card.
                    </Description>
                  </div>
                  <div className="p-6">
                    <RadioGroup
                      control={control}
                      errors={errors}
                      name="isDouble"
                      options={[
                        {
                          id: 'double',
                          label: 'Paired meals',
                          labelDescription:
                            "We'll send you meals in pairs. \n(Ideal for two people who eat together.)",
                          value: 'true',
                        },
                        {
                          id: 'single',
                          label: 'Assorted meals',
                          labelDescription: "We'll send you one of each meal.",
                          value: 'false',
                        },
                      ]}
                    />
                    <ButtonContainer>
                      <Button
                        onClick={async () => {
                          const valid = await trigger('isDouble')
                          if (valid) {
                            setStep(2)
                          }
                        }}
                        size="large"
                      >
                        Continue
                      </Button>
                    </ButtonContainer>
                  </div>
                </Step>
              )}

              {step === 2 && (
                <Step>
                  <div className="p-6">
                    <StepHeading>2/4: Autoselected Meals</StepHeading>
                    <Heading>
                      Would you like us to select meals with a surcharge for
                      you?
                    </Heading>
                    <Description>
                      Meals are usually $12.99, but we also offer higher-end
                      options featuring more expensive ingredients. For those
                      meals, we add a surcharge (typically, no more than $4.99).
                    </Description>
                  </div>
                  <div className="p-6">
                    <RadioGroup
                      control={control}
                      errors={errors}
                      name="autofillSurchargeOK"
                      options={[
                        {
                          id: 'surchargeOK',
                          label: "I'm fine with receiving surcharged meals.",
                          labelDescription:
                            'This option allows for more variety when we select meals for you.',
                          value: 'true',
                        },
                        {
                          id: 'surchargeNotOK',
                          label: "Don't send me surcharged meals.",
                          labelDescription:
                            "Got it! We'll only send you meals priced at $12.99.",
                          value: 'false',
                        },
                      ]}
                    />
                    <ButtonContainer>
                      <Button
                        onClick={async () => {
                          const valid = await trigger('autofillSurchargeOK')
                          if (valid) {
                            setStep(3)
                          }
                        }}
                        size="large"
                      >
                        Continue
                      </Button>
                    </ButtonContainer>
                  </div>
                </Step>
              )}

              {step === 3 && (
                <Step>
                  <div className="p-6">
                    <StepHeading>3/4: Autoselected Meals</StepHeading>
                    <Heading>How about breakfast meals?</Heading>
                    <Description>
                      Tovala Meals are great for lunch and dinner, but we can
                      also include breakfast options when we select meals for
                      you.
                    </Description>
                  </div>
                  <div className="p-6">
                    <RadioGroup
                      control={control}
                      errors={errors}
                      name="autofillBreakfastOK"
                      options={[
                        {
                          id: 'breakfastOK',
                          label: "I'm fine with receiving breakfast meals.",
                          labelDescription:
                            'Breakfast meals are subject to availability.',
                          value: 'true',
                        },
                        {
                          id: 'breakfastNotOK',
                          label: "Don't select breakfast meals for me.",
                          labelDescription:
                            "Got it! We'll only send you meals best for lunch and dinner.",
                          value: 'false',
                        },
                      ]}
                    />
                    <ButtonContainer>
                      <Button
                        onClick={async () => {
                          const valid = await trigger('autofillBreakfastOK')
                          if (valid) {
                            setStep(4)
                          }
                        }}
                        size="large"
                      >
                        Continue
                      </Button>
                    </ButtonContainer>
                  </div>
                </Step>
              )}

              {step === 4 && (
                <Step>
                  <div className="p-6">
                    <StepHeading>4/4: Meal Substitutions</StepHeading>
                    <Heading>It's not likely, but just in case...</Heading>
                    <Description>
                      In the very rare case a meal you select becomes
                      unavailable, please let us know what you'd prefer.
                    </Description>
                  </div>
                  <div className="space-y-4 p-6">
                    <RadioGroup
                      control={control}
                      errors={errors}
                      name="doNotReplace"
                      options={[
                        {
                          id: 'replace',
                          label: "I'm fine with a meal substitution.",
                          labelDescription:
                            'For those with dietary restrictions, please note we cannot guarantee substitutions will be similar to your original selection.',
                          value: 'true',
                        },
                        {
                          id: 'noReplace',
                          label:
                            "Don't substitute. I'd rather receive fewer meals.",
                          labelDescription:
                            "You will not be charged for any meals you don't receive.",
                          value: 'false',
                        },
                      ]}
                    />

                    {hasUpdatePlanPreferencesError && (
                      <APIErrorDisplay
                        error={updatePlanPreferencesError}
                        errorCodeMessageMap={UPDATE_PREFERENCES_ERRORS}
                      />
                    )}

                    <ButtonContainer>
                      <ButtonLoading
                        isLoading={isUpdatingPlanPreferences}
                        onClick={(event) => {
                          handleSubmit(onSubmit)(event)
                        }}
                        size="large"
                        type="submit"
                      >
                        Update Settings
                      </ButtonLoading>
                    </ButtonContainer>
                  </div>
                </Step>
              )}
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default MealPlanPreferencesModal

const ButtonContainer = ({ children }: { children: ReactNode }) => {
  return <div className="mt-4 flex flex-col md:mt-10">{children}</div>
}

const Step = ({ children }: { children: ReactNode }) => {
  return (
    <div className="grid max-w-[817px] grid-cols-2 gap-10 divide-x divide-grey-3 rounded-2xl bg-white md:grid-cols-1 md:gap-1">
      {children}
    </div>
  )
}

const StepHeading = ({ children }: { children: ReactNode }) => {
  return (
    <div className="mb-3 text-k/14_120 uppercase md:border-b md:border-grey-3 md:pb-6 md:text-center">
      {children}
    </div>
  )
}

const Heading = ({ children }: { children: ReactNode }) => {
  return <h3 className="mb-4 text-k/28_110 md:text-center">{children}</h3>
}

const Description = ({ children }: { children: ReactNode }) => {
  return <p className="text-k/14_120 text-grey-8 md:text-center">{children}</p>
}

function RadioGroup<FormData extends FieldValues>({
  control,
  errors,
  name,
  options,
}: {
  control: ControllerProps<FormData>['control']
  errors: FieldErrorsImpl<FormData>
  name: Path<FormData>
  options: {
    id: string
    label: string
    labelDescription: string
    value: string
  }[]
}) {
  return (
    <div className="space-y-2">
      <div className="rounded-xl bg-grey-2">
        {options.map(({ id, label, labelDescription, value }, index) => {
          return (
            <Controller
              key={id}
              control={control}
              name={name}
              render={({
                field: {
                  onChange,
                  onBlur,
                  value: inputValue,
                  name: inputName,
                  ref,
                },
              }) => {
                const checked = value === inputValue
                return (
                  <label
                    key={id}
                    className={clsx(
                      'flex cursor-pointer p-4',
                      index === 0 && 'border-b border-grey-4'
                    )}
                  >
                    <div className="flex-auto pr-4">
                      <span className="block text-k/16_125">{label}</span>
                      <span className="text-k/14_120 text-grey-9">
                        {labelDescription}
                      </span>
                    </div>

                    <input
                      ref={ref}
                      checked={checked}
                      className="absolute h-0 w-0 cursor-pointer opacity-0"
                      id={id}
                      name={inputName}
                      onBlur={onBlur}
                      onChange={onChange}
                      required
                      type="radio"
                      value={value}
                    />

                    {/* Create a custom radio. */}
                    <div className="mt-3 flex h-6 w-6 shrink-0 items-center justify-center rounded-full border border-grey-7 bg-white">
                      {checked && (
                        <div className="h-4 w-4 rounded-full bg-orange-2" />
                      )}
                    </div>
                  </label>
                )
              }}
              rules={{
                required: true,
              }}
            />
          )
        })}
      </div>

      {errors[name] && <FormFieldError>Please select an option</FormFieldError>}
    </div>
  )
}
