import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

export type ScreenSizes = '2xl' | 'lg' | 'md' | 'sm' | 'xl' | 'xs'

type ScreensMax = {
  [key in ScreenSizes]: string
}

const orderedScreens: ScreenSizes[] = ['xs', 'sm', 'md', 'lg', 'xl', '2xl']

/**
 * Gets the max width values from our Tailwind config file. If we add additional
 * screens, we'll need to update this, but we won't have to worry about keeping changes
 * to max values of existing screens in sync. Variables are defined in index.css.
 * https://github.com/tailwindlabs/tailwindcss/discussions/8674
 */
const style = window.getComputedStyle(document.documentElement)
const screensMax: ScreensMax = {
  xs: style.getPropertyValue('--screens-xs').trim(),
  sm: style.getPropertyValue('--screens-sm').trim(),
  md: style.getPropertyValue('--screens-md').trim(),
  lg: style.getPropertyValue('--screens-lg').trim(),
  xl: style.getPropertyValue('--screens-xl').trim(),
  '2xl': style.getPropertyValue('--screens-2xl').trim(),
}

const ScreenSizeContext = createContext<ScreenSizes | undefined>(undefined)

const ScreenSizeProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const screenSize = useMatchedMediaQuery()

  return (
    <ScreenSizeContext.Provider value={screenSize}>
      {children}
    </ScreenSizeContext.Provider>
  )
}

function useScreenSize() {
  const context = useContext(ScreenSizeContext)
  if (context === undefined) {
    throw new Error('useScreenSize must be used in an ScreenSizeProvider')
  }

  return context
}

export { ScreenSizeProvider, useScreenSize }

/**
 * Uses the provided media values (screen size and desired variant, for example button size)
 * to create MediaQueryLists using matchMedia (https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia)
 * and listen for changes on those MediaQueryLists, applying the applicable variant on each screen size.
 *
 * Inspired by https://dev.to/justincy/4-patterns-for-responsive-props-in-react-39ak#object-props
 * and https://usehooks.com/useMedia/.
 */
export const useMatchedMediaQuery = () => {
  const allQueries = orderedScreens.map((screen) => {
    return `(max-width: ${screensMax[screen]})`
  })

  const mediaQueryLists = allQueries.map((q) => {
    return window.matchMedia(q)
  })

  const [matchedIndex, setMatchedIndex] = useState(() => {
    return mediaQueryLists.findIndex((mql) => {
      return mql.matches
    })
  })

  useEffect(() => {
    const handler = () =>
      setMatchedIndex(() => {
        return mediaQueryLists.findIndex((mql) => mql.matches)
      })

    // Set a listener for each media query with above handler as callback.
    mediaQueryLists.forEach((mql) => {
      // The MediaQueryListEvent API is not supported in Safari < 14.
      // See https://caniuse.com/mdn-api_mediaquerylistevent.
      if (mql.addEventListener) {
        mql.addEventListener('change', handler)
      }
    })

    return () =>
      mediaQueryLists.forEach((mql) => {
        if (mql.removeEventListener) {
          mql.removeEventListener('change', handler)
        }
      })
  }, [mediaQueryLists])

  const screenSize = orderedScreens[matchedIndex]

  return screenSize || '2xl'
}
