import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'

import { hasFailedToLoadStripe } from 'utils/stripe'

import ErrorDisplay from './ErrorDisplay'

const CreditCardInput = () => {
  // We need separate state for checking if Stripe failed to load since the
  // "stripe" variable from "useStripe" will be null while Stripe is loading.
  const [failedToLoadStripe, setFailedToLoadStripe] = useState(false)

  const elements = useElements()
  const stripe = useStripe()

  useEffect(() => {
    if (stripe === null) {
      setFailedToLoadStripe(hasFailedToLoadStripe())
    }
  }, [stripe])

  return (
    <div className="space-y-4">
      {failedToLoadStripe ? (
        <ErrorDisplay
          helpToFix="You may have a browser extension blocking our script, but please reload the page to try again."
          why="We can't add a new card to your account because we couldn't load our payment processor."
        />
      ) : (
        <div
          className="cursor-text rounded-lg border border-grey-4 p-4"
          onClick={() => {
            // We can't add padding to the CardElement since it's not a supported CSS property
            // but we want a larger click area for our users, so we manually focus the element
            // when we get a click in this container.
            elements?.getElement('card')?.focus()
          }}
        >
          <CardElement
            options={{
              style: {
                base: {
                  color: '#6F6F6A',
                  fontFamily: 'Sohne, sans-serif',
                  fontSize: '14px',
                  letterSpacing: '0.02em',
                  '::placeholder': {
                    color: '#6F6F6A',
                  },
                },
              },
            }}
          />
        </div>
      )}
    </div>
  )
}

export default CreditCardInput
