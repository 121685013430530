import { Loader } from '@googlemaps/js-api-loader'

import { getEnvVar } from './env'

const loader = new Loader({
  apiKey: getEnvVar('GOOGLE_MAPS_API_KEY'),
  libraries: ['places'],
})

let autocompleteService: google.maps.places.AutocompleteService | undefined
let geocoder: google.maps.Geocoder | undefined
let googleService: typeof google | undefined

export interface GeocodedPlace {
  city: string
  line1: string
  state: string
  zipCode: string
}

function findGeocodeAddressComponent({
  components,
  type,
}: {
  components: google.maps.GeocoderAddressComponent[]
  type: string
}) {
  return components.find(({ types }) => types.includes(type))
}

async function loadAutocomplete() {
  if (!autocompleteService) {
    const google = await loadGoogle()

    autocompleteService = new google.maps.places.AutocompleteService()
  }

  return autocompleteService
}

async function loadGeocoder() {
  if (!geocoder) {
    const google = await loader.load()

    geocoder = new google.maps.Geocoder()
  }

  return geocoder
}

async function loadGoogle() {
  if (!googleService) {
    googleService = await loader.load()
  }

  return googleService
}

export async function geocodePlace({ placeID }: { placeID: string }) {
  const geocoder = await loadGeocoder()
  const geocodeResponse = await geocoder.geocode({ placeId: placeID })

  if (geocodeResponse.results.length === 0) {
    return null
  }

  const components = geocodeResponse.results[0].address_components

  const streetNumber =
    findGeocodeAddressComponent({ components, type: 'street_number' })
      ?.long_name ?? ''
  const street =
    findGeocodeAddressComponent({ components, type: 'route' })?.short_name ?? ''

  const geocodedPlace: GeocodedPlace = {
    city:
      findGeocodeAddressComponent({ components, type: 'locality' })
        ?.long_name ?? '',
    line1: streetNumber && street ? `${streetNumber} ${street}` : '',
    state:
      findGeocodeAddressComponent({
        components,
        type: 'administrative_area_level_1',
      })?.short_name ?? '',
    zipCode:
      components.find(({ types }) => types.includes('postal_code'))
        ?.long_name ?? '',
  }

  return geocodedPlace
}

export async function getAutocompleteSuggestions({ query }: { query: string }) {
  const autocompleteService = await loadAutocomplete()

  return autocompleteService.getPlacePredictions({
    componentRestrictions: { country: ['us'] },
    input: query,
    types: ['geocode'],
  })
}
