import { clsx } from 'clsx'
import { forwardRef, TextareaHTMLAttributes } from 'react'

export type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  hasError?: boolean
}

const Textarea = forwardRef<HTMLTextAreaElement, Props>(function Textarea(
  { hasError = false, ...rest },
  ref
) {
  return (
    <textarea
      ref={ref}
      className={clsx('w-full rounded-lg border bg-grey-0 p-4 text-k/14_120', {
        'border-red': hasError,
        'border-grey-4': !hasError,
      })}
      {...rest}
    />
  )
})

export default Textarea
