const BackgroundImageHeader = ({
  image,
  subtitle,
  subtitleColor,
  textShadow,
  title,
  titleColor,
}: {
  image: { url: string }
  subtitle: string
  subtitleColor?: string
  textShadow?: { color: string; radius: number }
  title: string
  titleColor?: string
}) => {
  return (
    <div
      className="h-full w-full space-y-6 rounded-2xl bg-cover bg-center px-6 py-10 text-center md:rounded-none"
      style={{
        backgroundImage: `url(${image.url})`,
        textShadow: textShadow
          ? `0 0 ${textShadow.radius}px ${textShadow.color}`
          : undefined,
      }}
    >
      <div
        className="whitespace-pre-line text-k/36_110 md:text-h/38_95"
        style={{ color: titleColor }}
      >
        {title}
      </div>
      {subtitle && (
        <p
          className="whitespace-pre-line text-k/16_125"
          style={{ color: subtitleColor }}
        >
          {subtitle}
        </p>
      )}
    </div>
  )
}

export default BackgroundImageHeader
