import { Button } from '@tovala/component-library'
import { ComponentProps, Fragment, ReactNode, useState } from 'react'
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'

import { events } from 'analytics/events'
import { track } from 'utils/analytics'

import { useUser } from 'contexts/user'
import Collapsible, {
  CollapsibleContent,
  CollapsibleTrigger,
} from 'components/common/Collapsible'
import DeactivateAccountModal from './DeactivateAccountModal'
import DeleteAccountModal from './DeleteAccountModal'
import ProfileHeading from './AccountHeading'
import UpdateEmailForm from './UpdateEmailForm'
import UpdatePasswordForm from './UpdatePasswordForm'

const ProfilePage = () => {
  const navigate = useNavigate()
  const { user } = useUser()

  const [dialogType, setDialogType] = useState<'deactivate' | 'delete' | null>(
    null
  )

  return (
    <>
      {dialogType === 'deactivate' && (
        <DeactivateAccountModal
          onClickClose={() => {
            track(events.TAPS_DEACTIVATE_ACCOUNT_CANCEL)
            setDialogType(null)
          }}
          userID={user.id}
        />
      )}
      {dialogType === 'delete' && (
        <DeleteAccountModal
          onClickClose={() => {
            track(events.TAPS_DELETE_ACCOUNT_CANCEL)
            setDialogType(null)
          }}
          user={user}
        />
      )}
      <div className="hidden md:block">
        <ProfileHeading to="/account">Profile</ProfileHeading>
      </div>
      <div className="w-full max-w-account space-y-4 md:max-w-full md:p-4">
        <h1 className="mb-6 text-k/44_110 md:hidden">Profile</h1>

        <ProfileCard>
          <ProfileCollapsible
            additionalButton={
              <ProfileButton
                onClick={() => {
                  navigate('/logout')
                }}
              >
                Logout
              </ProfileButton>
            }
            title={
              <>
                <div className="truncate">{user.info.email}</div>
                <div className="text-k/14_120">{user.info.name}</div>
              </>
            }
            triggerOpenText="Edit Account"
          >
            {({ close }) => {
              return <UpdateEmailForm closeCollapsible={close} user={user} />
            }}
          </ProfileCollapsible>
        </ProfileCard>

        <ProfileCard>
          <ProfileCollapsible title="Password" triggerOpenText="Change">
            {({ close }) => {
              return (
                <>
                  <div className="mb-6 text-k/14_120 underline">
                    <ReactRouterLink to="/forgot-password">
                      Forgot password or need to set one?
                    </ReactRouterLink>
                  </div>
                  <UpdatePasswordForm closeCollapsible={close} user={user} />
                </>
              )
            }}
          </ProfileCollapsible>
        </ProfileCard>

        {!user.subscription.isCommitment && (
          <>
            <ProfileCard>
              <ProfileCardContent
                buttonText="Deactivate"
                description="Permanently deactivates your account. You will not be able to
            access it again. Any data associated with your account (bugs,
            crashes, and app stats) will be anonymized for Tovala's
            continued software development."
                onButtonClick={() => {
                  track(events.TAPS_DEACTIVATE_ACCOUNT)
                  setDialogType('deactivate')
                }}
                title="Deactivate Account"
              />
            </ProfileCard>

            <ProfileCard>
              <ProfileCardContent
                buttonText="Delete"
                description="Permanently deletes all saved settings, favorites, credits and
            your recipes."
                onButtonClick={() => {
                  track(events.TAPS_DELETE_ACCOUNT)
                  setDialogType('delete')
                }}
                title="Delete Account"
              />
            </ProfileCard>
          </>
        )}
      </div>
    </>
  )
}

export default ProfilePage

const ProfileCard = ({ children }: { children: ReactNode }) => {
  return (
    <div className="rounded-lg bg-grey-2">
      <div className="p-6 md:px-4">{children}</div>
    </div>
  )
}

const ProfileCardContent = ({
  buttonText,
  description,
  onButtonClick,
  title,
}: {
  buttonText: string
  description: string
  onButtonClick(): void
  title: string
}) => {
  return (
    <div className="flex justify-between space-x-4">
      <div>
        <span className="text-k/18_120">{title}</span>

        <p className="mt-1 text-body-sm text-grey-9">{description}</p>
      </div>

      <ProfileButton onClick={onButtonClick}>{buttonText}</ProfileButton>
    </div>
  )
}

const ProfileCollapsible = ({
  additionalButton,
  children,
  title,
  triggerOpenText,
}: {
  additionalButton?: ReactNode
  children: (opts: { close(): void }) => JSX.Element
  title: ReactNode | string
  triggerOpenText: string
}) => {
  return (
    <Collapsible>
      {({ open }: { open: boolean }) => {
        return (
          <>
            <div className="flex items-center justify-between gap-4 sm:flex-wrap sm:justify-end">
              <div className="flex w-px grow flex-col text-k/18_120 sm:w-auto">
                {title}
              </div>

              <div className="flex space-x-4">
                {additionalButton}

                <CollapsibleTrigger as={Fragment}>
                  <ProfileButton>
                    {!open ? `${triggerOpenText}` : 'Cancel'}
                  </ProfileButton>
                </CollapsibleTrigger>
              </div>
            </div>
            <CollapsibleContent open={open}>
              {({ close }: { close: () => void }) => {
                return <div className="mt-6">{children({ close })}</div>
              }}
            </CollapsibleContent>
          </>
        )
      }}
    </Collapsible>
  )
}

const ProfileButton = (
  props: Omit<ComponentProps<typeof Button>, 'buttonStyle' | 'size'>
) => {
  return (
    <div className="h-7 w-28 shrink-0 text-k/12_120">
      <Button {...props} buttonStyle="stroke" size="fluid" />
    </div>
  )
}
