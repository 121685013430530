import { clsx } from 'clsx'
import { useEffect, useRef, useState } from 'react'

import { useScrollDirection } from 'hooks/general'
import { useVariantByScreenSize } from 'hooks/variantByScreenSize'

import {
  hideWebWidget,
  onMessengerClose,
  onMessengerOpen,
  openMessenger,
  showWebWidget,
} from 'utils/zendesk'

type LauncherStyle = 'default' | 'float' | 'float-mobile'

const LAUNCHER_BOTTOM_STYLE = {
  default: '16px',
  float: '128px',
  'float-mobile': '104px',
}

const CustomWebWidgetLauncher = () => {
  const { isHidden, launcherRef, onLauncherClick } =
    useCustomWebWidgetLauncher()

  return (
    <button
      ref={launcherRef}
      aria-label="Open messaging window"
      className={clsx(
        'opacity-1 fixed bottom-4 right-4 flex h-16 w-16 items-center justify-center bg-orange-1 shadow-[0px_20px_30px_0px_rgba(36,36,36,0.15)] transition ease-in-out',
        {
          'sr-only opacity-0': isHidden,
        }
      )}
      onClick={onLauncherClick}
      type="button"
    >
      <div className="h-[38px] w-[38px]">
        <ZDChatIcon />
      </div>
    </button>
  )
}

export default CustomWebWidgetLauncher

const ZDChatIcon = () => {
  return (
    <svg version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10,18 L6,22 L6,18 L10,18 Z M17,6 C19.7614237,6 22,8.23857625 22,11 C22,13.7614237 19.7614237,16 17,16 L17,16 L7,16 C4.23857625,16 2,13.7614237 2,11 C2,8.23857625 4.23857625,6 7,6 L7,6 Z"
        transform="translate(12.000000, 14.000000) scale(-1, 1) translate(-12.000000, -14.000000)"
      ></path>
    </svg>
  )
}

//
const useCustomWebWidgetLauncher = () => {
  const launcherRef = useRef<HTMLButtonElement | null>(null)
  const scrollDirection = useScrollDirection()

  const launcherStyle = useVariantByScreenSize<LauncherStyle>('default', {
    lg: 'float',
    md: 'float-mobile',
  })

  const [showCustomLauncher, setShowCustomLauncher] = useState(() => {
    if (isMessengerClosed() || launcherStyle === 'default') {
      return true
    }

    return false
  })

  useEffect(() => {
    if (isMessengerClosed()) {
      hideWebWidget()
    }

    return () => {
      // Make sure normal web widget shows up when navigating away from Menu Page
      showWebWidget()
      setLauncherBottomStyle(launcherRef, 'default')

      // reset messenger on close callback
      onMessengerClose(() => {
        showWebWidget()
      })
    }
  }, [])

  useEffect(() => {
    setLauncherBottomStyle(launcherRef, launcherStyle)

    onMessengerOpen(() => {
      setShowCustomLauncher(false)
    })

    onMessengerClose(() => {
      hideWebWidget()

      if (launcherStyle === 'default') {
        setShowCustomLauncher(true)
      }
    })

    if (scrollDirection === 'up') {
      if (isMessengerClosed()) {
        setShowCustomLauncher(true)
      }
    } else if (scrollDirection === 'down' && launcherStyle !== 'default') {
      if (isMessengerClosed()) {
        setShowCustomLauncher(false)
      }
    }
  }, [launcherStyle, scrollDirection])

  const onLauncherClick = () => {
    showWebWidget()
    openMessenger()
  }

  return {
    isHidden: !showCustomLauncher,
    onLauncherClick,
    launcherRef,
  }
}

function isMessengerClosed() {
  // Session storage item set by Zendesk set when messaging window is opened
  const widgetOpen = sessionStorage.getItem('ZD-widgetOpen')
  if (widgetOpen === null || widgetOpen === 'false') {
    return true
  }

  return false
}

function setLauncherBottomStyle(
  launcherRef: React.MutableRefObject<HTMLButtonElement | null>,
  launcherStyle: LauncherStyle
) {
  if (launcherRef.current) {
    launcherRef.current.style.bottom = LAUNCHER_BOTTOM_STYLE[launcherStyle]
  }
}
