import { clsx } from 'clsx'
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form'
import { UserSubscriptionType } from '@tovala/browser-apis-combinedapi'

type CardStyle = 'gray' | 'white'

const COLOR_CLASS_MAP: {
  [cardStyle in CardStyle]: string
} = {
  gray: 'border-grey-2 bg-grey-2 hover:bg-grey-6 transition-colors',
  white:
    'border-white bg-white hover:bg-grey-6 hover:border-grey-6 transition-colors',
}

function MealPlans<FormData extends FieldValues>({
  cardStyle = 'gray',
  control,
  mealPlans,
  name,
  onChangePlan,
}: {
  cardStyle?: CardStyle
  control: ControllerProps<FormData>['control']
  mealPlans: UserSubscriptionType[]
  name: Path<FormData>
  onChangePlan?(mealPlan: UserSubscriptionType): void
}) {
  return (
    <>
      {mealPlans.map((mealPlan) => {
        const { max_selections, id } = mealPlan

        return (
          <Controller
            key={id}
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value, name, ref } }) => {
              const checked = value === id
              return (
                <label
                  className={clsx(
                    'flex cursor-pointer items-center justify-center rounded-md border text-k/28_130 ',
                    checked
                      ? 'border-orange-1 bg-white transition-colors hover:bg-white'
                      : COLOR_CLASS_MAP[cardStyle]
                  )}
                >
                  <input
                    ref={ref}
                    checked={checked}
                    className="absolute h-0 w-0 cursor-pointer opacity-0"
                    name={name}
                    onBlur={onBlur}
                    onChange={(event) => {
                      onChange(event)

                      if (onChangePlan) {
                        onChangePlan(mealPlan)
                      }
                    }}
                    type="radio"
                    value={id}
                  />
                  {max_selections}
                </label>
              )
            }}
          />
        )
      })}
    </>
  )
}

export default MealPlans
