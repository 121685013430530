import { clsx } from 'clsx'
import { ReactNode } from 'react'

const MyOrdersCard = ({
  children,
  footer,
  header,
  onChildrenClick,
  removeRightChildPadding = false,
}: {
  children: ReactNode
  footer?: ReactNode | null
  onChildrenClick?: () => void
  header?: ReactNode
  // An example of when removing the padding is useful is for a meals carousel that has the overflow hidden
  // but that we want to stretch right up to the edge of the container.
  removeRightChildPadding?: boolean
}) => {
  return (
    <div className="rounded-xl bg-grey-2">
      {header && (
        <div className="border-b border-grey-4 px-6 py-3 md:p-4">{header}</div>
      )}

      <div
        className={clsx('cursor-pointer p-6 md:p-4', {
          'pr-0 md:pr-0': removeRightChildPadding,
        })}
        onClick={onChildrenClick}
      >
        {children}
      </div>

      {footer && (
        <div className="border-t border-grey-4 p-6 md:py-3">{footer}</div>
      )}
    </div>
  )
}

export default MyOrdersCard
