import { ReactNode } from 'react'

const MealImageTag = ({
  children,
  icon,
}: {
  children: ReactNode
  icon?: ReactNode
}) => {
  return (
    <div className="flex items-center justify-center space-x-1 rounded-full bg-white px-3 py-2 text-k/12_120 font-semibold">
      {icon && <div className="h-4 w-4 overflow-hidden">{icon}</div>}
      <div>{children}</div>
    </div>
  )
}

export default MealImageTag
