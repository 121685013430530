import { Button, XIcon } from '@tovala/component-library'
import { lazy, Suspense, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useLocation } from 'react-router-dom'
import { onlineManager } from '@tanstack/react-query'

import { page } from 'utils/analytics'

import { useVisibilityCallback } from 'hooks/general'
import Routes from 'Routes'
import ErrorDisplay from 'components/common/ErrorDisplay'

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
)

const App = () => {
  const [showDevtools, setShowDevtools] = useState(false)

  const location = useLocation()

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  useEffect(() => {
    page()
  }, [location.pathname])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <div className="bg-grey-0">
      <main>
        <Routes />
      </main>

      {/* See "Additional Context" on https://tovala.atlassian.net/browse/WAT-431. The tl;dr is that
      Chrome seems to sometimes think the user is not online when they actually are (or at least the
      navigator.onLine property is not set properly.) */}
      <OfflineWarning />

      {showDevtools && (
        <Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </Suspense>
      )}
    </div>
  )
}

export default App

const OfflineWarning = () => {
  const isOnline = onlineManager.isOnline()
  const [showWarning, setShowWarning] = useState(!isOnline)

  const { elementRef } = useVisibilityCallback<HTMLDivElement>({
    onIsVisible: () => {
      // Capturing this in Sentry allows us to debug any times that we may be showing
      // this warning to a user when it's not actually the case that we can't make
      // requests for them. Eventually, we should get rid of this once we're confident
      // that we're showing the warning appropriately. We use a visibility callback here
      // to try to prevent any false-positive capturing of messages.
      Sentry.captureMessage('Showed offline warning to user.')
    },
    visibilityThreshold: 1,
  })

  // Shows the warning message when using the offline mock behavior of react-query devTools
  useEffect(() => {
    setShowWarning(!isOnline)
  }, [isOnline])

  useEffect(() => {
    const onOffline = () => {
      setShowWarning(true)
    }
    const onOnline = () => {
      setShowWarning(false)
    }

    window.addEventListener('offline', onOffline)
    window.addEventListener('online', onOnline)

    return () => {
      window.removeEventListener('offline', onOffline)
      window.removeEventListener('online', onOnline)
    }
  }, [])

  if (showWarning) {
    return (
      <div
        ref={elementRef}
        className="fixed right-16 top-24 z-40 max-w-sm md:bottom-0 md:left-0 md:right-0 md:top-auto md:m-1 md:max-w-none"
      >
        <div className="absolute right-6 top-6 h-6 w-6">
          <Button
            buttonStyle="link"
            onClick={() => setShowWarning(false)}
            size="fluid"
          >
            <XIcon />
          </Button>
        </div>
        <ErrorDisplay
          display="page"
          helpToFix="Please check your network connection."
          wayOut="Toggling your network connection may help."
          whatHappened="Offline"
          why="We cannot process any of your requests because you may be offline."
        />
      </div>
    )
  }

  return null
}
