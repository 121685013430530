import { clsx } from 'clsx'
import { forwardRef, InputHTMLAttributes, ReactNode } from 'react'

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  hasError?: boolean
  leftIcon?: ReactNode
  rightIcon?: ReactNode
}

const Input = forwardRef<HTMLInputElement, Props>(function Input(
  { hasError = false, leftIcon = null, rightIcon = null, ...rest },
  ref
) {
  const input = (
    <input
      ref={ref}
      className={clsx(
        'h-14 w-full rounded-lg border bg-grey-0 px-4 text-k/14_120',
        {
          'border-red': hasError,
          'border-grey-4': !hasError,
          'pl-8': !!leftIcon,
          'pr-12': !!rightIcon,
        }
      )}
      {...rest}
    />
  )

  if (leftIcon || rightIcon) {
    return (
      <div className="relative">
        {leftIcon && (
          <div className="absolute left-0 top-0 h-full w-12">
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              {leftIcon}
            </div>
          </div>
        )}

        {input}

        {rightIcon && (
          <div className="absolute right-2 top-1/2 h-6 w-6 -translate-x-1/2 -translate-y-1/2">
            {rightIcon}
          </div>
        )}
      </div>
    )
  }

  return input
})

export default Input
