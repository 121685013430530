import { Button, ArrowLeftIcon } from '@tovala/component-library'
import { useNavigate } from 'react-router-dom'
import { UserTerm } from 'types/internal'
import MenuHeader from './MenuHeader'
import { track } from 'utils/analytics'
import { events } from 'analytics/events'

const ExtrasMenuHeader = ({
  selectedUserTerm,
}: {
  selectedUserTerm: UserTerm
}) => {
  const navigate = useNavigate()

  return (
    <MenuHeader
      backButton={
        <Button
          aria-label="Menu"
          buttonStyle="link"
          onClick={() => {
            navigate(`/menu/${selectedUserTerm.termID}`)

            track(events.EXTRA_BACK_CTA, {
              term_id: selectedUserTerm.termID,
            })
          }}
          size="auto"
        >
          <div className="mr-2 h-6 w-6">
            <ArrowLeftIcon />
          </div>
          <span className="text-k/16_125 md:hidden">Menu</span>
        </Button>
      }
      selectedUserTerm={selectedUserTerm}
    />
  )
}

export default ExtrasMenuHeader
