import { AnimatePresence, motion } from 'framer-motion'
import { Disclosure } from '@headlessui/react'
import { ReactNode } from 'react'

const Collapsible = ({
  children,
  ...rest
}: {
  children(opts: { open: boolean }): JSX.Element
  defaultOpen?: boolean
}) => {
  return (
    <Disclosure {...rest}>{({ open }) => <>{children({ open })}</>}</Disclosure>
  )
}

export default Collapsible

export const CollapsibleContent = ({
  children,
  open,
  ...rest
}: {
  children: ReactNode | ((opts: { close(): void }) => JSX.Element)
  open: boolean
}) => {
  return (
    <motion.div
      animate={open ? 'open' : 'closed'}
      initial="closed"
      style={{ overflow: open ? 'visible' : 'hidden' }}
      variants={{
        open: {
          height: 'auto',
          transition: { duration: 0.2, type: 'tween' },
        },
        closed: { height: 0 },
      }}
    >
      <AnimatePresence>
        {open && (
          <motion.div
            animate="open"
            exit="closed"
            initial="closed"
            variants={{
              open: {
                opacity: 1,
                transition: { duration: 0.2, type: 'tween' },
              },
              closed: { opacity: 0 },
            }}
          >
            <Disclosure.Panel static {...rest}>
              {children}
            </Disclosure.Panel>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export const CollapsibleTrigger = Disclosure.Button
