import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'

import { GoodErrorMessaging } from 'types/internal'

import Toast from 'components/common/Toast'

interface IToast {
  openToast(opts: ToastOpts): void
}

export type ToastOpts =
  | {
      heading: string
      message: string
      type: 'success'
    }
  | {
      goodErrorMessaging: GoodErrorMessaging
      type: 'error'
    }

const ToastContext = createContext<IToast | undefined>(undefined)

const ToastProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const location = useLocation()

  const [isOpen, setIsOpen] = useState(false)
  const [toastOpts, setToastOpts] = useState<ToastOpts>({
    heading: '',
    message: '',
    type: 'success',
  })

  useEffect(() => {
    setIsOpen(false)
  }, [location.pathname])

  useEffect(() => {
    let hideTimeout: number | undefined
    if (isOpen) {
      hideTimeout = window.setTimeout(() => {
        setIsOpen(false)
      }, 10000)
    }

    return () => {
      window.clearTimeout(hideTimeout)
    }
  }, [toastOpts, isOpen])

  const openToast = useCallback((opts: ToastOpts) => {
    setIsOpen(true)
    setToastOpts(opts)
  }, [])

  return (
    <ToastContext.Provider value={{ openToast }}>
      {children}

      <Toast
        {...toastOpts}
        isOpen={isOpen}
        onCloseToast={() => {
          setIsOpen(false)
        }}
      />
    </ToastContext.Provider>
  )
}

function useToast() {
  const context = useContext(ToastContext)
  if (context === undefined) {
    throw new Error('useToast must be used in an ToastProvider')
  }

  return context
}

export { ToastProvider, useToast }
