import { ButtonLoading, FormFieldError } from '@tovala/component-library'
import { useForm } from 'react-hook-form'
import { useRedeemGiftCard } from '@tovala/browser-apis-combinedapi'

import { ErrorCodeMessageMapCombinedAPI } from 'types/internal'
import { events } from 'analytics/events'
import { track } from 'utils/analytics'
import { wrapWithContactSupportTeam } from 'utils/errors'

import { useToast } from 'contexts/toast'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import FormGroup from 'components/common/FormGroup'
import Input from 'components/common/Input'

type FormValues = {
  cardID: string
}

const REDEEM_GIFT_CARD_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please double check the gift card and try again.',
    why: "We couldn't apply the gift card to your account due to a technical issue on our end.",
  },
  GiftCardAlreadyRedeemed: {
    wayOut: wrapWithContactSupportTeam('If you need further assistance'),
    why: "We couldn't apply that gift card to your account because it has already been applied.",
  },
  GiftCardNotFound: {
    helpToFix: 'Please double check the gift card and try again.',
    why: "We couldn't apply that gift card to your account because it doesn't exist.",
  },
}

const RedeemGiftCard = ({ userID }: { userID: number }): JSX.Element => {
  const { openToast } = useToast()

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<FormValues>({ defaultValues: { cardID: '' } })

  const {
    error: redeemError,
    isError: hasRedeemError,
    isLoading: redeemLoading,
    mutate: redeemGiftCard,
  } = useRedeemGiftCard({
    onSuccess: () => {
      reset()

      openToast({
        heading: 'Gift Card Redeemed',
        message: 'Your gift card has been applied to your account!',
        type: 'success',
      })

      track(events.REDEEMS_PROMO, { code_type: 'gift_card' })
    },
  })

  return (
    <form
      onSubmit={handleSubmit((data) =>
        redeemGiftCard({
          cardID: data.cardID,
          data,
          userID,
        })
      )}
    >
      <FormGroup
        label={
          <div className="space-y-1">
            <div>Gift Card</div>
            <p className="font-normal">Enter your gift card code below</p>
          </div>
        }
        labelFor="gift-card-input"
      >
        <div className="flex max-w-[500px] items-start space-x-4 md:block md:space-x-0">
          <div className="grow md:w-full">
            <Input
              hasError={!!errors.cardID}
              id="gift-card-input"
              placeholder="Enter Gift Card Code"
              type="text"
              {...register('cardID', {
                required: 'Please enter a gift card code',
              })}
            />

            {errors.cardID?.message && (
              <div className="mt-1">
                <FormFieldError>{errors.cardID.message}</FormFieldError>
              </div>
            )}
          </div>

          <div className="h-[56px] w-[185px] md:mt-3 md:h-[36px] md:w-[178px]  md:text-k/14_120">
            <ButtonLoading
              buttonStyle="stroke"
              isLoading={redeemLoading}
              size="fluid"
              type="submit"
            >
              Redeem Gift Card
            </ButtonLoading>
          </div>
        </div>

        {hasRedeemError && (
          <div className="mt-4">
            <APIErrorDisplay
              error={redeemError}
              errorCodeMessageMap={REDEEM_GIFT_CARD_ERRORS}
            />
          </div>
        )}
      </FormGroup>
    </form>
  )
}

export default RedeemGiftCard
