import { track } from 'utils/analytics'
import { events } from 'analytics/events'
import Link, { LinkType } from 'components/common/Link'

interface ColorMap {
  [key: string]: 'dark' | 'orange' | 'white'
}

const COLOR_MAP: ColorMap = {
  '#39392D': 'dark',
  '#CA4F0E': 'orange',
  '#FFFFFF': 'white',
}

const MealsSignUpBanner = ({
  backgroundLight,
  buttonColorLight,
  imageUrl,
  link,
  title,
  titleColorLight,
}: {
  backgroundLight: string
  buttonColorLight: string
  imageUrl: string
  link: {
    label: string
    to: string
    type: LinkType
  }
  title: string
  titleColorLight: string
}) => {
  return (
    <div
      className="grid grid-cols-[326px_minmax(0,_1fr)] grid-rows-[repeat(2,_minmax(min-content,max-content))] gap-x-9 gap-y-6 rounded-xl p-6 md:auto-rows-auto md:grid-cols-1 md:grid-rows-none md:rounded-none md:px-4 md:py-8"
      style={{ backgroundColor: `${backgroundLight}` }}
    >
      <h2
        className="max-w-xs self-end whitespace-pre-wrap text-k/32_105 font-semibold md:self-auto"
        style={{ color: `${titleColorLight}` }}
      >
        {title}
      </h2>
      <img
        alt=""
        className="row-span-2 h-full max-h-full w-full rounded-lg object-cover md:row-auto md:aspect-[16/9] md:rounded-md"
        src={imageUrl}
      />
      <div>
        <div className="inline-block">
          <Link
            linkStyle={COLOR_MAP[buttonColorLight] ?? 'orange'}
            linkType={link.type}
            onClick={() => {
              track(events.TAPS_TRY_MEALS_BANNER)
            }}
            size="small"
            to={link.to}
          >
            {link.label}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MealsSignUpBanner
