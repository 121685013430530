import { Button } from '@tovala/component-library'
import { useNavigate } from 'react-router-dom'

const MarketplaceStorefront = () => {
  const navigate = useNavigate()

  return (
    <div>
      <div className="my-20 flex justify-center">
        <div className="w-[666px] max-w-full space-y-10 px-4">
          <div className="flex flex-col items-center space-y-6">
            <h1 className="text-k/44_110">Marketplace Update</h1>
            <p className="text-center text-body-lg text-grey-9">
              We are no longer offering partner foods for purchase via
              Marketplace by Tovala. The good news: You can still purchase these
              items directly from partner brands or their participating
              retailers. You can also still cook them perfectly in your Tovala
              Smart Oven from the Tovala App.{' '}
              <a
                className="text-body-lg text-orange-2 underline"
                href="https://tovala.zendesk.com/hc/en-us/articles/22400557010075
"
                rel="noreferrer"
                target="_blank"
              >
                Learn more here.
              </a>
            </p>{' '}
          </div>

          <img
            alt={'Marketplace Image'}
            className="rounded-xl md:rounded-md"
            src={'https://cdn.tovala.com/marketplace/shutdown/mp-shutdown.png'}
          />
          <div className="flex justify-center">
            <Button
              buttonStyle="dark"
              onClick={() => navigate('/my-orders')}
              size={'large'}
            >
              My Orders
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketplaceStorefront
