import '@typeform/embed/build/css/popup.css'
import { createPopup } from '@typeform/embed'
import { useEffect, useRef } from 'react'

import { getEnvVar } from 'utils/env'

type TypeformSurvey = 'cancel' | 'pause' | 'postPurchase' | 'skip'

const ENABLE_SANDBOX = getEnvVar('APP_ENV') !== 'production'

const SURVEY_NAME_TO_FORM_ID: {
  [survey in TypeformSurvey]: string
} = {
  cancel: 'b0XJUW',
  pause: 'wOmQq7',
  postPurchase: 'lkCzSO',
  skip: 'GBOKHQ',
}

const SURVEY_SOURCE = 'web'

export function useTypeformPopup({
  hiddenFields,
  onClose,
  onReady,
  onSubmit,
  survey,
  userID,
}: {
  hiddenFields?: Record<string, string>
  onClose?: () => void
  onReady?: () => void
  onSubmit?: () => void
  survey: TypeformSurvey
  userID: string
}) {
  const popup = useRef<ReturnType<typeof createPopup> | null>(null)

  const formID = survey ? SURVEY_NAME_TO_FORM_ID[survey] : undefined

  useEffect(() => {
    const hidden = {
      source: SURVEY_SOURCE,
      userid: userID,
      ...hiddenFields,
    }

    if (formID) {
      popup.current = createPopup(formID, {
        autoClose: 2000,
        enableSandbox: ENABLE_SANDBOX,
        hidden,
        hideFooter: true,
        hideHeaders: true,
        onClose,
        onReady,
        onSubmit,
      })
    }

    return () => {
      popup.current?.close()
      popup.current?.unmount()
    }
  }, [formID, hiddenFields, onClose, onReady, onSubmit, userID])

  return {
    openTypeformPopup: () => {
      popup.current?.open()
    },
  }
}
