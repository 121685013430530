import { CaretRightIcon } from '@tovala/component-library'
import { clsx } from 'clsx'
import { ReactNode } from 'react'

const NavigationSteps = ({ children }: { children: ReactNode }) => {
  return <ul className="flex h-full space-x-5">{children}</ul>
}

export default NavigationSteps

export const NavigationStepsMobile = ({
  currentStep,
  steps,
}: {
  currentStep: string
  steps: { isFuture: boolean }[]
}) => {
  return (
    <div className="px-4">
      <div className="py-5 text-k/16_125">{currentStep}</div>
      <div className="flex space-x-2">
        {steps.map(({ isFuture }, i) => {
          return (
            <div
              key={i}
              className={clsx('h-1 grow rounded-lg', {
                'bg-grey-3': isFuture,
                'bg-orange-1': !isFuture,
              })}
            />
          )
        })}
      </div>
    </div>
  )
}

export const NavigationStep = ({
  children,
  isActive,
  isFuture,
  isLastStep = false,
}: {
  children: ReactNode
  isActive: boolean
  isFuture: boolean
  isLastStep?: boolean
}) => {
  return (
    <li className="flex h-full items-center space-x-5 lg:space-x-4">
      <a
        className={clsx(
          'relative block h-full py-8 text-k/14_120 transition-colors md:py-5',
          {
            "after:absolute after:bottom-0 after:block after:h-0.5 after:w-full after:translate-y-1/2 after:bg-orange-1 after:content-['']":
              isActive,
            'text-grey-8': isFuture,
          }
        )}
      >
        {children}
      </a>

      {!isLastStep && (
        <div
          className={clsx('h-3 w-3', {
            'text-grey-8': isFuture,
          })}
        >
          <CaretRightIcon />
        </div>
      )}
    </li>
  )
}
