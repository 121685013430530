import { Button } from '@tovala/component-library'
import ConfirmationDialog, {
  ConfirmationBody,
  ConfirmationButtons,
  ConfirmationHeader,
} from 'components/common/ConfirmationDialog'

const MealExtraDisabledConfirmationDialog = ({
  onClose,
}: {
  onClose(): void
}) => {
  return (
    <ConfirmationDialog onRequestClose={onClose}>
      <ConfirmationHeader
        heading="Select Meal to Add an Extra"
        onClickClose={onClose}
      />
      <ConfirmationBody>
        <p className="my-8 max-w-sm">
          Extras can only be ordered with an accompanying meal. Add the meal
          directly above to your order before selecting an extra.
        </p>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="grid">
          <Button onClick={onClose} size="large">
            Sounds good
          </Button>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}

export default MealExtraDisabledConfirmationDialog
