import {
  Listing,
  ListingSelection,
  MenuListingsLayout,
} from '@tovala/browser-apis-combinedapi'
import {
  MealImage,
  MealImageSoldOut,
  QuantityStepper,
} from '@tovala/component-library'
import { MouseEventHandler, ReactNode } from 'react'
import { formatCentsToDollars } from 'utils/currency'

const ExtrasGrid = ({
  canModifySelections,
  disabledDecrement,
  disabledIncrement,
  listingsSelections,
  menuListingsLayout,
  onClickDecrement,
  onClickIncrement,
  onClickListing,
}: {
  canModifySelections: boolean
  disabledDecrement: boolean
  disabledIncrement: boolean
  listingsSelections: ListingSelection[]
  menuListingsLayout: MenuListingsLayout
  onClickDecrement({ listingID }: { listingID: string }): void
  onClickIncrement({ listingID }: { listingID: string }): void
  onClickListing(listingID: string): void
}) => {
  if (
    !menuListingsLayout.sections ||
    menuListingsLayout.sections.length === 0
  ) {
    return null
  }

  const totalListingSelections = listingsSelections.reduce(
    (total, selection) => {
      return total + selection.quantity
    },
    0
  )

  function makeQuantityStepper({ listing }: { listing: Listing }) {
    const quantity =
      listingsSelections.find(
        (listingSelection) => listingSelection.id === listing.id
      )?.quantity ?? 0

    if (!canModifySelections || (listing.isSoldOut && quantity === 0)) {
      return { quantity: 0, stepper: null }
    }

    return {
      quantity,
      stepper: (
        <QuantityStepper
          disabledDecrement={disabledDecrement}
          disabledIncrement={disabledIncrement || listing.isSoldOut}
          labelDecrement="Remove Extra"
          labelIncrement="Add Extra"
          min={0}
          onClickDecrement={() => {
            onClickDecrement({ listingID: listing.id })
          }}
          onClickIncrement={() => {
            onClickIncrement({ listingID: listing.id })
          }}
          quantity={quantity}
          size="small"
        />
      ),
    }
  }

  return (
    <div className="mx-auto max-w-menu lg:px-4 md:px-0">
      <div className="space-y-10 md:space-y-6">
        <div className="md:px-4">
          <div className="flex items-start">
            <h2 className="mb-5 text-k/36_110">
              <span className="md:hidden">Shop </span>Extras
            </h2>
            {totalListingSelections > 0 && (
              <div className="ml-1 flex h-6 w-6 items-center justify-center rounded-full bg-orange-1 text-k/16_125 text-white">
                {totalListingSelections}
              </div>
            )}
          </div>

          <p className="text-k/16_125">
            Customize your order with delicious additions to your weekly meals.
          </p>
        </div>

        {menuListingsLayout.sections.map((section, index) => {
          if (section.listings.length > 0) {
            return (
              <div key={`section-${index}`}>
                <div className="grid grid-cols-3 gap-x-4 gap-y-8 md:grid-cols-2 md:gap-x-3 md:px-4">
                  {section.listings.map((listing) => {
                    return (
                      <Extra
                        key={listing.id}
                        listing={listing}
                        onClickListing={() => {
                          onClickListing(listing.id)
                        }}
                        stepper={makeQuantityStepper({ listing }).stepper}
                      />
                    )
                  })}
                </div>
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default ExtrasGrid

const Extra = ({
  listing,
  onClickListing,
  stepper,
}: {
  listing: Listing
  onClickListing: MouseEventHandler<HTMLDivElement>
  stepper?: ReactNode
}) => {
  return (
    <div className="space-y-4" data-testid={`listing-${listing.id}`}>
      <div className="relative">
        <div
          className="aspect-square cursor-pointer overflow-hidden rounded-lg"
          onClick={onClickListing}
        >
          <MealImage
            cover={
              listing.isSoldOut ? <MealImageSoldOut textSize="large" /> : null
            }
            image={{ url: listing.imageURL }}
          />
        </div>
        {stepper && <div className="absolute bottom-4 right-4">{stepper}</div>}
      </div>

      <div className="space-y-1">
        {listing.priceCents && (
          <p className="text-k/14_120 text-grey-8">
            {formatCentsToDollars(listing.priceCents)}
          </p>
        )}

        <p
          className="cursor-pointer text-k/20_125 md:text-k/16_125"
          onClick={onClickListing}
        >
          {listing.title}
        </p>
      </div>
    </div>
  )
}
