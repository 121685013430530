import { ArrowLeftIcon, Button } from '@tovala/component-library'
import { compact, flatMap } from 'lodash-es'
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import {
  OrderHistoryReceipt,
  useCreateZendeskRequest,
} from '@tovala/browser-apis-combinedapi'
import React, { ComponentProps, ReactNode, useEffect, useState } from 'react'

import { DATE_FORMATS, formatDate } from 'utils/dates'
import {
  ErrorCodeMessageMapCombinedAPI,
  IssueCategory,
  IssueConfig,
} from 'types/internal'
import { startChatWithMessage } from 'utils/zendesk'

import { useOrderHistory } from 'hooks/orderHistory'
import { useUser } from 'contexts/user'
import AffectedDelivery from './AffectedDelivery'
import AffectedMeal from './AffectedMeal'
import AffectedMeals from './AffectedMeals'
import AffectedMealsList from './AffectedMealsList'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import Comments from './Comments'
import Feedback from './Feedback'
import Issues from './Issues'
import Sidebar, {
  SidebarBody,
  SidebarButtons,
  SidebarHeader,
} from 'components/common/Sidebar'

interface FormData {
  affectedDelivery: string
  affectedMeal: string
  affectedMeals: string[]
  comments: string
  feedback: Record<string, string>
  issueID: string
  issueName: string
}

const CREATE_ZENDESK_REQUEST_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again or use the "Report & Start Chat" button.',
    why: "We couldn't create an issue for you due to a technical issue on our end.",
  },
}

const ReportIssue = ({
  buttonSize = 'medium',
}: {
  buttonSize?: ComponentProps<typeof Button>['size']
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const { user } = useUser()
  const { recentOrdersPastDeliveryDate } = useOrderHistory({ userID: user.id })

  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true)
        }}
        size={buttonSize}
      >
        Report An Issue
      </Button>

      {isOpen && (
        <ReportIssueSidebar
          onCloseSidebar={() => {
            setIsOpen(false)
          }}
          recentReceipts={recentOrdersPastDeliveryDate}
        />
      )}
    </>
  )
}

export default ReportIssue

const ReportIssueSidebar = ({
  onCloseSidebar,
  recentReceipts,
}: {
  onCloseSidebar(): void
  recentReceipts: OrderHistoryReceipt[]
}) => {
  const [page, setPage] = useState(0)
  const [showSubmittedMessage, setShowSubmittedMessage] = useState(false)

  const nextPage = () => {
    setPage((page) => page + 1)
  }

  const prevPage = () => {
    setPage((page) => page - 1)
  }

  const feedbackNames = compact(
    flatMap(
      Object.values(issues)
        .map((issue) => issue.feedback?.map((feedback) => [feedback.name, '']))
        .filter((name) => name)
    )
  )

  const formMethods = useForm<FormData>({
    defaultValues: {
      affectedDelivery: '',
      affectedMeal: '',
      affectedMeals: [],
      comments: '',
      feedback: Object.fromEntries(feedbackNames),
      issueID: '',
      issueName: '',
    },
  })

  const { getValues, reset, resetField, setValue, trigger, watch } = formMethods

  const values = watch()
  const selectedReceipt = getSelectedReceipt({
    receipts: recentReceipts,
    termID: values.affectedDelivery,
  })

  const setCommentValue = () => {
    const values = getValues()
    const comments = formatComment(values, selectedReceipt)
    setValue('comments', comments)
  }

  const submitButtons = (
    <SubmitButtons
      onCloseSidebar={onCloseSidebar}
      setShowSubmittedMessage={setShowSubmittedMessage}
    />
  )

  return (
    <FormProvider {...formMethods}>
      <form>
        <Sidebar onCloseSidebar={onCloseSidebar}>
          <SidebarHeader onClickClose={onCloseSidebar}>
            {page !== 0 && !showSubmittedMessage && (
              <div className="absolute left-6 top-1/2 -translate-y-1/2">
                <Button
                  aria-label="Back"
                  buttonStyle="link"
                  onClick={() => {
                    if (page === 1) {
                      reset()
                    } else if (page === 2 && values.affectedDelivery) {
                      resetField('affectedDelivery')
                    }
                    prevPage()
                  }}
                  size="auto"
                >
                  <div className="h-6 w-6">
                    <ArrowLeftIcon />
                  </div>
                </Button>
              </div>
            )}
            Report an Issue
          </SidebarHeader>

          {showSubmittedMessage ? (
            <SidebarBody>
              <div className="p-6 text-center">
                <h3 className="mb-4  text-k/20_125">
                  Thanks, your issue has been submitted.
                </h3>
                <p className="text-k/16_125">
                  You'll receive a follow up resolution email in the next
                  24-48hrs.
                </p>
              </div>
            </SidebarBody>
          ) : (
            <>
              {page === 0 && <Issues issues={issues} nextPage={nextPage} />}

              <Issue issue={issues.damagedDelivery}>
                {({ issue }) => {
                  return (
                    <>
                      <IssuePage>
                        <AffectedDelivery receipts={recentReceipts} />

                        {values.affectedDelivery && (
                          <Feedback
                            afterChange={() => {
                              setCommentValue()
                            }}
                            feedbackData={issue.feedback}
                            heading={'Issue'}
                          />
                        )}

                        {values.feedback && <Comments issue={issue} />}
                      </IssuePage>

                      {values.feedback && submitButtons}
                    </>
                  )
                }}
              </Issue>

              <LateDelivery
                receipts={recentReceipts}
                submitButtons={submitButtons}
              />

              <Issue issue={issues.mealQuality}>
                {({ issue }) => {
                  return (
                    <>
                      {page === 1 && (
                        <IssuePage>
                          <AffectedDelivery
                            afterChange={nextPage}
                            receipts={recentReceipts}
                          />
                        </IssuePage>
                      )}

                      {page === 2 && selectedReceipt && (
                        <>
                          <IssuePage>
                            <AffectedMeal receipt={selectedReceipt} />
                          </IssuePage>

                          <ContinueButton
                            onContinueClick={async () => {
                              const affectedMeal = await trigger('affectedMeal')
                              if (affectedMeal) {
                                nextPage()
                              }
                            }}
                          />
                        </>
                      )}

                      {page === 3 && (
                        <>
                          <IssuePage>
                            <AffectedMealsList
                              affectedMeals={[values.affectedMeal]}
                              receipt={selectedReceipt}
                            />

                            <Feedback
                              afterChange={() => {
                                setCommentValue()
                              }}
                              feedbackData={issue.feedback}
                              heading="Quality Issues"
                            />

                            <Comments issue={issue} />
                          </IssuePage>

                          {values.feedback && submitButtons}
                        </>
                      )}
                    </>
                  )
                }}
              </Issue>

              <Issue issue={issues.missingIngredients}>
                {({ issue }) => {
                  return (
                    <>
                      {page === 1 && (
                        <IssuePage>
                          <AffectedDelivery
                            afterChange={nextPage}
                            receipts={recentReceipts}
                          />
                        </IssuePage>
                      )}

                      {page === 2 && selectedReceipt && (
                        <>
                          <IssuePage>
                            <AffectedMeals receipt={selectedReceipt} />
                          </IssuePage>

                          <ContinueButton
                            onContinueClick={async () => {
                              const affectedMeals = await trigger(
                                'affectedMeals'
                              )
                              if (affectedMeals) {
                                setCommentValue()
                                nextPage()
                              }
                            }}
                          />
                        </>
                      )}

                      {page === 3 && (
                        <>
                          <IssuePage>
                            <div className="space-y-4">
                              <h3 className="text-k/20_125">
                                Affected Delivery
                              </h3>

                              <div className="inline-block rounded-full border border-orange-1 bg-white px-6 py-2 text-k/12_120">
                                {selectedReceipt
                                  ? formatDate(selectedReceipt.deliveryDate, {
                                      format: DATE_FORMATS.MONTH_ABBR_DAY,
                                    })
                                  : ''}
                              </div>
                            </div>

                            <AffectedMealsList
                              affectedMeals={values.affectedMeals}
                              heading="Affected Meals"
                              receipt={selectedReceipt}
                            />

                            <Comments issue={issue} />
                          </IssuePage>

                          {submitButtons}
                        </>
                      )}
                    </>
                  )
                }}
              </Issue>

              <Issue issue={issues.ovenIssue}>
                {({ issue }) => {
                  return (
                    <>
                      <IssuePage>
                        <Feedback
                          afterChange={() => {
                            setCommentValue()
                          }}
                          feedbackData={issue.feedback}
                          heading="The following apply to my oven issue"
                        />

                        {values.feedback && <Comments issue={issue} />}
                      </IssuePage>

                      {values.feedback && submitButtons}
                    </>
                  )
                }}
              </Issue>

              <Issue issue={issues.somethingElse}>
                {({ issue }) => {
                  return (
                    <>
                      <IssuePage>
                        <Comments issue={issue} label="Issue" />
                      </IssuePage>

                      {submitButtons}
                    </>
                  )
                }}
              </Issue>
            </>
          )}
        </Sidebar>
      </form>
    </FormProvider>
  )
}

const ContinueButton = ({ onContinueClick }: { onContinueClick(): void }) => {
  return (
    <SidebarButtons>
      <div className="flex justify-center p-6 md:p-4">
        <Button onClick={onContinueClick} size="large">
          Continue
        </Button>
      </div>
    </SidebarButtons>
  )
}

const Issue = ({
  children,
  issue,
}: {
  children(opts: { issue: IssueConfig }): JSX.Element
  issue: IssueConfig
}) => {
  const { watch } = useFormContext()
  const issueID = watch('issueID')

  if (issueID === issue.id) {
    return <>{children({ issue })}</>
  }

  return null
}

const IssuePage = ({ children }: { children: ReactNode[] | ReactNode }) => {
  return (
    <SidebarBody>
      <div className="space-y-6 p-6">{children}</div>
    </SidebarBody>
  )
}

const LateDelivery = ({
  receipts,
  submitButtons,
}: {
  receipts: OrderHistoryReceipt[]
  submitButtons: ReactNode
}) => {
  const { setValue } = useFormContext<FormData>()

  const affectedDeliveryTermID = useWatch<FormData>({
    name: 'affectedDelivery',
  })

  // Updates the form comments when the affected delivery changes.
  useEffect(() => {
    const selectedReceipt =
      typeof affectedDeliveryTermID === 'string'
        ? getSelectedReceipt({
            receipts,
            termID: affectedDeliveryTermID,
          })
        : undefined

    if (selectedReceipt) {
      setValue(
        'comments',
        `Late Delivery; ${getFormattedDelivery(selectedReceipt.deliveryDate)}; `
      )
    }
  }, [affectedDeliveryTermID, receipts, setValue])

  return (
    <Issue issue={issues.lateDelivery}>
      {({ issue }) => {
        return (
          <>
            <IssuePage>
              <AffectedDelivery receipts={receipts} />

              {affectedDeliveryTermID && <Comments issue={issue} />}
            </IssuePage>

            {affectedDeliveryTermID ? submitButtons : null}
          </>
        )
      }}
    </Issue>
  )
}

const SubmitButtons = ({
  onCloseSidebar,
  setShowSubmittedMessage,
}: {
  onCloseSidebar(): void
  setShowSubmittedMessage: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { user } = useUser()

  const { handleSubmit } = useFormContext<FormData>()

  const {
    error: createZendeskRequestError,
    isError: hasCreateZendeskRequestError,
    mutate: createZendeskRequest,
  } = useCreateZendeskRequest({
    onSuccess: () => {
      setShowSubmittedMessage(true)
    },
  })

  const submitFollowUp = (values: FormData) => {
    createZendeskRequest({
      request: {
        comment: {
          body: values.comments,
        },
        requester: {
          email: user.info.email,
          name: user.info.name || user.info.email,
        },
        subject: `${values.issueName}${
          values.affectedDelivery && ' Term #' + values.affectedDelivery
        }`,
      },
    })
  }

  const submitChat = (values: FormData) => {
    onCloseSidebar()

    startChatWithMessage(values.comments)
  }

  return (
    <SidebarButtons>
      <div className="space-y-4 p-6 md:p-4">
        {hasCreateZendeskRequestError && (
          <APIErrorDisplay
            error={createZendeskRequestError}
            errorCodeMessageMap={CREATE_ZENDESK_REQUEST_ERRORS}
          />
        )}

        <div className="mb-4 grid grid-cols-2 gap-4">
          <Button
            buttonStyle="stroke-filled"
            onClick={(e) => {
              handleSubmit(submitChat)(e)
            }}
            size="large"
            type="submit"
          >
            Report & Start Chat
          </Button>
          <Button
            onClick={(e) => {
              handleSubmit(submitFollowUp)(e)
            }}
            size="large"
            type="submit"
          >
            Report & We'll Follow Up
          </Button>
        </div>
        <div className="text-center text-k/14_120 text-grey-9">
          M-Th 10a-9p :: Fri 10a-8p :: Sat-Sun 10a-6p CT
        </div>
      </div>
    </SidebarButtons>
  )
}

const formatComment = (
  values: FormData,
  receipt: OrderHistoryReceipt | undefined
) => {
  const { issueName, affectedDelivery, feedback, affectedMeal, affectedMeals } =
    values

  const deliveryComment =
    (affectedDelivery &&
      receipt &&
      `${getFormattedDelivery(receipt.deliveryDate)}; `) ||
    ''
  const feedbackValues =
    feedback && Object.values(feedback).filter((value) => value)
  const feedbackComment = feedbackValues.length
    ? `${feedbackValues.join(', ')}; `
    : ''

  const affectedMealIDs = affectedMeal ? [affectedMeal] : affectedMeals

  const meals =
    (affectedMealIDs.length &&
      affectedMealIDs.map((affectedMeal) => {
        const mealID = Number(affectedMeal.split('-')[0])
        const meal =
          receipt && receipt.selectedMeals.find((meal) => meal.id === mealID)

        if (!meal) {
          return
        }

        return meal
      })) ||
    ''

  const affectedMealTitles =
    (meals &&
      `${compact(meals)
        .map((meal) => meal.title)
        .join('; ')}; `) ||
    ''

  return `${issueName}; ${deliveryComment}${feedbackComment}${affectedMealTitles}`
}

function getFormattedDelivery(deliveryDate: string) {
  return `${formatDate(deliveryDate, {
    format: DATE_FORMATS.MONTH_FULL_DAY_SUFFIX,
  })} Delivery`
}

function getSelectedReceipt({
  receipts,
  termID,
}: {
  receipts: OrderHistoryReceipt[]
  termID: string
}) {
  return receipts.find((receipt) => receipt.termID === Number(termID))
}

const issues: {
  [category in IssueCategory]: IssueConfig
} = {
  lateDelivery: {
    id: 'lateDelivery',
    name: 'Late Delivery',
    feedback: null,
    autofillComments: true,
    commentsRequired: false,
  },
  missingIngredients: {
    id: 'missingIngredients',
    name: 'Missing/unusable ingredients',
    feedback: null,
    autofillComments: true,
    commentsRequired: false,
  },
  damagedDelivery: {
    id: 'damagedDelivery',
    name: 'Damaged Delivery',
    feedback: [
      {
        id: '',
        name: 'Damaged Delivery Feedback',
        tags: ['section', 'singleSelection'],
        displayOrder: 1,
        children: [
          {
            id: '',
            name: 'Spoiled',
            tags: null,
            displayOrder: 1,
            children: null,
          },
          {
            id: '',
            name: 'Missing Components',
            tags: null,
            displayOrder: 2,
            children: null,
          },
          {
            id: '',
            name: 'Damaged',
            tags: null,
            displayOrder: 3,
            children: null,
          },
          {
            id: '',
            name: 'Other',
            tags: [],
            displayOrder: 3,
            children: null,
          },
        ],
      },
    ],
    autofillComments: true,
    commentsRequired: false,
  },
  mealQuality: {
    id: 'mealQuality',
    name: 'Meal Quality',
    feedback: [
      {
        name: 'Section A',
        tags: ['trailingDivider', 'section', 'alignCenter'],
        displayOrder: 3,
        children: [
          {
            name: 'Missing items',
            tags: null,
            displayOrder: 1,
            children: null,
          },
          {
            name: 'Spoiled items',
            tags: null,
            displayOrder: 2,
            children: null,
          },
        ],
      },
      {
        name: 'Section B',
        tags: ['trailingDivider', 'section', 'singleSelection', 'alignCenter'],
        displayOrder: 2,
        children: [
          {
            name: 'Overcooked',
            tags: null,
            displayOrder: 1,
            children: null,
          },
          {
            name: 'Undercooked',
            tags: null,
            displayOrder: 2,
            children: null,
          },
        ],
      },
      {
        name: 'Section C',
        tags: ['section', 'alignCenter'],
        displayOrder: 1,
        children: [
          {
            name: 'Taste',
            tags: null,
            displayOrder: 1,
            children: null,
          },
          {
            name: 'Portion Size',
            tags: null,
            displayOrder: 2,
            children: null,
          },
          {
            name: 'Texture',
            tags: null,
            displayOrder: 3,
            children: null,
          },
          {
            name: 'Ingredient quality',
            tags: null,
            displayOrder: 4,
            children: null,
          },
          {
            name: 'Other',
            tags: null,
            displayOrder: 5,
            children: null,
          },
        ],
      },
    ],
    commentsRequired: false,
    autofillComments: true,
  },
  ovenIssue: {
    id: 'ovenIssue',
    name: 'Oven Issue',
    feedback: [
      {
        name: 'Oven Issue Feedback',
        tags: ['section', 'singleSelection', 'alignCenter'],
        displayOrder: 1,
        children: [
          {
            name: 'Cooking Issue',
            tags: null,
            displayOrder: 1,
            children: null,
          },
          {
            name: 'Other',
            tags: [],
            displayOrder: 2,
            children: null,
          },
          {
            name: 'Barcode Scanning',
            tags: null,
            displayOrder: 3,
            children: null,
          },
          {
            name: 'WiFi Password',
            tags: null,
            displayOrder: 4,
            children: null,
          },
          {
            name: 'WiFi Connection',
            tags: null,
            displayOrder: 5,
            children: null,
          },
        ],
      },
    ],
    autofillComments: true,
    commentsRequired: false,
  },
  somethingElse: {
    id: 'somethingElse',
    name: 'Something else',
    feedback: null,
    autofillComments: false,
    commentsRequired: true,
  },
}
