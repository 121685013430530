import {
  AccountIcon,
  Button,
  CartIcon,
  GiftIcon,
  MenuIcon,
  OvenIcon,
  XIcon,
} from '@tovala/component-library'
import { clsx } from 'clsx'
import { Disclosure } from '@headlessui/react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { ReactNode } from 'react'
import { UserV1 } from '@tovala/browser-apis-combinedapi'

import { events } from 'analytics/events'
import { track } from 'utils/analytics'

import { useAuth } from 'contexts/auth'
import { useOverlays } from 'contexts/overlays'
import { useMenuTutorial } from 'hooks/menuTutorial'
import { useVariantByScreenSize } from 'hooks/variantByScreenSize'
import LogoLockUpIcon from './icons/LogoLockUpIcon'
import MenuTutorialPopover from 'components/core/menu/MenuTutorialPopover'

type ScreenSize = 'desktop' | 'mobile'

const Header = () => {
  const { user } = useAuth()

  const screenSize = useVariantByScreenSize<ScreenSize>('desktop', {
    md: 'mobile',
  })

  const { isMenuTutorialSkipMultipleOpen } = useOverlays()

  return (
    <HeaderWithLogo>
      <nav aria-label="Site navigation">
        {/* Desktop Nav */}
        <ul className="flex h-full items-center space-x-8 text-k/14_120 lg:space-x-4 md:hidden">
          <NavLinks navType="desktop" screenSize={screenSize} />
        </ul>

        {/* Mobile Nav */}
        <Disclosure
          as="div"
          className="hidden h-6 md:flex md:h-full md:items-center"
        >
          {({ open }) => (
            <>
              {/*
               * Since we always render both desktop nav and mobile nav and (hide when appropriate using CSS)
               * and both include a ref to the popover, we need to check navType and screen size
               * to ensure we're only showing this menu tutorial popover on mobile nav at mobile screen sizes.
               */}
              {location.pathname.includes('/menu') &&
              screenSize === 'mobile' &&
              isMenuTutorialSkipMultipleOpen ? (
                <MenuTutorialMobileNav
                  isOpen={isMenuTutorialSkipMultipleOpen}
                  user={user}
                />
              ) : (
                <Disclosure.Button aria-label="Menu">
                  <div className="h-6 w-6">
                    <MenuIcon />
                  </div>
                </Disclosure.Button>
              )}

              <Disclosure.Panel static>
                {({ close }) => {
                  return (
                    <motion.div
                      animate={open ? 'open' : 'closed'}
                      className="fixed left-0 right-0 top-0 z-[1000] overflow-hidden"
                      initial="closed"
                      variants={{
                        open: {
                          height: '100vh',
                        },
                        closed: { height: '0px' },
                      }}
                    >
                      <div className="h-full w-full bg-grey-1 px-4">
                        <div className="flex items-center justify-between pt-5">
                          <Brand />
                          <Button
                            aria-label="Close"
                            buttonStyle="link"
                            onClick={() => close()}
                            size="auto"
                          >
                            <div className="h-6 w-6">
                              <XIcon />
                            </div>
                          </Button>
                        </div>
                        <div className="flex h-full w-full items-center justify-center">
                          <ul className="space-y-10 text-center text-k/18_120">
                            <NavLinks
                              closeNav={close}
                              navType="mobile"
                              screenSize={screenSize}
                            />
                          </ul>
                        </div>
                      </div>
                    </motion.div>
                  )
                }}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </nav>
    </HeaderWithLogo>
  )
}

export default Header

export const HeaderWithLogo = ({
  children,
  useReactRouter = true,
}: {
  children?: ReactNode
  useReactRouter?: boolean
}) => {
  return (
    <header className="relative mx-16 flex h-20 justify-between border-b border-grey-3 xl:mx-0 xl:px-4 md:h-16">
      {/*
       * In some scenarios, we can't use a react-router-dom Link component since we may not
       * be rendering these children in the appropriate providers. For example, our UncaughtErrorPage
       * is the top-level of the tree to catch any errors in providers.
       */}
      <div className="relative z-10">
        {useReactRouter ? (
          <Brand />
        ) : (
          <a href="/">
            <Logo />
          </a>
        )}
      </div>

      {children}
    </header>
  )
}

const Brand = () => {
  const { isLoggedIn } = useAuth()
  const homePath = isLoggedIn ? '/my-orders' : '/login'

  return (
    <Link aria-label="Homepage" to={homePath}>
      <Logo />
    </Link>
  )
}

const Logo = () => {
  return (
    <div className="flex h-full items-center">
      <div className="h-4 w-logo text-black">
        <LogoLockUpIcon />
      </div>
    </div>
  )
}

const HeaderNavLink = ({
  children,
  closeNav,
  icon,
  path,
}: {
  children: ReactNode
  closeNav?: () => void
  icon?: ReactNode
  path: string
}) => {
  return (
    <NavLink
      className={({ isActive }) => {
        return clsx('flex items-center justify-center', {
          'text-orange-1': isActive,
        })
      }}
      onClick={closeNav}
      to={path}
    >
      {icon && (
        <div className="mr-3 hidden h-6 w-6 align-middle md:inline">{icon}</div>
      )}
      {children}
    </NavLink>
  )
}

const MenuTutorialAccount = ({
  closeNav,
  isOpen,
  user,
}: {
  closeNav?: () => void
  isOpen: boolean
  user: UserV1 | undefined
}) => {
  const { closeMenuTutorial } = useMenuTutorial({ userID: user?.id })

  return (
    <MenuTutorialPopover
      description="Skip or adjust multiple deliveries."
      heading="Skip or adjust your delivery day and order size for multiple weeks"
      isOpen={isOpen}
      onOpenChange={closeMenuTutorial}
    >
      <NavLink
        className={({ isActive }) => {
          return clsx('flex items-center justify-center', {
            'text-orange-1': isActive,
          })
        }}
        onClick={closeNav}
        to="/account"
      >
        Account
      </NavLink>
    </MenuTutorialPopover>
  )
}

const MenuTutorialMobileNav = ({
  isOpen,
  user,
}: {
  isOpen: boolean
  user: UserV1 | undefined
}) => {
  const { closeMenuTutorial } = useMenuTutorial({ userID: user?.id })

  return (
    <MenuTutorialPopover
      description="Skip or adjust multiple deliveries from the Account page."
      heading="Skip or adjust your delivery day and order size for multiple weeks"
      isOpen={isOpen}
      onOpenChange={closeMenuTutorial}
    >
      <Disclosure.Button aria-label="Menu">
        <div className="h-6 w-6">
          <MenuIcon />
        </div>
      </Disclosure.Button>
    </MenuTutorialPopover>
  )
}

const NavLinks = ({
  closeNav = undefined,
  navType,
  screenSize,
}: {
  closeNav?: () => void
  navType: 'desktop' | 'mobile'
  screenSize: ScreenSize
}) => {
  const location = useLocation()
  const { isLoggedIn, user } = useAuth()

  const { isMenuTutorialSkipMultipleOpen } = useOverlays()

  if (location.pathname.includes('/referral')) {
    return null
  }

  if (isLoggedIn) {
    return (
      <>
        <li>
          <HeaderNavLink
            closeNav={closeNav}
            icon={<CartIcon />}
            path="/my-orders"
          >
            <span className="md:text-black">My Orders</span>
          </HeaderNavLink>
        </li>
        <li>
          {/*
           * Since we always render both desktop nav and mobile nav and (hide when appropriate using CSS)
           * and both include NavLinks with a ref to the popover, we need to check navType and screen size
           * to ensure we're only showing this menu tutorial popover on desktop nav at desktop screen sizes.
           */}
          {location.pathname.includes('/menu') &&
          navType === 'desktop' &&
          screenSize === 'desktop' &&
          isMenuTutorialSkipMultipleOpen ? (
            <MenuTutorialAccount
              closeNav={closeNav}
              isOpen={isMenuTutorialSkipMultipleOpen}
              user={user}
            />
          ) : (
            <HeaderNavLink
              closeNav={closeNav}
              icon={<AccountIcon />}
              path="/account"
            >
              <span className="md:text-black">Account</span>
            </HeaderNavLink>
          )}
        </li>

        <li>
          <a
            className="flex items-center justify-center"
            href="https://support.tovala.com"
            rel="noreferrer"
            target="_blank"
          >
            <div className="mr-3 hidden h-6 w-6 md:inline">
              <OvenIcon />
            </div>
            <span>Support</span>
          </a>
        </li>
        <li>
          <HeaderNavLink
            closeNav={closeNav}
            icon={<GiftIcon />}
            path="/gift-cards/purchase"
          >
            <span className="md:text-black">Gift Cards</span>
          </HeaderNavLink>
        </li>

        <li>
          <GetFreeMeals
            onClickGetFreeMeals={() => {
              if (closeNav) {
                closeNav()
              }
            }}
            user={user}
          />
        </li>
      </>
    )
  }

  return (
    <li>
      <NavLink to="/login">Login</NavLink>
    </li>
  )
}

const GetFreeMeals = ({
  onClickGetFreeMeals,
  user,
}: {
  onClickGetFreeMeals(): void
  user: UserV1 | undefined
}) => {
  const { setIsReferralModalOpen } = useOverlays()

  return (
    <>
      <Button
        disabled={!user}
        onClick={() => {
          setIsReferralModalOpen(true)
          track(events.OPENS_REFERRAL_SCREEN)
          onClickGetFreeMeals()
        }}
        size="medium"
      >
        Get Free Meals
      </Button>
    </>
  )
}
