import {
  useInvalidateUsers,
  SkippedWeeks,
  UserV1Subscription,
  useInvalidateListings,
} from '@tovala/browser-apis-combinedapi'
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

import {
  changeSubscriptionStatus,
  ChangeSubscriptionStatus,
  getSkippedWeeks,
  getUserSubscriptionTypes,
  GetUserSubscriptionTypesResponse,
  skipWeek,
  SkipWeek,
  unskipWeek,
  UnskipWeek,
  updatePlanPreferences,
  UpdatePlanPreferences,
} from 'services/combinedAPI/subscriptions'
import { events } from 'analytics/events'
import { isCombinedAPIResponseError } from 'utils/api'
import { minutesToMs } from 'utils/general'
import { track } from 'utils/analytics'

import { useInvalidateTermStatuses } from './termStatus'

export function useChangeSubscriptionStatus(
  opts?: Omit<
    UseMutationOptions<
      unknown,
      Error,
      ChangeSubscriptionStatus & { reactivationDate?: string }
    >,
    'mutationFn'
  >
) {
  const { invalidateUserTermStatuses } = useInvalidateTermStatuses()
  const { invalidateUserV0, invalidateUserV1 } = useInvalidateUsers()
  const { invalidateAllListingSelections } = useInvalidateListings()

  return useMutation({
    ...opts,
    mutationFn: ({ data, subscriptionStatus, userID }) => {
      return changeSubscriptionStatus({ data, subscriptionStatus, userID })
    },
    onError: (...args) => {
      const err = args[0]
      const { userID } = args[1]

      if (
        isCombinedAPIResponseError(err) &&
        err.response?.data.message ===
          'UnableToUpdateSubscriptionToExistingState'
      ) {
        invalidateUserV0(userID)
        invalidateUserV1(userID)
      }

      opts?.onError?.(...args)
    },
    onSuccess: (...args) => {
      const { subscriptionStatus, userID } = args[1]

      if (subscriptionStatus === 'pause') {
        track(events.PAUSES_MEAL_PLAN)
      } else if (subscriptionStatus === 'cancel') {
        track(events.CANCELS_MEAL_PLAN)
      }

      invalidateUserV0(userID)
      invalidateUserV1(userID)
      invalidateUserTermStatuses(userID)
      invalidateAllListingSelections(userID)

      opts?.onSuccess?.(...args)
    },
  })
}

export function useSkippedWeeks({
  userID,
  ...rest
}: { userID: number | undefined } & Omit<
  UseQueryOptions<SkippedWeeks, Error>,
  'enabled' | 'queryFn' | 'queryKey' | 'staleTime'
>) {
  return useQuery<SkippedWeeks, Error>({
    ...rest,
    enabled: !!userID,
    queryFn: () => {
      if (!userID) {
        throw new Error('Must provide userID to get skipped weeks for')
      }

      return getSkippedWeeks({ userID })
    },
    queryKey: ['skipped-weeks', userID],
    staleTime: minutesToMs(1),
  })
}

export function useSkipWeek(
  opts?: Omit<UseMutationOptions<SkippedWeeks, Error, SkipWeek>, 'mutationFn'>
) {
  const queryClient = useQueryClient()

  return useMutation({
    ...opts,
    mutationFn: ({ termID, userID }) => {
      return skipWeek({ termID, userID })
    },
    onSuccess: (...args) => {
      const { termID, userID } = args[1]

      track(events.SKIPS_WEEK, {
        term_id: termID,
      })

      queryClient.invalidateQueries(['skipped-weeks', userID])

      opts?.onSuccess?.(...args)
    },
  })
}

export function useUnskipWeek(
  opts?: Omit<UseMutationOptions<SkippedWeeks, Error, UnskipWeek>, 'mutationFn'>
) {
  const queryClient = useQueryClient()

  return useMutation({
    ...opts,
    mutationFn: ({ termID, userID }) => {
      return unskipWeek({ termID, userID })
    },
    onSuccess: (...args) => {
      const { userID } = args[1]

      queryClient.invalidateQueries(['skipped-weeks', userID])

      opts?.onSuccess?.(...args)
    },
  })
}

export function useUpdatePlanPreferences(
  opts?: Omit<
    UseMutationOptions<UserV1Subscription, Error, UpdatePlanPreferences>,
    'mutationFn'
  >
) {
  const { invalidateUserV1 } = useInvalidateUsers()

  return useMutation({
    ...opts,
    mutationFn: ({ data, userID }) => {
      return updatePlanPreferences({ data, userID })
    },
    onError: (...args) => {
      opts?.onError?.(...args)
    },
    onSuccess: (...args) => {
      const { userID } = args[1]

      invalidateUserV1(userID, { newSubscription: args[0] })

      opts?.onSuccess?.(...args)
    },
  })
}

export function useUserSubscriptionTypes({
  userID,
  ...rest
}: { userID: number | undefined } & Omit<
  UseQueryOptions<GetUserSubscriptionTypesResponse, Error>,
  'enabled' | 'queryFn' | 'queryKey' | 'staleTime'
>) {
  return useQuery<GetUserSubscriptionTypesResponse, Error>({
    ...rest,
    enabled: !!userID,
    queryFn: () => {
      if (!userID) {
        throw new Error('No userID provided for user subscription types')
      }

      return getUserSubscriptionTypes({ userID })
    },
    queryKey: ['subscription-types', userID],
    staleTime: minutesToMs(30),
  })
}
