import Cookies, { CookieAttributes } from 'js-cookie'

import { getEnvVar } from './env'

const COOKIES = {
  JWT_TOKEN: getEnvVar('JWT_COOKIE_NAME'),
}

type CookieName = keyof typeof COOKIES

export function getCookie(cookieName: CookieName) {
  return Cookies.get(COOKIES[cookieName])
}

export function getLocalStorageItem<ItemType>(key: string) {
  try {
    const item = localStorage.getItem(key)
    if (item) {
      return JSON.parse(item) as ItemType
    }
  } catch (err) {
    // Pass on error. Code should not depend on local storage operations to be successful.
  }
}

export function removeCookie(
  cookieName: CookieName,
  options?: CookieAttributes
) {
  Cookies.remove(COOKIES[cookieName], options)
}

export function removeLocalStorageItem(localStorageKey: string) {
  try {
    localStorage.removeItem(localStorageKey)
  } catch (err) {
    // Pass on error. Code should not depend on local storage operations to be successful.
  }
}

export function setCookie({
  content,
  cookieName,
  options,
}: {
  content: string
  cookieName: CookieName
  options: CookieAttributes
}) {
  Cookies.set(COOKIES[cookieName], content, options)
}

export function setLocalStorageItem<ItemType>(key: string, item: ItemType) {
  try {
    const itemStr = JSON.stringify(item)

    localStorage.setItem(key, itemStr)
  } catch (err) {
    // Pass on error. Code should not depend on local storage operations to be successful.
  }
}
