import { ButtonLoading } from '@tovala/component-library'
import { useForm } from 'react-hook-form'
import { useUpdateUser, UserV1 } from '@tovala/browser-apis-combinedapi'

import { ErrorCodeMessageMapCombinedAPI } from 'types/internal'
import { wrapWithContactSupportTeam } from 'utils/errors'

import { useToast } from 'contexts/toast'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import FormInputRHF from 'components/common/FormInputRHF'
import { useState } from 'react'
import RevealPassword from '../RevealPassword'

type FormValues = {
  email: string
  name: string
  password: string
  passwordConfirm: string
  passwordOld: string
}

const UPDATE_PASSWORD_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't update your password due to a technical issue on our end.",
  },
  PasswordMismatch: {
    helpToFix: 'Please double check your current password and try again.',
    wayOut: wrapWithContactSupportTeam('If you need further assistance'),
    why: "We couldn't update your password because your current password may be incorrect.",
  },
  PasswordTooShort: {
    helpToFix: 'Please enter at least 8 characters and try again.',
    wayOut: wrapWithContactSupportTeam('If you need further assistance'),
    why: 'The new password you entered is too short.',
  },
}

const UpdatePasswordForm = ({
  closeCollapsible,
  user,
}: {
  closeCollapsible: () => void
  user: UserV1
}) => {
  const { openToast } = useToast()
  const [revealOldPassword, setRevealOldPassword] = useState(false)
  const [revealNewPassword, setRevealNewPassword] = useState(false)
  const [revealConfirmPassword, setRevealConfirmPassword] = useState(false)

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      password: '',
      passwordConfirm: '',
      passwordOld: '',
    },
  })

  const {
    error: updateUserError,
    isError: hasUpdateUserError,
    isLoading: updateUserLoading,
    mutate: updateUserRecord,
  } = useUpdateUser({
    onSuccess: () => {
      openToast({
        heading: 'Password Updated',
        message: 'Your password has been updated.',
        type: 'success',
      })
      reset()
      closeCollapsible()
    },
  })

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit((data) => {
        return updateUserRecord({
          data: { ...data, email: user.info.email, name: user.info.name },
          userID: user.id,
        })
      })}
    >
      <FormInputRHF
        autoComplete="current-password"
        error={errors?.passwordOld?.message}
        id="passwordOld"
        label="Current Password"
        labelFor="passwordOld"
        rightIcon={
          <RevealPassword
            revealPassword={revealOldPassword}
            setRevealPassword={setRevealOldPassword}
          />
        }
        type={revealOldPassword ? 'text' : 'password'}
        {...register('passwordOld', {
          required: 'Please enter your current password',
        })}
      />
      <FormInputRHF
        autoComplete="new-password"
        error={errors?.password?.message}
        id="password"
        label="New Password"
        labelFor="password"
        rightIcon={
          <RevealPassword
            revealPassword={revealNewPassword}
            setRevealPassword={setRevealNewPassword}
          />
        }
        type={revealNewPassword ? 'text' : 'password'}
        {...register('password', {
          required: 'Please provide a new password',
        })}
      />
      <FormInputRHF
        autoComplete="new-password"
        error={errors?.passwordConfirm?.message}
        id="passwordConfirm"
        label="Confirm New Password"
        labelFor="passwordConfirm"
        rightIcon={
          <RevealPassword
            revealPassword={revealConfirmPassword}
            setRevealPassword={setRevealConfirmPassword}
          />
        }
        type={revealConfirmPassword ? 'text' : 'password'}
        {...register('passwordConfirm', {
          required: 'Please confirm your new password',
          validate: (value) =>
            value === getValues().password || 'Passwords do not match',
        })}
      />

      {hasUpdateUserError && (
        <div className="mt-4">
          <APIErrorDisplay
            error={updateUserError}
            errorCodeMessageMap={UPDATE_PASSWORD_ERRORS}
          />
        </div>
      )}

      <ButtonLoading isLoading={updateUserLoading} size="large" type="submit">
        Change Password
      </ButtonLoading>
    </form>
  )
}

export default UpdatePasswordForm
