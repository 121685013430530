import { ReactNode } from 'react'

import { HeaderWithLogo } from 'components/common/Header'

const AuthPage = ({
  children,
  headerContent,
  subHeader,
}: {
  children: ReactNode
  headerContent?: ReactNode
  subHeader?: ReactNode
}) => {
  return (
    <div>
      <HeaderWithLogo>{headerContent}</HeaderWithLogo>
      {subHeader}
      <main className="px-4 py-20 md:py-12">{children}</main>
    </div>
  )
}

export default AuthPage
