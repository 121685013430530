import { HeaderWithLogo } from 'components/common/Header'

const UncaughtErrorPage = () => {
  return (
    <div>
      <HeaderWithLogo useReactRouter={false} />

      <div className="my-20 flex justify-center">
        <div className="w-[666px] max-w-full space-y-10 px-4">
          <div className="flex flex-col items-center space-y-6 text-center">
            <h1 className="text-k/44_110 md:text-k/28_110">
              Cannot display this page
            </h1>

            <p className="text-body-lg text-grey-9">
              We couldn't display the content you were trying to view due to a
              technical issue on our end. Rest assured, we have been alerted to
              the issue and are working on a resolution. Please refresh the page
              to try to view the content again. If the issue keeps happening,
              please{' '}
              <a
                className="text-orange-1 underline"
                href="https://support.tovala.com"
                rel="noreferrer noopener"
                target="_blank"
              >
                contact our support team.
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UncaughtErrorPage
