import { omitBy, round } from 'lodash-es'
import validatorIsURL from 'validator/es/lib/isURL'

export const convertBooleanToString = (value: boolean | null) => {
  return typeof value === 'boolean' ? value.toString() : value
}

export function formatPhone(value: string) {
  const onlyNums = value.replace(/[^\d]/g, '')

  if (onlyNums.length <= 3) {
    return onlyNums
  }

  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
  }

  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`
}

/**
 * Given a total rating (between 0-5), this helper returns an array where each
 * index in the array is a number in the set {0, 0.5, 1} and the sum of the values
 * equals the provided total rating rounded to the nearest 0.5 value.
 */
export function getPercentFilledArray({
  totalRating,
}: {
  totalRating: number
}) {
  if (totalRating < 0 || totalRating > 5) {
    return []
  }

  let currentRating = totalRating
  const percentFilled: number[] = []

  for (let i = 0; i < 5; i++) {
    let filled = 0
    if (currentRating > 0.7) {
      filled = 1
    } else if (currentRating > 0.2) {
      filled = 0.5
    }

    percentFilled.push(filled)

    // Decimal math here gets wonky if you have something
    // like a total rating of 3.7. Subtracting 1 continuously eventually
    // results in 0.70000000002. So we round to keep our one level of
    // decimal precision.
    currentRating = round(currentRating - 1, 1)
  }

  return percentFilled
}

export function isURL(value: string) {
  return validatorIsURL(value)
}

export function minutesToMs(minutes: number) {
  return minutes * 60 * 1000
}

export function removeQueryParamsEmptyKeys(
  obj: Record<string, boolean | string | null | undefined | ''>
) {
  return omitBy(obj, (value) => {
    return value === undefined || value === ''
  })
}
