import { useEffect } from 'react'

import { events } from 'analytics/events'
import { formatCentsToDollars } from 'utils/currency'
import { track } from 'utils/analytics'

import { useUser } from 'contexts/user'
import RedeemGiftCard from './RedeemGiftCard'
import RedeemPromo from './RedeemPromo'
import ProfileHeading from './AccountHeading'

const AccountBalancePage = (): JSX.Element => {
  const { user } = useUser()

  useEffect(() => {
    track(events.OPENS_PROMO_SCREEN)
  }, [])

  return (
    <>
      <div className="hidden md:block">
        <ProfileHeading to="/account">Apply Promo</ProfileHeading>
      </div>
      <div className="w-full max-w-account p-4">
        <h1 className="sr-only md:not-sr-only md:hidden">Apply Promo</h1>

        <div className="mb-6 grid grid-cols-2 gap-4 md:grid-cols-1">
          <div className="rounded-xl bg-grey-3 p-6">
            <h2 className="mb-4 text-k/20_125 font-semibold">
              Tovala Cash - {formatCentsToDollars(user.cashCents)}
            </h2>
            <p className="text-k/16_125">
              Gift Cards, refunds, or referral bonuses
            </p>
          </div>

          <div className="rounded-xl bg-grey-3 p-6">
            <h2 className="mb-4 text-k/20_125 font-semibold">
              My Discounts - {formatCentsToDollars(user.discountCents)}
            </h2>
            <p className="text-k/16_125">Coupons, discounts, and promotions</p>
          </div>
        </div>

        <p className="mb-10 text-k/16_125 md:text-k/14_120">
          Discounts and Tovala Cash are automatically applied towards your next
          order
        </p>

        <div className="space-y-6">
          <h2 className="text-k/28_130 md:text-k/20_110">
            Redeem gift cards & promotions
          </h2>

          <RedeemPromo userID={user.id} />
          <RedeemGiftCard userID={user.id} />
        </div>
      </div>
    </>
  )
}

export default AccountBalancePage
