function MealImage({
  alt = '',
  imageURL,
}: {
  alt?: string
  imageURL: string | undefined
}) {
  if (!imageURL) {
    return <div className="w-full bg-grey-4" />
  }

  return (
    <img
      alt={alt}
      className="h-full w-full object-cover"
      loading="lazy"
      src={formatHttpsURL(imageURL)}
    />
  )
}

export default MealImage

export function SmallMealImage({ imageURL }: { imageURL: string }) {
  return (
    <div className="h-[86px] w-[86px] overflow-hidden rounded-lg">
      {imageURL ? (
        <MealImage imageURL={imageURL} />
      ) : (
        <div className="h-full w-full bg-grey-4" />
      )}
    </div>
  )
}

function formatHttpsURL(url: string) {
  let httpsURL = url

  if (url.charAt(0) === '/') {
    httpsURL = `https:${url}`
  }

  return httpsURL
}
