import { ReactNode } from 'react'

import { events } from '../../analytics/events'
import { track } from 'utils/analytics'

const TrackOrder = ({
  children,
  termID,
  trackingURL,
}: {
  children: ReactNode
  termID: number
  trackingURL: string | null
}) => {
  if (trackingURL) {
    return (
      <a
        aria-label="Track Order"
        href={trackingURL}
        onClick={() => {
          track(events.OPENS_ORDER_TRACKING, {
            term_id: termID,
          })
        }}
        rel="noreferrer"
        target="_blank"
      >
        {children}
      </a>
    )
  }

  return null
}

export default TrackOrder
