import { useCustomer } from '@tovala/browser-apis-combinedapi'
import { useFeatures } from 'contexts/features'
import { useUser } from 'contexts/user'
import {
  useHomeScreenConfig,
  useMobileAppConfiguration,
} from '@tovala/browser-apis-cdn'
import { getEnvVar } from 'utils/env'

type UTMSource = 'account' | 'banner' | 'menu'

const UTM_SOURCE_MAP = {
  account: 'SignUpSettings',
  banner: 'SignUpHomeBannerDynamic',
  menu: 'SignUpMenuStaticHeader',
}

export const useMealsSignUp = ({ utmSource }: { utmSource: UTMSource }) => {
  const { userRegistrationInAppFlow } = useFeatures()
  const { user } = useUser()

  const { data: customer } = useCustomer({
    customerID: user.subscription.customerID,
    userID: user.id,
  })

  const hasShippingAddress = user.shippingAddresses.length > 0
  const hasPaymentSource = !!(customer && customer.sources.data.length > 0)

  const { data: mobileAppConfiguration } = useMobileAppConfiguration()
  const { signupPath } = mobileAppConfiguration || {}

  const { data: homeScreenConfig } = useHomeScreenConfig()
  const signUpPrompt = homeScreenConfig?.signUpPrompt

  const useInAppSignUp = !!(
    (hasShippingAddress && hasPaymentSource) ||
    userRegistrationInAppFlow ||
    !signupPath
  )

  const link = useInAppSignUp
    ? {
        label: 'Sign Up',
        type: 'internal' as const,
        to: '/account',
      }
    : {
        label: signUpPrompt?.buttonText ? signUpPrompt.buttonText : 'Sign Up',
        type: 'external' as const,
        to: `${getEnvVar('BUY_URL')}${signupPath}?utmSource=${
          UTM_SOURCE_MAP[utmSource]
        }`,
      }

  return {
    link,
    signUpPrompt,
  }
}
