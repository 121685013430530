import { ErrorCodeMessageMap } from 'types/internal'
import { getAPIErrorCode, getAPIErrorMessage } from 'utils/api'

import ErrorDisplay, { Props as ErrorDisplayProps } from './ErrorDisplay'

function APIErrorDisplay<ErrorCodes extends string>({
  display,
  error,
  errorCodeMessageMap,
}: {
  display?: ErrorDisplayProps['display']
  error: Error | null
  errorCodeMessageMap: ErrorCodeMessageMap<ErrorCodes>
}) {
  const errorKey = getAPIErrorCode<ErrorCodes>({ error })

  // We'll only log Sentry errors for errors that we're not currently configured
  // to handle. This helps us identify places where we're not showing the best error
  // messaging to our users.
  const captureSentryError =
    errorKey === undefined || errorCodeMessageMap[errorKey] === undefined

  return (
    <ErrorDisplay
      captureSentryError={captureSentryError}
      display={display}
      sentryError={error}
      {...getAPIErrorMessage({
        error,
        errorCodeMessageMap,
      })}
    />
  )
}

export default APIErrorDisplay
