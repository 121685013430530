import { useElements, useStripe } from '@stripe/react-stripe-js'
import { useState } from 'react'

import { GoodErrorMessaging } from 'types/internal'

export function useStripeSubmission({
  action,
  onTokenCreated,
}: {
  action: string
  onTokenCreated(token: string): Promise<unknown>
}) {
  const elements = useElements()
  const stripe = useStripe()

  const [isProcessingSubmit, setIsProcessingSubmit] = useState(false)
  const [submissionError, setSubmissionError] =
    useState<GoodErrorMessaging | null>(null)

  const onSubmit = async () => {
    setSubmissionError(null)

    const goodErrorMessaging = {
      helpToFix: 'Please reload the page and try again.',
      why: `We couldn't ${action} due to a technical issue on our end.`,
    }

    const cardElement = elements?.getElement('card')

    if (!stripe || !cardElement) {
      setSubmissionError(goodErrorMessaging)
      return
    }

    setIsProcessingSubmit(true)

    const { error, token } = await stripe.createToken(cardElement)

    if (error || !token) {
      setSubmissionError(goodErrorMessaging)
    } else {
      try {
        await onTokenCreated(token.id)

        cardElement.clear()
      } catch (err) {
        // Caller is expected to handle any error here.
      }
    }

    setIsProcessingSubmit(false)
  }

  return {
    isProcessingSubmit,
    onSubmit,
    submissionError,
  }
}
