import { CustomizeIcon } from '@tovala/component-library'

const MealWithOptionsTitle = ({
  subtitle,
  title,
}: {
  subtitle?: string
  title: string
}) => {
  return (
    <div className="space-y-2">
      <h3 className="inline border-b-2 border-black text-k/20_125 md:text-k/24_120">
        <span className="mr-3 inline-block w-5 text-orange-1">
          <CustomizeIcon />
        </span>
        {title}
      </h3>

      {subtitle && <p className="text-k/16_125 md:text-k/18_120">{subtitle}</p>}
    </div>
  )
}

export default MealWithOptionsTitle
