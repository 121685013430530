import { Controller, useFormContext } from 'react-hook-form'

import { IssueConfig } from 'types/internal'

import FormGroup from 'components/common/FormGroup'
import Textarea from 'components/common/Textarea'

const Comments = ({
  issue,
  label = 'Comments',
}: {
  issue: IssueConfig
  label?: string
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name="comments"
      render={({
        field: { onChange, onBlur, name, ref, value },
        fieldState: { error },
      }) => {
        const errorMessage = error?.message

        return (
          <div className="space-y-4">
            <label className="block text-k/20_125">{label}</label>

            <FormGroup error={errorMessage}>
              <Textarea
                ref={ref}
                hasError={!!errorMessage}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                rows={4}
                value={value}
              />
            </FormGroup>
          </div>
        )
      }}
      rules={{
        required: issue.commentsRequired ? 'Please provide comments' : false,
      }}
    />
  )
}

export default Comments
