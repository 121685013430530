import { isArray } from 'lodash-es'

import { ErrorCodeMessageMapCombinedAPI } from 'types/internal'
import { events } from 'analytics/events'
import { storageAvailable } from 'utils/storageAvailable'
import { track } from 'utils/analytics'

import { useSendMenuFeedback } from 'hooks/combinedAPI/tools'
import { useToast } from 'contexts/toast'
import { useUser } from 'contexts/user'
import APIErrorDisplay from 'components/common/APIErrorDisplay'

const SEND_FEEDBACK_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't save your feedback due to a technical issue on our end.",
  },
}

const MenuFeedback = ({
  onMenuFeedbackSaved,
  tableID,
  termID,
  ...rest
}: {
  onMenuFeedbackSaved(): void
  options: { label: string; value: number }[]
  subtitle?: string
  tableID: string
  termID: number
  title: string
}) => {
  const { openToast } = useToast()

  const { user } = useUser()

  const {
    error: sendMenuFeedbackError,
    isError: hasSendMenuFeedbackError,
    mutate: sendMenuFeedback,
  } = useSendMenuFeedback({
    onSuccess: () => {
      if (storageAvailable('localStorage')) {
        let menuFeedback = localStorage.getItem('menuFeedback')

        menuFeedback = menuFeedback ? JSON.parse(menuFeedback) : []

        if (isArray(menuFeedback)) {
          menuFeedback.push(termID)

          localStorage.setItem('menuFeedback', JSON.stringify(menuFeedback))
        }
      }

      onMenuFeedbackSaved()

      openToast({
        heading: 'Feedback Saved',
        message: 'Thanks for your feedback!',
        type: 'success',
      })
    },
  })

  return (
    <MenuFeedbackDisplay
      {...rest}
      onClickRating={(rating) => {
        track(events.RATES_MENU, {
          rating,
          term_id: termID,
        })

        sendMenuFeedback({
          data: {
            records: [
              {
                fields: {
                  createdAt: new Date().toISOString(),
                  rating,
                  source: 'web' as const,
                  termID,
                  userID: user.id,
                },
              },
            ],
          },
          tableID,
        })
      }}
      saveError={hasSendMenuFeedbackError ? sendMenuFeedbackError : null}
    />
  )
}

export default MenuFeedback

export const MenuFeedbackDisplay = ({
  onClickRating,
  options,
  saveError,
  subtitle,
  title,
}: {
  onClickRating(rating: number): void
  options: { label: string; value: number }[]
  saveError: Error | null
  subtitle?: string
  title: string
}) => {
  return (
    <div className="space-y-4">
      <div className="text-center">
        <p className="mb-3 text-k/20_125 md:text-k/16_125">{title}</p>
        {subtitle && (
          <p className="mb-6 text-k/16_125 text-grey-8 md:text-k/14_120">
            {subtitle}
          </p>
        )}
        <div className="cursor-pointer space-x-4 text-3xl">
          {options.map(({ label, value }, i) => {
            return (
              <span
                key={i}
                className="grayscale transition-all hover:grayscale-0"
                onClick={() => {
                  onClickRating(value)
                }}
              >
                {label}
              </span>
            )
          })}
        </div>
      </div>

      {saveError && (
        <div className="mx-auto max-w-menu md:max-w-full">
          <APIErrorDisplay
            error={saveError}
            errorCodeMessageMap={SEND_FEEDBACK_ERRORS}
          />
        </div>
      )}
    </div>
  )
}
