import { clsx } from 'clsx'

import {
  DATE_FORMATS,
  formatDate,
  getHumanReadableDurationFromNow,
  parseToDate,
} from 'utils/dates'
import { UserTerm } from 'types/internal'

import { useIsNoMenuTerm } from 'hooks/combinedAPI/meals'
import { useUserNextAvailableTermID } from 'hooks/combinedAPI/terms'
import { ReactNode } from 'react'
import StickyHeader from './StickyHeader'
import { getTotalCreditsCents } from 'utils/user'
import { useUser } from 'contexts/user'
import CreditsBanner from './CreditsBanner'

const MenuHeader = ({
  backButton,
  filters,
  selectedUserTerm,
}: {
  backButton: ReactNode
  filters?: ReactNode
  selectedUserTerm: UserTerm
}) => {
  const { user } = useUser()

  const { isNoMenuTerm } = useIsNoMenuTerm({ term: selectedUserTerm })

  const { data: nextTermID } = useUserNextAvailableTermID()

  const isPresentTerm = selectedUserTerm.termID === nextTermID

  const deliveryDate = selectedUserTerm.selectedSubTerm
    ? formatDate(selectedUserTerm.selectedSubTerm.deliveryDate, {
        format: DATE_FORMATS.DOW_MONTH_DAY,
      })
    : undefined
  const deliveryText = selectedUserTerm.isSkipped ? 'Skipped' : 'Arrives'
  const timeLeft = selectedUserTerm
    ? getHumanReadableDurationFromNow(
        parseToDate(selectedUserTerm.orderByDate),
        { moreFriendly: true }
      )
    : undefined
  const description = selectedUserTerm.isSkipped
    ? 'left to unskip.'
    : 'left to edit this order'

  const userTotalCreditsCents = getTotalCreditsCents({ user })

  return (
    <StickyHeader>
      {({ isHeaderSticky }) => {
        return (
          <div className="mx-auto max-w-menu">
            <div
              className={clsx('lg:px-4 md:border-t md:border-grey-3 md:py-4', {
                'py-6': isHeaderSticky,
                'py-10': !isHeaderSticky,
              })}
            >
              <div className="grid grid-cols-[150px_1fr_150px] items-center justify-between md:grid-cols-[40px_1fr_40px]">
                <div className="shrink-0">{backButton}</div>

                <div>
                  <h1
                    className={clsx('text-center md:text-k/28_130', {
                      'text-k/36_110': isHeaderSticky,
                      'text-k/52_110': !isHeaderSticky,
                    })}
                  >
                    {!isNoMenuTerm && <span>{deliveryText} </span>}
                    {deliveryDate}
                  </h1>
                  {!isHeaderSticky && isPresentTerm && timeLeft && (
                    <div className="mt-2 text-center text-k/16_125 text-grey-9 md:hidden">
                      {timeLeft} {description}
                    </div>
                  )}
                </div>

                <div className="shrink-0 justify-self-end">{filters}</div>
              </div>
            </div>

            {userTotalCreditsCents > 0 ? (
              <CreditsBanner totalCreditsCents={userTotalCreditsCents} />
            ) : null}
          </div>
        )
      }}
    </StickyHeader>
  )
}

export default MenuHeader
