import axios, { AxiosRequestConfig } from 'axios'

import { getCookie } from 'utils/storage'
import { getEnvVar } from 'utils/env'

export type APIVersion = 'v0' | 'v1'

export function getAPIUrl({
  path,
  version,
}: {
  path: string
  version: APIVersion
}) {
  return `${getEnvVar('COMBINED_API_URL')}/${version}${path}`
}

function getDefaultHeaders({
  isPublic = false,
}: {
  isPublic?: boolean
}): Record<string, string> {
  const headers: Record<string, string> = {
    // This AppID is used is back-end logs so we can identify the application that is making a request.
    'X-Tovala-AppID': 'MyTovala',
  }

  const jwt = getCookie('JWT_TOKEN')
  if (!isPublic && jwt) {
    headers.Authorization = `Bearer ${jwt}`
  }

  return headers
}

export async function apiDelete<ResponseType>({
  config = {},
  data,
  isPublic = false,
  path,
  version,
}: {
  config?: AxiosRequestConfig<ResponseType>
  data?: unknown
  isPublic?: boolean
  path: string
  version: APIVersion
}) {
  const { headers, ...rest } = config

  const response = await axios.delete<ResponseType>(
    getAPIUrl({ path, version }),
    {
      data,
      headers: { ...getDefaultHeaders({ isPublic }), ...headers },
      ...rest,
    }
  )

  return response.data
}

export async function get<ResponseType>({
  config = {},
  isPublic = false,
  path,
  version,
}: {
  config?: AxiosRequestConfig<ResponseType>
  isPublic?: boolean
  path: string
  version: APIVersion
}) {
  const { headers, ...rest } = config

  const response = await axios.get<ResponseType>(getAPIUrl({ path, version }), {
    headers: { ...getDefaultHeaders({ isPublic }), ...headers },
    ...rest,
  })

  return response.data
}

export async function patch<ResponseType>({
  config = {},
  data,
  isPublic = false,
  path,
  version,
}: {
  config?: AxiosRequestConfig<ResponseType>
  data: unknown
  isPublic?: boolean
  path: string
  version: APIVersion
}) {
  const { headers, ...rest } = config

  const response = await axios.patch<ResponseType>(
    getAPIUrl({ path, version }),
    data,
    {
      headers: { ...getDefaultHeaders({ isPublic }), ...headers },
      ...rest,
    }
  )

  return response.data
}

export async function post<ResponseType>({
  config = {},
  data = undefined,
  isPublic = false,
  path,
  version,
}: {
  config?: AxiosRequestConfig<ResponseType>
  data?: unknown
  isPublic?: boolean
  path: string
  version: APIVersion
}) {
  const { headers, ...rest } = config

  const response = await axios.post<ResponseType>(
    getAPIUrl({ path, version }),
    data,
    {
      headers: { ...getDefaultHeaders({ isPublic }), ...headers },
      ...rest,
    }
  )

  return response.data
}

export async function put<ResponseType>({
  config = {},
  data,
  isPublic = false,
  path,
  version,
}: {
  config?: AxiosRequestConfig<ResponseType>
  data: unknown
  isPublic?: boolean
  path: string
  version: APIVersion
}) {
  const { headers, ...rest } = config

  const response = await axios.put<ResponseType>(
    getAPIUrl({ path, version }),
    data,
    {
      headers: { ...getDefaultHeaders({ isPublic }), ...headers },
      ...rest,
    }
  )

  return response.data
}
