import { TermStatus } from '@tovala/browser-apis-combinedapi'

import { get, post, put } from './baseAPI'

export interface CreateUserTermStatus {
  data: TermStatusUpdateData
  userID: number
}

export interface EditUserTermStatus {
  data: TermStatusUpdateData
  termStatusID: string
  userID: number
}

export interface GetUserTermStatuses {
  userID: number
}

export type GetUserTermStatusesResponse = TermStatus[]

interface TermStatusUpdateData {
  notes: string
  subscriptionTypeID: string
  subTermID: string
  termID: number
  userID: number
}

export const ENDPOINTS = {
  CREATE_USER_TERM_STATUS: ({
    userID,
  }: Pick<CreateUserTermStatus, 'userID'>) => ({
    path: `/users/${userID}/termStatus`,
    version: 'v1' as const,
  }),
  EDIT_USER_TERM_STATUS: ({
    termStatusID,
    userID,
  }: Pick<EditUserTermStatus, 'termStatusID' | 'userID'>) => ({
    path: `/users/${userID}/termStatus/${termStatusID}`,
    version: 'v1' as const,
  }),
  GET_USER_TERM_STATUSES: ({ userID }: GetUserTermStatuses) => ({
    path: `/users/${userID}/termStatuses`,
    version: `v1` as const,
  }),
}

export async function createUserTermStatus({
  data,
  userID,
}: CreateUserTermStatus): Promise<TermStatus> {
  return post({
    data,
    ...ENDPOINTS.CREATE_USER_TERM_STATUS({ userID }),
  })
}

export async function editUserTermStatus({
  data,
  termStatusID,
  userID,
}: EditUserTermStatus): Promise<TermStatus> {
  return put({
    data,
    ...ENDPOINTS.EDIT_USER_TERM_STATUS({ termStatusID, userID }),
  })
}

export async function getUserTermStatuses({
  userID,
}: GetUserTermStatuses): Promise<GetUserTermStatusesResponse> {
  return get(ENDPOINTS.GET_USER_TERM_STATUSES({ userID }))
}
