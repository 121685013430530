import { useEffect } from 'react'

import { events } from 'analytics/events'
import { track } from 'utils/analytics'

import { useAuth } from 'contexts/auth'

const Logout = () => {
  const { onJWTChanged } = useAuth()

  useEffect(() => {
    track(events.USER_LOGGED_OUT)

    onJWTChanged(null)
  }, [onJWTChanged])

  return null
}

export default Logout
