import {
  AddIcon,
  ButtonRound,
  SubtractIcon,
  TrashIcon,
} from '@tovala/component-library'
import { clsx } from 'clsx'
import { MouseEventHandler } from 'react'

type Size = 'medium' | 'small'

const QuantityStepper = ({
  disabledDecrement = false,
  disabledIncrement = false,
  labelDecrement = 'Remove',
  labelIncrement = 'Add',
  max = Infinity,
  min = 0,
  onClickDecrement,
  onClickIncrement,
  quantity,
  size,
}: {
  disabledDecrement?: boolean
  disabledIncrement?: boolean
  labelDecrement?: string
  labelIncrement?: string
  max?: number
  min?: number
  onClickDecrement: MouseEventHandler<HTMLButtonElement>
  onClickIncrement: MouseEventHandler<HTMLButtonElement>
  quantity: number
  size: Size
}) => {
  const decrementDisabled = disabledDecrement || quantity <= min
  const incrementDisabled = disabledIncrement || quantity >= max
  const nextDecrementIsTrash = quantity === min + 1 && min <= 0

  if (quantity <= 0) {
    return (
      <ButtonRound
        buttonSize={size}
        buttonStyle="orange"
        disabled={incrementDisabled}
        icon={<AddIcon />}
        iconSize="small"
        label="Add Meal"
        onClick={onClickIncrement}
      />
    )
  }

  return (
    <div
      className={clsx('flex items-center rounded-full bg-white', {
        'h-12 px-4 text-k/18_120': size === 'medium',
        'h-10 px-3 text-k/16_125': size === 'small',
      })}
    >
      <button
        aria-label={labelDecrement}
        className="disabled:text-grey-7"
        disabled={decrementDisabled}
        onClick={onClickDecrement}
      >
        {nextDecrementIsTrash ? (
          <div className="h-4 w-4">
            <TrashIcon />
          </div>
        ) : (
          <div className="h-4 w-4">
            <SubtractIcon />
          </div>
        )}
      </button>
      <div
        className={clsx('text-center', {
          'w-12': size === 'medium',
          'w-10': size === 'small',
        })}
      >
        {quantity}
      </div>
      <button
        aria-label={labelIncrement}
        className="disabled:text-grey-7"
        disabled={incrementDisabled}
        onClick={onClickIncrement}
      >
        <div className="h-4 w-4">
          <AddIcon />
        </div>
      </button>
    </div>
  )
}

export default QuantityStepper
