import { MouseEventHandler, ReactNode } from 'react'

import { MealTagCollapsible } from 'types/internal'

import MealImage from './MealImage'
import MealImageSoldOut from './MealImageSoldOut'
import MealImageTag from './MealImageTag'
import MealTagsScrolling from './MealTagsScrolling'

export const MEAL_IMAGE_CONTAINER_CLASSES =
  'h-menu-meal-image md:aspect-5/4 md:h-auto'

const Meal = ({
  image,
  imageTag,
  isSoldOut,
  onClickMeal,
  stepper,
  subtitle,
  surcharge = '',
  tags,
  title,
}: {
  image: { url: string }
  imageTag?: { icon?: string; title: string }
  isSoldOut: boolean
  onClickMeal: MouseEventHandler<HTMLDivElement>
  stepper?: ReactNode
  subtitle: string
  surcharge?: string | null
  tags: MealTagCollapsible[]
  title: string
}) => {
  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <div className={`relative ${MEAL_IMAGE_CONTAINER_CLASSES}`}>
          <div
            className="h-full w-full cursor-pointer overflow-hidden rounded-lg"
            onClick={onClickMeal}
          >
            <MealImage
              cover={isSoldOut ? <MealImageSoldOut textSize="large" /> : null}
              image={image}
              tag={
                imageTag ? (
                  <MealImageTag icon={imageTag.icon}>
                    {imageTag.title}
                  </MealImageTag>
                ) : undefined
              }
            />
          </div>

          {stepper && (
            <div className="absolute bottom-4 right-4">{stepper}</div>
          )}
        </div>
        <div className="space-y-2">
          {surcharge && <p className="text-k/14_120">{surcharge}</p>}

          <div className="cursor-pointer space-y-2" onClick={onClickMeal}>
            <p className="text-k/20_125 md:text-k/24_120">{title}</p>
            <p className="text-k/16_125 md:text-k/18_120">{subtitle}</p>
          </div>
        </div>
      </div>

      {tags.length > 0 && <MealTagsScrolling tags={tags} />}
    </div>
  )
}

export default Meal
