import { Button, ButtonLoading } from '@tovala/component-library'
import { MealTag } from '@tovala/browser-apis-combinedapi'

import { ErrorCodeMessageMapCombinedAPI } from 'types/internal'
import { getSubscriptionPreferences } from 'utils/subscriptions'
import { showChatWindow } from 'utils/zendesk'

import { useUpdatePlanPreferences } from 'hooks/combinedAPI/subscriptions'
import { useUser } from 'contexts/user'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import ConfirmationDialog, {
  ConfirmationBody,
  ConfirmationButtons,
  ConfirmationHeader,
} from '../../common/ConfirmationDialog'

const UPDATE_PREFERENCES_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't update your account to indicate you have a Tovala Sheet Tray due to a technical issue on our end.",
  },
}

const SheetTrayConfirmation = ({
  oilWarning,
  onClose,
  onConfirm,
}: {
  oilWarning: MealTag | null | undefined
  onClose(): void
  onConfirm(): void
}) => {
  const { user } = useUser()

  const planPreferences = getSubscriptionPreferences({ user })

  const {
    error: updatePlanPreferencesError,
    isError: hasUpdatePlanPreferencesError,
    isLoading: isUpdatingPlanPreferences,
    mutate: updatePlanPreferences,
  } = useUpdatePlanPreferences({
    onSuccess: () => {
      onConfirm()
    },
  })

  const handleSubmit = () => {
    if (!planPreferences) {
      return
    }

    updatePlanPreferences({
      data: { ...planPreferences, hasBlackSheetTray: true },
      userID: user.id,
    })
  }

  const handleReplacement = () => {
    onClose()
    showChatWindow()
  }

  return (
    <ConfirmationDialog onRequestClose={onClose}>
      <ConfirmationHeader
        heading="Requires Your Tovala Sheet Tray"
        onClickClose={onClose}
      />
      <ConfirmationBody>
        <div className="py-6">
          <img
            alt="Tovala sheet tray"
            className="mx-auto max-w-xs"
            src="https://cdn.tovala.com/tovala.com/oven/black_sheet_tray.jpg"
          />

          <p className="mx-auto my-4 max-w-sm text-body-lg">
            This meal cooks on the sheet tray that was included with your Tovala
            Oven. (We recommend covering it with aluminum foil.)
          </p>

          {oilWarning && (
            <p className="mx-auto mb-4 max-w-sm text-body-lg">
              {oilWarning.description}
            </p>
          )}
        </div>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="space-y-4">
          {hasUpdatePlanPreferencesError && (
            <APIErrorDisplay
              error={updatePlanPreferencesError}
              errorCodeMessageMap={UPDATE_PREFERENCES_ERRORS}
            />
          )}

          <div className="grid grid-cols-2 gap-2">
            <Button
              buttonStyle="stroke"
              onClick={handleReplacement}
              size="large"
            >
              I need a replacement
            </Button>

            <ButtonLoading
              isLoading={isUpdatingPlanPreferences}
              onClick={handleSubmit}
              size="large"
            >
              Yes, I have one
            </ButtonLoading>
          </div>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}

export default SheetTrayConfirmation
