import { round } from 'lodash-es'

export function formatCentsToDollars(
  value: number,
  opts: {
    digits: number
  } = {
    digits: 2,
  }
) {
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: opts.digits,
    maximumFractionDigits: opts.digits,
  }).format(round(value / 100, 2))
}
