import jwtDecode from 'jwt-decode'
import { JWTPayload } from '@tovala/browser-apis-combinedapi'

export function getLoginRedirect({
  queryRedirect,
}: {
  queryRedirect: string | null
}) {
  if (queryRedirect) {
    let type: 'external' | 'internal' = 'internal'

    try {
      new URL(queryRedirect)

      type = 'external'
    } catch (err) {
      // Pass on error. We're just testing if the URL is external or not.
    }

    return { to: queryRedirect, type }
  }

  return { to: '/my-orders', type: 'internal' }
}

export function getUserIdFromJWT(jwt: string) {
  try {
    const decodedUserToken = jwtDecode<JWTPayload>(jwt)

    return decodedUserToken.userId
  } catch (err) {
    return
  }
}
